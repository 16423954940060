<template>
    <div class="referenceDetailPopup">
        <b-modal id="description-edit-popup" centered :title="itemData.popupTitle" no-enforce-focus>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div class="input-group">
                            <div class="col-12">
                                <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" :init="initObj" v-model="itemData.description"></editor>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <button class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right" @click="closeDialog">Cancel</button>
                    <button size="sm" @click="updateValue" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right mr-2">Update</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';
export default {
    components: {
        Editor
    },
    data() {
        return {
            itemData: {
                popupTitle: '',
                description: this.itemValue,
            },
            initObj: {
                height: 250,
                branding: false,
                menubar: false,
                statusbar: false,
                plugins: ['link', 'paste', 'image', 'lists'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    methods: {
        updateValue: function() {
            this.$emit('updateValue', this.itemData.description);
        },
        setDescriptionData(description, title) {
            this.itemData.description = description;
            this.itemData.popupTitle = title;
        },
        closeDialog() {
            this.$bvModal.hide('description-edit-popup');
            this.$emit('closeDialog');
        },
    }
}
</script>