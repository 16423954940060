<template>
    <div>
        <PlanMasterList :ermMasterInfo="ermMasterInfo"></PlanMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import PlanMasterList from '@/components/ermcomponents/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        PlanMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            routePath: '',
            ermMasterInfo: {
                title: "Plan List",
                entity: 'plan_master',
                refEntity: 'plan',
                tooltip: 'New Plan',
                redirectURLName: 'plan',
                newEntityMasterURL: 'plnmas',
                redirectURL: '/plnmas',
                actionName: 'Plan',
                previewURLName: 'plnrep',
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
        if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURLName = 'cliplan';
            this.ermMasterInfo.newEntityMasterURL = 'cliplnmas';
            this.ermMasterInfo.redirectURL = '/client/plnmas';
            this.ermMasterInfo.actionName = 'Plan';
            this.ermMasterInfo.previewURLName = 'cliplnrep';
        } else {
            this.ermMasterInfo.redirectURLName = 'plan';
            this.ermMasterInfo.newEntityMasterURL = 'plnmas';
            this.ermMasterInfo.redirectURL = '/plnmas';
            this.ermMasterInfo.actionName = 'Plan';
            this.ermMasterInfo.previewURLName = 'plnrep';
        }
    },
    methods: {}
}
</script>