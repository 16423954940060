import CalendarEvent from '@/views/communication/CalendarEvent.vue'
import AddCalendarEvent from '@/views/communication/AddCalendarEvent.vue'
import EditCalendarEvent from '@/views/communication/EditCalendarEvent.vue'
import ViewCalendarEvent from '@/views/communication/ViewCalendarEvent.vue'

export default [
    {
        path: '/calevt',
        name: 'calevt',
        component: CalendarEvent,
        meta: { layout: 'ermpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/addcalevt',
        name: 'addcalevt',
        component: AddCalendarEvent,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/editcalevt',
        name: 'editcalevt',
        component: EditCalendarEvent,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/viewcalevt',
        name: 'viewcalevt',
        component: ViewCalendarEvent,
        meta: { layout: 'viewpage', accessedby: 'PRM|TMB' }
    },
];