<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-6">
                            <h2 class="pl-2 mb-0 titel-text">New Company</h2>
                        </div>
                        <div class="col-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.company.companyName.$error }">
                                            <label for="companyName" class="inp">
                                                <input type="text" id="companyName" placeholder=" " v-model="company.companyName" class="mobile-form-control"  :class="{ 'is-invalid': $v.company.companyName.$error }">
                                                <span class="label">Company Name</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                        </div>
                                        <label v-if="$v.company.companyName.$error && !$v.company.companyName.minLength" class="text-danger">Company name must have at least {{$v.company.companyName.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.company.companyName.$error && !$v.company.companyName.required" class="text-danger">Please enter company name</label>
                                        <label v-if="$v.company.companyName.$error && !$v.company.companyName.maxLength" class="text-danger">Company name must not exceed {{$v.company.companyName.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                 <div class="col-12">
                                    <div class="form-group">
                                        <label for="industryId">Industry</label>
                                        <select v-model="company.industryId" class="form-control" id="industryId">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in industries" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="shortName" class="inp">
                                            <input type="text" id="shortName" placeholder=" " v-model="company.shortName" class="mobile-form-control">
                                            <span class="label">Short Name</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="ownerName" class="inp">
                                            <input type="text" id="ownerName" placeholder=" " v-model="company.ownerName" class="mobile-form-control">
                                            <span class="label">Director</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="contactName" class="inp">
                                            <input type="text" id="contactName" placeholder=" " v-model="company.contactName" class="mobile-form-control">
                                            <span class="label">Contact Person</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="email" class="inp">
                                            <input type="text" id="email" placeholder=" " v-model="company.emailId" class="mobile-form-control">
                                            <span class="label">Email</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="mobile" class="inp">
                                            <input type="text" id="mobile" placeholder=" " v-model="company.mobile" class="mobile-form-control">
                                            <span class="label">Mobile</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="phone" class="inp">
                                            <input type="text" id="phone" placeholder=" " v-model="company.phone" class="mobile-form-control">
                                            <span class="label">Phone</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card-body p-0 mt-3 mb-3">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                <label class="d-block p-1 accordion-title" block href="javascript:" v-b-toggle.accordion-1>
                                                    <span>Contact Info</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-1" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                <b-card-body class="p-0">
                                                    <div class="row">
                                                            <div class="col-12 mt-1">
                                                                <div class="form-group">
                                                                    <b-form-input type="text" v-model="company.officePhone" placeholder="Office Phone Number" class="form-control"></b-form-input>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-1">
                                                                <div class="form-group">
                                                                    <b-form-input type="text" v-model="company.alternateEmailId" placeholder="Company Email Address" class="form-control"></b-form-input>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-1">
                                                                <div class="form-group">
                                                                    <b-form-input type="text" v-model="company.website" placeholder="Company Website" class="form-control"></b-form-input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card-body p-0 mt-1 mb-3">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                <label class="d-block p-1 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                    <span>Address</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-2" accordion="my-accordion2" role="tabpanel" class="p-0">
                                                <b-card-body class="p-0">
                                                    <div class="row">
                                                             <div class="col-12 mt-1">
                                                                <div class="form-group">
                                                                    <b-form-input type="text" v-model="company.address1" placeholder="Company Address" class="form-control"></b-form-input>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-1">
                                                                <div class="form-group">
                                                                    <b-form-input type="text" v-model="company.streetName" placeholder="Street Name" class="form-control"></b-form-input>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-1">
                                                                <div class="form-group">
                                                                    <b-form-input type="text" v-model="company.city" placeholder="City" class="form-control"></b-form-input>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-1">
                                                                <div class="form-group">
                                                                    <b-form-input type="text" v-model="company.state" placeholder="State" class="form-control"></b-form-input>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-1">
                                                                <div class="form-group">
                                                                    <select v-model="company.countryId" class="form-control">
                                                                        <option value="">Country</option>
                                                                        <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-12 mt-1">
                                                                <div class="form-group">
                                                                    <b-form-input type="text" v-model="company.pincode" placeholder="Pincode" class="form-control"></b-form-input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:" @click="saveCompany" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</a>
                            <a href="javascript:" @click="clearData" class="btn btn-info mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsMixin } from "@/mixins/requestsMixin";
export default {
    name: 'app',
    mixins: [requestsMixin],
    data() {
        return {
            industries: [],
            companyType: [],
            users: [],
            countries: [],
            company: {
                id: '',
                companyId: '',
                companyName: '',
                industryId: '',
                shortName: '',
                ownerName: '',
                contactName: '',
                phone: '',
                officePhone: '',
                mobile: '',
                emailId: '',
                alternateEmailId: '',
                address1: '',
                streetName: '',
                city: '',
                state: '',
                country: '',
                countryId: '',
                pincode: '',
                website: '',
                createdById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                hasContactInfo: '',
                active: '',
                industry: ''
            }

        }
    },
    mounted() {
        this.lookupData();
    },
    validations: {
        company: {
            companyName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
   methods: {
        // lookupData: function() {
        //     let data = {
        //         projectId: this.company.projectId,
        //         vhextAccountId: this.company.vhextAccountId,
        //         entity: this.company.entity,
        //     }
        //     axios.post(this.$store.getters.getAPIBasePath + '/company/lookup/all', data)
        //         .then((response) => {
        //             let result = response.data;
        //            console.log(result)
        //             this.industries= result.industryLookup;                   
        //             this.companyType = result.customTypeLookup;
        //             this.users = result.userLookup;
        //             this.countries = result.countryLookup;
        //         })

        // },
        async lookupData() {
            try {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId")
                }
                const response = await this.getCompanyLookup(data);
                let result = response.data;
                this.industries= result.industryLookup;                   
                this.companyType = result.customTypeLookup;
                this.users = result.userLookup;
                this.countries = result.countryLookup;
            } catch (error) {
                console.log(error);
            }
        },
        async saveCompany() {
            this.$v.company.$touch();
            if (!this.$v.company.$invalid) {
                try {                    
                    console.log(this.company)
                    const response = await this.addCompany(this.company);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.$router.push('/company');
                    }
                } catch (error) {
                    console.log(error);

                }
            }
        },
        clearData: function() {
            this.company.companyName = '';
            this.company.industryId = '';
            this.company.shortName = '';
            this.company.ownerName = '';
            this.company.contactName = '';
            this.company.emailId = '';
            this.company.mobile = '';
            this.company.phone = '';
            this.company.officePhone = '';
            this.company.alternateEmailId = '';
            this.company.website = '';
            this.company.address1 = '';
            this.company.streetName = '';
            this.company.city = '';
            this.company.state = '';
            this.company.countryId = '';
            this.company.pincode = '';
            this.$v.company.$reset();
        }
    }
}
</script>