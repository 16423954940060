<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.humanResource.name.$error }">
                                        <label for="name" class="inp">
                                            <input type="text" id="name" placeholder=" " v-model="humanResource.name" class="mobile-form-control"  :class="{ 'is-invalid': $v.humanResource.name.$error }">
                                            <span class="label">Name</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                        <label v-if="$v.humanResource.name.$error && !$v.humanResource.name.minLength" class="text-danger">Name must have at least {{$v.humanResource.name.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.humanResource.name.$error && !$v.humanResource.name.required" class="text-danger">Please enter Name</label>
                                        <label v-if="$v.humanResource.name.$error && !$v.humanResource.name.maxLength" class="text-danger">Name must not exceed {{$v.humanResource.name.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                    	<label for="position" class="inp">
                                            <input type="text" id="position" placeholder=" " v-model="humanResource.position" class="mobile-form-control" >
                                            <span class="label">Position</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                    	<label for="position" class="inp">
                                            <input type="text" id="qualification1" placeholder=" " v-model="humanResource.qualification" class="mobile-form-control" >
                                            <span class="label">Qualification</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                    	<div class="required-field-block" :class="{ 'is-invalid': $v.humanResource.emailId.$error }">
	                                    	<label for="email" class="inp">
	                                            <input type="email" id="shortName" placeholder=" " v-model="humanResource.emailId" class="mobile-form-control" :class="{ 'is-invalid': $v.humanResource.emailId.$error }">
	                                            <span class="label">Email</span>
	                                            <span class="focus-bg"></span>
	                                        </label>
	                                    </div>
                                        <div v-if="$v.humanResource.emailId.$error" class="invalid-feedback">
                                            <span v-if="!$v.humanResource.emailId.required" class="text-danger">EmailId is required</span>
                                            <span v-if="!$v.humanResource.emailId.email">Email is not valid</span>
                                        </div>
                                	</div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                    	<label for="mobile" class="inp">
                                            <input type="text" id="mobile" placeholder=" " v-model="humanResource.mobile" class="mobile-form-control" >
                                            <span class="label">Phone</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-6 pr-1">
                                    <div class="form-group">
                                    	<label for="experienceYears" class="inp">
                                            <input type="number" id="experienceYears" placeholder=" " v-model="humanResource.experienceYears" class="mobile-form-control" >
                                            <span class="label">Exp Years</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-6 pl-3">
                                     <div class="form-group">
                                     	<label for="experienceMonths" class="inp">
                                            <input type="number" id="experienceMonths" placeholder=" " v-model="humanResource.experienceMonths" class="mobile-form-control" >
                                            <span class="label">Exp Months</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { EventBus } from '@/main';
export default {
    name: 'app',
    data() {
        return {

            userTypes: [],
            humanResourcesLookup: [],
            clientLookup: [],
            humanResource: {
                name: '',
                userTypeId: '',
                userKeyValue: '',
                member:'',
                position: '',
                qualification: '',
                emailId: '',
                mobile: '',
                humanResourceId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            },

            error: false,

            elements: {
                showDropdownName: false,
            },
        }
    },
    validations: {
        humanResource: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            emailId: {
                required,
                email
            },
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'New Resource';
        this.lookupData();
    },
    created() {
        EventBus.$on("savePage", this.saveHumanResource);
    },
    destroyed() {
        EventBus.$off("savePage", this.saveHumanResource);
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/prmlanding/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                        console.log(result);
                    this.humanResourcesLookup = result.humanResourceLookup;
                    this.clientLookup = result.clientLookup;
                    this.userTypes = result.typeLookup;

                })
        },
        saveHumanResource: function() {
            console.log(this.humanResource);
            this.$v.humanResource.$touch();
            if (!this.$v.humanResource.$invalid) {
                axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/add', this.humanResource)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                name: 'Success',
                                message: result.message,
                                position: 'topRight'
                                
                            });
                            this.clearDataUser();
                            this.$router.push('/landing/resource');

                        }
                    })
                }
        },
        clearDataUser: function() {
            this.humanResource.member = '';
            this.humanResource.name = '';
            this.humanResource.emailId = '';
            this.humanResource.position = '';
            this.humanResource.qualification = '';
            this.humanResource.experienceYears = '';
            this.humanResource.experienceMonths = '';
            this.humanResource.mobile = '';
            this.error = false;
            this.$v.humanResource.$reset();
        },
        getHumanResource: function(id) {
            console.log(id);
            if (id !== undefined && id !== '') {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        this.humanResource.emailId = result.humanResourceView.emailId;
                    })
            }
        },
        getClient: function(id) {
            console.log(id);
            if (id !== undefined || id !== '') {
                axios.get(this.$store.getters.getAPIBasePath + '/client/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        this.humanResource.emailId = result.client.emailId;
                    })
            }
        },
    }
}
</script>