<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-8">
                            <h2 class="pl-2 mb-0 titel-text">Issue</h2>
                        </div>
                        <div class="col-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply" aria-hidden="true"></i> Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pb-1 px-3">
                            <p class="mx-0 mb-2 mt-0">
                                <span class="font-light mr-2">Title</span>
                                <input type="text" id="title" v-model="issue.title" @change="updateIssue" class="view-form w-50  " 
                                 >
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Issue Type</span>
                                <a :title="typeLookupTitle" href="javascript:" v-b-modal.typeModal></a>
                                <select v-model="issue.issueTypeId" @change="updateIssue" class="view-form w-50  ">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in issueTypes" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Functionality</span>
                                <select v-model="issue.functionalityId" @change="updateIssue" class="view-form w-50  ">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in functionalities" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Activity</span>
                                <select v-model="issue.activityId" @change="updateIssue" class="view-form w-50  ">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in issueActivities " :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Description</span>
                                <textarea type="text" v-model="issue.description" @change="updateIssue" class="view-form w-100 mt-0" placeholder="Enter Description" name="description" ></textarea>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Solution</span>
                                <textarea type="text" v-model="issue.solution" @change="updateIssue" class="view-form w-100 mt-0" placeholder="Enter Solution" name="solution" ></textarea>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Action Taken</span>
                                <textarea type="text" v-model="issue.actionTaken" @change="updateIssue" class="view-form w-100 mt-0" placeholder="Enter Action Taken" name="actionTaken" ></textarea>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">solution By</span>
                                <select v-model="issue.solutionById" @change="updateIssue" class="view-form w-50  ">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">soln Date</span>
                                <input type="date" v-model="issue.solutionDt" @change="updateIssue" class="view-form w-50 " placeholder="Enter solution Date" name="solutionDt" >
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Severity</span>
                                <select v-model="issue.severityId" @change="updateIssue" class="view-form w-50  ">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in severities" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Status</span>
                                <select v-model="issue.statusId" @change="updateIssue" class="view-form w-50 ">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in issueStatus" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsProject } from "@/mixins/requestsProject";
export default {
    name: 'app',
    mixins: [requestsProject],
    data() {
        return {
           vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entityRecordMasterId: this.$route.params.ermId,
            entity: 'issue',
            userId: localStorage.getItem("userId"),
            htmlData: '',
            htmlDetailViewTitle: 'Issue Detail',
            typeLookupTitle: 'Issue Type Settings',
            issueStatus: [],
            users: [],
            issueTypes: [],
            severities: [],
            functionalities: [],
            issueActivities: [],
            issue: {},
        }
    },
    mounted() {
        this.issue = JSON.parse(localStorage.getItem("rowData"));
        this.lookupData();
    },
    
    methods: {
        async lookupData() {
            try {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            console.log(data);
                    const response = await this.getIssueLookup(data);
                    let result = response.data;
                    this.functionalities = result.requirementLookup;
                    this.issueActivities = result.activityLookup;
                    this.issueTypes = result.customTypeLookup;
                    this.issueStatus = result.statusLookup;
                    this.severities = result.severityLookup;
                    this.users = result.userLookup;
            }catch (error) {
                console.log(error);
            }

        },
        async updateIssue() {
            try {
                    const response = await this.updateIssues(this.issue);
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                        }
                    console.log(result);
                    localStorage.setItem("rowData", JSON.stringify(this.issue));
                } catch (error) {
                console.log(error);
            }

        },
    }
}
</script>