<template>
    <div>
        <b-input-group>
            <b-input-group-prepend class="custom-input-group-prepend">
                <span class="input-group-text">
                    <i v-bind:class="[ (prependText === 'INR') ? 'fa fa-inr' : 'fa fa-usd']"></i>
                </span>
            </b-input-group-prepend>
            <b-form-input type="text" :value="value" @input="handleInput" :disabled="disabled" :placeholder="placeholderText">
            </b-form-input>
            <b-input-group-append class="custom-input-group-append">
                <span class="input-group-text">
                    .00
                </span>
            </b-input-group-append>
        </b-input-group>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        placeholderText: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: Number
    },
    computed: {
        prependText: function() {
            return localStorage.getItem("currency") == "null" ? 'INR' : localStorage.getItem("currency")
        }
    },
    data() {
        return {}
    },
    validations: {},
    mounted() {},
    methods: {
        handleInput(e) {
            this.$emit('money-input', e)
        }
    }
}
</script>