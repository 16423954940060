<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row px-2">
                <div class="card col-12 col-lg-4 col-xl-4 mb-4 ">
                    <div class="profile_header">
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Title</span>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" v-model="financialTxn.title" class="view-form" placeholder="Title" name="title">
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Txn Date</span>
                                    </div>
                                    <div class="col-7">
                                        <input type="date" v-model="financialTxn.transactionDt" class="view-form" placeholder="Transaction Date" name="financialTxn">
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Account Head</span>
                                    </div>
                                    <div class="col-7">
                                        <select v-model="financialTxn.accountHeadId" class="view-form">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in accountHeads" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Txn Type</span>
                                    </div>
                                    <div class="col-7">
                                        <select v-model="financialTxn.transactionTypeId" class="view-form">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in financialTxnTypes" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Category</span>
                                    </div>
                                    <div class="col-7">
                                        <select v-model="financialTxn.categoryId" class="view-form">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in financialTxnCategories" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Amount</span>
                                    </div>
                                    <div class="col-7">
                                        <input type="number" v-model="financialTxn.amount" class="view-form text-right" placeholder="Set Amount" name="amount">
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Transaction By</span>
                                    </div>
                                    <div class="col-7">
                                        <select v-model="financialTxn.transactionById" class="view-form">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in users" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div  class="mx-0 mt-2 mb-0 float-right">
                                    <span class="font-light fs-small">Updated {{ getTimeDuration(financialTxn.lastUpdatedDt) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsFinance } from "@/mixins/requestsFinance";
import { EventBus } from '@/main';
export default {
    name: 'app',
    mixins: [requestsFinance],
    data() {
        return {
            financialTxn: {},
            financialTxnTypes: [],
            financialTxnCategories: [],
            users: [],
            accountHeads: [],
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entityRecordMasterId: localStorage.getItem("ermId"),
            entity: 'financial_transaction',
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'Edit Financial Txn';
        this.financialTxn = JSON.parse(localStorage.getItem("rowData"));
        this.lookupData();
    },
    created() {
        EventBus.$on("savePage", this.updateFinancialTxnData);
    },
    destroyed() {
        EventBus.$off("savePage", this.updateFinancialTxnData);
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/fintxnboard/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.accountHeads = result.accountHeadLookup;
                    this.financialTxnTypes = result.customTypeLookup;
                    this.financialTxnCategories = result.customCategoryLookup;
                    this.users = result.userLookup;
                    // this.entityERMLookup = result.entityERMLookup;
                })

        },
        async updateFinancialTxnData() {
            try {
                this.financialTxn.isArchive = false
                this.financialTxn.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.financialTxn.projectId = localStorage.getItem("projectId");
                this.$store.state.showAppLoader = true;
                console.log(this.financialTxn);
                const response = await this.updateFinancialTxn(this.financialTxn);
                this.$store.state.showAppLoader = false;
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topCenter'
                    });
                }
                this.financialTxn.lastUpdatedDt = new Date();
                localStorage.setItem("rowData", JSON.stringify(this.financialTxn));
                this.$nextTick(() => {
                    this.$router.push('/viewfinancialtxn')
                })
            } catch(error) {
                this.$store.state.showAppLoader = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
    }
}
</script>