<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12  col-md-6">
                            <div class="float-left">
                                <h1 class="text-dark">Companies</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" show-empty small selectable @row-selected="onRowSelected" :select-mode="selectMode" 
                                    sort-icon-left :items="companies" :fields="companyFields" responsive="sm" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                        <template v-slot:cell(companyName)="data">
                                            <div  class="">
                                                <p>{{ data.item.companyName }} 
                                                    <span v-if="data.item.industry" class="fs-small"> - {{ data.item.industry }}</span>
                                                </p>
                                                <p class="fs-small">{{ data.item.contactName }}</p>
                                            </div>
                                        </template>
                                        <template v-slot:cell(emailId)="data">
                                            <div class="">
                                                <p v-if="data.item.phone"><i class="fa fa-xs fa-mobile text-primary pr-1" aria-hidden="true"></i>{{ data.item.phone }} </p>
                                                <p v-if="data.item.emailId"><i class="fa fa-xs fa-envelope text-primary pr-1" aria-hidden="true"></i>{{ data.item.emailId }} </p>
                                            </div>
                                        </template>
                                        <template  v-slot:empty="scope">
                                            <div class="text-center">No Companies Found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="companies.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" @click="$router.push('/company/add')" size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsMixin } from "@/mixins/requestsMixin";

export default {
    mixins: [requestsMixin],
    data() {
        return {
            companyFields: [
                { key: 'companyName', label: 'Company Name', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Contact', sortable: true, sortDirection: 'desc' },
            ],
            selectMode: 'single',
            companies: [],
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
            totalRows: 1,
        }
    },
    mounted() {
        this.getAllCompanies();
    },
    methods: {
        async getAllCompanies() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            const response = await this.getCompanies(data);
            console.log(response);
            let result = response.data;
            console.log(result);
            if (result.companylist && result.companylist.length != 0) {
                this.companies = result.companylist;
                
                this.totalRows = this.companies.length;
            }
        },
        onRowSelected: function(items) {
             if (items && items.length > 0){
                let data = items[0];
                localStorage.setItem("rowData", JSON.stringify(data));
                this.$nextTick(() => {
                    this.$router.push('/viewcompany/' + data.id);
                });
            }
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>