<template>
    <div class="content_wrapper">
        <div v-if="elements.showMasterList" class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row">
                        <div class="col-8 pl-2 pr-0">
                            <h2 class="pl-2 mb-0 titel-text">{{ermMasterInfo.title}}</h2>
                        </div>
                        <div class="col-4 px-0">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                    </li>
                                    <!-- <li class="breadcrumb-item">
                                        <router-link to="/settings/email" title="Email">
                                            <i class="fa fa-envelope action-link" aria-hidden="true"></i>
                                        </router-link>
                                    </li> -->
                                    <!-- <li class="breadcrumb-item">
                                        <h4 class="pl-2 mb-0 fs-14 text-right">#{{this.projId}} - {{this.projectName}}</h4>
                                    </li> -->
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <section class="chart_section">
                <div class="row mb-5">
                    <!-- <div class="col-sm-12">
                        <div class="float-left">
                            <div class="dataTables_filter">
                                <label>
                                    <input type="search" v-model="filter" class="form-control form-control-sm" placeholder="Search...">
                                </label>
                            </div>
                        </div>
                        <div v-if="!archive" class="float-right pt-2 pr-2">
                            <a class="action-link" :title="ermMasterInfo.tooltip" href="javascript:" @click="getERMHistory()"><i class="fa fa-history"></i> Archive</a>
                        </div>
                        <div v-if="archive" class="float-right pt-2 pr-2">
                            <a class="action-link" :title="ermMasterInfo.tooltip" href="javascript:" @click="getERMList()"><i class="fa fa-history"></i> {{ermMasterInfo.title}}</a>
                        </div>
                        <div v-if="!ermMasterInfo.newEntityMasterURL==''" class="float-right pt-2 pr-4">
                            <router-link class="action-link" :title="ermMasterInfo.tooltip" :to="{ name: ermMasterInfo.newEntityMasterURL}"><i class="fa fa-plus-circle pr-2"></i>{{ermMasterInfo.tooltip}}</router-link>
                        </div>
                        <div class="clearfix"></div>
                    </div> -->
                    <div class="col-12 px-2">
                        <div class="full_chart card mb-4 py-1 px-2">
                            <div class="data-table-rows data-tables-hide-filter">
                                <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" ref="ermTable" show-empty  :busy="isBusy" small selectable :select-mode="selectMode" 
                                    sort-icon-left :items="entityRecordMasters" :fields="fields" responsive="sm" @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)" @row-selected="onRowSelected" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                    <!-- <template #cell(show_details)="row">
                                        <i @click="row.toggleDetails" :class="row.detailsShowing ? 'fa fa-minus-circle' : 'fa fa-plus-circle'"></i>
                                    </template> -->
                                        <template v-slot:cell(title)="data">
                                            <span>{{data.value}} [{{data.item.recCount}}]</span>
                                            <span v-if="data.item.status == 'Draft'" class="badge badge-pill badge-light mb-1">{{ data.item.status }}</span>
                                            <span v-else-if="data.item.status == 'Open'" class="badge badge-pill badge-info mb-1">{{ data.item.status }}</span>
                                            <span v-else-if="data.item.status == 'In Progress'" class="badge badge-pill badge-info mb-1">{{ data.item.status }}</span>
                                            <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-primary mb-1">{{ data.item.status }}</span>
                                            <span v-else-if="data.item.status == 'Reviewed'" class="badge badge-pill badge-warning mb-1">{{ data.item.status }}</span>
                                            <span v-else-if="data.item.status == 'Approved'" class="badge badge-pill badge-success mb-1">{{ data.item.status }}</span>
                                            <span v-else-if="data.item.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{ data.item.status }}</span>
                                            <span v-else-if="data.item.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{ data.item.status }}</span>
                                            <span v-else class="badge badge-pill badge-secondary">{{ data.item.status }}</span>
                                        </template>
                                        <!-- <template #row-details="row">
                                            <b-card class="expandRow mt-2 mb-2 py-1">
                                                <ul class="table-expand">
                                                    <li>
                                                        <router-link class="action-link" title="Preview" :to="{ name: ermMasterInfo.previewURLName, params: { ermId: row.item.id }}"><i class="fa fa-eye" aria-hidden="true"></i> Report</router-link>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:" class="action-link" title="Share" @click="ermShare(row.item)"  v-b-modal.shareModal><i class="fa fa-share-alt pr-1" aria-hidden="true"></i>Share</a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:" class="action-link" title="Review" @click="reviewShare(row.item)" v-b-modal.reviewModal><i class="fa fa-comments-o pr-1" aria-hidden="true"></i>Review</a>
                                                    </li>
                                                    <li>
                                                        <a href="javascript:" class="action-link" title="Review" @click.prevent="ermId=row.item.id" v-b-modal.reviewModal><i class="fa fa-comments-o pr-1" aria-hidden="true"></i>Review</a>
                                                    </li>                                                
                                                    <li>
                                                        <a href="javascript:" class="action-link" title="Approval" @click="approvalShare(row.item)" v-b-modal.approvalModal><i class="fa fa-check pr-1" aria-hidden="true"></i>Approval</a>
                                                        <router-link v-show="false" class="action-link" title="Approval" :to="{ name: ermMasterInfo.previewURLName, params: { ermId: row.item.id }}"><i class="fa fa-check pr-1" aria-hidden="true"></i>Approval</router-link>
                                                    </li>
                                                    <li>
                                                        <div v-if="ermMasterInfo.showAssignment">
                                                            <a href="javascript:" class="action-link" title="Assign" @click.prevent="taskAssignment(row.item)" v-b-modal.ermAssignments><i class="fa fa-comments-o pr-1" aria-hidden="true"></i>Assign</a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <a class="action-link">
                                                        <input type="checkbox" v-model="row.item.isPublic" @click="ermPublicAccess(row.item)"> Public
                                                        </a>
                                                    </li>                                                

                                                    <li>
                                                        <a><i class="fa fa-download" title="Download" aria-hidden="true"></i></a>
                                                    </li>
                                                    <li>
                                                        <a><i class="fa fa-print" title="Print" aria-hidden="true"></i></a>
                                                    </li>
                                                    <li>
                                                        <div v-if="!archive">
                                                            <a><i class="fa fa-archive" title="Archive" @click="archiveERM(row.item.id)" aria-hidden="true"></i></a>
                                                        </div>
                                                        <div v-if="archive">
                                                            <a><i class="fa fa-undo" title="Unarchive" @click="archiveERM(row.item.id)" aria-hidden="true"></i></a>
                                                        </div>
                                                    </li>
                                                    <li><a><i class="fa fa-trash" title="Delete" @click="deleteERM(row.item.id)" aria-hidden="true"></i></a>
                                                    </li>

                                                </ul>
                                            </b-card>
                                        </template> -->
    <!--                                                     <template v-slot:cell(isPublic)="data">
                                                            <b-form-checkbox class="ml-3" v-model="data.value" :disabled=notEnabled>
                                                            </b-form-checkbox>
                                                        </template> -->

                                        <!--                <template v-slot:cell(type)="data">
                                            <div v-show="false">{{ data.item.type }}</div>
                                        </template>
                                        <template v-slot:head(type)="field">
                                            <div v-show="false">{{ field.label }}</div>
                                        </template> -->
                                        <template v-slot:cell(actions)="data">
                                            <router-link v-if="data.item.ermTypeId==null"  title="Add" :to="{ name: ermMasterInfo.redirectURLName, params: { ermId: data.item.id }}" @click="setMasterData(data.item.id,data.item.title)"> {{ermMasterInfo.actionName}}
                                            </router-link>
                                            <a v-if="data.item.ermTypeId!=null" :title="data.item.type" @click="redirectPage(data.item.id,data.item.type)"> {{data.item.type}}</a>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span v-if="data.item.status == 'Draft'" class="badge badge-pill badge-light mb-1">{{data.value}}</span>
                                            <span v-else-if="data.item.status == 'Open'" class="badge badge-pill badge-info mb-1">{{data.value}}</span>
                                            <span v-else-if="data.item.status == 'In Progress'" class="badge badge-pill badge-info mb-1">{{data.value}}</span>
                                            <span v-else-if="data.item.status == 'Completed'" class="badge badge-pill badge-primary mb-1">{{data.value}}</span>
                                            <span v-else-if="data.item.status == 'Reviewed'" class="badge badge-pill badge-warning mb-1">{{data.value}}</span>
                                            <span v-else-if="data.item.status == 'Approved'" class="badge badge-pill badge-success mb-1">{{data.value}}</span>
                                            <span v-else-if="data.item.status == 'Cancelled'" class="badge badge-pill badge-danger mb-1">{{data.value}}</span>
                                            <span v-else-if="data.item.status == 'On Hold'" class="badge badge-pill badge-dark mb-1">{{data.value}}</span>
                                            <span v-else class="badge badge-pill badge-secondary">{{data.value}}</span>
                                        </template>
                                        <template v-slot:empty="scope">
                                            <div class="text-center">No Master Record Found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                        <template v-slot:table-busy>
                                            <div class="text-center text-info my-2">
                                                <b-spinner class="align-middle"></b-spinner>
                                                <strong>Loading...</strong>
                                            </div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="entityRecordMasters.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-4 col-xl-4 px-2">
                        <div class="card mb-4">
                            <div class="card-body p-2">
                                <b-tabs content-class="mt-3" lazy>
                                    <b-tab title="Details">
                                        <div v-if="elements.showDetail">
                                            <div class="row input-group-label">
                                                <div class="col-4">
                                                    <b-input-group prepend="Id" class="text mb-2 ">
                                                        <b-input-group-prepend is-text><b>{{entityRecordMaster.entityRecordMasterId}}</b></b-input-group-prepend>
                                                    </b-input-group>
                                                </div>
                                                <div class="col-6 pl-0">
                                                    <b-input-group prepend="Status" class="mb-2">
                                                        <b-form-input v-if="!elements.editMode" aria-label="First name" disabled v-model="statusText"></b-form-input>
                                                        <b-dropdown v-if="elements.editMode" :text="statusText" class="btn-dd" v-model="entityRecordMaster.statusId">
                                                            <b-dropdown-item v-for="(info, index)  in masterStatus" :key="info.id" :value="info.id" @click="entityRecordMaster.statusId = info.id;statusText = info.value">
                                                                {{info.value}}
                                                            </b-dropdown-item>
                                                        </b-dropdown>
                                                    </b-input-group>
                                                </div>
                                                <div class="col-md-2 col-sm-2 pl-0">
                                                    <div class="more-btns text-center">
                                                        <button v-if="!elements.editMode" href="javascript:" @click="elements.editMode = true" class="sbtn btn btn-xs action-link p-1" title="Edit">
                                                            <i class="fa fa-pencil fa-sm action-link" aria-hidden="true"></i>
                                                        </button>
                                                        <div v-if="elements.editMode">
                                                            <button type="submit" @click="updateERMaster" title="Save" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check"></i></button>
                                                            <button type="submit" @click="cancelEdit" title="Cancel" class="sbtn btn btn-xs p-1 action-link"><i class="fa fa-times" aria-hidden="true"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-4 col-sm-12">
                                                    <label for="title1">Title</label>
                                                    <input :disabled="!elements.editMode" v-model="entityRecordMaster.title" type="text" class="form-control">
                                                </div>
                                            </div>


                                            <div class="row">
                                                <div v-if="ermMasterInfo.showTypeLookup" class="col-6 col-sm-6 p-3">
                                                    <div class="form-group">
                                                        <label for="title">Type</label>
                                                        <select v-if="elements.editMode" v-model="entityRecordMaster.ermTypeId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in entityRecordCustomType" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <input v-if="!elements.editMode" disabled v-model="entityRecordMaster.type" type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div v-if="ermMasterInfo.showTypeLookup" class="col-6 col-sm-6 p-3">
                                                    <div class="form-group">
                                                        <label for="client">Client</label>
                                                        <select v-if="elements.editMode" v-model="entityRecordMaster.clientId" id="clientId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <input v-if="!elements.editMode" disabled v-model="entityRecordMaster.clientName" type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div v-else class="col-6 col-sm-12 p-3">
                                                    <div class="form-group">
                                                        <label for="client">Client</label>
                                                        <select v-if="elements.editMode" v-model="entityRecordMaster.clientId" id="clientId" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <input v-if="!elements.editMode" disabled v-model="entityRecordMaster.clientName" type="text" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="card-body p-0 my-0">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.accordion-1>
                                                                    <label>Description</label>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="accordion-1" accordion="my-accordion1" role="tabpanel">
                                                                <b-card-body class="p-1 pb-0">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <textarea :disabled="!elements.editMode" v-model="entityRecordMaster.description" class="form-control" id="descriptionDetail" rows="4" maxlength="1000" placeholder="Enter Description"></textarea>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="card-body p-0 my-2">
                                                        <b-card no-body>
                                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                                <label class="d-block p-2 my-0 accordion-title" block href="javascript:" v-b-toggle.attachment>
                                                                    <label>Attachment</label>
                                                                </label>
                                                            </b-card-header>
                                                            <b-collapse id="attachment" accordion="my-accordion1" role="tabpanel">
                                                                <b-card-body class="p-1 pb-0">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <div id="Multi-attachment" v-for="(input, index) in addMore">
                                                                                    <div class="attachments mb-2">
                                                                                        <b-form-file ref="fileinput" class="attachments-list" size="sm" v-model="masterAttachments[index]" placeholder="Choose a file.">
                                                                                            <template slot="file-name" slot-scope="{ names }">
                                                                                                <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                                                            </template>
                                                                                        </b-form-file>
                                                                                        <a href="javascript:" title="Save attachment" @click="saveAttachment()" class="float-right  p-2 attachments-remove action-link">
                                                                                            <i class="fa fa-check pr-2"></i>
                                                                                        </a>
                                                                                    </div>
                                                                                    <label v-if="error" class="text-danger">Please choose a file.</label>
                                                                                </div>
                                                                            </div>
                                                                            <div v-show="attachments.length != 0" class="col-12">
                                                                                <div class="form-group">
                                                                                    <div>
                                                                                        <p v-for="(attachment, index) in attachments">
                                                                                            <i class="fa fa-file-image-o pr-2 icon-doc" aria-hidden="true"></i>
                                                                                            <a :href="attachment.attachmentPath" target="_blank">{{attachment.fileName}}</a>
                                                                                            <a href="javascript:" data-toggle="modal" data-target="#deleteAttachment" @click="deleteFile(attachment.id)" title="Delete attachment" class="float-right button-remove p-2 attachments-remove">
                                                                                                <i class="fa fa-trash pr-2 action-link"></i>
                                                                                            </a>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </b-card-body>
                                                            </b-collapse>
                                                        </b-card>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="createdById">Prepared By</label>
                                                        <select v-if="elements.editMode" v-model="entityRecordMaster.createdById" id="createdById" class="form-control">
                                                            <option value="">Select</option>
                                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                        </select>
                                                        <input v-if="!elements.editMode" disabled v-model="entityRecordMaster.createdBy" type="text" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <b-table show-empty small stacked="md" sort-icon-left :items="entityReviewNotApprovalList" :fields="reviewFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                        <template v-slot:empty="">
                                                            <div class="text-center">No Reviews found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="">
                                                            <div class="text-center">{{ }}</div>
                                                        </template>
                                                    </b-table>
                                                </div>
                                                <template v-for="(info, index) in entityReviewApprovalList">
                                                    <div class="col-6" v-if="index==0">
                                                        <div class="form-group">
                                                            <label for="scopeTypeId">Approved By</label>
                                                            <select v-if="elements.editMode" v-model="entityRecordMaster.createdById" class="form-control">
                                                                <option value="">Select</option>
                                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                            </select>
                                                            <input v-if="!elements.editMode" disabled v-model="info.reviewedBy" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="col-6" v-if="index==0">
                                                        <div class="form-group">
                                                            <label for="startDate">Approved Date</label>
                                                            <input :disabled="!elements.editMode" v-model="info.reviewDt" type="date" class="form-control">
                                                        </div>
                                                    </div>
                                                </template>
                                                <div v-if="!elements.editMode" class="col-6">
                                                    <div class="form-group">
                                                        <label>Created Date</label>
                                                        <input type="text" class="form-control" disabled :value="formatDate(entityRecordMaster.createdDt)">
                                                    </div>
                                                </div>
                                                <div v-if="!elements.editMode" class="col-6">
                                                    <div class="form-group">
                                                        <label>Updated Date</label>
                                                        <input type="text" class="form-control" disabled :value="formatDate(entityRecordMaster.lastUpdatedDt)">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>
                                    <b-tab title="Add Reference">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                                                <label class="d-block p-2 mb-2 accordion-title" block href="#" v-b-toggle.accordion-2>
                                                    <span>Reference Search</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-2" role="tabpanel">
                                                <div class="row ">
                                                    <div class="col-sm-12">
                                                        <div class="float-right">
                                                            <div class="dataTables_length">
                                                                <b-form-group label="Per page" label-cols-sm="7" label-align="right" label-size="sm" label-for="perPageSelectRef" class="mb-0">
                                                                    <b-form-select v-model="perPageRef" id="perPageSelectRef" size="sm" :options="pageOptions"></b-form-select>
                                                                </b-form-group>
                                                            </div>
                                                        </div>
                                                        <div class="float-left">
                                                            <div class="dataTables_filter">
                                                                <label>
                                                                    <input type="search" v-model="ReferenceFilter" class="form-control" placeholder="Search Reference">
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                                <div class="mt-2">
                                                    <b-table class="white-header" show-empty small stacked="md" sort-icon-left :items="projectERMList" :fields="ReferenceFields" responsive="sm" :current-page="currentPageRef" :per-page="perPageRef" :filter="ReferenceFilter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFilteredRef">
                                                        <template v-slot:cell(title)="data">
                                                            <span :title="data.value"> {{getShortString(data.value)}}</span>
                                                        </template>
                                                        <template v-slot:cell(entity)="data">
                                                            <span :title="data.value"> {{getShortString(data.value)}}</span>
                                                        </template>
                                                        <template v-slot:cell(actions)="data">
                                                            <a href="javascript:" title="Add Reference" class="text-success" @click="addRef(data.item)"><i class="fa fa-plus-circle pr-2"></i></a>
                                                        </template>


                                                        <template v-slot:empty="">
                                                            <div class="text-center">No Reference found</div>
                                                        </template>
                                                        <template v-slot:emptyfiltered="">
                                                            <div class="text-center">{{ }}</div>
                                                        </template>
                                                    </b-table>
                                                    <b-pagination v-if="projectERMList.length!=0" pills v-model="currentPageRef" :total-rows="totalRowsRef" :per-page="perPageRef" align="fill" size="sm" class="pt-1 mr-1 pull-right"></b-pagination>
                                                </div>
                                            </b-collapse>
                                        </b-card>
                                        <div class="mt-2">
                                            <label class="mb-1">References</label>
                                            <b-table show-empty small stacked="md" sort-icon-left :items="ermReferenceList" :fields="AddReferenceFields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                                                <template v-slot:cell(title)="data">
                                                    <span :title="data.value"> {{getShortString(data.value)}}</span>
                                                </template>
                                                <template v-slot:cell(refEntity)="data">
                                                    <span :title="data.value"> {{getShortString(data.value)}}</span>
                                                </template>
                                                <template v-slot:cell(actions)="data">
                                                    <a href="javascript:" title="Remove Reference" class="text-danger" @click="removeReference(data.item.id)" data-toggle="modal" data-target="#removeReference"><i class="fa fa-minus-circle pr-2"></i></a>
                                                </template>
                                                <template v-slot:empty="">
                                                    <div class="text-center">No References found</div>
                                                </template>
                                                <template v-slot:emptyfiltered="">
                                                    <div class="text-center">{{ }}</div>
                                                </template>
                                            </b-table>
                                        </div>
                                    </b-tab>
                                </b-tabs>
                            </div>
                        </div>
                    </div> -->
                </div>
            </section>
            <b-button class="btn floating-btn btn-info" size="sm">
                <span class="icon-plus">
                    <router-link class="text-white" :title="ermMasterInfo.tooltip" :to="{ name: ermMasterInfo.newEntityMasterURL}">+</router-link></span>
            </b-button>
        </div>
        <Dialog id="deleteAttachment" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete attachment?" />
        <Dialog id="deleteERM" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete?" />
        <Dialog id="ermPublicAccess" :onYes="onYes" :returnParams="deleteParam" title="Access Confirmation" message="Are you sure to give public access?" />
        <Dialog id="archiveERM" :onYes="onYes" :returnParams="deleteParam" title="Archive Confirmation" message="Are you sure to archive?" />
        <!-- <Share ref="ermShareRef" :masterData="masterData" :ermId="ermId" :entity="entity" :sharedUrl="ermMasterInfo.sharedUrlName" />
        <Review ref="reviewShareRef" :masterData="masterData" :ermId="ermId" :entity="entity" :previewURL="ermMasterInfo.previewURLName" />
        <Approval ref="approvalShareRef" :masterData="masterData" :ermId="ermId" :entity="entity" :previewURL="ermMasterInfo.previewURLName" /> -->

        <!-- <AssignmentPopup ref="ermAssignmentsRef" :masterData="masterData" /> -->
        <Dialog id="removeReference" :onYes="onYesRef" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to remove Reference?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
// import Review from '@/components/usercontrols/ReviewControl.vue';
// import Approval from '@/components/usercontrols/ApprovalControl.vue';
// import AssignmentPopup from '@/components/blocks/lookup/AssignmentPopup.vue';
// import Share from '@/components/usercontrols/ShareControl.vue';
export default {
    name: 'app',
    props: {
        ermMasterInfo: Object
    },
    // components: {
    //     Review,
    //     Approval,
    //     AssignmentPopup,
    //     Share
    // },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showMasterList: false,
                showColumn: 'd-none'
            },
            error: false,
            addReference: {
                refErdId: '',
                refEntityId: '',
                title: '',
                refEntity: '',
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            },
            masterData: {
                refErmId: 0,
                title:'',
                refEntityId: 0,
                refErdTitle: '',
                refEntity: '',
                sharedUrl: '',
                previewURL: '',
            },

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            entityRecordMasterId: '',

            archive: false,
            isArchive: false,

            masterAttachments: [],
            attachments: [],
            addMore: [],
            ermId: 0,
            entityId: 0,
            entity: this.ermMasterInfo.entity,
            refEntity: this.ermMasterInfo.refEntity,

            validated: false,
            statusText: '',
            isBusy: true,
            selectMode: 'single',
            entityRecordMaster: [],
            projectERMList: [],
            entityReviewList: [],
            entityReviewApprovalList: [],
            entityReviewNotApprovalList: [],

            clients: [],
            entityRecordCustomType: [],
            masterStatus: [],
            users: [],
            entityRecordMasters: [],
            ermReferenceList: [],
            deleteParam: {
                id: 0
            },
            fields: [
                // { label: '' },
                // { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'clientName', label: 'Client', sortable: true, sortDirection: 'desc', class: '' },
                // { key: 'createdBy', label: 'Prepared By', tdClass: 'table-name-col-size', sortable: true, sortDirection: 'desc', class: '' },
                // { key: 'status', label: 'Status', tdClass: 'table-status-col-size' },
                { key: 'actions', label: 'Actions' }
            ],
            ReferenceFields: [
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'entity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            AddReferenceFields: [
                { key: 'entityRecordId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'refEntity', label: 'Entity', sortable: true, sortDirection: 'desc' },
                { key: 'actions', label: 'Actions' }
            ],
            reviewFields: [
                { key: 'reviewedBy', label: 'Reviewed By', sortable: true, sortDirection: 'desc' },
                { key: 'reviewDt', label: 'Date', formatter: "formatDateTimeAmPm", sortable: true, sortDirection: 'desc' }
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            totalRowsRef: 1,
            currentPageRef: 1,
            perPageRef: 5,
            pageOptions: [5, 10, 15],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            ReferenceFilter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.addMore.push(this.addMore.length + 1);
        this.getEntityRecordMasterList(this.entity);
        this.masterLookupData();
        this.getProjectEntityRecordMasterList();

    },
    methods: {
        getEntityRecordMasterList: function(entity) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: entity,
                isArchive: this.isArchive,
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordMasters = result.entityRecordMasterList;
                    if (this.entityRecordMasters != null && this.entityRecordMasters.length > 0) {
                        this.elements.showDetail = true;
                        this.elements.editMode = false;
                        if (this.ermId == '') {
                            this.ermId = this.entityRecordMasters[0].id;
                            this.entityRecordMasters[0]._showDetails = true;
                            this.getAttachment(this.ermId);
                        } else {
                            this.showDetail(this.entityRecordMaster.id, false);
                        }
                        this.entityRecordMasterCollection(this.ermId);
                    }
                    this.totalRows = this.entityRecordMasters.length;
                    this.isBusy = !this.isBusy;
                    if (this.totalRows == 0) {
                        if (this.archive == false) {
                            this.$router.push(this.ermMasterInfo.redirectURL);
                        }
                    }
                    this.elements.showMasterList = true;
                }).catch(error => {
                    this.isBusy = !this.isBusy;
                    this.elements.showMasterList = true;
                });
        },
        masterLookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
                refEntity: this.ermMasterInfo.refEntity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.clients = result.clientLookup;
                    this.entityRecordCustomType = result.typeLookup;
                    this.masterStatus = result.statusLookup;
                    this.users = result.userLookup;
                })

        },
        entityRecordMasterCollection: function(id) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: id,
                entity: this.ermMasterInfo.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmastercollection', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordMaster = result.entityRecordMaster;
                    this.entityReviewList = result.entityReviewApprovalList;
                    this.ermReferenceList = result.entityRecordMasterReferenceList;
                    this.statusText = this.entityRecordMaster.status;
                    this.entityReviewNotApprovalList = this.filterReviewApproval(false);
                    this.entityReviewApprovalList = this.filterReviewApproval(true);
                })
        },
        getProjectEntityRecordMasterList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/projectentityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    this.projectERMList = result.projectEntityRecordMasterList;
                    if (this.projectERMList != null && this.projectERMList.length > 0)
                        this.totalRowsRef = this.projectERMList.length;
                })
        },
        getERMReferenceList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entityRecordMasterId: this.ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmasterreference/list', data)
                .then((response) => {
                    let result = response.data;
                    this.ermReferenceList = result.entityRecordMasterReferenceList;
                })
        },
        updateERMaster: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/update', this.entityRecordMaster)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.ermId = result.id;
                        this.getEntityRecordMasterList(this.entity);
                        this.elements.editMode = false;
                    }
                })
        },
        archiveERM: function(id) {
            let data = {
                entityRecordMasterId: id,
                isArchive: (this.archive == true ? false : true),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/archive', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEntityRecordMasterList(this.entity);
                    }
                })
        },
        
        ermPublicAccess: function(itemData) {
            let data = {
                entityRecordMasterId: itemData.id,
                isPublic: (itemData.isPublic == true ? false : true),
            }
            var confirmMessage ='';
            if(itemData.isPublic == false)
            {
                confirmMessage = "Are you sure to give public access?"
            }
            else
            {
                confirmMessage = "Are you sure to give private access?"
            }
            if(confirm(confirmMessage)){
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/publicaccess', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Record Updated',
                            message: result.message,
                            position: 'topRight'
                        });
                        // this.getEntityRecordMasterList(this.entity);
                    }
                })
            }
        },

        deleteERM: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getEntityRecordMasterList(this.entity);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },

        cancelEdit: function() {
            this.entityRecordMasterCollection(this.entityRecordMaster.id);
            this.elements.editMode = false;
        },
        onFilteredRef(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRowsRef = filteredItems.length
            this.currentPageRef = 1
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        onRowSelected: function(items) {
            if (items != null && items.length > 0) {
                let data = items. [0];
                this.elements.showDetail = true;
                this.elements.editMode = false;
                this.entityRecordMasterCollection(data.id);
                this.masterLookupData();
                this.getAttachment(data.id);
            }
        },
        addRef: function(data) {
            this.addReference.vhextAccountId = this.vhextAccountId;
            this.addReference.projectId = this.projectId;
            this.addReference.entityId = this.entityRecordMaster.entityId;
            this.addReference.entityRecordId = data.entityRecordMasterId;
            this.addReference.ermId = this.entityRecordMaster.id;
            this.addReference.erdId = '';
            this.addReference.refEntityId = data.entityId;
            this.addReference.refErmId = data.id;
            this.addReference.refErdId = data.refErdId;
            this.addReference.title = data.title;
            this.addReference.entity = this.entity
            this.addReference.addedById = localStorage.getItem("userId");
            this.saveRef(this.entityRecordMaster.entityId);
        },
        saveRef: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/common/reference/add', this.addReference)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Reference Added',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getERMReferenceList();
                    }
                })
        },
        removeReference: function(id) {
            this.deleteParam.id = id;
        },
        deleteFile: function(id) {
            this.deleteParam.id = id;
        },
        onYesRef: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/common/reference/delete/' + this.deleteParam.id)
                .then(response => {
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Reference Removed',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getERMReferenceList();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        filterReviewApproval(value) {
            return this.entityReviewList.filter(function(data, key) {
                if (data.isApproval == value) {
                    return data;
                }
            });
        },
        redirectPage: function(ermId, type) {
            this.routhPath = this.$route.path;

            if (this.routhPath != null && this.routhPath.indexOf('service') == -1) {
                switch (type) {
                    case "Meeting Plan":
                        this.$router.push('/meetingplan/' + ermId);
                        break;
                    case "Reporting Plan":
                        this.$router.push('/reportingplan/' + ermId);
                        break;
                    case "Project Budget":
                        if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                            this.$router.push('/financialplan/' + ermId);
                            break;
                        }
                        this.$router.push('/budgetplan/' + ermId);
                        break;
                    case "Monthly Budget":
                        if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                            this.$router.push('/financialplan/' + ermId);
                            break;
                        }
                        this.$router.push('/monthlybudgetplan/' + ermId);
                        break;
                    case "Project Funding":
                        this.$router.push('/projectfunding/' + ermId);
                        break;
                    case "Financial Transaction":
                        this.$router.push('/financialtxn/' + ermId);
                        break;
                    default:
                        this.$router.push({ name: this.ermMasterInfo.redirectURLName, params: { ermId: ermId } })
                }
            } else {
                switch (type) {
                    case "Meeting Plan":
                        this.$router.push('/service/meetingplan/' + ermId);
                        break;
                    case "Reporting Plan":
                        this.$router.push('/service/reportingplan/' + ermId);
                        break;
                    case "Project Budget":
                        if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                            this.$router.push('/service/financialplan/' + ermId);
                            break;
                        }
                        this.$router.push('/service/budgetplan/' + ermId);
                        break;
                    case "Monthly Budget":
                        if (this.ermMasterInfo.redirectURLName == 'financialplan') {

                            this.$router.push('/service/financialplan/' + ermId);
                            break;
                        }
                        this.$router.push('/service/monthlybudgetplan/' + ermId);
                        break;
                    case "Project Funding":

                        this.$router.push('/service/projectfunding/' + ermId);
                        break;
                    case "Financial Transaction":
                        this.$router.push('/service/financialtxn/' + ermId);
                        break;
                    default:
                        this.$router.push({ name: this.ermMasterInfo.redirectURLName, params: { ermId: ermId } })
                }
            }

        },
        onYes: function(id) {
            axios.delete(this.$store.getters.getAPIBasePath + '/attachment/delete/' + this.deleteParam.id)
                .then(response => {
                    let result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAttachment(this.ermId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        saveAttachment: function() {
            if (this.masterAttachments != null && this.masterAttachments.length > 0) {
                this.error = false;
                let formData = new FormData();
                for (const row of Object.keys(this.masterAttachments)) {
                    formData.append('files', this.masterAttachments[row]);
                }
                formData.append('vhextAccountId', this.vhextAccountId);
                formData.append('attachedById', localStorage.getItem("userId"));
                formData.append('projectId', this.projectId);
                formData.append('refEntity', this.entity);
                formData.append('refRecordId', this.ermId);
                axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        let result = response.data;
                        if (result.status == "OK") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.masterAttachments = [];
                            //this.$refs.fileinput.reset();
                            //this.$refs['fileinput'].reset();
                            this.getAttachment(this.ermId);
                        }
                        console.log('Attachment uploaded');
                    })
                    .catch(error => {
                        console.log('Error Attachment uploaded');
                    });
            } else {
                this.error = true;
                iziToast.info({
                    message: 'Please choose file.',
                    position: 'topRight'
                });
            }
        },
        getAttachment: function(ermId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refEntity: this.entity,
                refRecordId: ermId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachments = result.attachmentList;
                })
        },
        setMasterData: function(ermId, ermTitle) {
            localStorage.setItem('ermId', ermId);
            localStorage.setItem('ermTitle', ermTitle);
            return;
        },
        taskAssignment: function(data) {
            this.masterData.refErmId = data.id;
            this.masterData.refEntityId = data.entityId;
            this.masterData.refEntity = this.entity;
            this.masterData.refErdTitle = data.title;
            this.$refs.ermAssignmentsRef.onRefDataChange();
        },
        ermShare: function(data) {
            this.masterData.refErmId = data.id;
            this.masterData.refEntity = this.entity;
            this.masterData.title = data.title;
            this.masterData.sharedUrl = this.ermMasterInfo.sharedUrlName;
            this.masterData.previewURL = this.ermMasterInfo.previewURL;

            this.$refs.ermShareRef.onRefDataChange();
        },  
        reviewShare: function(data) {
            this.masterData.refErmId = data.id;
            this.masterData.refEntity = this.entity;
            this.masterData.title = data.title;
            this.masterData.sharedUrl = this.ermMasterInfo.sharedUrlName;
            this.masterData.previewURL = this.ermMasterInfo.previewURL;

            this.$refs.reviewShareRef.onRefDataChange();
        },  
        approvalShare: function(data) {
            this.masterData.refErmId = data.id;
            this.masterData.refEntity = this.entity;
            this.masterData.title = data.title;
            this.masterData.sharedUrl = this.ermMasterInfo.sharedUrlName;
            this.masterData.previewURL = this.ermMasterInfo.previewURL;

            this.$refs.approvalShareRef.onRefDataChange();
        },  


        getERMList: function() {
            this.archive = false;
            this.isArchive = false;

            this.getEntityRecordMasterList(this.entity);
        },
        getERMHistory: function() {
            this.archive = true;
            this.isArchive = true;

            this.getEntityRecordMasterList(this.entity);
        },
        showDetail: function(item, isArray) {
            let id = ''
            if (isArray) {
                id = item.id;
            } else id = item;

            this.entityRecordMasters = this.entityRecordMasters.map((data)=>{
                if (data.id !== id) {
                    return { ...data, _showDetails: false }
                } else {
                    return { ...data, _showDetails: true }
                }
            })
            // this.$nextTick(() => {
            //     this.entityRecordMasters.filter((data)=>{
            //         if (data._showDetails) {
            //             let index = this.entityRecordMasters.indexOf(data);
            //             console.log(index);
            //             this.$refs.ermTable.selectRow(index);
            //         }
            //     })
            // })

        },

    }
}
</script>