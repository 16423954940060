<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-6">
                            <h2 class="pl-2 mb-0 titel-text">New Issue</h2>
                        </div>
                        <div class="col-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70 ">
                        <div class="card-body pt-4 pb-4 pl-4 pr-4">
                            <div class="row mb-2">
                                <div class="row mb-2">
                                    <div class="col-12 mt-0">
                                        <div class="form-group">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.issue.title.$error }">
                                                <label for="title" class="inp">
                                                    <input type="text" id="title" placeholder="  " v-model="issue.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.issue.title.$error }" >
                                                    <span class="label">Title</span>
                                                </label>
                                                <label v-if="$v.issue.title.$error && !$v.issue.title.minLength" class="text-danger">Title must have at least {{$v.issue.title.$params.minLength.min}} letters.</label>
                                                <label v-if="$v.issue.title.$error && !$v.issue.title.required" class="text-danger">Please enter Title</label>
                                                <label v-if="$v.issue.title.$error && !$v.issue.title.maxLength" class="text-danger">Title must not exceed {{$v.issue.title.$params.maxLength.max}} letters.</label>
                                            </div>   
                                        </div>
                                    </div>
                                    <div class="col-12" >
                                        <div class="form-group">
                                            <label for="issueTypeId">Issue Type</label>
                                             <a :title="typeLookupTitle" class="setting-icon ml-2" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                            <select v-model="issue.issueTypeId" class="form-control" id="issueTypeId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in issueTypes" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12" >
                                        <div class="form-group">
                                            <label for="functionality">Functionality</label>
                                            <select v-model="issue.functionalityId" class="form-control" id="functionalityId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in functionalities" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12" >
                                        <div class="form-group">
                                            <label for="activityId">Activity</label>
                                            <select v-model="issue.activityId" class="form-control" id="activityId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in issueActivities" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="description">Description</label>
                                            <textarea class="form-control"  v-model="issue.description" placeholder="Enter Description" rows="2">
                                            </textarea>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="solution">Solution</label>
                                            <textarea class="form-control"  v-model="issue.solution"  placeholder="Enter Solution" id="solution" rows="2" maxlength="2000" required>
                                            </textarea>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="actionTaken">Action Taken</label>
                                            <textarea class="form-control"  v-model="issue.actionTaken"  placeholder="Enter Action Taken" id="actionTaken" rows="2" maxlength="2000" required>
                                            </textarea>
                                        </div>
                                    </div>
                                    <!-- <div class="col-12">
                                        <div class="form-group">
                                            <label for="reportedBy">Reported By</label>
                                                <select v-model="issue.reportedById" id="reportedBy" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                </select>
                                        </div>
                                    </div> -->
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="solutionBy">Assigned To</label>
                                                <select v-model="issue.solutionById" id="solutionBy" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                                </select>
                                        </div>
                                    </div>
                                    <!-- <div class="col-12">
                                        <div class="form-group">
                                            <label for="reportedDt">Reported Date</label>
                                            <input type="date" class="form-control" v-model="issue.reportedDt" id="reportedDt">
                                        </div>
                                    </div> -->
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="solutionDt">Solution Date</label>
                                            <input type="date" class="form-control" v-model="issue.solutionDt" id="solutionDt">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="severityId">Severity</label>
                                            <select v-model="issue.severityId" class="form-control" id="severityId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in severities" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="statusId">Status</label>
                                            <select v-model="issue.statusId" class="form-control" id="statusId">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in issueStatus" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:" @click="saveIssue" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</a>
                            <a href="javascript:" @click="clearData" class="btn btn-info mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsProject } from "@/mixins/requestsProject";
export default {
    mixins: [requestsProject],
    name: 'app',

    data() {
        return {
            elements: {
                alreadyExists: false
            },

            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            entityRecordMasterId: this.$route.params.ermId,
            entity: 'issue',
            userId: localStorage.getItem("userId"),
            htmlData: '',
            htmlDetailViewTitle: 'Issue Detail',
            typeLookupTitle: 'Issue Type Settings',

            elements: {
                editMode: false,
                visible: false,
                viewMode: false,
            },
            issueStatus: [],
            users: [],
            issueTypes: [],
            severities: [],
            functionalities: [],
            issueActivities: [],
            issue: {
                id: '',
                issueId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                entity: 'issue',
                entityId: '',
                entityRefId: '',
                title: '',
                issueTypeId: '',
                type: '',
                functionalityId: '',
                functionality: '',
                activityId: '',
                activity: '',
                description: '',
                solution: '',
                reportedById: localStorage.getItem("userId"),
                reportedBy: '',
                reportedDt: new Date().toISOString().substr(0, 10),
                solutionById: '',
                solutionBy: '',
                solutionDt: '',
                actionTaken: '',
                severityId: '',
                severity: '',
                statusId: '',
                status: '',
                hasReference: '',
                hasAttachment: '',
                hasRefLink: '',
                hasComment: '',
                hasReview: '',
                solutionDt: ''
            },
        }

    },
    mounted() {
        this.$store.state.pageTitle = 'New Issue';
        this.lookupData();
    },
    validations: {
        issue: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        async lookupData() {
            try {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            console.log(data);
                    const response = await this.getIssueLookup(data);
                    let result = response.data;
                    this.functionalities = result.requirementLookup;
                    this.issueActivities = result.activityLookup;
                    this.issueTypes = result.customTypeLookup;
                    this.issueStatus = result.statusLookup;
                    this.severities = result.severityLookup;
                    this.users = result.userLookup;
            }catch (error) {
                console.log(error);
            }

        },
        async saveIssue() {
            this.$v.issue.$touch();
            console.log(this.issue);
            if (!this.$v.issue.$invalid) {
                try {
                    const response = await this.addIssue(this.issue);
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.clearData();
                            this.$router.push('/issue/' + this.entityRecordMasterId );
                        }
                    }catch (error) {
                        console.log(error);
                    }
            }
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.issue.id = '';
            this.issue.issueId = '';
            this.issue.title = '';
            this.issue.issueTypeId = '';
            this.issue.type = '';
            this.issue.activityId = '';
            this.issue.activity = '';
            this.issue.functionalityId = '';
            this.issue.functionality = '';
            this.issue.description = '';
            this.issue.solution = '';
            this.issue.actionTaken = '';
            this.issue.reportedById = localStorage.getItem("userId");
            this.issue.reportedBy = '';
            this.issue.reportedDt = new Date().toISOString().substr(0, 10);
            this.issue.solutionById = '';
            this.issue.solutionBy = '';
            this.issue.solutionDt = '';
            this.issue.severityId = '';
            this.issue.severity = '';
            this.issue.statusId = '';
            this.issue.status = '';
            this.$v.issue.$reset();
        },
        

    }
}
</script>