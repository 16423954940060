import Client from '@/views/client/Client.vue'
import AddClient from '@/views/client/AddClient.vue'
import ViewClient from '@/views/client/ViewClient.vue'
import EditClient from '@/views/client/EditClient.vue'

export default [{
        path: '/client',
        name: 'client',
        component: Client,
        meta: { accessedby: 'pm' }
    },
    {
        path: '/landing/addclient',
        name: 'addclient',
        component: AddClient,
        meta: { layout: 'addpage', accessedby: 'pm' }
    },
    {
        path: '/landing/viewclient',
        name: 'viewclient',
        component: ViewClient,
        meta: { layout: 'viewpage', accessedby: 'pm' }
    },
    {
        path: '/landing/editclient',
        name: 'editclient',
        component: EditClient,
        meta: { layout: 'addpage', accessedby: 'pm' }
    },
];