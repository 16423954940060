<template>
    <div class="activityDetailPopup">
        <b-modal id="activityDetailPopup" ref="activityDetailPopupRef" size="xl" @hide="closeDialog" body-class="p-0" footer-class="py-2" title="Process / Activity" ok-title="Close" ok-variant="secondary" centered scrollable ok-only>
            <div v-if="loading" id="loader"></div>
            <div v-if="!loading">
                <div class="row mb-auto w-100 no-gutters">
                    <div class="col-12 text-left">
                        <div class="card-body">
                            <div class="form-row">
                                <div class="col-12 col-md-6">
                                    <div class="row">
                                        <div class="col-3 pr-0">
                                            <label class="py-2 d-block title-block">Process</label>
                                        </div>
                                        <div class="col-9 pl-0">
                                            <label class="p-2 d-block header-details">{{ popupData.process }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="row">
                                        <div class="col-3 pr-0">
                                            <label class="py-2 d-block title-block">Activity</label>
                                        </div>
                                        <div class="col-9 pl-0">
                                            <label class="p-2 d-block header-details">{{ popupData.activity }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="popupData.activityDesc" class="row">
                                <div class="col-12 col-sm-12">
                                    <div class="card">
                                        <div class="card-body py-1 px-0">
                                            <p class="contact-title ml-2"><span>Activity Description</span></p>
                                            <div class="row">
                                                <div class="col-12 col-md-12 m-0 px-3">
                                                    <label class="p-2 mb-0 d-block header-details" v-html="popupData.activityDesc"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="card mb-2">
                                        <div class="card-body py-1 px-0">
                                            <p class="contact-title ml-2"><span>Entry</span></p>
                                            <div class="row">
                                                <div class="col-12 col-md-12 m-0 px-3">
                                                    <label class="p-2 mb-0 d-block header-details" v-html="popupData.input"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="card mb-2">
                                        <div class="card-body py-1 px-0">
                                            <p class="contact-title ml-2"><span>Task</span></p>
                                            <div class="row">
                                                <div class="col-12 col-md-12 m-0 px-3">
                                                    <label class="p-2 mb-0 d-block header-details" v-html="popupData.task"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="card mb-2">
                                        <div class="card-body py-1 px-0">
                                            <p class="contact-title ml-2"><span>Verify</span></p>
                                            <div class="row">
                                                <div class="col-12 col-md-12 m-0 px-3">
                                                    <label class="p-2 mb-0 d-block header-details" v-html="popupData.verify"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6 col-md-3">
                                    <div class="card mb-2">
                                        <div class="card-body py-1 px-0">
                                            <p class="contact-title ml-2"><span>Exit</span></p>
                                            <div class="row">
                                                <div class="col-12 col-md-12 m-0 px-3">
                                                    <label class="p-2 mb-0 d-block header-details" v-html="popupData.output"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
export default {
    props: {
        referenceDetailId: Number,
        refEntity: String,
        detailViewData: Object
    },
    data() {
        return {
            loading: false,
            popupData: {},
        }
    },
    methods: {
        getDetails: function(processId, activityId) {
            this.loading = true;
            this.$bvModal.show('activityDetailPopup');
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                processId: processId,
                activityId: activityId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/process/processactivity/etvx/list', data)
            .then((response) => {
                let result = response.data;
                if (result.processActivityList) {
                    this.popupData = result.processActivityList[0];
                    this.loading = false;
                }
            })
            .catch(error => {
                this.loading = false;
                console.log(error);
            });
        },
        closeDialog() {
            this.$emit('closeDialog');
        }
    }
}
</script>