<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <!-- breadcrumb -->
                <div class="page-heading">
                    <div class="row d-flex align-items-center">
                        <div class="col-12">
                            <div class="page-breadcrumb">
                                <h1>Dashboard</h1>
                            </div>
                        </div>
                        <div class="col-12  d-md-flex">
                            <div class="breadcrumb_nav">
                                <ol class="breadcrumb">
                                    <li>
                                        <i class="fa fa-home"></i>
                                        <a class="parent-item" href="#">Home</a>
                                        <i class="fa fa-angle-right"></i>
                                    </li>
                                    <li class="active">
                                        Dashboard
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- breadcrumb_End -->
                <!-- Section -->
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <div class="info_items bg_green d-flex align-items-center">
                                <span class="info_items_icon">
                                    <i class="fa fa-android"></i>
                                </span>
                                <div class="info_item_content">
                                    <span class="info_items_text">New users</span>
                                    <span class="info_items_number">450</span>
                                    <div class="progress">
                                        <div class="progress-bar width-45"></div>
                                    </div>
                                    <span class="progress-description"> 45% Increase in 28 Days </span>
                                </div>
                            </div>
                        </div>
                        <!-- /info-box-content -->
                        <div class="col-12 col-sm-6 col-md-3">
                            <div class="info_items bg_yellow d-flex align-items-center">
                                <span class="info_items_icon">
                                    <i class="fa fa-apple"></i>
                                </span>
                                <div class="info_item_content">
                                    <span class="info_items_text">Avg Time</span>
                                    <span class="info_items_number">155</span>
                                    <div class="progress">
                                        <div class="progress-bar width-40"></div>
                                    </div>
                                    <span class="progress-description"> 40% Increase in 28 Days </span>
                                </div>
                            </div>
                        </div>
                        <!-- /info-box-content -->
                        <div class="col-12 col-sm-6 col-md-3">
                            <div class="info_items bg_blue d-flex align-items-center">
                                <span class="info_items_icon">
                                    <i class="fa fa-apple"></i>
                                </span>
                                <div class="info_item_content">
                                    <span class="info_items_text">Total Prodcut</span>
                                    <span class="info_items_number">52</span>
                                    <div class="progress">
                                        <div class="progress-bar width-85"></div>
                                    </div>
                                    <span class="progress-description"> 85% Increase in 28 Days </span>
                                </div>
                            </div>
                        </div>
                        <!-- /info-box-content -->
                        <div class="col-12 col-sm-6 col-md-3">
                            <div class="bg_pink info_items d-flex align-items-center">
                                <span class="info_items_icon">
                                    <i class="fa fa-shopping-bag"></i>
                                </span>
                                <div class="info_item_content">
                                    <span class="info_items_text">Sales</span>
                                    <span class="info_items_number">13,921</span>
                                    <span>$</span>
                                    <div class="progress">
                                        <div class="progress-bar width-50"></div>
                                    </div>
                                    <span class="progress-description"> 50% Increase in 28 Days </span>
                                </div>
                                <!-- /.info-box-content -->
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4 d-flex align-items-stretch">
                            <div class="stats-wrap full_chart card mb-4">
                                <div class="chart_header">
                                    <div class="chart_headibg">
                                        <h3>Sales</h3>
                                    </div>
                                </div>
                                <div class="card_chart">
                                    <div class="stats-wrap">
                                        <h2>
                                            <b>65,800</b>
                                            <span>+
                                                <b>20</b>%</span>
                                        </h2>
                                        <p class="text-grey">
                                            Total Sale
                                            <small>This Year</small>
                                        </p>
                                        <h4>
                                            <b>1,204</b>
                                            <span>+
                                                <b>5</b>%</span>
                                        </h4>
                                        <p>
                                            Sale
                                            <small>This week</small>
                                        </p>
                                        <h4>
                                            <b>2,690</b>
                                            <span>+
                                                <b>125</b>
                                            </span>
                                        </h4>
                                        <p>
                                            New Sales
                                            <small>This Month</small>
                                        </p>
                                        <h4>
                                            <b>2,690</b>
                                            <span>+
                                                <b>125</b>
                                            </span>
                                        </h4>
                                        <p>
                                            New Sale
                                            <small>This Month</small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 d-flex align-items-stretch">
                            <div class="stats-wrap full_chart card mb-4">
                                <div class="chart_header">
                                    <div class="chart_headibg">
                                        <h3>Activity</h3>
                                    </div>
                                </div>
                                <div class="card_chart">
                                    <div class="events-nest">
                                        <div class="drak_blue">
                                            <h1>25</h1>
                                            <div class="middle_text">
                                                <span>3 Event</span>
                                                <p>
                                                    Monday
                                                    <i class="fa fa-calendar-check-o"></i> february 2019
                                                </p>
                                            </div>
                                        </div>
                                        <ul>
                                            <li>
                                                <h4>
                                                    <span class="counter-up-fast">8</span>:00
                                                    <small>PM</small>
                                                </h4>
                                                <p>
                                                    Mathematic Lesson
                                                    <i class="fa fa-gratipay"></i>
                                                    <i class="fa fa-heart-o"></i>
                                                </p>
                                            </li>
                                            <li>
                                                <h4>
                                                    <span class="counter-up-fast">9</span>:
                                                    <span class="counter-up-fast">30</span>
                                                    <small>PM</small>
                                                </h4>
                                                <p>
                                                    Meeting With Josh
                                                    <i class="fa fa-gratipay"></i>
                                                    <i class="fa fa-heart-o"></i>
                                                </p>
                                            </li>
                                            <li>
                                                <h4>
                                                    <span class="counter-up-fast">12</span>:
                                                    <span class="counter-up-fast">15</span>
                                                    <small>PM</small>
                                                </h4>
                                                <p>
                                                    Launch time
                                                    <i class="fa fa-gratipay"></i>
                                                    <i class="fa fa-heart-o"></i>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 d-flex align-items-stretch">
                            <div class="stats-wrap full_chart card mb-4">
                                <div class="chart_header">
                                    <div class="chart_headibg">
                                        <h3>Referrals</h3>
                                    </div>
                                </div>
                                <div class="card_chart">
                                    <ul class="list-unstyled list-referrals">
                                        <li class="mb-4">
                                            <p><span class="value">2301</span><span class="text-muted float-right">visits from Facebook</span></p>
                                            <div class="progress">
                                                <div class="progress-bar bg-warning width-25" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </li>
                                        <li class="mb-4">
                                            <p><span class="value">1357</span><span class="text-muted float-right">visits from Twitter</span></p>
                                            <div class="progress">
                                                <div class="progress-bar badge-info width-25" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </li>
                                        <li class="mb-4">
                                            <p><span class="value">2765</span><span class="text-muted float-right">visits from Search</span></p>
                                            <div class="progress">
                                                <div class="progress-bar bg-primary width-25" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </li>
                                        <li class="mb-4">
                                            <p><span class="value">3121</span><span class="text-muted float-right">visits from Linkedin</span></p>
                                            <div class="progress">
                                                <div class="progress-bar badge-danger width-25" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12  col-md-6">
                            <div class="full_chart card mb-4">
                                <div class="chart_header">
                                    <div class="chart_headibg">
                                        <h3>Social Campaigns</h3>
                                    </div>
                                </div>
                                <div class="card_chart">
                                    <div class="student_table table-responsive-lg">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Campaign</th>
                                                    <th>Client</th>
                                                    <th>Changes</th>
                                                    <th>Budget</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span class="weight-500">Facebook</span>
                                                    </td>
                                                    <td>Beavis</td>
                                                    <td>
                                                        <span class="txt-success">
                                                            <i class="fa fa-sort-up mr-10 "></i>
                                                            <span>2.43%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="weight-500">$1478</span>
                                                    </td>
                                                    <td>
                                                        <span class="label label-warning">Active</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span class="weight-500">Youtube</span>
                                                    </td>
                                                    <td>Felix</td>
                                                    <td>
                                                        <span class="txt-success">
                                                            <i class="fa fa-sort-up mr-10 "></i>
                                                            <span>1.43%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="weight-500">$951</span>
                                                    </td>
                                                    <td>
                                                        <span class="label label-danger">Closed</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span class="weight-500">Twitter</span>
                                                    </td>
                                                    <td>Cannibus</td>
                                                    <td>
                                                        <span class="txt-danger">
                                                            <i class="fa fa-sort-down mr-10 "></i>
                                                            <span>-8.43%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class="weight-500">$632</span>
                                                    </td>
                                                    <td>
                                                        <span class="label label-default">Hold</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span class="weight-500">Spotify</span>
                                                    </td>
                                                    <td>Neosoft</td>
                                                    <td>
                                                        <span class="txt-success">
                                                            <i class="fa fa-sort-up mr-10 "></i>
                                                            <span>7.43%</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span class=" weight-500">$325</span>
                                                    </td>
                                                    <td>
                                                        <span class="label label-default">Hold</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12  col-md-6">
                            <div class="full_chart card mb-4">
                                <div class="chart_header">
                                    <div class="chart_headibg">
                                        <h3>Resent Chat</h3>
                                    </div>
                                </div>
                                <div class="card_chart">
                                    <div class="chat_box scroll_auto">
                                        <div class="left_align_me">
                                            <img src="@/assets/images/img1.jpg" alt="" class="rounded-circle" />
                                            <div class="chat-info">
                                                <span class="message">Hello, John<br>What is the update on Project?</span>
                                            </div>
                                        </div>
                                        <div class="right_align_me">
                                            <img src="@/assets/images/img2.jpg" alt="" class="rounded-circle" />
                                            <div class="chat-info">
                                                <span class="message">Hello, John<br>What is the update on Project?</span>
                                            </div>
                                        </div>
                                        <div class="left_align_me">
                                            <img src="@/assets/images/img1.jpg" alt="" class="rounded-circle" />
                                            <div class="chat-info">
                                                <span class="message">Hello, John<br>What is the update on Project?</span>
                                            </div>
                                        </div>
                                        <div class="right_align_me">
                                            <img src="@/assets/images/img2.jpg" alt="" class="rounded-circle" />
                                            <div class="chat-info">
                                                <span class="message">Hello, John<br>What is the update on Project?</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="fa fa-paper-plane-o"></i></span>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Enter text here...">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-3">
                            <div class="card card-inverse card-danger mb-4">
                                <div class="card-body pb-0">
                                    <div class="btn-group float-right">
                                        <div class="dropdown ">
                                            <a href="#" class="btn btn-transparent text-light dropdown-hover p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class=" fa fa-ellipsis-h"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right ">
                                                <a class="dropdown-item" href="#">
                                                    <i class="fa fa-sticky-note-o text-info pr-2"></i> Edit</a>
                                                <a class="dropdown-item" href="#">
                                                    <i class="fa fa-times text-danger pr-2"></i> Delete</a>
                                                <a class="dropdown-item" href="#">
                                                    <i class="fa fa-shield text-warning pr-2"></i> Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 class="mb-0 text-light">9876</h4>
                                    <p class="text-light"> Total Profit</p>
                                </div>
                                <div class="px-">
                                    <canvas id="myChart2-alt" height="100"></canvas>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <div class="card card-inverse card-success mb-4">
                                <div class="card-body pb-0">
                                    <div class="btn-group float-right">
                                        <div class="dropdown ">
                                            <a href="#" class="btn btn-transparent text-light dropdown-hover p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class=" fa fa-ellipsis-h"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right ">
                                                <a class="dropdown-item" href="#">
                                                    <i class="fa fa-sticky-note-o text-info pr-2"></i> Edit</a>
                                                <a class="dropdown-item" href="#">
                                                    <i class="fa fa-times text-danger pr-2"></i> Delete</a>
                                                <a class="dropdown-item" href="#">
                                                    <i class="fa fa-shield text-warning pr-2"></i> Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 class="mb-0 text-light">234</h4>
                                    <p class="text-light">New Orders</p>
                                </div>
                                <div class="px-">
                                    <canvas id="myChart2" height="100"></canvas>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <div class="card card-inverse card-warning mb-4">
                                <div class="card-body pb-0">
                                    <div class="btn-group float-right">
                                        <div class="dropdown ">
                                            <a href="#" class="btn btn-transparent text-light dropdown-hover p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class=" fa fa-ellipsis-h"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right ">
                                                <a class="dropdown-item" href="#">
                                                    <i class="fa fa-sticky-note-o text-info pr-2"></i> Edit</a>
                                                <a class="dropdown-item" href="#">
                                                    <i class="fa fa-times text-danger pr-2"></i> Delete</a>
                                                <a class="dropdown-item" href="#">
                                                    <i class="fa fa-shield text-warning pr-2"></i> Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 class="mb-0 text-light">12083</h4>
                                    <p class="text-light">Yearly Revineue</p>
                                </div>
                                <div class="px-4">
                                    <canvas id="myChart3" height="100"></canvas>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3">
                            <div class="card card-inverse card-primary mb-4">
                                <div class="card-body pb-0 ">
                                    <div class="btn-group float-right">
                                        <div class="dropdown ">
                                            <a href="#" class="btn btn-transparent text-light dropdown-hover p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class=" fa fa-ellipsis-h"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right ">
                                                <a class="dropdown-item" href="#">
                                                    <i class="fa fa-sticky-note-o text-info pr-2"></i> Edit</a>
                                                <a class="dropdown-item" href="#">
                                                    <i class="fa fa-times text-danger pr-2"></i> Delete</a>
                                                <a class="dropdown-item" href="#">
                                                    <i class="fa fa-shield text-warning pr-2"></i> Cancel</a>
                                            </div>
                                        </div>
                                    </div>
                                    <h4 class="mb-0 text-light">12083</h4>
                                    <p class="text-light">New Users</p>
                                </div>
                                <div class="">
                                    <canvas id="myChart" height="100"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--graph widget end-->
                </section>
                <!-- Section_End -->
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsMixin } from "@/mixins/requestsMixin";
export default {
    mixins: [requestsMixin],
    data() {
        return {
            elements: {
                showRegister: false,
            },
            disableLogin: false,
            error: false,
            entity: 'vhext_account',
            submitted: false,
            accountTypes: [],
            countries: [],
            vhextAccount: {
                accountName: '',
                vhextAccountTypeId: '',
                emailId: '',
                phone: '',
                password: '',
                confirmationPassword: '',
                terms: '',
                company: '',
                jobTitle: '',
                countryId: ''
            },
            login: {
                loginName: '',
                password: '',
                key: this.$route.params.key,
                userType: this.$route.params.userType
            }
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'Dashbaord';
        this.getAllProjects();
        this.getProjects1();
    },
    methods: {
        async getAllProjects() {
            let data = {
                vhextAccountId: 117,
                userId: 305,
                isArchive: 0,
            }
            const response = await this.getProjects(data);
            let result = response.data;
        },
        getProjects1: function() {
            let data = {
                vhextAccountId: 117,
                userId: 305,
                isArchive: 0,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/project/list', data)
                .then((response) => {
                }).catch(error => {                   
                    console.log(error);
                });
        },


        clearData: function() {
            this.elements.showRegister = false;
            this.vhextAccount.accountName = '';
            this.vhextAccount.vhextAccountTypeId = '';
            this.vhextAccount.emailId = '';
            this.vhextAccount.phone = '';
            this.vhextAccount.password = '';
            this.vhextAccount.terms = '';
            this.vhextAccount.confirmationPassword = '';
            this.vhextAccount.company = '';
            this.vhextAccount.title = '';
            this.vhextAccount.countryId = '';
            this.$v.vhextAccount.$reset();
        },
        filterAccountType: function(individual, company) {
            return this.accountTypes.filter(function(data, key) {
                if (data.value == individual || data.value == company) {
                    return data;
                }
            });
        }

    }
}
</script>