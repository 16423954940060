<template>
    <div class="attachmentPopup">
        <b-modal id="attachmentPopup" ref="attachmentPopupRef" scrollable title="Attachments" size="lg" modal-class="attachmentPopupRef" @hide="closeDialog" centered>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row mb-2">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="pl-2 action-link pointer">
                            </label>
                            <div class="custom-file">
                                <input ref="add_attachment" type="file" accept=".doc,.docx,.pdf,.DOCX,image/*" class="custom-file-input" id="customFile" value="Upload File" @change="uploadFile">
                                <!-- <input type="file" class="custom-file-input" id="customFile"> -->
                                <label class="custom-file-label" for="customFile">Choose file</label>
                            </div>
                        </div>
                        <div v-for="(info, index) in attachments" :key="index">
                            {{info.name}}
                            <a class="pl-2" href="javascript:" tilte="delete" @click="popAttachment(index)">
                                <i class="fa fa-close action-link"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </b-modal>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {

    data() {
        return {
            showOverlay: false,
            checklistTitle: '',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),

            attachments: [],
            attachmentList: [],
            deleteParam: {
                id: 0,
                refEntityId: '',
                refRecordId: ''
            },
        }
    },
    // mounted() {
    //     this.checklistTitle = this.checklistTaskRef.title;
    //     alert('this.checklistTitle');
    // },
    methods: {
        uploadFile: function(e) {
            this.attachments.push(this.$refs.add_attachment.files[0]);
            e.target.value = null;
        },
        popAttachment(index) {
            this.$delete(this.attachments, index);
        },
        saveAttachment: function(entity, refRecordId) {
            if (this.attachments != null && this.attachments.length > 0) {
                let formData = new FormData();
                for (const row of Object.keys(this.attachments)) {
                    formData.append('files', this.attachments[row]);
                }
                formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
                formData.append('attachedById', localStorage.getItem("userId"));
                formData.append('projectId', localStorage.getItem("projectId"));
                formData.append('refEntity', entity);
                formData.append('refRecordId', refRecordId);
                return axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        let result = response.data;
                        if (result.status == "OK" && this.attachmentInfo.successMessage == 'true') {                        
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.clearAttachment();
                        }
                    })
                    .catch(error => {
                        console.log('Error Attachment uploaded');
                    });
            }
        },
        getAttachmentData: function(entity, refRecordId, vhextAccountId, projectId) {
            let data = {
                refEntity: entity,
                refRecordId: refRecordId,
                vhextAccountId: vhextAccountId,
                projectId: projectId
            }
            console.log(data)
            this.attachmentList = {};
            return axios.post(this.$store.getters.getAPIBasePath + '/attachment/list', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentList = result.attachmentList;
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'center'
                   });
                })
        },
        deleteFile: function(attachment) {
            this.deleteParam.id = attachment.id;
            this.deleteParam.refEntityId = attachment.refEntityId;
            this.deleteParam.refRecordId = attachment.refRecordId;
        },
        onYes: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/delete',this.deleteParam)
                .then(response => {

                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'center'
                        });
                        this.getAttachmentByRefEntityId(this.deleteParam.refEntityId, this.deleteParam.refRecordId, localStorage.getItem("vhextAccountId"), localStorage.getItem("projectId"));
                    }
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'center'
                   });
                })
        },
        getAttachmentByRefEntityId: function(refEntityId, refRecordId, vhextAccountId, projectId) {
            let data = {
                refEntityId: refEntityId,
                refRecordId: refRecordId,
                vhextAccountId: vhextAccountId,
                projectId: projectId
            }
            console.log(data)
            this.attachmentList = {};
            return axios.post(this.$store.getters.getAPIBasePath + '/attachment/view', data)
                .then((response) => {
                    let result = response.data;
                    this.attachmentList = result.attachmentList;
                })
                .catch(error => {
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'center'
                   });
                })                
        },
        hasAttachment() {
            if (this.attachments.length>0 || this.attachmentList.length>0)
                return true;
            else return false;
        },

        clearAttachment() {
            this.attachmentList = [];
        },
        closeDialog() {
            this.$emit('updateAttachmentCount', this.attachments.length);
        },
    }
}
</script>