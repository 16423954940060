<template>
    <div>
        <div v-if="meetingMinutes.length > 0" class="row">
            <div class="col-12 col-sm-12 px-0">
                <!-- <h6 class="contact-title mb-3 ml-2"><span>Minutes of Meeting</span></h6> -->
                <div class="accordion" role="tablist">
                    <template v-for="meetingMinute in meetingMinutes">
                        <b-card no-body class="mb-1 mx-3">
                            <b-card-header header-tag="header" class="p-1 text-left" role="tab">
                                <b-button block v-b-toggle.accordion-1 variant="info" class="sbtn text-left">{{ meetingMinute.title }}
                                    <span v-if="meetingMinute.deadline" class="float-right">Deadline: {{ meetingMinute.deadline | formatDate }}<i class="fa fa-angle-down fa-lg font-weight-bold pointer ml-2"></i></span>
                                </b-button>
                            </b-card-header>
                            <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                                <b-card-body class="pt-3 px-2">
                                    <div class="row">
                                        <div v-if="meetingMinute.responsiblePerson" class="col-12 col-md-6 label-border">
                                            <div class="row no-gutters mb-2 header-details">
                                                <div class="col-4">
                                                    <label class="p-1 m-0 d-block">Res. Person</label>
                                                </div>
                                                <div class="col-8">
                                                    <label class="p-1 m-0 d-block">{{ meetingMinute.responsiblePerson }}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="meetingMinute.initiatedPerson" class="col-12 col-md-6 label-border">
                                            <div class="row no-gutters mb-2 header-details">
                                                <div class="col-4">
                                                    <label class="p-1 m-0 d-block">Initiated By</label>
                                                </div>
                                                <div class="col-8">
                                                    <label class="p-1 m-0 d-block">{{ meetingMinute.initiatedPerson }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div v-if="meetingMinute.description" class="col-12 col-md-6">
                                            <div class="card mt-2 mb-0">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Agenda Item</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="meetingMinute.description"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="meetingMinute.discussion" class="col-12 col-md-6">
                                            <div class="card mt-2 mb-0">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Discussion</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="meetingMinute.discussion"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div v-if="meetingMinute.decision" class="col-12 col-md-6">
                                            <div class="card mt-2 mb-0">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Decision/Action Items</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="meetingMinute.decision"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="meetingMinute.actionTaken" class="col-12 col-md-6">
                                            <div class="card mt-2 mb-0">
                                                <div class="card-body py-1 px-0">
                                                    <p class="contact-title mb-2 ml-2"><span>Action Taken</span></p>
                                                    <div class="row">
                                                        <div class="col-12 col-md-12 m-0 px-3">
                                                            <label class="p-2 mb-0 d-block header-details" v-html="meetingMinute.actionTaken"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </template>
                </div>
            </div>
        </div>
        <div v-else class="text-center">
            <p>No Meeting Minutes Found</p>
        </div>
    </div>
</template>
<script>
import dayList from '@/json/daysofweek.json';
export default {
    props: {
        momData: Object
    },
    data() {
        return {
            loading: false,
            popupData: {},
            eventParticipants: [],
            meetingMinutes: [],
            days: dayList,
        }
    },
    mounted() {
        this.getMeetingMinute();
    },
    methods: {
        getMeetingMinute: function() {
            if (this.momData.eventId) {
                let data = {
                    entityRecordMasterId: this.momData.entityRecordMasterId,
                    calendarEventId: this.momData.calendarEventId,
                    eventId: this.momData.eventId,
                }
                this.loading = true;
                axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminute/list', data)
                    .then((response) => {
                        this.loading = false;
                        let result = response.data;
                        if (result.meetingMinuteList && result.meetingMinuteList.length > 0) {
                            this.meetingMinutes = result.meetingMinuteList;
                        }
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error);
                    });
            }
        },
    }
}
</script>