<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row px-2">
                <div class="card col-12 col-lg-4 col-xl-4 mb-4 ">
                    <div class="profile_header">
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Title</span>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" v-model="task.title" class="view-form" placeholder="Title" name="title">
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Start Date</span>
                                    </div>
                                    <div class="col-7">
                                        <span class="float-left" @click="showDurationEdit">{{ task.estimatedStartDt | formatDateTimeYearCheck }}</span>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Due Date</span>
                                    </div>
                                    <div class="col-7">
                                        <span class="float-left" @click="showDurationEdit">{{ task.estimatedEndDt | formatDateTimeYearCheck }}</span>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Process / Activity</span>
                                    </div>
                                    <div class="col-7">
                                        <select v-model="processActivity" class="view-form">
                                            <option :value="{activityId: '', processId: ''}">Select</option>
                                            <option v-for="(info,index) in processActivities" :key="index" :value="{activityId: info.id, processId: info.processId}">{{ info.process ? info.process + ' / ' : ''}}{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Assigned To</span>
                                    </div>
                                    <div class="col-7">
                                        <select v-model="task.assignedToId" class="view-form">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in users" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Status</span>
                                    </div>
                                    <div class="col-7">
                                        <select v-model="task.statusId" class="view-form">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in taskStatus" :key="index" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Task Group</span>
                                    </div>
                                    <div class="col-7">
                                        <select v-model="task.entityRecordMasterId" class="view-form">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in entityERMLookup" :key="index" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group px-3">
                                    <span class="font-light">Description</span>
                                    <label class="description-field" v-html="task.description" @click="showDescriptionEdit" ></label>
                                    <!-- <textarea class="form-control" v-model="task.description" placeholder="Task Description" rows="2">
                                    </textarea> -->
                                </div>
                                <div  class="mx-0 mt-2 mb-0 float-right">
                                    <span class="font-light fs-small">Updated {{ getTimeDuration(task.lastUpdatedDt) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="task-duration" centered title="Task Duration">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Start Date</label>
                        <div class="input-group">
                            <div class="col-6 pl-0 pr-0">
                                <input type="date" class="form-control" v-model="task.estimatedStartDate" id="estimatedStartDt1" required>
                            </div>
                            <div class="col-6 pl-1">
                                <input type="time" class="form-control" v-model="task.estimatedStartTime" required>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label>Due Date</label>
                        <div class="input-group">
                            <div class="col-6 pl-0 pr-0">
                                <input type="date" class="form-control" v-model="task.estimatedEndDate" id="estimatedStartDt1" required>
                            </div>
                            <div class="col-6 pl-1">
                                <input type="time" class="form-control" v-model="task.estimatedEndTime" id="estimatedStartDt1" required>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <button class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right" @click="closeDialog">Cancel</button>
                    <button size="sm" @click="updateDuration" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right mr-2">Update</button>
                </div>
            </template>
        </b-modal>
        <DescriptionPopup ref="descriptionEditPopup" @updateValue="updateDescription" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsTask } from "@/mixins/requestsTask";
import Editor from '@tinymce/tinymce-vue';
import { EventBus } from '@/main';
import DescriptionPopup from '@/components/popup/action/DescriptionEditPopup.vue';
export default {
    name: 'app',
    components: {
        DescriptionPopup,
        Editor,
    },
    mixins: [requestsTask],
    data() {
        return {
            task: {},
            processActivity: {
                activityId: '',
                processId: '',
            },
            entityERMLookup: [],
            users: [],
            processActivities: [],
            taskStatus: [],
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            userId: localStorage.getItem("userId"),
            entity: 'task',
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'Edit Task';
        this.task = JSON.parse(localStorage.getItem("rowData"));
        this.processActivity.activityId = this.task.activityId;
        this.processActivity.processId = this.task.processId;
        console.log(this.task)
        this.lookupData();
    },
    created() {
        EventBus.$on("savePage", this.updateTaskData);
    },
    destroyed() {
        EventBus.$off("savePage", this.updateTaskData);
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: this.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.processActivities = result.processActivityLookup;
                    this.taskStatus = result.statusLookup;
                    this.entityERMLookup = result.entityERMLookup;
                })
        },
        async updateTaskData() {
            this.task.processId = this.processActivity.processId;
            this.task.activityId = this.processActivity.activityId;
            this.task.progressPercentage = this.task.progressPercentage ? this.task.progressPercentage : 0;
            try {
                this.$store.state.showAppLoader = true;
                const response = await this.updateTask(this.task);
                this.$store.state.showAppLoader = false;
                let result = response.data;
                if (result.status == 'CREATED') {
                    this.task.lastUpdatedDt = new Date();
                    localStorage.setItem("rowData", JSON.stringify(this.task));
                    this.$nextTick(() => {
                        this.$router.push('/viewtsk')
                    })
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topCenter'
                    });
                }
                // this.closeDialog();
            } catch(error) {
                this.$store.state.showAppLoader = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        showDurationEdit(data) {
            this.task.estimatedStartDate = this.getDateValue(this.task.estimatedStartDt);
            this.task.estimatedStartTime = this.getTimeValue(this.task.estimatedStartDt);
            this.task.estimatedEndDate = this.getDateValue(this.task.estimatedEndDt);
            this.task.estimatedEndTime = this.getTimeValue(this.task.estimatedEndDt);
            console.log(this.task);
            this.$nextTick(() => {
                this.$bvModal.show('task-duration');
            })
        },
        updateDuration() {
            this.task.estimatedStartDt = this.combineDateAndTime(this.task.estimatedStartDate, this.task.estimatedStartTime);
            this.task.estimatedEndDt = this.combineDateAndTime(this.task.estimatedEndDate, this.task.estimatedEndTime);
            this.$nextTick(() => {
                this.$bvModal.hide('task-duration');
            })
        },
        showDescriptionEdit() {
            let popupTitle = 'Description';
            let value = this.task.description;
            this.$refs.descriptionEditPopup.setDescriptionData(value, popupTitle);
            this.$nextTick(() => {
                this.$bvModal.show('description-edit-popup');
            })
        },
        updateDescription(val) {
            this.task.description = val;
            this.$nextTick(() => {
                this.$bvModal.hide('description-edit-popup');
            })
        },
        closeDialog() {
            this.task = JSON.parse(localStorage.getItem("rowData"));
            this.$bvModal.hide('task-duration');
        }
    }
}
</script>