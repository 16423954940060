import Client from '@/views/client/Client.vue'
import Contact from '@/views/contact/Contact.vue'
import PrmLanding from '@/views/landing/PRMLanding.vue'
import Resource from '@/views/resource/Resource.vue'
import User from '@/views/user/User.vue'

export default [{
        path: '/prm/landing/client',
        name: 'prmclient',
        component: Client,
        meta: { layout: 'landing', accessedby: 'PRM' }
    },
    {
        path: '/prm/landing/contact',
        name: 'prmcontact',
        component: Contact,
        meta: { layout: 'landing', accessedby: 'PRM' }
    },
    {
        path: '/prm/landing',
        name: 'prmlanding',
        component: PrmLanding,
        meta: { layout: 'landing', accessedby: 'PRM' }
    },
    {
        path: '/prm/landing/resource',
        name: 'prmresource',
        component: Resource,
        meta: { layout: 'landing', accessedby: 'PRM' }
    },
    {
        path: '/prm/landing/user',
        name: 'prmuser',
        component: User,
        meta: { layout: 'landing', accessedby: 'PRM' }
    },


];