<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div v-if="showDateRange" class="col-2 text-right ">
                            <a href="javascript:" @click="showDateRange=false;" class="btn btn-primary btn-sm py-1 px-2 mt-2">M</a>
                        </div>
                        <div v-else class="col-2 text-right">
                            <a href="javascript:" @click="showDateRange=true;" class="btn btn-primary btn-sm py-1 px-2 mt-2">D</a>
                        </div>
                        <div class="col-8" v-if="showDateRange">
                            <div class="text-center">
                                <div class="row mt-2 mb-0 text-center px-0">
                                    <div class="col pull-left px-0">
                                        <a href="javascript:" @click="changeDateRange('prevPage',1)" class="btn btn-info btn-xs mr-2 py-1 px-2"><i class="fa fa-lg fa-angle-left mt-0"></i></a>
                                    </div>
                                    <div class="col-6 px-0">
                                        <h6 class="py-1 my-0" v-if="currentStartDate">
                                            {{ currentStartDate | formatDate }}
                                        </h6>
                                    </div>
                                    <div class="col pull-right px-0">
                                        <a href="javascript:" @click="changeDateRange('nextPage',1)" class="btn btn-info btn-xs mr-2 py-1 px-2"><i class="fa fa-lg fa-angle-right mt-0"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-8" v-else>
                            <div class="text-center">
                                <div class="row mt-2 mb-0 text-center px-0">
                                    <div class="col pull-left px-0">
                                        <a href="javascript:" @click="changeMonth('prevPage',1)" class="btn btn-info btn-xs mr-2 py-1 px-2"><i class="fa fa-lg fa-angle-left mt-0"></i></a>
                                    </div>
                                    <div class="col-6 px-0">
                                        <h6 class="py-1 my-0" v-if="currentMonth">
                                            <!-- {{ months[currentMonth.getMonth()] }} - {{ currentMonth.getFullYear() }} -->
                                            {{ months[currentMonth.getMonth()].slice(0,3) }} - 
                                            {{ currentMonth.getFullYear() }}
                                        </h6>
                                    </div>
                                    <div class="col pull-right px-0">
                                        <a href="javascript:" @click="changeMonth('nextPage',1)" class="btn btn-info btn-xs mr-2 py-1 px-2"><i class="fa fa-lg fa-angle-right mt-0"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <h2 class="">
                                <span id="dropdownCal" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="badge badge-pill badge-info pointer float-right py-2 my-2" title="Jump to Date">
                                    <i class="fa fa-calendar text-light"></i>
                                    <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownCal">
                                        <b-calendar v-model="selectedDate" today-variant="primary" selected-variant="secondary" @context="dateList(selectedDate)">
                                        </b-calendar>
                                    </div>
                                </span>
                            </h2>
                        </div>
                    </div>
                </div>
                <section class="chart_section">
                    <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0" rounded="sm">
                        <div class="row">
                            <div v-for="day,index in daysToShow" :key="index" class="col p-0 ml-2 mr-1">
                                <div v-if="currentDateList.length > 0" class="card text-right mx-auto py-2 mt-1">
                                    <div class="row px-2">
                                        <div class="col-6 pr-0">
                                            <span title="DR" class="float-left text-danger"><i class="fa fa-arrow-down pr-1" aria-hidden="true"></i>{{ currency && currency!== 'null' ? currency : '' }} {{ getDebitSum(itemsByDate(currentDateList[index])).toFixed(2) }}</span>
                                        </div>
                                        <div class="col-6 pl-0">
                                            <span title="CR" class="float-right text-success"><i class="fa fa-arrow-up pr-1" aria-hidden="true"></i>{{ currency && currency!== 'null' ? currency : '' }} {{ getCreditSum(itemsByDate(currentDateList[index])).toFixed(2) }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="action-item-vl card mt-2">
                                    <div v-for="financialTxn,fnIndex in itemsByDate(currentDateList[index])" :key="fnIndex" class="row">
                                        <div class="col-12 text-left mb-2">
                                            <div class="action-card my-0 pointer" >
                                                <div class="action-card-body dropdown show p-0">
                                                    <a type="button" class="float-right pt-2 mr-2 ml-1" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fa fa-ellipsis-v p-1"></i>
                                                    </a>
                                                    <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                        <!-- <a class="dropdown-item addnew-tassk pb-1 py-1" href="#"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Transaction Details</a> -->
                                                        <a class="dropdown-item addnew-tassk py-1" @click.prevent="viewItemDetails(financialTxn)" href="#"><i class="fa fa-pencil pr-2"></i>Edit Transaction</a>
                                                        <a class="dropdown-item py-1" @click.prevent="deleteFinancialTxn(financialTxn.id)" v-b-modal.deleteFinancialTxn href="#"><i class="fa fa-trash pr-2"></i>Delete Transaction</a>
                                                    </div>
                                                    <div class="pt-2 pb-1 px-2">
                                                        <span v-if="financialTxn.amountTypeCode == '1'" class="badge badge-success pointer float-right fs-12" >
                                                            {{ currency && currency!== 'null' ? currency : '' }} {{ financialTxn.amount }}
                                                        </span>
                                                        <span v-else class="badge badge-danger pointer float-right fs-80">
                                                            {{ currency && currency!== 'null' ? currency : '' }} {{ financialTxn.amount }}
                                                        </span>
                                                        <div class="card-media-body-top">
                                                            <a href="#" :title="financialTxn.title" @click.prevent="viewItemDetails(financialTxn)" class="pointer action-link">{{ financialTxn.title }}</a>
                                                        </div>
                                                        <div class="container-fluid">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="row card-media-object-social-list">
                                                                        <div class="col-6 p-0 text-left">
                                                                            <div v-if="financialTxn.transactionBy">
                                                                                <span class="float-left text-muted" :title="`Transaction By`"> {{ financialTxn.transactionBy }} </span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-6 px-0 text-right">
                                                                            <div v-if="(financialTxn.transactionDt != null)">
                                                                                <span class="fs-12" :title="`Transaction Date: ${formatDate(financialTxn.transactionDt)}`"> <i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ financialTxn.transactionDt | formatDate }} </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr v-if="fnIndex !== (itemsByDate(currentDateList[index]).length -1)" class="m-0">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-overlay>
                </section>
                <b-button class="btn floating-btn btn-info" @click="$router.push('/addfinancialtxn')" size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
        <Dialog id="deleteFinancialTxn" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete this Transaction?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { requestsMixin } from "@/mixins/requestsMixin";
import Dialog from '../../components/popup/dialogbox/Dialog.vue';
import { EventBus } from '@/main';
export default {
  components: { Dialog },
    mixins: [requestsMixin],
    data() {
        return {
            showOverlay: false,
            showDateRange: true,
            refPopupTitle: '',
            referencePopupId: 0,
            detailViewData: {},

            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            userId: localStorage.getItem("userId"),
            entityRecordMasterId: '',
            entity: 'financial_transaction',

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            currency: localStorage.getItem('currencySymbol'),

            daysToShow: 1,
            selectedDate: null,
            currentStartDate: new Date(),
            currentEndDate: new Date(),
            currentMonth: new Date(),
            currentDateList: [],

            days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

            financialTxn: {
                editMode: null,
                id: '',
                financialTransactionId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: localStorage.getItem("ermId"),
                entity: 'financial_transaction',
                accountHeadId: '',
                transactionTypeId: '',
                categoryId: '',
                title: '',
                description: '',
                amount: '',
                preparedById: '',
                transactionDt: '',
                transactionById: '',
                accountablePersonId: '',
                statusId: '',
                activityId: '',
                entityId: '',
            },       

            finTransactions: [],
            allFinTransactions: [],
            ermLookup: [],

            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
    },
    created() {
        EventBus.$on("changeERM", this.changeERM);
        EventBus.$on("refreshERMLookup", this.lookupData);
    },
    destroyed() {
        EventBus.$off("changeERM", this.changeERM);
        EventBus.$off("refreshERMLookup", this.lookupData);
        this.$store.state.ermLookup = [];
    },
    mounted() {
        this.$store.state.pageTitle = 'Finance';
        this.$store.state.ermMasterInfo.title = "Financial Txn Master";
        this.$store.state.ermMasterInfo.entity = 'financial_txn_master';
        this.$store.state.ermMasterInfo.refEntity = 'financial_transaction';
        this.dateList();
        this.lookupData();
        this.getAllTransactions();
    },
    methods: {
        getAllTransactions: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/list/all', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.finTransactions = result.financialTransactionList;
                    this.allFinTransactions = this.finTransactions;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })

        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: 'financial_txn_master',
            }
            this.$store.state.showAppLoader = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/entityerm', data)
                .then((response) => {
                    this.$store.state.showAppLoader = false;
                    let result = response.data;
                    this.ermLookup = result.entityERMLookup;
                    this.$store.state.ermLookup = result.entityERMLookup;
                    if(this.ermLookup && this.ermLookup.length > 0) {
                        this.entityRecordMasterId = this.ermLookup[0].id;
                        this.$store.state.ermId = this.ermLookup[0].id;
                        localStorage.setItem('ermId', this.ermLookup[0].id);
                    }
                    else {
                        this.entityRecordMasterId = '';
                        this.$store.state.ermId = '';
                        localStorage.setItem('ermId', '');
                    }
                })
                .catch(error => {
                    this.$store.state.showAppLoader = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })

        },
        dateList(selectedDate) {
            if (selectedDate) {
                this.currentStartDate = moment(selectedDate).toDate();
                this.daysToShow = 1;
            }
            else {

                /* to set Sunday as first day */
                // this.currentStartDate = moment().startOf('week').toDate();

                /* to set Monday as first day */
                // this.currentStartDate = moment().startOf('isoweek').toDate();

                /* to set current day as first day */
                this.currentStartDate = new Date();
            }
            var endDate = new Date();
            endDate.setDate(this.currentStartDate.getDate() + 6);

            this.currentEndDate = endDate;
            this.currentDateList = this.getDaysArray(this.currentStartDate, endDate);
        },
        changeDateRange(page, number) {
            var startDate = new Date(this.currentStartDate);
            var endDate = new Date(this.currentEndDate);

            if (page === 'nextPage') {
                startDate.setDate(this.currentStartDate.getDate() + number);
                endDate.setDate(this.currentEndDate.getDate() + number);
            }
            if (page === 'prevPage') {
                startDate.setDate(this.currentStartDate.getDate() - number);
                endDate.setDate(this.currentEndDate.getDate() - number);
            }
            this.currentStartDate = startDate;
            this.currentEndDate = endDate;

            this.currentDateList = this.getDaysArray(this.currentStartDate, this.currentEndDate);
        },
        changeMonth(page, number) {
            var currentMonth = new Date(this.currentMonth);

            if (page === 'nextPage') {
                currentMonth.setMonth(this.currentMonth.getMonth() + number);
            }
            if (page === 'prevPage') {
                currentMonth.setMonth(this.currentMonth.getMonth() - number);
            }
            this.currentMonth = currentMonth;
        },
        getDaysArray: function(startDate, endDate) {
            for (var arr = [], d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
                arr.push(new Date(d));
            }
            return arr;
        },
        itemsByDate(date) {
            if (date) {
                if (this.showDateRange) {
                    return this.finTransactions.filter(item=> this.getDateValue(item.transactionDt) == this.getDateValue(date));
                }
                else {
                    return this.finTransactions.filter(item=> new Date(item.transactionDt).getFullYear() == new Date(this.currentMonth).getFullYear() && new Date(item.transactionDt).getMonth() == new Date(this.currentMonth).getMonth());
                }
            }
        },
        changeERM: function(ermId) {
            localStorage.setItem('ermId', ermId);
            this.entityRecordMasterId = ermId;
            this.$store.state.ermId = ermId;
            if (ermId == '') {
                this.finTransactions = this.allFinTransactions;
            }
            else if (ermId !== null && ermId) {
                this.finTransactions = this.allFinTransactions;
                if (this.finTransactions.length>0) {
                    this.finTransactions = this.finTransactions.filter(function(ftxn) {
                        return ftxn.entityRecordMasterId == ermId;
                    });
                }
            }
            console.log(localStorage.getItem('ermId'));
        },
        getCreditSum(array) {
            array = array.filter(arr=> {
                if (arr.amountTypeCode == '1') return arr
            });
            if (array && array.length > 0) {
                return array.reduce((acc,item)=> {
                    return acc + item.amount;
                }, 0);
            } else return 0;
        },
        getDebitSum(array) {
            array = array.filter(arr=> {
                if (arr.amountTypeCode == '2') return arr;
            });
            if (array && array.length > 0) {
                return array.reduce((acc,item)=> {
                    return acc + item.amount;
                }, 0);
            } else return 0;
        },
        viewItemDetails: function(data) {
            data.amount = Math.abs(data.amount);
            localStorage.setItem("rowData", JSON.stringify(data));
            localStorage.setItem("ermId", data.id);
            this.$nextTick(() => {
                this.$router.push('/viewfinancialtxn');
            });
        },
        deleteFinancialTxn: function(id) {
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getAllTransactions();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        openFinTxnPopup: function(data, flag) {
            if (flag == true) {
                this.financialTxn.id = data.id;
                this.financialTxn.financialTransactionId = data.financialTransactionId;
                this.financialTxn.vhextAccountId = data.vhextAccountId;
                this.financialTxn.projectId = data.projectId;
                this.financialTxn.entityRecordMasterId = data.entityRecordMasterId;
                this.financialTxn.entity = data.entity;
                this.financialTxn.accountHeadId = data.accountHeadId;
                this.financialTxn.transactionTypeId = data.transactionTypeId;
                this.financialTxn.categoryId = data.categoryId;
                this.financialTxn.title = data.title;
                this.financialTxn.description = data.description;
                this.financialTxn.amount = data.amount;
                this.financialTxn.preparedById = data.preparedById;
                this.financialTxn.transactionDt = data.transactionDt;
                this.financialTxn.transactionById = data.transactionById;
                this.financialTxn.accountablePersonId = data.accountablePersonId;
                this.financialTxn.statusId = data.statusId;
                this.financialTxn.activityId = data.activityId;
                this.financialTxn.entityId = data.entityId;
            } else {
                this.financialTxn.id = '';
                this.financialTxn.financialTransactionId = '';
                this.financialTxn.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.financialTxn.projectId = localStorage.getItem("projectId");
                this.financialTxn.entityRecordMasterId = '';
                this.financialTxn.entity = 'financial_transaction';
                this.financialTxn.accountHeadId = '';
                this.financialTxn.transactionTypeId = '';
                this.financialTxn.categoryId = '';
                this.financialTxn.title = '';
                this.financialTxn.description = '';
                this.financialTxn.amount = '';
                this.financialTxn.preparedById = localStorage.getItem("userId");
                this.financialTxn.transactionDt = this.getDateValue(new Date());
                this.financialTxn.transactionById = localStorage.getItem("userId");
                this.financialTxn.accountablePersonId = localStorage.getItem("userId");
                this.financialTxn.statusId = '';
                this.financialTxn.activityId = '';
                this.financialTxn.entityId = '';
            }
            this.financialTxn.editMode = flag;
            this.$nextTick(()=> {
                this.$bvModal.show('addFinTxnPopup');
            })
        },
    }
}
</script>