<template>
    <div>
        <IssueMaster :ermMasterInfo="ermMasterInfo"></IssueMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import IssueMaster from '@/components/ermcomponents/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        IssueMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            routePath: '',
            ermMasterInfo: {
                title: "Issue Master",
                entity: 'issue_master',
                refEntity: 'issue',
                redirectURL: '/issmas/list',
                erdRedirectURL: '/issue/'
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
        if (this.routePath != null && this.routePath.indexOf('service') == 1) {
            this.ermMasterInfo.redirectURL = '/service/issmas/list';
        } else if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURL = '/client/issmas/list';
        } else {
            this.ermMasterInfo.redirectURL = '/issmas/list';
        }
    },
    methods: {}
}
</script>