<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-6">
                            <h2 class="pl-2 mb-0 titel-text">Objective</h2>
                        </div>
                        <div class="col-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.objective.title.$error }">
                                            <label for="title" class="inp">
                                                <input type="text" id="title" placeholder=" " v-model="objective.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.objective.title.$error }">
                                                <span class="label">Title</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                        </div>
                                        <label v-if="$v.objective.title.$error && !$v.objective.title.minLength" class="text-danger">Title must have at least {{$v.objective.title.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.objective.title.$error && !$v.objective.title.required" class="text-danger">Please enter Title</label>
                                        <label v-if="$v.objective.title.$error && !$v.objective.title.maxLength" class="text-danger">Title must not exceed {{$v.objective.title.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="objectiveTypeId">Type</label>
                                        <select v-model="objective.objectiveTypeId" class="form-control">
                                            <option value="">Type</option>
                                            <option v-for="(info, index) in objectiveTypes" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="timeline">Timeline</label>
                                        <input type="date" v-model="objective.timeline" class="form-control" id="timeline">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="objective">Objective</label>
                                        <textarea type="text" v-model="objective.objective" maxlength="2000" class="form-control" id="objective" rows="2" required></textarea>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="deliverables">Deliverables</label>
                                        <!-- <select v-model="objective.deliverables" class="form-control">
                                            <option value="">Deliverables</option>
                                            <option v-for="(info, index) in deliverables" :value="info.id">{{info.value}}</option>
                                        </select> -->
                                        <multiselect v-model="objective.deliverables" :options="deliverables" name="deliverable" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="accountablePersonId">Responsible Person</label>
                                        <select v-model="objective.accountablePersonId" class="form-control">
                                            <option value="">Responsible Person</option>
                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="statusId">Status</label>
                                        <select v-model="objective.statusId" class="form-control">
                                            <option value="">Status</option>
                                            <option v-for="(info, index) in objectiveStatus" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                
                                
                            </div>
                            <a href="javascript:" @click="saveObjective" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</a>
                            <a href="javascript:" @click="clearData" class="btn btn-info mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsMixin } from "@/mixins/requestsMixin";
import Multiselect from 'vue-multiselect';
export default {
    name: 'app',
    components: {
        Multiselect,
    },
    mixins: [requestsMixin],
    data() {
        return {
            objectiveStatus: [],
            objectiveTypes: [],
            deliverables: [],
            users: [],
            entity: 'objective',
           
            objective: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                entity: 'objective',
                goalId: '',
                objectiveId: '',
                title: '',
                objectiveTypeId: '',
                objective: '',
                timeline: '',
                accountablePerson: '',
                achievementRating: '',
                deliverables: [],
                accountablePersonId: '',
                projectEntityId: ''
            }

        }
    },
    mounted() {
        this.lookupData();
    },
    validations: {
        objective: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
   methods: {
        async lookupData() {
            try {                
                let data = {
                    projectId: this.objective.projectId,
                    vhextAccountId: this.objective.vhextAccountId,
                    entity: 'objective',
                }
                const response = await this.getObjectiveLookup(data);
                let result = response.data;
                this.objectiveStatus = result.statusLookup;
                this.objectiveTypes = result.customTypeLookup;
                this.users = result.userLookup;
                this.deliverables = result.deliverableLookup;
            } catch(error) {
                console.log(error);
            }
        },
        async saveObjective() {
            this.$v.objective.$touch();
            if (!this.$v.objective.$invalid) {
                try {                    
                    console.log(this.objective)
                    const response = await this.addObjective(this.objective);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: 'Objective info Saved',
                            position: 'topRight'
                        });
                        this.clearData();
                        this.$router.push('/objective/' + this.objective.entityRecordMasterId );
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        },
        clearData: function() {
            this.objective.title = '';
            this.objective.objectiveTypeId = '';
            this.objective.accountablePersonId = '';
            this.objective.timeline = '';
            this.objective.statusId = '';
            this.objective.objective = '';
            this.objective.deliverables = [];
            this.objective.id = '';
            this.$v.objective.$reset();
        }
    }
}
</script>