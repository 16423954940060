import LandingSettings from '@/views/settings/LandingSettings.vue'
import Settings from '@/views/settings/Settings.vue'
import VhextAccountProfile from '@/views/settings/VhextAccountProfile.vue'

export default [
    {
        path: '/landing/settings',
        name: 'settings',
        component: LandingSettings,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/vhextaccountprofile',
        name: 'vhextaccountprofile',
        component: VhextAccountProfile,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
];