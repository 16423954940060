<template>
    <div>
        <div class="footer-menu">
            <b-overlay :show="showAppLoader" z-index="20000" opacity="0.4" rounded="sm">
                <div class="row no-gutters justify-content-center">
                    <div v-for="menu in footerMenu" class="col-auto">
                        <a href="#" @click.prevent="activeMenuRoute = menu.route;routeToPage(menu)" :class="menu.route == activeMenuRoute ? 'active' : ''">
                            <i class="fa" :class="menu.icon"></i>
                            <p>{{menu.menu}}</p>
                        </a>
                    </div>
                </div>
                <template #overlay>
				<div class="">
				</div>
                </template>
            </b-overlay>
        </div>
    </div>
</template>
<script>
export default {
    name: 'footer-menu',
    data() {
        return {
            activeMenuRoute: '',

            footerMenu: [
                { id: 101, menu: 'Projects', icon: 'fa-home', route: '/prm/landing' },
                { id: 102, menu: 'Clients', icon: 'fa-user-o', route: '/prm/landing/client' },
                { id: 103, menu: 'Users', icon: 'fa-users', route: '/prm/landing/user' },
                { id: 104, menu: 'Resources', icon: 'fa-id-card', route: '/prm/landing/resource' },
                { id: 105, menu: 'Contacts', icon: 'fa-address-card-o', route: '/prm/landing/contact' },
            ],

        }
    },
    computed: {
        showAppLoader: {
			get() {
				return this.$store.state.showAppLoader;
			},
			set(val) {
				this.$store.state.showAppLoader = val;
			}
		}
    },
    mounted() {
        this.activeMenuRoute = this.$route.path;
    },
    methods: {
        routeToPage: function(menu) {
            let currentPath = this.$route.path;
            if (currentPath && currentPath !== menu.route) {
                this.$router.push(menu.route);
            }
        }
    }
}
</script>