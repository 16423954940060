<template>
    <div>
        <RequirementSpecMasterList :ermMasterInfo="ermMasterInfo"></RequirementSpecMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import RequirementSpecMasterList from '../../components/ermcomponents/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        RequirementSpecMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "System Requirement Spec Docs",
                entity: 'system_requirement_spec_master',
                refEntity:'requirement_spec',
                tooltip: 'New Requirement Spec Doc',
                redirectURLName: 'reqspc',
                newEntityMasterURL: 'srsmas',
                redirectURL: '/srsmas',
                actionName: 'Requirements',
                previewURLName: 'srsrep',
                sharedUrlName:'reqspc'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>