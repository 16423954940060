import ax from 'axios'

window.axios = ax;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.request.use(
    function(config) {
        const token = localStorage.getItem('userAuthData');        
        if (token) {
            config.headers["Authorization"] =  token;
        }
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);


window.axios.interceptors.response.use(function(response) {
    //$('#globalapploader').fadeOut('slow');
    // console.log(response);
    return response;
}, function(error) {
    //$('#globalapploader').fadeOut('slow');
    console.log(error.response);
    return Promise.reject(error) // this is the important part
});

