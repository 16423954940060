<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 pr-2">
                    <div class="row no-gutters">
                        <div class="col">
                            <div class="row no-gutters">
                                <div class="col-12">
                                    <label class="mb-0 d-block font-weight-bold my-1">{{ task.title }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col float-right pl-1">
                            <div class="row no-gutters">
                                <div class="col-12 text-right">
                                    <label class="p-1 mb-0 d-inline-block header-details">{{ task.estimatedEndDt | formatDateTimeYearCheck }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row px-2">
                <div class="card col-12 col-lg-4 col-xl-4 mb-5 px-0">
                    <div class="">
                        <div class="card-body py-2 px-0 mx-0 mb-3">
                            <div class="col-lg-12 col-xl-12">
                                <div class="row no-gutters mb-2">
                                    <div class="col-4 text-left pr-1">
                                        <label class="px-1 py-2 mb-0 d-block header-details" title="Status">{{ task.status }}</label>
                                    </div>
                                    <div class="col-8">
                                        <div class="p-1 d-block header-details">
                                            <span class="" title="Task Progression">{{ task.progressPercentage ? task.progressPercentage : 0 }}% done</span>
                                            <b-progress height="7px" :variant="progressBarVariant(task)" v-model="task.progressPercentage"></b-progress>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details text-muted">Activity</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details">{{task.activity}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div v-if="task.description" class="col-12">
                                        <div class="card mb-2">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 pl-1 text-muted"><span>Task Description</span></p>
                                                <div class="row no-gutters">
                                                    <div class="col-12 m-0">
                                                        <label class="px-1 mb-0 d-block header-details" v-html="task.description"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="task.response" class="col-12">
                                        <div class="card mb-2">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 pl-1 text-muted"><span>Response</span></p>
                                                <div class="row no-gutters">
                                                    <div class="col-12 m-0">
                                                        <label class="px-1 mb-0 d-block header-details" v-html="task.response"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div v-if="task.estimatedStartDt !== ''" class="col-12">
                                        <div class="row no-gutters mb-2">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details text-muted">Start Date</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details">{{task.estimatedStartDt | formatDateTimeAmPm }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="task.assignedBy !== ''" class="col-12">
                                        <div class="row no-gutters mb-2">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details text-muted">Assigned By</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details">{{task.assignedBy}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="task.assignedTo !== ''" class="col-12">
                                        <div class="row no-gutters mb-2">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details text-muted">Assigned To</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details">{{task.assignedTo}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div v-if="task.effort" class="col-12">
                                        <div class="row no-gutters mb-2 header-details">
                                            <div class="col-4">
                                                <label class="mb-0 p-1 d-block">
                                                {{ task.effort }}
                                                <span v-if="task.effortType > 0">{{getEffortType(effortTypes, task.effortType)}}</span>
                                                </label>
                                            </div>

                                        </div>
                                    </div> -->
                                    <div v-if="task.effortType" class="col-12">
                                        <div class="row no-gutters mb-2">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details text-muted">Effort</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details">{{ task.effort }} {{ getEffortType(effortTypes, task.effortType) }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="timeSheets.length>0" class="row no-gutters mt-2">
                                    <div class="col-12 py-2 px-0">
                                        <h6 class="contact-title mb-2 ml-2"><span>Task Progression</span></h6>
                                        <div v-for="timeSheet in timeSheets" :key="timeSheet.id" class="card px-3 pt-2 pb-1 mb-2">
                                            <p class="mb-0">
                                                <label class="badge badge-info float-right" title="Status">{{ timeSheet.status }}</label>
                                                <span  class="font-weight-bold">{{ timeSheet.taskTitle }}</span>
                                            </p>                                            
                                            <div class="row no-gutters">
                                                <div class="col-12 col-md-4">
                                                    <div class="row no-gutters">
                                                        <div class="col-10 text-left">
                                                            <span v-if="timeSheet.startDate && timeSheet.startDate !== null" class="float-left pr-2">{{ timeSheet.startDate | formatDateTimeYearCheck }}</span> to
                                                            <span v-if="timeSheet.endDate && timeSheet.endDate !== null" class="">{{ timeSheet.endDate | formatDateTimeYearCheck }}</span>
                                                            <span v-else>-</span>
                                                        </div>
                                                        <div class="col-2 text-right">
                                                            <span class="float-right border">{{ (timeSheet.dateTimeDiff && timeSheet.dateTimeDiff !== null) ? timeSheet.dateTimeDiff : '-' }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="col-12 col-md-4">
                                                    <div class="row no-gutters">
                                                        <div class="col-4">
                                                            <span class="font-weight-bold">End Time</span>
                                                        </div>
                                                        <div class="col-7 ml-auto mr-0">
                                                            
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <!-- <div class="col-12 col-md-4">
                                                    <div class="row no-gutters">
                                                        <div class="col-4">
                                                            <span class="font-weight-bold">Duration</span>
                                                        </div>
                                                        <div class="col-7 ml-auto mr-0">
                                                            <span>{{ (timeSheet.dateTimeDiff && timeSheet.dateTimeDiff !== null) ? timeSheet.dateTimeDiff : '-' }}</span>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                            <div v-if="timeSheet.description" class="row no-gutters mb-1">
                                                <div class="col-4">
                                                    <span class="font-weight-bold">Description</span>
                                                </div>
                                                <div class="col-7 ml-auto mr-0 text-muted">
                                                    <span v-html="timeSheet.description"></span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col float-left">
                                                    <span class="subtle fs-small">{{ timeSheet.preparedBy }}</span>
                                                </div>
                                                <div class="col mr-0 ml-auto text-right">
                                                    <span class="subtle fs-small">{{ timeSheet.lastUpdatedDt | formatDateTimeYearCheck }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteTask" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Task?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsTask } from "@/mixins/requestsTask";
import { EventBus } from '@/main';

export default {
    name: 'app',
    mixins: [requestsTask],
    data() {
        return {
            task: {},
            timeSheets: [],
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            entity: 'task',
            
            effortTypes: [
                { name: "Man Hours", id: 1 },
                { name: "Man Days", id: 2 },
                { name: "Man Months", id: 3 },
                { name: "Man Years", id: 4 },
            ],

            deleteParam: {
                id: 0
            },
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'Task';
        this.$store.state.editPageUrl = 'edittsk';
        if(this.$store.state.isActionItemRedirect) {
            let erdId = localStorage.getItem("erdId");
            this.getTaskDetails(erdId);
        }
        else {
            this.task = JSON.parse(localStorage.getItem("rowData"));
            this.getTimeSheets(this.task.id);
        }
    },
    created() {
        EventBus.$on("triggerDelete", this.deleteTask);
    },
    destroyed() {
        EventBus.$off("triggerDelete", this.deleteTask);
        this.$store.state.isActionItemRedirect = false;
        this.$store.state.ermLookup = [];
    },
    methods: {
        getTaskDetails: function(id) {
            this.$nextTick(() => {
                this.getTimeSheets(id)
            });
            axios.get(this.$store.getters.getAPIBasePath + '/task/view/' + id)
            .then((response) => {
                let result = response.data;
                if (result.task) {
                    let task = JSON.stringify(result.task);
                    localStorage.setItem("rowData", task);
                    this.task = JSON.parse(localStorage.getItem("rowData"));
                }
            })
            .catch(error => {
                console.log(error);
            });
        },
        getTimeSheets: function(id) {
            if (id && id != '') {
                let data = {
                    taskId: id
                }
                console.log(data);
                axios.post(this.$store.getters.getAPIBasePath + '/task/timesheetbytask/list', data)
                    .then((response) => {
                        let result = response.data;
                        this.timeSheets = result.timeSheetListByTask;
                        console.log(result);
                    })
                    .catch(error => {
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    });
            }
        },
        deleteTask: function() {
            this.deleteParam.id = this.task.id;
            this.$nextTick(() => {
                this.$bvModal.show('deleteTask');
            })
        },
        onYes: function() {
            this.$store.state.showAppLoader = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/task/delete/' + this.deleteParam.id)
                .then(response => {
                    this.$store.state.showAppLoader = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/task');
                    }
                })
                .catch(error => {
                    this.$store.state.showAppLoader = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                });
        },
        progressBarVariant: function(task) {
            if (task.progressPercentage != null) {
                if (task.progressPercentage < 25) {
                    return 'danger';
                } else if (task.progressPercentage < 50) {
                    return 'warning';
                } else if (task.progressPercentage < 75) {
                    return 'info';
                } else if (task.progressPercentage < 100) {
                    return 'success';
                } else if (task.progressPercentage == 100) {
                    return 'primary';
                }
            }
        },
        getEffortType: function(effortTypes, type) {
            let effortType = this.effortTypeFilter(effortTypes, type);
            if (effortType != null) {
                return effortType[0].name;
            }
        },
    }
}
</script>