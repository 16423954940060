<template>
    <div>
        <header class="main-header">
            <div class="container_header">
                <div class="logo text-left">
                    <div class="row d-flex min-h pos-md-r">
                        <div class="col-6 pr-0">
                            <!-- <div  class="icon_menu full_menu">
                                <a href="#" class="menu-toggler sidebar-toggler"></a>
                            </div> -->
                            <a href="#" class="logo-img-section">
                                <span class="">
                                    <!-- {{ pageTitle }} -->
                                    <img src="@/assets/images/VHextvMx.png" alt=""> 
                                </span>
                            </a>
                        </div>
                        <div class="col-6 pl-0">
                            <div class="right_bar_top landing-page-user">
                                <!-- DropDown_Inbox -->
                                <div v-if="false" class="dropdown dropdown-inbox">
                                    <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                        <i class="fa fa-envelope-o"></i> <span class="badge_coun"> 2 </span> </a>
                                    <ul class="dropdown-menu scroll_auto height_fixed">
                                        <li class="bigger">
                                            <h3><span class="bold">Messages</span></h3>
                                            <span class="notification-label">New 2</span>
                                        </li>
                                        <li>
                                            <ul class="dropdown-menu-list">
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <!--DropDown_Inbox_End -->
                                <!-- Dropdown_User -->
                                <div class="dropdown dropdown-user ">
                                    <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="true"> <img class="img-circle pro_pic" src="@/assets/images/about-1.jpg" alt=""> </a>
                                    <ul class="dropdown-menu dropdown-menu-default">
                                        <li>
                                            <a href="#"> <i class="fa fa-user-o"></i> Profile </a>
                                        </li>
                                        <li>
                                            <a href="#" @click.prevent="$router.push('/landing/settings')"> <i class="fa fa-cog"></i> Settings </a>
                                        </li>
                                        <!-- <li>
                                            <a href="#"> <i class="fa fa-info"></i> Help </a>
                                        </li> -->
                                        <!-- <li class="divider"></li> -->
                                        <!-- <li>
                                            <a href="#"> <i class="fa fa-lock"></i> Lock </a>
                                        </li> -->
                                        <!-- <li>
                                            <a href="/login"><i class="fa fa-sign-in" aria-hidden="true"></i> Log In</a>
                                        </li> -->
                                        <li>
                                            <a class="dropdown-item" @click="performLogout" href="Javascript:void(0);"><i class="fa fa-unlock-alt"></i> Log Out </a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- Dropdown_User_End -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
import { requestsMixin } from "@/mixins/requestsMixin";
export default {
    name: 'app',
    components: {},
    mixins: [requestsMixin],
    data() {
        return {
            elements: {
                showDetail: false,                
            },
            routePath: '',
        }
    },
/*    watch: {
        '$route.path': {
            handler: function(path) {
                if (path != null && path.indexOf('landing') != -1) {
                    this.elements.showMenu = false;
                } else {
                    this.elements.showMenu = true;
                }
            },
            deep: true,
            immediate: true
        },
    },*/
    mounted() {},
    methods: {}
}
</script>