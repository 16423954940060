<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <!-- breadcrumb -->
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="float-left">
                                <h1 class="text-dark titel-text">Projects</h1>
                            </div>
                            <b-button class="btn btn-info float-right" @click="$router.push('/landing/addproject')" size="sm"><i class="fa fa-plus"></i></b-button>
                        </div>
                    </div>
                </div>
                <!-- breadcrumb_End -->
                <!-- Section -->
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                        <b-table variant="primary" show-empty small selectable :select-mode="selectMode" sort-icon-left :items="projects" :fields="projectFields" responsive="sm" @row-selected="onRowSelected" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4">
                                            <template v-slot:cell(title)="data">
                                                <div :class="data.item.status" class="text-overflow-dots">
                                                    <a href="javascript:" @click.prevent="setSession(data.item)" >{{data.value}}</a>
                                                    <p>
                                                        <span>{{ formatDate(data.item.endDate) }}</span>
                                                    </p>
                                                </div>
                                            </template>
                                            <template v-slot:cell(status)="data">
                                                <span v-if="data.item.status == 'Closed'" class="label label-info mb-1">{{data.item.status}}</span>
                                                <span v-else-if="data.item.status == 'Started'" class="label label-primary mb-1">{{data.item.status}}</span>
                                                <span v-else-if="data.item.status == 'Open'" class="label label-success mb-1">{{data.item.status}}</span>
                                                <span v-else-if="data.item.status == 'Cancelled'" class="label label-danger mb-1">{{data.item.status}}</span>
                                                <span v-else-if="data.item.status == 'On Hold'" class="label label-warning mb-1">{{ data.item.status }}</span>
                                                <span v-else-if="data.item.status == 'Completed'" class="label label-success mb-1">{{data.item.status}}</span>
                                                <p v-if="data.item.initialBudget"><i class="fa fa-inr" aria-hidden="true"></i> {{data.item.initialBudget}} </p>
                                            </template>
                                            <template v-slot:empty="scope">
                                                <div class="text-center">No Projects Found</div>
                                            </template>
                                            <template v-slot:emptyfiltered="scope">
                                                <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                            </template>
                                        </b-table>
                                        <b-pagination v-if="projects.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" @click="$router.push('/landing/addproject')" size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import { requestsMixin } from "@/mixins/requestsMixin";
import iziToast from 'izitoast';
export default {
    mixins: [requestsMixin],
    data() {
        return {
            elements: {
                showRegister: false,
            },
            disableLogin: false,
            error: false,
            entity: 'vhext_account',
            submitted: false,
            accountTypes: [],
            countries: [],
            vhextAccount: {
                accountName: '',
                vhextAccountTypeId: '',
                emailId: '',
                phone: '',
                password: '',
                confirmationPassword: '',
                terms: '',
                company: '',
                jobTitle: '',
                countryId: ''
            },
            login: {
                loginName: '',
                password: '',
                key: this.$route.params.key,
                userType: this.$route.params.userType
            },
            projectFields: [
                { key: 'title', label: 'Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' }
            ],
            selectMode: 'single',
            projects: [],
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
            isBusy: true,
            totalRows: 1,
            projectCount: '0',
        }
    },
    mounted() {
        this.getAllProjects();
    },
    methods: {
        async getAllProjects() {
            try {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    userId: localStorage.getItem("userId"),
                    isArchive: false,
                }
                this.$store.state.showAppLoader = true;
                const response = await this.getProjects(data);
                this.$store.state.showAppLoader = false;
                console.log(response);
                let result = response.data;
                if (result.projectList.length != 0) {
                    this.projects = result.projectList;
                    console.log(result);
                    this.totalRows = this.projects.length;
                    this.projectCount = this.totalRows;
                    this.isBusy = !this.isBusy
                }
            }
            catch(error) {
                this.$store.state.showAppLoader = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },


        clearData: function() {
            this.elements.showRegister = false;
            this.vhextAccount.accountName = '';
            this.vhextAccount.vhextAccountTypeId = '';
            this.vhextAccount.emailId = '';
            this.vhextAccount.phone = '';
            this.vhextAccount.password = '';
            this.vhextAccount.terms = '';
            this.vhextAccount.confirmationPassword = '';
            this.vhextAccount.company = '';
            this.vhextAccount.title = '';
            this.vhextAccount.countryId = '';
            this.$v.vhextAccount.$reset();
        },
        onRowSelected: function(items) {

            if (items && items.length > 0) {
                let data = items[0];
                console.log(data)
                localStorage.setItem('projectId', data.id);
                localStorage.setItem('projectTypeId', data.projectTypeId);
                localStorage.setItem('projectName', data.title);
                localStorage.setItem('currency', data.currencySymbol);
                localStorage.setItem("rowData", JSON.stringify(data));
                this.$nextTick(() => {
                    this.$router.push('/actionitem');
                });
            }
        },
        setSession(data) {
            localStorage.setItem('projectId', data.id);
            localStorage.setItem('projectTypeId', data.projectTypeId);
            localStorage.setItem('projectName', data.title);
            localStorage.setItem('currency', data.currencySymbol);
            localStorage.setItem("rowData", JSON.stringify(data));
            this.$nextTick(() => {
                this.$router.push('/viewproject/' + data.id)
            })
        },
        filterAccountType: function(individual, company) {
            return this.accountTypes.filter(function(data, key) {
                if (data.value == individual || data.value == company) {
                    return data;
                }
            });
        },
    }
}
</script>