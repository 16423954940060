<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-6">
                            <h2 class="pl-2 mb-0 titel-text">New TimeSheet</h2>
                        </div>
                        <div class="col-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.timeSheet.title.$error }">
                                            <label for="title" class="inp">
                                                <input type="text" id="title" placeholder=" " v-model="timeSheet.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.timeSheet.title.$error }">
                                                <span class="label">Title</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                        </div>
                                        <label v-if="$v.timeSheet.title.$error && !$v.timeSheet.title.minLength" class="text-danger">Title must have at least {{$v.timeSheet.title.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.timeSheet.title.$error && !$v.timeSheet.title.required" class="text-danger">Please enter Title</label>
                                        <label v-if="$v.timeSheet.title.$error && !$v.timeSheet.title.maxLength" class="text-danger">Title must not exceed {{$v.timeSheet.title.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="taskId">Task</label>
                                        <select v-model="timeSheet.taskId" class="form-control">
                                            <option value="">Task</option>
                                            <option v-for="(info, index) in tasks" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="activityId">Activity</label>
                                        <select v-model="timeSheet.activityId" class="form-control">
                                            <option value="">Activity</option>
                                            <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="startDate">Start Date</label>
                                        <div>
                                            <input v-model="timeSheet.startDate" type="date" class="form-control" placeholder="Start Date">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="endDate">End Date</label>
                                        <div>
                                            <input v-model="timeSheet.endDate" type="date" class="form-control" placeholder="End Date">
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="statusId">Task Status</label>
                                        <select v-model="timeSheet.statusId" class="form-control">
                                            <option value="">Task Status</option>
                                            <option v-for="(info, index) in timeSheetStatus" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div> 
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="card-body p-0 mb-3">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                <label class="d-block p-1 accordion-title" block href="javascript:" v-b-toggle.accordion-1>
                                                    <span>Description</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-1" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                <b-card-body class="p-0">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <!-- <label for="description">Objective</label> -->
                                                                <textarea type="text" v-model="timeSheet.description" maxlength="2000" class="form-control" id="description" rows="2" required></textarea>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:" @click="saveTimeSheet" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</a>
                            <a href="javascript:" @click="clearData" class="btn btn-info mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsTask } from "@/mixins/requestsTask";
import Multiselect from 'vue-multiselect';
export default {
    name: 'app',
    components: {
        Multiselect,
    },
    mixins: [requestsTask],
    data() {
        return {
            tasks: [],
            activities: [],
            timeSheetStatus: [],
            entity: 'time_sheet',
           
            timeSheet: {
                id: '',
                timeSheetId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                preparedById: localStorage.getItem("userId"),
                entityRecordMasterId: this.$route.params.ermId,
                title: '',
                taskId: '',
                task: '',
                activityId: '',
                activity: '',
                preparedBy: '',
                startDate: '',
                endDate: '',
                description: '',
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: '',
                entity: this.entity
            }

        }
    },
    mounted() {
        this.lookupData();
    },
    validations: {
        timeSheet: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
   methods: {
        async lookupData() {
            try {                
                let data = {
                    vhextAccountId: this.timeSheet.vhextAccountId,
                    projectId: this.timeSheet.projectId,
                    entityRecordMasterId: this.timeSheet.entityRecordMasterId,
                    entity: 'time_sheet',
                }
                const response = await this.getTimeSheetLookup(data);
                let result = response.data;
                this.tasks = result.taskLookup;
                this.activities = result.activityLookup;
                this.timeSheetStatus = result.statusLookup;
            } catch(error) {
                console.log(error);
            }
        },
        async saveTimeSheet() {
            this.$v.timeSheet.$touch();
            if (!this.$v.timeSheet.$invalid) {
                try {                    
                    console.log(this.timeSheet)
                    const response = await this.addTimeSheet(this.timeSheet);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: 'TimeSheet info Saved',
                            position: 'topRight'
                        });
                        this.clearData();
                        this.$router.push('/timesheetlist/' + this.timeSheet.entityRecordMasterId );
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        },
        clearData: function() {
            this.timeSheet.timeSheetId = '';
            this.timeSheet.taskId = '';
            this.timeSheet.title = '';
            this.timeSheet.statusId = '';
            this.timeSheet.activityId = '';
            this.timeSheet.activity = '';
            this.timeSheet.status = '';
            this.timeSheet.activity = '';
            this.timeSheet.startDate = '';
            this.timeSheet.endDate = '';
            this.timeSheet.description = '';
            this.$v.timeSheet.$reset();
        }
    }
}
</script>