<template>
        <div class="wrapper">
            <Header />
            <div class="container_full h-100">
                <LeftMenu />
                <main class="h-100 pagenotfound-height">
                    <slot />
                </main>
            </div>
            <Footer />
        </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from '@/layouts/blocks/FooterMenu';
import LeftMenu from '@/layouts/blocks/LeftMenu';
export default {
    components: {
        Header,
        Footer,
        LeftMenu
    },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>