<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12  col-md-6">
                            <div class="float-left">
                                <h1 class="text-dark titel-text">Requirement</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" show-empty small selectable :select-mode="selectMode" 
                                    sort-icon-left :items="requirementSpecs" :fields="budgetPlanFields" responsive="sm" @row-selected="onRowSelected" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                        <template v-slot:cell(title)="data">
                                            <div :class="data.item.title" class="text-overflow-dots">
                                                <p >{{ data.value }} </p>
                                                <p v-if="data.item.requirementTypeId"><span class="pr-2 font-light">Req.Type</span>{{ data.item.type }} </p>
                                                <p v-if="data.item.preparedById"><span class="pr-2 font-light">Req.By</span>{{ data.item.preparedBy }} </p>
                                            </div>
                                        </template>
                                        
                                        <template v-slot:cell(status)="data">
                                            
                                            <span v-if="data.item.statusId" class="label label-primary mb-1 mr-1"> {{data.item.status}}</span>
                                            <p v-if="data.item.priorityId" class="pr-1 " ><span class="pr-2 font-light">Priority</span>{{ data.item.priority }} </p>
                                            <p v-if="data.item.dueDate" class="pr-1 " ><span class="pr-2 font-light">Due Date</span>{{ formatDate(data.item.dueDate) }} </p>
                                       </template>
                                        <template  v-slot:empty="scope">
                                            <div class="text-center">No Requirement Spec Found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="requirementSpecs.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" @click="$router.push('/addrequirementspec/' + entityRecordMasterId)" size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsRequirement } from "@/mixins/requestsRequirement";

export default {
     mixins: [requestsRequirement],
    data() {
        return {
            elements: {
                showRegister: false,
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            htmlData: '',
            htmlDetailViewTitle: 'Requirement Specification',
            typeLookupTitle: 'Requirement Type Settings',
            functionalRefPopupTitle: 'Add Reference',
            entity: 'requirement_spec',
            users: [],
            requirementTypes: [],
            requirementStatus: [],
            requirementPriorities: [],
            requirementActivities: [],
            ermReferenceList: [],
            requirementSpecs: [],
            requirementSpec: {
                id: 0,
                requirementSpecId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                title: '',
                requirementTypeId: '',
                requirementSpecification: '',
                preparedById: '',
                preparedDt: new Date().toISOString().substr(0, 10),
                priorityId: '',
                dueDate: '',
                statusId: '',
            },


            budgetPlanFields: [
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
            ],
            selectMode: 'single',
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
           
            totalRows: 1,
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })

        },

    },
    mounted() {
        this.initialSettings();
        this.getRequirementSpecs();
    },
    methods: {

         
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'requirement_spec';
            this.userId = localStorage.getItem("userId");

            this.requirementSpec.vhextAccountId = this.vhextAccountId;
            this.requirementSpec.projectId = this.projectId;
            this.requirementSpec.entityRecordMasterId = this.entityRecordMasterId;
            this.requirementSpec.preparedById = this.userId;
            if (this.entityRecordMasterId.length > 10) {
                this.getShareDecodedData(this.entityRecordMasterId);
            } else {
                this.getRequirementSpecs();
            }
        },
        async getRequirementSpecs() {
            try {
                let data = {
                        entityRecordMasterId: this.entityRecordMasterId,
                        vhextAccountId: localStorage.getItem("vhextAccountId"),
                        userId: localStorage.getItem("userId"),
                        isArchive: false,
                    }
                    const response = await this.getRequirementSpec(data);
                    console.log(response);
                    let result = response.data;
                    if (result.requirementSpecList.length != 0) {
                        this.requirementSpecs = result.requirementSpecList;
                        console.log(result);
                        this.totalRows = this.requirementSpecs.length;
                    }
                }catch (error) {
                    console.log(error);
            }
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },


        onRowSelected: function(items) {
             if (items && items.length > 0){
                let data = items[0];
                console.log(data)
                localStorage.setItem("rowData", JSON.stringify(data));
                this.$nextTick(() => {
                    this.$router.push('/viewRequirementSpec/' + data.id);
                });
            }
        },

        filterAccountType: function(individual, company) {
            return this.accountTypes.filter(function(data, key) {
                if (data.value == individual || data.value == company) {
                    return data;
                }
            });
        },
    }
}
</script>