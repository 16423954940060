<template>
    <div>
        <ProjectDocumentMaster :ermMasterInfo="ermMasterInfo"></ProjectDocumentMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ProjectDocumentMaster from '../../components/ermcomponents/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        ProjectDocumentMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            ermMasterInfo: {
                title: "Project Folder",
                entity: 'project_document_master',
                refEntity: 'project_document',
                ermListRedirectURL: '/pdcmas/list',
                erdRedirectURL: '/projectdocument/'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>
