<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <div class="row">
                                
                                <div class="col-12">
                                    <div class="form-group ">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.client.clientName.$error }">
                                            <label for="clientName" class="inp">
                                                <input type="text" id="clientName" placeholder=" " v-model="client.clientName" class="mobile-form-control"  :class="{ 'is-invalid': $v.client.clientName.$error }">
                                                <span class="label">Client Name</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                        </div>
                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.minLength" class="text-danger">Client Name must have at least {{$v.client.clientName.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.required" class="text-danger">Please enter Client Name</label>
                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.maxLength" class="text-danger">Client Name must not exceed {{$v.client.clientName.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="clientType">Client Type</label>
                                        <select v-model="client.clientType"  @change="changeClientType(client.clientType)" class="form-control" id="clientType">
                                            <option value="">Client Type</option>
                                            <option v-for="(info, index) in filteredClientTypes" :key="index" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-if="client.clientTypeName != 'company'" class="col-12">
                                    <div class="form-group">
                                        <label for="profession" class="inp">
                                            <input type="text" id="profession" placeholder=" " v-model="client.profession" class="mobile-form-control">
                                            <span class="label">Profession</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div v-if="client.clientTypeName != 'company'" class="col-12">
                                    <div class="form-group">
                                        <label for="employer" class="inp">
                                            <input type="text" id="employer" placeholder=" " v-model="client.employer" class="mobile-form-control">
                                            <span class="label">Employer</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.client.emailId.$error }">
                                            <label for="emailId" class="inp">
                                                <input type="text" id="emailId" placeholder=" " v-model="client.emailId" class="mobile-form-control"  :class="{ 'is-invalid': $v.client.emailId.$error }">
                                                <span class="label">Email ID</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                        </div>
                                        <label v-if="$v.client.emailId.$error && !$v.client.emailId.email" class="text-danger">Enter Valid EmailId</label>
                                        <label v-if="$v.client.emailId.$error && !$v.client.emailId.required" class="text-danger">Please enter EmailId</label>
                                        <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="card-body p-0 mb-2">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                <label class="d-block p-1 accordion-title" block href="javascript:" v-b-toggle.accordion-1>
                                                    <span>Contact Info</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-1" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                <b-card-body class="p-0">
                                                    <div class="row">
						                                    <div class="col-12">
						                                        <div class="form-group">					        
                                                                    <label for="mobile" class="inp">
                                                                        <input type="number" id="phone" placeholder=" " v-model="client.phone" class="mobile-form-control">
                                                                        <span class="label">Phone</span>
                                                                        <span class="focus-bg"></span>
                                                                    </label>
						                                        </div>
						                                    </div>
						                                    <div class="col-12">
						                                        <div class="form-group">					        
                                                                    <label for="mobile" class="inp">
                                                                        <input type="number" id="mobile" placeholder=" " v-model="client.mobile" class="mobile-form-control">
                                                                        <span class="label">Mobile</span>
                                                                        <span class="focus-bg"></span>
                                                                    </label>
						                                        </div>
						                                    </div>
					                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-12">
                                    <div class="card-body p-0 mb-3">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                <label class="d-block p-1 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                    <span>Address</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-2" accordion="my-accordion2" role="tabpanel" class="p-0">
                                                <b-card-body class="p-0">
                                                    <div class="row">
					                                    <div class="col-12">
					                                        <div class="form-group">					        
                                                                <label for="address1" class="inp">
                                                                    <input type="text" id="address1" placeholder=" " v-model="client.address1" class="mobile-form-control">
                                                                    <span class="label">Address 1</span>
                                                                    <span class="focus-bg"></span>
                                                                </label>
					                                        </div>
					                                    </div>
					                                    <div class="col-12">
					                                        <div class="form-group">
                                                                <label for="address2" class="inp">
                                                                    <input type="text" id="address2" placeholder=" " v-model="client.address2" class="mobile-form-control">
                                                                    <span class="label">Address 2</span>
                                                                    <span class="focus-bg"></span>
                                                                </label>
					                                        </div>
					                                    </div>
					                                    <div class="col-12">
					                                        <div class="form-group">
                                                                <label for="mobile" class="inp">
                                                                    <input type="text" id="city" placeholder=" " v-model="client.city" class="mobile-form-control">
                                                                    <span class="label">City</span>
                                                                    <span class="focus-bg"></span>
                                                                </label>
					                                        </div>
					                                    </div>
					                                    <div class="col-12">
					                                        <div class="form-group">
                                                                <label for="state" class="inp">
                                                                    <input type="text" id="state" placeholder=" " v-model="client.state" class="mobile-form-control">
                                                                    <span class="label">State</span>
                                                                    <span class="focus-bg"></span>
                                                                </label>
					                                        </div>
					                                    </div>
					                                    <div class="col-12">
					                                        <div class="form-group">
				                                                <select v-model="client.countryId" class="form-control">
				                                                    <option value="">Country</option>
				                                                    <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
				                                                </select>
					                                        </div>
					                                    </div>
					                                    <div class="col-12">
					                                        <div class="form-group">
                                                                <label for="pincode" class="inp">
                                                                    <input type="number" id="pincode" placeholder=" " v-model="client.pincode" class="mobile-form-control">
                                                                    <span class="label">Pincode</span>
                                                                    <span class="focus-bg"></span>
                                                                </label>
					                                        </div>
					                                    </div>
					                                </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsMixin } from "@/mixins/requestsMixin";
import { EventBus } from '@/main';
export default {
    name: 'app',
    mixins: [requestsMixin],
    data() {
        return {
            elements: {
                alreadyExists: false
            },
            error: false,
            clientTypes: [],
            countries: [],
            prependText: 'Full Name',

            client: {
                clientId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                userId: localStorage.getItem("userId"),
                entity: 'client',
                clientType: {
                    id: 1,
                    text: 'Individual'
                },
                clientTypeId: '',
                clientTypeName: '',
                clientName: '',
                contactPerson: '',
                firstName: '',
                middleName: '',
                lastName: '',
                location: '',
                emailId: '',
                phone: '',
                mobile: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                countryId: '',
                pincode: '',
                hasContactInfo: '',
                active: '',
                profession: '',
                employer: '',
                position: '',
                createdById: localStorage.getItem("userId"),
                invite: false
            },

        }
    },
    computed: {
        filteredClientTypes() {
            return this.clientTypes.filter((type) => type.value !== 'Corporate');
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'New Client';
        this.lookupData();
    },
    created() {
        EventBus.$on("savePage", this.saveClient);
    },
    destroyed() {
        EventBus.$off("savePage", this.saveClient);
    },
    validations: {
        client: {
            clientName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
            emailId: {
                required,
                email,
            }
        }
    },
    methods: {
        async lookupData() {
            try {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId")
                }
                const response = await this.getClientLookup(data);
                let result = response.data;
                this.clientTypes = result.typeLookup;
                this.countries = result.countryLookup;
            } catch (error) {
                console.log(error);
            }
        },
        async saveClient() {
            try {
                if (this.client.clientType != null) {
                    this.client.clientTypeId = this.client.clientType.id;
                }
                this.$v.client.$touch();
                if (!this.$v.client.$invalid) {
                    this.error = false;
                    if (this.client.clientTypeName == 'company') {
                       	 this.client.contactPerson = this.client.firstName + " " + this.client.middleName + " " + this.client.lastName
                    } else {
                        this.client.contactPerson = '';
                    }
                    this.$store.state.showAppLoader = true;
                    const response = await this.addClient(this.client);
                    this.$store.state.showAppLoader = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: 'Client info Saved',
                            position: 'topRight'
                        });
                        this.$router.push('/landing/client');
                    } else if (result.status == 'OK') {
                        this.elements.alreadyExists = true;
                        this.error = true;
                    }
                } else {
                    this.error = true;
                }
            } catch (error) {
                this.$store.state.showAppLoader = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        clearData: function() {
            this.client.title = '';
            this.client.clientType = '';
            this.client.clientTypeId = '';
            this.client.clientTypeName = '';
            this.client.clientName = '';
            this.client.contactPerson = '';
            this.client.firstName = '';
            this.client.middleName = '';
            this.client.lastName = '';
            this.client.emailId = '';
            this.client.phone = '';
            this.client.mobile = '';
            this.client.address1 = '';
            this.client.address2 = '';
            this.client.city = '';
            this.client.state = '';
            this.client.countryId = '';
            this.client.pincode = '';
            this.client.hasContactInfo = '';
            this.client.active = '';
            this.client.profession = '';
            this.client.employer = '';
            this.client.position = '';
            this.elements.alreadyExists = false;
            this.error = false;
            this.$v.client.$reset();
        },
        changeClientType: function(data) {
            if (data.text != null && data.text.length > 0) {
                this.client.clientTypeName = data.text.toLowerCase();
            }
            if (this.client.clientTypeName == 'company') {
                this.prependText = 'Contact Person';
            } else {
                this.prependText = 'Full Name';
            }
        }

    }
}
</script>