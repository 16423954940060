<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 pr-2">
                    <div class="row no-gutters">
                        <div class="col">
                            <div class="row no-gutters">
                                <div class="col-12">
                                    <label class="mb-0 d-block font-weight-bold my-1">{{ financialTxn.title }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row px-2">
                <div class="card col-12 col-lg-4 col-xl-4 mb-5 px-0">
                    <div class="">
                        <div class="card-body py-2 px-0 mx-0 mb-3">
                            <div class="col-lg-12 col-xl-12">
                                <div class="row no-gutters">
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details text-muted">Txn Date</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details">{{financialTxn.transactionDt}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details text-muted">Account Head</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details">{{financialTxn.accountHead }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details text-muted">Txn Type</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details">{{financialTxn.type}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details text-muted">Category</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details">{{financialTxn.category}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details text-muted">Amount</label>
                                            </div>
                                            <div class="col-6 pr-0">
                                                <label class="p-1 mb-0 d-block header-details">{{ currencySymbol }} {{ financialTxn.amount ? financialTxn.amount.toFixed(2) : '' }}</label>
                                            </div>
                                            <div  class="col-2 pl-0">
                                                <label v-if="financialTxn.amountType=='CR'" class="p-1 mb-0 d-block text-white text-center bg-success">{{ financialTxn.amountType }}</label>
                                                <label v-if="financialTxn.amountType=='DR'" class="p-1 mb-0 d-block text-white text-center bg-danger">{{ financialTxn.amountType }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details text-muted">Transaction By</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details">{{financialTxn.transactionBy}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteFinancialTxn" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete this Transaction?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsFinance } from "@/mixins/requestsFinance";
import { EventBus } from '@/main';
export default {
    name: 'app',
    mixins: [requestsFinance],
    data() {
        return {
            financialTxn: {},
            financialTxnTypes: [],
            financialTxnCategories: [],
            users: [],
            accountHeads: [],
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entityRecordMasterId: localStorage.getItem("ermId"),
            currencySymbol: localStorage.getItem("currency"),
            entity: 'financial_transaction',

            deleteParam: {
                id: 0
            }

        }
    },
    mounted() {
        this.$store.state.pageTitle = 'Financial Txn';
        this.$store.state.editPageUrl = 'editfinancialtxn';
        this.financialTxn = JSON.parse(localStorage.getItem("rowData"));
        console.log(this.financialTxn)
    },
    created() {
        EventBus.$on("triggerDelete", this.deleteFinancialTxn);
    },
    destroyed() {
        EventBus.$off("triggerDelete", this.deleteFinancialTxn);
    },
    methods: {
        deleteFinancialTxn: function() {
            this.deleteParam.id = this.financialTxn.id;
            this.$nextTick(() => {
                this.$bvModal.show('deleteFinancialTxn');
            })
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/fintxnbrd' );
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },


    }
}
</script>