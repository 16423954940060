<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <!-- breadcrumb -->
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12  col-md-6">
                            <div class="float-left">
                                <h1 class="text-dark">Settings</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- breadcrumb_End -->
                <!-- Section -->
                <section class="chart_section">
                    <div class="settings">
                        <div class="row">
                            <div class="col-12 pb-1">
                                <router-link title="Company Settings" class="settings-box make-it-slow" :to="{ name: 'vhextaccountprofile'}">
                                    <div class="row col-12 ml-0 left-bar-tab py-3">
                                        <div class="col-1">
                                            <i class="fa fa-tag fa-lg icon-b" aria-hidden="true"></i>
                                        </div>
                                        <div class="col-10 text-left align-self-center">
                                            <h6 class="m-0">Prime Settings</h6>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-12 pb-1">
                                <router-link title="Company Settings" class="settings-box make-it-slow" :to="{ name: 'vhextaccountprofile'}">
                                    <div class="row col-12 ml-0 left-bar-tab py-3">
                                        <div class="col-1">
                                            <i class="fa fa-tag fa-lg icon-b" aria-hidden="true"></i>
                                        </div>
                                        <div class="col-10 text-left align-self-center">
                                            <h6 class="m-0">Company Settings</h6>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-12 pb-1">
                                <router-link title="Activity Settings" class="settings-box make-it-slow" :to="{ name: 'process'}">
                                    <div class="row col-12 ml-0 left-bar-tab py-3">
                                        <div class="col-1">
                                            <i class="fa fa-tag fa-lg icon-b" aria-hidden="true"></i>
                                        </div>
                                        <div class="col-10 text-left align-self-center">
                                            <h6 class="m-0">Process/Activity Settings</h6>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-12 pb-1">
                                <router-link title="Activity Settings" class="settings-box make-it-slow" :to="{ name: 'activity'}">
                                    <div class="row col-12 ml-0 left-bar-tab py-3">
                                        <div class="col-1">
                                            <i class="fa fa-tag fa-lg icon-b" aria-hidden="true"></i>
                                        </div>
                                        <div class="col-10 text-left align-self-center">
                                            <h6 class="m-0">Activity Settings</h6>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-12 pb-1">
                                <router-link title="Deliverable Settings" class="settings-box make-it-slow" :to="{ name: 'deliverable'}">
                                    <div class="row col-12 ml-0 left-bar-tab py-3">
                                        <div class="col-1">
                                            <i class="fa fa-tag fa-lg icon-b" aria-hidden="true"></i>
                                        </div>
                                        <div class="col-10 text-left align-self-center">
                                            <h6 class="m-0">Deliverable Settings</h6>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                            <div class="col-12 pb-1">
                                <router-link title="Account Head Settings" class="settings-box make-it-slow" :to="{ name: 'acchead'}">
                                    <div class="row col-12 ml-0 left-bar-tab py-3">
                                        <div class="col-1">
                                            <i class="fa fa-tag fa-lg icon-b" aria-hidden="true"></i>
                                        </div>
                                        <div class="col-10 text-left align-self-center">
                                            <h6 class="m-0">Account Head Settings</h6>
                                        </div>
                                    </div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    mounted() {
        this.$store.state.pageTitle = 'Settings';
    },
    methods: {}
}
</script>