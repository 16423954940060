<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-8">
                            <h2 class="pl-2 mb-0 titel-text">Plan View</h2>
                        </div>
                        <div class="col-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Title</span>
                                <input type="text" v-model="plan.phaseTitle" @change="updatePlanData" class="view-form w-50" placeholder="Title" name="PhaseTitle">
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Process</span>
                                <select v-model="plan.processId" @change="updatePlanData" class="view-form w-50">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in process" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Activity</span>
                                <select v-model="plan.activityId" @change="updatePlanData" class="view-form w-60">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Responsible Person</span>
                                <select v-model="plan.responsibilityId" @change="updatePlanData" class="view-form w-50">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Start Date</span>
                                <input type="date" v-model="plan.startDate" @change="updatePlanData" class="view-form w-50" placeholder="StartDate" name="startDate">
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">End Date</span>
                                <input type="date" v-model="plan.endDate" @change="updatePlanData" class="view-form w-50" placeholder="EndDate" name="endDate">
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Deliverables</span>
                                <!-- <span v-if="plan.deliverable" v-for="deliverable in (JSON.parse(plan.deliverable))">{{deliverable.value}}, </span> -->
                                <!-- <multiselect v-model="plan.deliverables" :options="deliverables" class="view-form w-50" name="deliverable" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect> -->
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Status</span>
                                <select v-model="plan.statusId" @change="updatePlanData" class="view-form w-50">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in status" :value="info.id">{{info.value}}</option>
                                </select>
                            </p> 
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import { requestsPlan } from "@/mixins/requestsPlan";

export default {
    name: 'app',
    mixins: [requestsPlan],

    components: {
        Multiselect
    },
    data() {
        return {
            plan: {},
            planTypes: [],
            deliverables: [],
            process: [],
            users: [],
            activities: [],
            status: [],

            entity: 'plan',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            activityLookupTitle: 'Activity Settings',


        }

    },
    mounted() {
        this.plan = JSON.parse(localStorage.getItem("rowData"));
        this.lookupData();
        console.log(this.plan);
    },
    methods: {
        lookupData: function() {
            let data = {
                projectId: this.projectId,
                vhextAccountId: this.vhextAccountId,
                entity: this.entity,
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/plan/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.deliverables = result.deliverableLookup;
                    this.process = result.processLookup;
                    this.activities = result.activityLookup;
                    this.users = result.userLookup;
                    this.status = result.statusLookup;
                })

        },
        async updatePlanData() {
            try {
                console.log(this.plan);
                const response = await this.updatePlan(this.plan);
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topCenter'
                    });
                    console.log('saved')
                }
                localStorage.setItem("rowData", JSON.stringify(this.plan));
            } catch (error) {
                console.log(error);
            }
        },
    },

    
}

</script>