<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <div class="row">
                                <div class="col-10">
                                    <div class="form-group">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.financialTxn.title.$error }">
                                            <label for="title" class="inp">
                                                <input type="text" id="title" placeholder=" " v-model="financialTxn.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.financialTxn.title.$error }">
                                                <span class="label">Item Title</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                        </div>
                                        <label v-if="$v.financialTxn.title.$error && !$v.financialTxn.title.minLength" class="text-danger">Item Title must have at least {{$v.financialTxn.title.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.financialTxn.title.$error && !$v.financialTxn.title.required" class="text-danger">Please enter Item Title</label>
                                        <label v-if="$v.financialTxn.title.$error && !$v.financialTxn.title.maxLength" class="text-danger">Item Title must not exceed {{$v.financialTxn.title.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-2 pl-0">
                                    <div class="form-control text-center">
                                        <a href="javascript:" class="d-block" v-b-modal.attachmentPopup><i class="fa fa-paperclip fa-lg" aria-hidden="true"></i><span class="badge badge-secondary position-absolute fs-11 mt-1">{{ attachmentCount ? attachmentCount : '' }}</span></a>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="accountHeadId">Account Head</label>
                                        <select v-model="financialTxn.accountHeadId" class="form-control" id="accountHeadId">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in accountHeads" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="transactionDt">Transaction Date</label>
                                        <input type="date" class="form-control" id="transactionDt" v-model="financialTxn.transactionDt">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="transactionTypeId">Txn Type</label>
                                        <select v-model="financialTxn.transactionTypeId" class="form-control">
                                            <option value="">Txn Type</option>
                                            <option v-for="(info, index) in financialTxnTypes" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="categoryId">Category</label>
                                        <select v-model="financialTxn.categoryId" class="form-control">
                                            <option value="">Category</option>
                                            <option v-for="(info, index) in financialTxnCategories" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Amount</label>
                                        <b-input-group>
                                            <b-input-group-prepend class="custom-input-group-prepend">
                                                <span class="input-group-text">
                                                    <i class="fa fa-inr" aria-hidden="true"></i>
                                                </span>
                                            </b-input-group-prepend>
                                            <b-form-input type="number" v-model="financialTxn.amount">
                                            </b-form-input>
                                            <b-input-group-append class="custom-input-group-append">
                                                <span class="input-group-text">
                                                    .00
                                                </span>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="categoryId">Financial Txn Group</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.financialTxn.entityRecordMasterId.$error }">
                                            <select v-model="financialTxn.entityRecordMasterId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in entityERMLookup" :value="info.id">{{info.value}}</option>
                                            </select>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.financialTxn.entityRecordMasterId.$error && !$v.financialTxn.entityRecordMasterId.required" class="text-danger">Please Select a Financial Txn Group</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="transactionById">Transaction By</label>
                                        <select v-model="financialTxn.transactionById" class="form-control">
                                            <option value="">Transaction By</option>
                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AttachmentPopup ref="attachmentPopupRef" @updateAttachmentCount="updateAttachmentCount" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsFinance } from "@/mixins/requestsFinance";
import { EventBus } from '@/main';
import AttachmentPopup from '@/components/popup/action/AttachmentPopup.vue';
export default {
    name: 'app',
    mixins: [requestsFinance],
    components: {
        AttachmentPopup
    },
    data() {
        return {
            financialTxnTypes: [],
            financialTxnCategories: [],
            users: [],
            accountHeads: [],
            entityERMLookup: [],
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entityRecordMasterId: localStorage.getItem("ermId"),
            entity: 'financial_transaction',
            attachmentCount: 0,

            financialTxn: {
                id: '',
                financialTransactionId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: localStorage.getItem("ermId"),
                entity: 'financial_transaction',
                accountHeadId: '',
                transactionTypeId: '',
                type: '',
                categoryId: '',
                category: '',
                title: '',
                description: '',
                amount: '',
                preparedById: '',
                transactionDt: '',
                transactionById: '',
                transactionBy: '',
                accountablePersonId: '',
                accountablePerson: '',
                statusId: '',
                activityId: '',
                entityId: '',
            },

            attachmentInfo: {},
            attachments: [],

        }
    },
    mounted() {
        this.$store.state.pageTitle = 'New Financial Txn';
        this.lookupData();
    },
    created() {
        EventBus.$on("savePage", this.saveFinancialTxn);
    },
    destroyed() {
        EventBus.$off("savePage", this.saveFinancialTxn);
    },
    validations: {
        financialTxn: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            entityRecordMasterId: {
                required
            }
        }
    },
   methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/finance/fintxnboard/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.accountHeads = result.accountHeadLookup;
                    this.financialTxnTypes = result.customTypeLookup;
                    this.financialTxnCategories = result.customCategoryLookup;
                    this.users = result.userLookup;
                    this.entityERMLookup = result.entityERMLookup;
                })

        },
        async saveFinancialTxn() {
            this.$v.financialTxn.$touch();
            if (!this.$v.financialTxn.$invalid) {
                try {                    
                    this.$store.state.showAppLoader = true;
                    this.financialTxn.hasAttachment = await this.$refs.attachmentPopupRef.hasAttachment();
                    const response = await this.addFinancialTxn(this.financialTxn);
                    let result = response.data;
                    await this.$refs.attachmentPopupRef.saveAttachment(this.entity, result.id);
                    this.$store.state.showAppLoader = false;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: 'Financial Txn info Saved',
                            position: 'topRight'
                        });
                        this.clearData();
                        this.$router.push('/fintxnbrd' );
                    }
                } catch (error) {
                    this.$store.state.showAppLoader = false;
                    iziToast.error({
                        title: 'Error',
                        position: 'topRight'
                    });
                }
            }
        },
        clearData: function() {
            this.financialTxn.title = '';
            this.financialTxn.transactionDt = '';
            this.financialTxn.accountHeadId = '';
            this.financialTxn.transactionTypeId = '';
            this.financialTxn.categoryId = '';
            this.financialTxn.description = '';
            this.financialTxn.amount = '';
            this.financialTxn.transactionById = '';
            this.financialTxn.accountablePersonId = '';
            this.financialTxn.activityId = '';
            this.$v.financialTxn.$reset();
        },
        updateAttachmentCount(attachmentCount) {
            this.attachmentCount = attachmentCount;
        }
    }
}
</script>