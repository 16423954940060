export const commonMixin = {
    methods: {
        getRedirectUrlName(value) {
            let output = '';
            if (value != null) {
                switch (value) {
                    case "scope_statement_master":
                        output = 'sstrep';
                        break;
                    case "goal_master":
                        output = 'objrep';
                        break;
                    case "user_requirement_master":
                        output = 'urqrep';
                        break;
                    case "system_requirement_spec_master":
                        output = 'srsrep';
                        break;
                    case "task":
                        output = 'viewtsk';
                        break;
                    case "mailbox":
                        output = 'mailbox';
                        break;
                    case "calendar_event":
                        output = 'viewcalevt';
                        break;
                    case "uat_master":
                        output = '';
                        break;
                    case "effort_estimation_master":
                        output = '';
                        break;
                    case "process_model_master":
                        output = '';
                        break;
                    case "test_plan_master":
                        output = '';
                        break;
                    case "communication_plan_master":
                        output = '';
                        break;
                    case "resource_plan_master":
                        output = '';
                        break;
                    case "cost_estimation_master":
                        output = '';
                        break;
                    case "budget_plan_master":
                        output = '';
                        break;
                    case "project_document_master":
                        output = '';
                        break;
                    case "project_plan_master":
                        output = '';
                        break;
                    case "quality_assurance_plan_master":
                        output = '';
                        break;
                    case "development_plan_master":
                        output = '';
                        break;
                    case "risk_plan_master":
                        output = '';
                        break;
                    case "project_team_master":
                        output = '';
                        break;
                    case "design_master":
                        output = '';
                        break;
                    case "financial_txn_master":
                        output = '';
                        break;
                    default:
                        output = '';
                }
            }
            return output;
        },
    }
};