export const requestsMixin = {
    methods: {
        performLogout() {
            this.$store.dispatch("doLogout");
            this.$router.push('/login');
        },
        addClient: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/client/add', data);
        },
        addContact: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/contact/add', data);
        },
        addObjective: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/goal/objective/add', data);
        },
        addProject: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/project/add', data);
        },
        addResouce: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/add', data);
        },
        addUser: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/user/account/create', data);
        },
        addCompany: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/company/add', data);
        },
        getCompanies: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/company/list', data);
        },
        updateCompany: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/company/update', data);
        },
        getCompanyLookup: function(data) {            
            return axios.post(this.$store.getters.getAPIBasePath + '/company/lookup/all', data);
        },
        getClientLookup: function(data) {            
            return axios.post(this.$store.getters.getAPIBasePath + '/client/lookup/all', data);
        },
        getClients: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/client/companyclient/list', data);
        },
        getCompanyUsers: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/user/companyuser/list', data);
        },
        getContacts: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/contact/list', data);
        },
        getFinancialTxns: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/list', data);
        },
        getObjectives: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/goal/objective/list', data);
        },
        getObjectiveLookup: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/goal/objective/lookup/all', data);
        },
        getProjects: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/project/list', data);
        },
        getResources: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/list', data);
        },
        getProjectLookup: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/project/lookup/all', data);
        },
        performLogin(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/user/authenticate', data);
        },
        updateClient: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/client/update', data);
        },
        updateContact: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/contact/update', data);
        },
        updateObjective: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/goal/objective/update', data);
        },
        updateProject: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/project/update', data);
        },
        updateResouce: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/humanresource/update', data);
        },
    }
};