<template>
    <div class="content_wrapper">
        <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
            <div class="container-fluid">
                <!-- <div class="page-heading p-0 task-page"> -->
                    <div class="row">
                        <div class="col-12 px-2 pt-0 pb-2">
                            <div class="row ">
                                <div class="col-7">
                                    <h2 class="pl-2 mb-0 titel-text">Company Settings</h2>
                                </div>
                                <div class="col-5">
                                    <!-- <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                        <ol class="breadcrumb pt-0 pb-0 mb-0 px-0">
                                            <li class="breadcrumb-item">
                                                <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                            </li>
                                            <li class="ml-2">
                                                <a type="button" id="dropdownMenuDelete" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-ellipsis-v pr-1" aria-hidden="true"></i></a>
                                                <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuDelete">
                                                    <a href="#" class="dropdown-item py-1" @click="deleteTask(company.id)" v-b-modal.deleteTask><i class="fa fa-trash pr-2"></i>Delete Task</a>
                                                </div>
                                            </li>
                                            
                                        </ol>
                                    </nav> -->
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- </div> -->
                <!-- <section class="chart_section task-page"> -->
                    <div class="row px-2">
                        <div class="card col-12 col-lg-4 col-xl-4 mb-4 ">
                            <div class="profile_header">
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <div class="my-company-img mt-2">
                                            <img :src="company.logo" alt="Profile picture">
                                            <label class="upload-icon upload-icon-contacts">
                                                <i class="fa fa-upload"></i>
                                                    <input ref="companyLogo" type="file" accept="image/*" @change="previewImage" class="uploadFile img" id="companyLogo" style="width: 0px;height: 0px;overflow: hidden;">                                                                    
                                            </label>
                                        </div>
                                        <h6>Company Info</h6>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Company Name</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="text" maxlength="100"  placeholder="Enter Company Name" class="view-form" v-model="company.companyName" id="companyName" :class="{ 'is-invalid': $v.company.companyName.$error }" >
                                                <label v-if="$v.company.companyName.$error && !$v.company.companyName.minLength" class="text-danger">Company name must have at least {{$v.company.companyName.$params.minLength.min}} letters.</label>
                                                <label v-if="$v.company.companyName.$error && !$v.company.companyName.required" class="text-danger">Please enter Company name</label>
                                                <label v-if="$v.company.companyName.$error && !$v.company.companyName.maxLength" class="text-danger">Company name must not exceed {{$v.company.companyName.$params.maxLength.max}} letters.</label>
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Industry</span>
                                            </div>
                                            <div class="col-7">
                                                <select v-model="company.industryId" class="view-form">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in industries" :value="info.id" :key="index">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Short Name</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="text" v-model="company.shortName" class="view-form" placeholder="Enter Short Name" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Director</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="text" v-model="company.ownerName" class="view-form" placeholder="Enter Director Name" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                    </div>
                                    <div class="col-12">
                                        <h6>Contact Person</h6>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Contact Person</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="text" v-model="company.contactName" class="view-form" placeholder="Enter Name" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Email Id</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="email" v-model="company.emailId" class="view-form" placeholder="Enter Email Id" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Mobile</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="text" v-model="company.mobile" class="view-form" placeholder="Enter Mobile Number" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Phone</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="text" v-model="company.phone" class="view-form" placeholder="Enter Phone Number" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                    </div>
                                    <div class="col-12">
                                        <h6>Contact Info</h6>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Office Phone</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="text" v-model="company.officePhone" class="view-form" placeholder="Enter Office Phone Number" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Email Id</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="email" v-model="company.alternateEmailId" class="view-form" placeholder="Enter Email Id" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Website</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="text" v-model="company.website" class="view-form" placeholder="Enter Website" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <!-- <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Logo</span>
                                            </div>
                                            <div class="col-7">
                                                <input ref="companyLogo" type="file" accept="image/*" @change="previewImage" class="form-control-file" value="dfdf" id="companyLogo">

                                                <div class="border p-2 mt-3">                                                    
                                                    <template v-if="preview">
                                                    <img :src="preview" class="img-fluid" />
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div> -->
                                    </div>
                                    <div class="col-12">
                                        <h6>Address Info</h6>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Address</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="text" v-model="company.address1" class="view-form" placeholder="Enter Address" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Street Name</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="email" v-model="company.streetName" class="view-form" placeholder="Enter Street Name" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">City</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="text" v-model="company.city" class="view-form" placeholder="Enter City" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">State</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="text" v-model="company.state" class="view-form" placeholder="Enter State" name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Country</span>
                                            </div>
                                            <div class="col-7">
                                                <select v-model="company.countryId" class="view-form">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in countries" :value="info.id" :key="index">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                        <div class="row form-group">
                                            <div class="col-5">
                                                <span class="font-light">Pin Code</span>
                                            </div>
                                            <div class="col-7">
                                                <input type="text" v-model="company.pincode" class="view-form" placeholder="Enter " name="title">
                                            </div>
                                        </div>
                                        <div class="divider-line mb-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <!-- </section> -->
            </div>
        </b-overlay>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsTask } from "@/mixins/requestsTask";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { EventBus } from '@/main';
export default {
    name: 'app',
    mixins: [requestsTask],
    data() {
        return {
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            entity: 'project',
            company: {},
            preview: null,
            countries: [],
            industries:[],
        }
    },
    validations: {
        company: {
            companyName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'Settings';
        this.viewCompany();
        this.lookupData();
    },
    created() {
        EventBus.$on("savePage", this.updateCompany);
    },
    destroyed() {
        EventBus.$off("savePage", this.updateCompany);
    },
    methods: {
        viewCompany: function() {
            let data = {
                vhextAccountId : this.vhextAccountId
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/company/view/vhextid',  data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    console.log(result)
                    this.company = result.company;
                    this.preview = this.company.logo;
                    localStorage.setItem("logo",this.company.logo);
                })
                .catch(error => {
                    iziToast.error({
                        title: 'Error Occured',
                        message: 'Error',
                        position: 'topRight'
                    });
                });

        },
        lookupData: function() {
             let data = {
                projectId: this.projectId,
                vhextAccountId: this.vhextAccountId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/company/lookup/all', data)
                .then((response) => {
                    let result = response.data;   
                    this.industries= result.industryLookup; 
                    this.countries = result.countryLookup;
                })
            
        },
        previewImage: function(event) {
            var input = event.target;
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.company.logo = e.target.result;
                }
                this.image=input.files[0];
                reader.readAsDataURL(input.files[0]);
                this.uploadLogo();
            }
        },
        uploadLogo: function() {
            if(this.$refs.companyLogo.files[0] != undefined){
                let formData = new FormData();
                formData.append('file', this.$refs.companyLogo.files[0]);
                formData.append('vhextAccountId', this.vhextAccountId);
                formData.append('id', this.company.id);

                axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload/logo', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        var result = response.data;
              
                        iziToast.success({
                            title: 'Success',
                            message: 'Logo uploaded Successfully',
                            position: 'topRight'
                        });         
                    })
                    .catch(error => {
                        iziToast.error({
                            title: 'Error Occured',
                            message: 'Unable to upload logo',
                            position: 'topRight'
                        });
                    });
            }
        },
        updateCompany: function() {
            this.$v.company.$touch();
            if (!this.$v.company.$invalid) {
                 axios.post(this.$store.getters.getAPIBasePath + '/company/update', this.company)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                        } 
                        this.viewCompany();
                    })
                    .catch(error => {
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },
    }
}
</script>