<template>
    <div>
        <IssueMasterList :ermMasterInfo="ermMasterInfo"></IssueMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import IssueMasterList from '../../components/ermcomponents/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        IssueMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            routePath: '',
            ermMasterInfo: {
                title: "Issue Documents",
                entity: 'issue_master',
                refEntity: 'issue',
                tooltip: 'New Issue Doc',
                redirectURLName: 'issue',
                newEntityMasterURL: 'issmas',
                redirectURL: '/issmas',
                actionName: 'Issue',
                previewURLName: 'issrep'
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
        if (this.routePath != null && this.routePath.indexOf('service') == 1) {
            this.ermMasterInfo.redirectURLName = 'srvissue';
            this.ermMasterInfo.newEntityMasterURL = 'srvissmas';
            this.ermMasterInfo.redirectURL = '/service/issmas';
            this.ermMasterInfo.actionName = 'Issue';
            this.ermMasterInfo.previewURLName = 'srvissrep';
        } else if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURLName = 'cliissue';
            this.ermMasterInfo.newEntityMasterURL = 'cliissmas';
            this.ermMasterInfo.redirectURL = '/client/issmas';
            this.ermMasterInfo.actionName = 'Issue';
            this.ermMasterInfo.previewURLName = 'cliissrep';
        } else {
            this.ermMasterInfo.redirectURLName = 'issue';
            this.ermMasterInfo.newEntityMasterURL = 'issmas';
            this.ermMasterInfo.redirectURL = '/issmas';
            this.ermMasterInfo.actionName = 'Issue';
            this.ermMasterInfo.previewURLName = 'issrep';
        }
    },
    methods: {}
}
</script>