<template>
    <div>
        <BudgetPlanMasterList :ermMasterInfo="ermMasterInfo"></BudgetPlanMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import BudgetPlanMasterList from '@/components/ermcomponents/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        BudgetPlanMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            routePath: '',
            ermMasterInfo: {
                title: "Budget Plan List",
                entity: 'budget_plan_master',
                refEntity: 'budget_plan',
                tooltip: 'New Budget Plan',
                redirectURLName: '',
                newEntityMasterURL: '',
                redirectURL: '',
                actionName: 'Budget Plan',
                previewURLName: '',
                showTypeLookup: true
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
        if (this.routePath != null && this.routePath.indexOf('service') == 1) {
            this.ermMasterInfo.redirectURLName = 'srvbudgetplan';
            this.ermMasterInfo.newEntityMasterURL = 'srvbplmas';
            this.ermMasterInfo.redirectURL = '/service/bplmas';
            this.ermMasterInfo.actionName = 'Budget Plan';
            this.ermMasterInfo.previewURLName = 'srvbplrep';
        } else if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURLName = 'clibudgetplan';
            this.ermMasterInfo.newEntityMasterURL = 'clibplmas';
            this.ermMasterInfo.redirectURL = '/client/bplmas';
            this.ermMasterInfo.actionName = 'Budget Plan';
            this.ermMasterInfo.previewURLName = 'clibplrep';
        } else {
            this.ermMasterInfo.redirectURLName = 'budgetplan';
            this.ermMasterInfo.newEntityMasterURL = 'bplmas';
            this.ermMasterInfo.redirectURL = '/bplmas';
            this.ermMasterInfo.actionName = 'Budget Plan';
            this.ermMasterInfo.previewURLName = 'bplrep';
        }
    },
    methods: {}
}
</script>