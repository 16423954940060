import Activity from '@/views/process/Activity.vue'
import AddObjective from '@/views/goal/AddObjective.vue'
import Objective from '@/views/goal/Objective.vue'
import ViewObjective from '@/views/goal/ViewObjective.vue'
import Process from '@/views/process/Process.vue'
import Deliverable from '@/views/process/Deliverable.vue'

export default [
    {
        path: '/activity',
        name: 'activity',
        component: Activity,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/addobjective/:ermId',
        name: 'addfinancialtxn',
        component: AddObjective,
        meta: { accessedby: 'pm' }
    },
    {
        path: '/objective/:ermId',
        name: 'objective',
        component: Objective,
        meta: { accessedby: 'pm' }
    },
    {
        path: '/viewobjective/:ermId',
        name: 'viewobjective',
        component: ViewObjective,
        meta: { accessedby: 'pm' }
    },
    {
        path: '/process',
        name: 'process',
        component: Process,
        meta: { accessedby: 'pm' }
    },
    {
        path: '/deliverable',
        name: 'deliverable',
        component: Deliverable,
        meta: { accessedby: 'pm' }
    },
    
];