export const requestsTask = {
    methods: {
        addTask: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/task/add', data);
        },
        updateTask: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/task/update', data);
        },
    	addTimeSheet: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/add', data);
        },
        getTimeSheets: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/task/timesheetbyuser/list', data);
        },
        updateTimeSheet: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/update', data);
        },
        getTimeSheetLookup: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/task/timesheet/lookup/all', data);
        },
    }
};