<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-8">
                            <h2 class="pl-2 mb-0 titel-text">New Plan</h2>
                        </div>
                        <div class="col-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.plan.phaseTitle.$error }">
                                        <label for="phaseTitle" class="inp">
                                            <input type="text" id="phaseTitle" placeholder=" " v-model="plan.phaseTitle" class="mobile-form-control"  :class="{ 'is-invalid': $v.plan.phaseTitle.$error }">
                                            <span class="label">Title</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                        <label v-if="$v.plan.phaseTitle.$error && !$v.plan.phaseTitle.minLength" class="text-danger">Title must have at least {{$v.plan.phaseTitle.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.plan.phaseTitle.$error && !$v.plan.phaseTitle.required" class="text-danger">Please enter Title</label>
                                        <label v-if="$v.plan.phaseTitle.$error && !$v.plan.phaseTitle.maxLength" class="text-danger">Title must not exceed {{$v.plan.phaseTitle.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="process">Process</label>
                                        <select v-model="plan.processId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in processes" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12 pr-4">
                                    <div class="form-group">
                                        <label>Activity</label>
                                        <select v-model="plan.activityId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <!-- <a :title="activityLookupTitle" class="setting-icon pr-2" href="javascript:" v-b-modal.activityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a> -->                                                            
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Responsibility</label>
                                        <select v-model="plan.responsibilityId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="startDate">Start Date</label>
                                        <div>
                                            <input v-model="plan.startDate" type="date" class="form-control" placeholder="Start Date">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label for="endDate">End Date</label>
                                        <div>
                                            <input v-model="plan.endDate" type="date" class="form-control" placeholder="End Date">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="deliverables">Deliverables</label>
                                        <multiselect v-model="plan.deliverables" :options="deliverables" name="deliverable" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                                    </div>
                                    <!-- <div class="col-13">
                                        <a :title="deliverableLookupTitle" class="setting-icon" href="javascript:" v-b-modal.deliverableModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                    </div>   -->                                                                                                           
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="status">Status</label>
                                        <select v-model="plan.statusId"  class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in statuses" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:" @click="savePlan" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</a>
                            <a href="javascript:" @click="clearData" class="btn btn-info mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <ActivityLookup ref="activityLookupModal" :entity="this.entity" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" />
        <DeliverableLookup ref="deliverableLookupModal" :entity="this.entity" :deliverableLookupTitle="deliverableLookupTitle" @refresh-lookup="lookupData" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsPlan } from "@/mixins/requestsPlan";
export default {
    name: 'app',
    mixins: [requestsPlan],
    components: {
        Multiselect,
    },
    data() {
        return {
            files: '',
            addMore: [],

            entity: 'plan',
            projectId: localStorage.getItem("projectId"),
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            entityRecordMasterId: this.$route.params.ermId,

            plan: {
                id: '',
                planId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                phaseTitle: '',
                processId: '',
                process: '',
                activityId: '',
                activity: '',
                deliverable: '',
                startDate: new Date().toISOString().substr(0, 10),
                endDate: new Date().toISOString().substr(0, 10),
                estimatedStartDate: '',
                estimatedEndDate: '',
                responsibilityId: '',
                responsibility: '',
                description: '',
                isTentative: '',
                statusId: '',
                status: '',
                createdDt: '',
                lastUpdatedDt: '',
                deliverables: []
            },
            
            deliverables: [],
            processes: [],
            activities: [],
            statuses: [],
            users: [],


        }

    },
    mounted() {
        this.lookupData();
    },
    validations: {
        plan: {
            phaseTitle: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        }
    },
    methods: {
        async lookupData() {
            try {                
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    entity: this.entity,

                }
                const response = await this.getPlanLookup(data);
                let result = response.data;
                console.log(result);
                this.users = result.userLookup;
                this.deliverables = result.deliverableLookup;
                this.processes = result.processLookup;
                this.activities = result.activityLookup;
                this.statuses = result.statusLookup;
            } catch(error) {
                console.log(error);
            }
        },
        savePlan: function() {
            this.$v.plan.$touch();
            if (!this.$v.plan.$invalid){
                console.log(this.plan);
                axios.post(this.$store.getters.getAPIBasePath + '/plan/add', this.plan)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.clearData();
                        this.$router.push('/planlist/' + this.entityRecordMasterId );

                        }
                    })
            }
        },

        clearData: function() {
            this.plan.title = '';
            this.plan.processId = '';
            this.plan.activityId = '';
            this.plan.responsibilityId = '';
            this.plan.deliverables = '';
            this.plan.statusId = '';
            this.plan.startDate = '';
            this.plan.endDate = '';
            this.plan.statusId = '1';
            this.$v.plan.$reset();
        },

    }
}
</script>