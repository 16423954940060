<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row">
                        <div class="col-8 pr-0">
                            <h2 class="mb-0 titel-text">{{project.title}}</h2>
                        </div>
                        <div class="col-4 pl-0 text-right">
                            <span class="label label-primary ml-2">{{ project.status }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pb-1 px-3">
                            <p class="mx-0 mb-2 mt-0 row">
                                <span class="font-light p-0 col-6"><i class="fa fa-user pr-2" aria-hidden="true"></i>Project Manager</span>
                                <select v-model="project.projectManagerId" @change="updateProjectData" class="view-form col-6">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2 row">
                                <span class="font-light p-0 col-6"><i class="fa fa-sitemap pr-1" aria-hidden="true"></i>Type</span>
                                <select v-model="project.projectTypeId" @change="updateProjectData" class="view-form col-6">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in projectTypes" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2 row">
                                <span class="font-light p-0 col-6"><i class="fa fa-sliders pr-2" aria-hidden="true"></i>Category</span>
                                <select v-model="project.categoryId" @change="updateProjectData" class="view-form col-6">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in projectCategory" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <div class="mx-0 my-2 row">
                                <span class="font-light p-0 col-3"><i class="fa fa-calendar pr-2" aria-hidden="true"></i>Period</span>
                                <div class="col p-0 text-right">
                                    <b-dropdown ref="ddStartDt" :text="project.startDate | formatDate" variant="outline-secondary" no-caret size="sm" class="p-0">
                                        <b-calendar v-model="project.startDate" @context="updateProjectData; $refs.ddStartDt.hide(true)" today-variant="primary" hide-header label-help="" selected-variant="secondary"></b-calendar>
                                    </b-dropdown>
                                    -
                                    <b-dropdown ref="ddEndDt" :text="project.endDate | formatDate" variant="outline-secondary" no-caret size="sm" class="p-0">
                                        <b-calendar v-model="project.endDate" @context="updateProjectData; $refs.ddEndDt.hide(true)" today-variant="primary" hide-header label-help="" selected-variant="secondary"></b-calendar>
                                    </b-dropdown>
                                </div>
                            </div>
                            <p class="mx-0 my-1 no-outline row">
                                <span v-b-modal.modal-1 class="font-light no-outline p-0 col-3"><i class="fa fa-clock-o pr-2" aria-hidden="true"></i>Duration</span>
                                <span class="col text-right p-0">
                                <span v-b-modal.modal-1 v-if="project.durationYears" class="no-outline">
                                    {{ project.durationYears }} {{ project.durationYears == 1 ? 'year' : 'years'}}
                                </span>
                                <span v-b-modal.modal-1 v-if="project.durationMonths" class="no-outline">
                                     {{ project.durationMonths }} {{ project.durationMonths == 1 ? 'month' : 'months'}}
                                </span>
                                <span v-b-modal.modal-1 v-if="project.durationDays" class="no-outline">
                                     {{ project.durationDays }} {{ project.durationDays == 1 ? 'day' : 'days'}}
                                 </span>
                                </span>
                            </p>
                            <p  class="mx-0 my-2 row">
                                <span class="font-light p-0 col-6"><i class="fa fa-money pr-2" aria-hidden="true"></i>Budget</span>
                                <input type="number" v-model="project.initialBudget" @change="updateProjectData" class="view-form col-6 text-right" placeholder="Set budget" name="projectManager">
                            </p>
                            <p  class="mx-0 my-2 row">
                                <span class="font-light p-0 col-6"><i class="fa fa-map-marker pr-2" aria-hidden="true"></i>Location</span>
                                <input type="text" v-model="project.location" @change="updateProjectData" class="view-form col-6 text-right" placeholder="Project location" name="projectManager">
                            </p>
                            <!-- <p class="mx-0 my-2">
                                <textarea class="form-control" @change="updateProjectData" v-model="project.description" placeholder="Description" rows="2">
                                </textarea>
                            </p> -->
                            <div class="row form-group px-3">
                                <span class="font-light">Description</span>
                                <label class="description-field" v-html="project.description" @click="showDescriptionEdit" ></label>
                                <!-- <textarea class="form-control" v-model="task.description" placeholder="Task Description" rows="2">
                                </textarea> -->
                            </div>
                            <p  class="mx-0 mt-2 mb-0 float-right">
                                <span class="font-light fs-small">last updated {{ getTimeDuration(project.lastUpdatedDt) }}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-1" @hide="updateProjectData" title="Project Duration">
            <div class="row">
                <div class="col-4">
                    <label>Years</label>
                    <select v-model="project.durationYears" class="form-control">
                        <option value="">0</option>
                        <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                    </select>
                </div>                                            
                <div class="col-4 pl-0">
                    <label>Months</label>
                    <select v-model="project.durationMonths" class="form-control">
                        <option value="">0</option>
                        <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                    </select>
                </div>
                <div class="col-4 pl-0">
                    <label>Days</label>
                    <select v-model="project.durationDays" class="form-control">
                        <option value="">0</option>
                        <option v-for="(info, index) in daysList" :value="info.id">{{info.name}}</option>
                    </select>
                </div>
            </div>
        </b-modal>
        <DescriptionPopup ref="descriptionEditPopup" @updateValue="updateDescription" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import month from '@/json/month.json';
import days from '@/json/days.json';
import { requestsMixin } from "@/mixins/requestsMixin";
import DescriptionPopup from '@/components/popup/action/DescriptionEditPopup.vue';
export default {
    name: 'app',
    mixins: [requestsMixin],
    components: {
        DescriptionPopup
    },
    data() {
        return {
            monthList: month,
            daysList: days,
            projectTypes: [],
            projectCategory: [],
            users: [],
            project: {},
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'Project';
        this.$store.state.editPageUrl = '';
        this.lookupData();
        this.project = JSON.parse(localStorage.getItem("rowData"));
    },
    methods: {
        async lookupData() {
            try {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                }
                const response = await this.getProjectLookup(data);
                let result = response.data;
                this.projectTypes = result.typeLookup;
                this.users = result.userLookup;
                this.projectCategory = result.customCategoryLookup;
            } catch (error) {
                iziToast.error({
                    title: 'Error',
                    position: 'topCenter'
                });
            }
        },
        async updateProjectData() {
            try {
                this.project.isArchive = false
                this.project.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.$store.state.showAppLoader = true;
                const response = await this.updateProject(this.project);
                this.$store.state.showAppLoader = false;
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topCenter'
                    });
                }
                this.project.lastUpdatedDt = new Date();
                localStorage.setItem("rowData", JSON.stringify(this.project));
            } catch (error) {
                this.$store.state.showAppLoader = false;
                iziToast.error({
                    title: 'Error',
                    position: 'topCenter'
                });
            }
        },
        showDescriptionEdit() {
            let popupTitle = 'Description';
            let value = this.project.description;
            this.$refs.descriptionEditPopup.setDescriptionData(value, popupTitle);
            this.$nextTick(() => {
                this.$bvModal.show('description-edit-popup');
            })
        },
        updateDescription(val) {
            this.project.description = val;
            this.$nextTick(() => {
                this.$bvModal.hide('description-edit-popup');
                this.updateProjectData();
            })
        },
    }
}
</script>