import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "@/views/Dashboard.vue";
import About from "@/views/About.vue";
import Login from "@/views/Login.vue";
import PageNotFound from '@/views/PageNotFound'
import Register from "@/views/Register.vue";
import UserActivation from "@/views/UserActivation.vue";
import clientroutes from './clientroutes';
import communicationroutes from './communicationroutes';
import companyroutes from './companyroutes';
import financeroutes from './financeroutes';
import planroutes from './planroutes';
import prmroutes from './prm/prmroutes';
import projectroutes from './projectroutes';
import processroutes from './processroutes';
import requirementroutes from './requirementroutes';
import resourceroutes from './resourceroutes';
import scoperoutes from './scoperoutes';
import settingroutes from './settingroutes';

Vue.use(VueRouter)

const baseroutes = [{
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard
    },
    {
        path: "/",
        name: "login",
        component: Login,
        meta: { layout: 'login' }
    }, 
    {
        path: "/login",
        name: "login",
        component: Login,
        meta: { layout: 'login' }
    },
    {
        path: '*',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: { layout: 'pagenotfound' }
    },
    {
        path: "/register",
        name: "register",
        component: Register,
        meta: { layout: 'login' }
    },
    {
        path: "/activation",
        name: "activation",
        component: UserActivation,
        meta: { layout: 'login' }
    },
    {
        path: "/about",
        name: "About",
        component: About
    },
];

const routes = baseroutes.concat( clientroutes, communicationroutes, companyroutes, financeroutes, planroutes, prmroutes, processroutes, projectroutes, requirementroutes, resourceroutes, scoperoutes, settingroutes);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router