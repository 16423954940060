<template>
    <div>
        <header class="main-header">
            <div class="container_header">
                <div class="logo ">
                    <div class="row d-flex min-h pos-md-r">
                        <div class="col-12 pr-0 pl-4 text-left">
                            <div  class="icon_menu full_menu">
                                <!-- <a href="#" class="menu-toggler sidebar-toggler"></a> -->
                                <a href="#" class="menu-toggler sidebar-toggler">
                                    <i class="fa fa-bars" aria-hidden="true"></i>
                                </a>
                            </div>
                            <a href="#" class="logo-img-section">
                                <!-- <strong class="logo_icon">
                                    <img src="@/assets/images/VHextvMx.png" alt=""> 
                                </strong> -->
                                <!-- <span class="logo-default">
                                    vhextech
                                    <img src="@/assets/images/logo2.png" alt=""> 
                                </span> -->
                                <span class="text-light">
                                    {{ pageTitle }}
                                    <!-- <img src="@/assets/images/VHextvMx.png" alt="">  -->
                                </span>
                            </a>
                        </div>
                        <div v-if="false" class="col-6 pl-0">
                            <div class="right_bar_top align-items-end">
                                <!-- <div class="header-search-f">
                                    <label for="search" class="header-search-w">
                                    <button class="header-search-b"><i class="fa fa-search"></i></button>
                                    <input type="text" id="search" class="header-search-i" />
                                    </label>
                                </div> -->
                                
                                <!-- DropDown_Inbox -->
                                <div  v-if="false"  class="dropdown dropdown-inbox">
                                    <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                        <i class="fa fa-envelope-o"></i> <span class="badge_coun"> 2 </span> </a>
                                    <ul class="dropdown-menu scroll_auto height_fixed">
                                        <li class="bigger">
                                            <h3><span class="bold">Messages</span></h3>
                                            <span class="notification-label">New 2</span>
                                        </li>
                                        <li>
                                            <ul class="dropdown-menu-list">
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <!--DropDown_Inbox_End -->
                                <!-- Dropdown_User -->
                                <div  v-if="false"  class="dropdown dropdown-user">
                                    <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="true">
                                         <img class="img-circle pro_pic" src="@/assets/images/about-1.jpg" alt=""> 
                                        </a>
                                    <ul class="dropdown-menu dropdown-menu-default">
                                        <li>
                                            <a href="#"> <i class="fa fa-user-o"></i> Profile </a>
                                        </li>
                                        <li>
                                            <a href="#"> <i class="fa fa-cog"></i> Settings </a>
                                        </li>
                                        <li>
                                            <a href="#"> <i class="fa fa-info"></i> Help </a>
                                        </li>
                                        <li class="divider"></li>
                                        <li>
                                            <a href="#"> <i class="fa fa-lock"></i> Lock </a>
                                        </li>
                                        <li>
                                            <a href="/login"><i class="fa fa-sign-in" aria-hidden="true"></i> Log In</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" @click="performLogout" href="Javascript:void(0);"><i class="fa fa-unlock-alt"></i> Log Out </a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- Dropdown_User_End -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right_detail">
                    <div class="row d-flex align-items-center min-h pos-md-r">
                        <!-- <div class="col-6 search_col ">
                            <div class="top_function">
                                <div class="search">
                                    <a id="toggle_res_search" data-toggle="collapse" data-target="#search_form" class="res-only-view collapsed" href="javascript:void(0);" aria-expanded="false"> <i class=" icon-magnifier"></i> </a>
                                    <form id="search_form" role="search" class="search-form collapse" action="#">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Search...">
                                            <button type="button" class="btn" data-target="#search_form" data-toggle="collapse" aria-label="Close">
                                                <i class="fa fa-search"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> -->
                        <div v-if="false" class=" col-12 d-flex justify-content-end">
                            <div class="right_bar_top d-flex align-items-center">
                                <div class="header-search-f">
                                    <label for="search" class="header-search-w">
                                    <button class="header-search-b"><i class="fa fa-search"></i></button>
                                    <input type="text" id="search" class="header-search-i" />
                                    </label>
                                </div>
                                <div class="form-group select-box-mobile">
                                    <label class="m-0" for="processActivity"></label>
                                    <select class="form-control custom-select" id="processActivity">
                                        <option value="">Select</option>
                                        <option value="option1">option 1</option>
                                    </select>
                                </div>
                                <!-- DropDown_Inbox -->
                                <div  v-if="false"  class="dropdown dropdown-inbox">
                                    <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true">
                                        <i class="fa fa-envelope-o"></i> <span class="badge_coun"> 2 </span> </a>
                                    <ul class="dropdown-menu scroll_auto height_fixed">
                                        <li class="bigger">
                                            <h3><span class="bold">Messages</span></h3>
                                            <span class="notification-label">New 2</span>
                                        </li>
                                        <li>
                                            <ul class="dropdown-menu-list">
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                                <li>
                                                    <a href="#"> <span class="photo"> <img src="@/assets/images/about-1.jpg" class="img-circle" alt=""> </span>
                                                        <span class="subject"> <span class="from"> Sarah Smith </span> <span class="time">Just Now </span> </span>
                                                        <span class="message"> Jatin I found you on LinkedIn... </span> </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                                <!--DropDown_Inbox_End -->
                                <!-- Dropdown_User -->
                                <div  v-if="false"  class="dropdown dropdown-user">
                                    <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="true">
                                         <img class="img-circle pro_pic" src="@/assets/images/about-1.jpg" alt=""> 
                                        </a>
                                    <ul class="dropdown-menu dropdown-menu-default">
                                        <li>
                                            <a href="#"> <i class="fa fa-user-o"></i> Profile </a>
                                        </li>
                                        <li>
                                            <a href="#"> <i class="fa fa-cog"></i> Settings </a>
                                        </li>
                                        <li>
                                            <a href="#"> <i class="fa fa-info"></i> Help </a>
                                        </li>
                                        <li class="divider"></li>
                                        <li>
                                            <a href="#"> <i class="fa fa-lock"></i> Lock </a>
                                        </li>
                                        <li>
                                            <a href="/login"><i class="fa fa-sign-in" aria-hidden="true"></i> Log In</a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" @click="performLogout" href="Javascript:void(0);"><i class="fa fa-unlock-alt"></i> Log Out </a>
                                        </li>
                                    </ul>
                                </div>
                                <!-- Dropdown_User_End -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
export default {
    name: 'app',
    components: {},
    data() {
        return {
            routePath: '',
        }
    },
    computed: {
        pageTitle() {
            return this.$store.state.pageTitle;
        },
    },
/*    watch: {
        '$route.path': {
            handler: function(path) {
                if (path != null && path.indexOf('landing') != -1) {
                    this.elements.showMenu = false;
                } else {
                    this.elements.showMenu = true;
                }
            },
            deep: true,
            immediate: true
        },
    },*/
    mounted() {},
    methods: {}
}
</script>