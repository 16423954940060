<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="industryId">User Type</label>
                                        <select v-model="userData.userKeyValue" id="companyName" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in userTypes" :value="{id: info.id, text:info.value}" >{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="companyName">Name</label>
                                        <a href="javascript:" class="float-right button-add" @click="elements.showDropdownName=!elements.showDropdownName;">
                                            <i v-if="!elements.showDropdownName" @click="userData.userName=''" title="Select existing members" class="fa fa-lg fa-angle-double-right pr-2 mr-2"></i>
                                            <i v-if="elements.showDropdownName" @click="userData.member=''" title="Add new member" class="fa fa-lg fa-angle-double-left pr-2 mr-2"></i>
                                        </a>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.userData.userName.$error }">
                                            <select v-if="elements.showDropdownName && humanResourcesLookup.length!=0 && userData.userKeyValue.text != 'Client'" v-model="userData.member" @change="getHumanResource(userData.member.id);userData.userName=userData.member" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in humanResourcesLookup" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                            </select>
                                            <select v-if="elements.showDropdownName && clientLookup.length!=0 && userData.userKeyValue.text === 'Client'" v-model="userData.member" @change="getClient(userData.member.id);userData.userName=userData.member" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in clientLookup" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                            </select>
                                            <input v-if="!elements.showDropdownName || humanResourcesLookup.length==0" type="text" class="form-control" v-model="userData.userName" placeholder="Enter Name">
                                            <div class="required-icon"></div>
                                        </div>


                                        <label v-if="$v.userData.userName.$error && !$v.userData.userName.required" class="text-danger">Please enter user name</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="shortName">Email</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.userData.emailId.$error }">
                                            <input type="email" autocomplete="nope" class="form-control" v-model="userData.emailId" id="shortName" placeholder="Email Id">
                                            <div class="required-icon"></div>
                                        </div>
                                        <div v-if="$v.userData.emailId.$error" class="invalid-feedback">
                                            <span v-if="!$v.userData.emailId.required" class="text-danger">EmailId is required</span>
                                            <span v-if="!$v.userData.emailId.email">Email is not valid</span>
                                        </div>
                                        <label v-if="elements.alreadyExistsUser" class="text-danger">Email already exists.</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="ownerName">Phone</label>
                                        <input type="text" class="form-control" v-model="userData.phone" id="ownerName" placeholder="Phone">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsResource } from "@/mixins/requestsResource.js";
import { EventBus } from '@/main';
export default {
    name: 'app',
    mixins: [requestsResource],

    data() {
        return {

            userTypes: [],
            humanResourcesLookup: [],
            clientLookup: [],
            userData: {
                userName: '',
                userTypeId: '',
                userKeyValue: '',
                member: '',
                jobTitle: '',
                emailId: '',
                phone: '',
                humanResourceId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            },

            error: false,

            elements: {
                showDropdownName: false,
                alreadyExistsUser: false,
            },
        }
    },
    validations: {
        userData: {
            userName: {
                required
            },
            emailId: {
                required,
                email
            },
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'New User';
        this.lookupData();
    },
    created() {
        EventBus.$on("savePage", this.createUserAccount);
    },
    destroyed() {
        EventBus.$off("savePage", this.createUserAccount);
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/prmlanding/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                        console.log(result);
                    this.humanResourcesLookup = result.humanResourceLookup;
                    this.clientLookup = result.clientLookup;
                    this.userTypes = result.typeLookup;

                })
        },
        createUserAccount: function() {
            if (this.userData.member.text != null) {
                this.userData.userName = this.userData.member.text;
                this.userData.humanResourceId = this.userData.member.id;
            }
            if(this.userData.userKeyValue != null){
                this.userData.userTypeId = this.userData.userKeyValue.id;
            }
            console.log(this.userData);
            this.$v.userData.$touch();
            if (!this.$v.userData.$invalid) {
                this.error = false;
                axios.post(this.$store.getters.getAPIBasePath + '/user/account/create', this.userData)
                    .then((response) => {
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.clearDataUser();
                            this.$router.push('/landing/user/');
                        } else if (result.status == 'OK') {
                            this.error = true;
                            iziToast.info({
                                message: result.message,
                                position: 'topRight'
                            });

                        }
                    })
            } else {
                this.error = true;
            }
        },
        clearDataUser: function() {
            this.userData.member = '';
            this.userData.userName = '';
            this.userData.userTypeId = '';
            this.userData.emailId = '';
            this.userData.phone = '';
            this.error = false;
            this.elements.alreadyExistsUser = false;
            this.$v.userData.$reset();
        },
        getHumanResource: function(id) {
            console.log(id);
            if (id !== undefined && id !== '') {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        this.userData.emailId = result.humanResourceView.emailId;
                    })
            }
        },
        getClient: function(id) {
            console.log(id);
            if (id !== undefined || id !== '') {
                axios.get(this.$store.getters.getAPIBasePath + '/client/view/' + id)
                    .then((response) => {
                        let result = response.data;
                        console.log(result);
                        this.userData.emailId = result.client.emailId;
                    })
            }
        },
    }
}
</script>