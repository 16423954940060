<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row px-2 mb-5">
                <div class="card col-12 col-lg-4 col-xl-4 mb-4">
                    <div id="profile" class=" h-100">
                        <div class="profile_header">
                            <div class="row">
                                <div class="col-12 col-lg-6 col-xl-6">
                                    <div class="my-profile-img">
                                        <img src="https://lh3.googleusercontent.com/-P_cpGe3NM1Q/AAAAAAAAAAI/AAAAAAAAAAA/gYOuFmMJ28g/photo.jpg" alt="Profile picture">
                                        <label class="upload-icon upload-icon-contacts">
                                            <i class="fa fa-camera"></i>
                                            <input ref="profile_photo" id="profilePhoto" type="file" accept="image/*" class="uploadFile img" value="Upload Photo" style="width: 0px;height: 0px;overflow: hidden;">
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12 col-lg-6 col-xl-6 text-left">
                                    <div>
                                        <h6 class="mb-0" v-if="client.clientName">{{client.clientName}} <span v-if="client.clientType">[{{client.clientType}}]</span></h6>
                                        <p v-if="client.profession" class="m-0">
                                            <span>{{client.profession}}</span>
                                        </p>
                                        <p v-if="client.employer || client.clientType || client.position" class="m-0">
                                            <span v-if="client.employer">{{client.employer}}</span>
                                            <span v-if="client.clientType != 'Individual' && client.firstName">{{client.firstName}}</span>
                                            <span v-if="client.position"> / {{client.position}}</span>
                                        </p>
                                        <p v-if="client.location" class="m-0">
                                            <span>{{client.location}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="client.emailId" class="row">
                                <div class="col-12 text-left">
                                    <label class="ml-0 mr-2"><i class="fa fa-envelope-o font-weight-bold action-link" aria-hidden="true"></i> {{client.emailId}}</label>
                                </div>
                            </div>
                            <div v-if="client.phone || client.mobile" class="row">
                                <div v-if="client.phone" class="col text-left">
                                    <label class="ml-0 mr-2"><i class="fa fa-phone font-weight-bold action-link" aria-hidden="true"></i> {{client.phone}}</label>
                                </div>
                                <div v-if="client.mobile" class="col text-left">
                                    <label class="ml-0 mr-2"><i class="fa fa-mobile font-weight-bold action-link" aria-hidden="true"></i> {{client.mobile}}</label>
                                </div>
                            </div>
                        </div>
                        <template v-if="client.firstName">
                            <div class="divider"></div>
                            <div class="profile_custom">
                                <h3 class="mt-3 mb-3">Full Name</h3>
                                <div class="pl-2">
                                    <p><i class="fa fa-caret-right ml-0 mr-2 font-weight-bold action-link" aria-hidden="true"></i>{{client.firstName}} {{client.middleName}} {{client.lastName}}</p>
                                </div>
                            </div>
                        </template>
                        <template v-if="client.address1 || client.address2 || client.city || client.state || client.country">
                            <div class="divider"></div>
                            <div class="profile_custom">
                                <h3 class="mt-3 mb-3">Address Info</h3>
                                <div class="pl-2">
                                    <p class="dynamic-list">
                                        <i class="fa fa-map-marker ml-0 mr-2 action-link" aria-hidden="true"></i>
                                        <span v-if="client.address1" class="d-inline-block">{{client.address1}}</span>
                                        <span v-if="client.address2" class="d-inline-block pl-1">{{client.address2}}</span>
                                        <span v-if="client.city" class="d-inline-block pl-1">{{client.city}}</span>
                                        <span v-if="client.state" class="d-inline-block pl-1">{{client.state}}</span>
                                        <span v-if="client.country" class="d-inline-block pl-1">{{client.country}}</span>
                                        <span v-if="client.pincode" class="d-inline-block pl-1">{{client.pincode}}</span>
                                    </p>
                                </div>
                            </div>
                        </template>
                        <template v-if="client.website || client.facebookId">
                        <div class="divider"></div>
                            <div class="profile_custom">
                                <h3 class="mt-3 mb-3">Social Connect</h3>
                                <div class="pl-2">
                                    <p class="ml-0 mr-4" ><i class="fa fa-globe font-weight-bold action-link" aria-hidden="true"></i> {{ client.website }}</p>
                                    <p class="ml-0 mr-4" ><i class="fa fa-facebook-square font-weight-bold action-link" aria-hidden="true"></i> {{ client.facebookId }}</p>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteClient" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Client?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsMixin } from "@/mixins/requestsMixin";
import { EventBus } from '@/main';
export default {
    name: 'app',
    mixins: [requestsMixin],
    data() {
        return {
        	client: {},
            entity: 'client',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),

            deleteParam: {
                id: 0
            },
        }
    },
    mounted() {
        this.$store.state.editPageUrl = 'editclient';
        this.$store.state.pageTitle = 'Client';
        this.client = JSON.parse(localStorage.getItem("rowData"));
    },
    created() {
        EventBus.$on("triggerDelete", this.deleteClient);
    },
    destroyed() {
        EventBus.$off("triggerDelete", this.deleteClient);
    },
    methods: {
        deleteClient: function(id) {
            this.deleteParam.id = this.client.id;
            this.$nextTick(() => {
                this.$bvModal.show('deleteClient');
            })
        },
        onYes: function() {
            // let data = {
            //     projectId: this.projectId,
            //     id: this.deleteParam.id,
            //     entity: this.entity,
            // }
            this.$store.state.showAppLoader = true;
            axios.delete(this.$store.getters.getAPIBasePath + '/client/delete/' + this.deleteParam.id)
                .then(response => {
                    this.$store.state.showAppLoader = false;
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/landing/client');
                    }
                })
                .catch(error => {
                    this.$store.state.showAppLoader = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                });

        },
    }
}
</script>