import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css';
import 'izitoast/dist/css/iziToast.min.css';
import './assets/css/jquery.mCustomScrollbar.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'font-awesome/css/font-awesome.css'

import Vue from 'vue'
import App from './App.vue'
import Bootstrap from 'bootstrap';
import BootstrapVue from 'bootstrap-vue';
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from './plugins/axios';
import Vuelidate from 'vuelidate';
import moment from 'moment';
import Multiselect from 'vue-multiselect';

import FullCalendar from "./plugins/FullCalendar.vue";
import Default from "./layouts/DefaultLayout.vue";
import Login from "./layouts/LoginLayout.vue";
import Landing from "./layouts/LandingLayout.vue";
import ERMPage from "./layouts/ERMLayout.vue";
import AddPage from "./layouts/AddPageLayout.vue";
import ViewPage from "./layouts/ViewPageLayout.vue";
import PageNotFound from "./layouts/PageNotFoundLayout.vue";
import Dialog from "./components/popup/dialogbox/Dialog.vue";

export const EventBus = new Vue();

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(Multiselect);
Vue.config.productionTip = false

Vue.component('fullcalendar', FullCalendar);
Vue.component('default-layout', Default);
Vue.component('login-layout', Login);
Vue.component('landing-layout', Landing);
Vue.component('ermpage-layout', ERMPage);
Vue.component('addpage-layout', AddPage);
Vue.component('viewpage-layout', ViewPage);
Vue.component('pagenotfound-layout', PageNotFound);
Vue.component('Dialog', Dialog);

Vue.mixin({
    methods: {
        getPrevUrl() {
            this.$router.go(-1);
        },
        formatDate(value) {
            if (value) {
                //return moment(String(value)).format('DD-MMM-YYYY');
                return moment(String(value)).format('MMM DD, YYYY');
            }
        },
        formatDateTimeAmPm(value) {
            if (value) {
                return moment(String(value)).format('DD-MMM-YYYY  LT');
            }
        },
        getDateValue(value) {
            if (value) {
                value = (new Date(value).toISOString());
                return moment(String(value)).format('YYYY-MM-DD');
            }
        },
        getTimeValue(value) {
            if (value) {
                value = (new Date(value).toISOString());
                return moment(String(value)).format('HH:mm');
            }
        },
        getShortString(value) {
            if (value != null && value.length > 20) {
                return value.substring(0, 50) + "...";
            } else
                return value;
        },
        getCommaSeperatedString(object) {
            let output = '';
            if (object != null) {
                let length = object.length;
                for (var index = 0; index < length; index++) {
                    output = output + object[index].emailId;
                    if (index != (length - 1)) {
                        output = output + ",";
                    }
                }
            }
            return output;
        },
        setFocus: function() {
            this.$refs.setFocus.focus();
        },
        combineDateAndTime: function(date, time) {
            console.log(date, time);
            if (time !== '') {
                return moment(date.toString() + 'T' + time.toString());
            } else return moment(date.toString() + 'T' + '00:00');

        },
        formatDateTimeYearCheck: function(value) {
            if (value) {
                let currentYear = new Date().getFullYear();

                if (currentYear == new Date(value).getFullYear()) {
                    return moment(String(value)).format('DD-MMM  LT');
                } else return moment(String(value)).format('DD-MMM-YYYY  LT');
            }
        },
        formatDateYearCheck: function(value) {
            if (value) {
                let currentYear = new Date().getFullYear();

                if (currentYear == new Date(value).getFullYear()) {
                    return moment(String(value)).format('DD-MMM');
                } else return moment(String(value)).format('DD-MMM-YYYY');
            }
        },
        getTimeDuration (date) {
            let today = new Date();
            let updatedDate = new Date(date);

            let diffInMilliSeconds = Math.abs(today - updatedDate) / 1000;

            // calculate days
            const days = Math.floor(diffInMilliSeconds / 86400);
            diffInMilliSeconds -= days * 86400;

            // calculate hours
            const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
            diffInMilliSeconds -= hours * 3600;

            // calculate minutes
            const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
            diffInMilliSeconds -= minutes * 60;

            if(days == 0) {
                    if(hours == 0 && minutes == 0) {
                        return `Just Now`;
                    }
                    else if(hours == 0 && minutes < 60) {
                        return `${minutes} ${minutes == 1 ? 'minute' : 'minutes'} ago`;
                    }
                    else return `${hours} ${hours == 1 ? 'hour' : 'hours'} ago`;
            } else if(days == 1 && (today.getDate() - updatedDate.getDate() == 1)) {
                return `Yesterday`;
            } else if(days < 100) {
                return `${days} ${days == 1 ? 'day' : 'days'} ago`;
            } else return this.formatDateYearCheck(date);
        },
        effortTypeFilter: function(object, effortType) {
            return object.filter(function(data, key) {
                if (data.id == effortType) {
                    return data;
                }
            });
        },
    }
});

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('MMM DD, YYYY');
    }
});
Vue.filter('formatTime', function(value) {
    if (value) {
        return moment(String(value), ["h:mm:ss"]).format('LT');
    }
});
Vue.filter('formatTimeAmPm', function(value) {
    if (value) {
        return moment(String(value)).format('LT');
    }
});
Vue.filter('formatDateTimeAmPm', function(value) {
    if (value) {
        return moment(String(value)).format('DD-MMM-YYYY  LT');
    }
});
Vue.filter('formatDateTimeYearCheck', function(value) {
    if (value) {
        let currentYear = new Date().getFullYear();
        if (currentYear == new Date(value).getFullYear()) {
            return moment(String(value)).format('DD-MMM  LT');
        } else return moment(String(value)).format('DD-MMM-YYYY  LT');
    }
});
Vue.filter('getAcronym', function(keyName) {
    let keywords = [];
    let acronym = "";
    keywords = keyName.split(" ");
    let self = this;
    if (keywords.length == 1) {
        keywords.map(function(keyword) {
            acronym += keyword.slice(0, 2);
        });
    } else {
        keywords.map(function(keyword) {
            let newKeyword = acronym + keyword.charAt(0);
            acronym = newKeyword.slice(0, 2);
        });
    }
    acronym = acronym.toUpperCase();
    return acronym;
});

router.beforeEach((to, from, next) => {
    let publicPage = false;
    if (to.path.includes("registration") || to.path.includes("client/activation") || to.path.includes("team/activation") || to.path.includes("prm/activation")) {
        publicPage = true;
    }

    const publicPages = [
        '/'
    ];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('userAuthData');
    const userinfo = store.getters.currentUser;
    const defaultredirects = store.getters.getDefaultRedirects;
    let defaultURL = '';

    if (userinfo.userType == "CMR") {
        defaultURL = defaultredirects.cmr;
    } else {
        defaultURL = defaultredirects.prm;
    }
    if (to.path == publicPages[0] && loggedIn) {
        next(defaultURL);
    }
    next();
});



new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')