<template>
    <div class="checklistReportPopup">
        <b-modal id="checklistReportPopup" ref="checklistReportPopupRef" scrollable :title="checklistTitle" size="lg" modal-class="checklistReportPopupRef" @hide="closeDialog" centered>
            <b-overlay :show="showOverlay" opacity="0.4">
                <div class="row mb-2">
                    <div class="col-12">
                        <div class="form-group">
                            <div v-for="checklist,index in checklistArray" :key="index" class="form-check mx-4 py-1">
                                <input type="checkbox" v-model="checklist.state" class="form-check-input" id="checkbox-1">
                                <input type="text" :id="'checklist'+(index)" :class="{'strikeThrough' : checklist.state}" :tabindex="index" class="no-border-textarea w-80" v-model="checklist.checklist" @input="addChecklist(index)" @keyup.enter="focusNextChecklist(index)" placeholder="Enter Checklist Item...">
                                <a v-if="!(index == (checklistArray.length-1) && checklist.checklist=='')" href="javascript:" class="text-danger" @click="removeChecklist(index)" title="Remove"><i class="fa fa-times-circle fa-lg float-right mt-1" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="primary" size="sm" @click="createChecklistArray" class="float-right mr-2">
                        <span>Save Checklist</span>
                    </b-button>
                    <b-button variant="secondary" size="sm" class="float-right mr-2" @click="clearData();">Clear</b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {
    props: {
        checklistTaskRef: Object,
    },

    data() {
        return {
            showOverlay: false,
            checklistTitle: '',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),

            checklist: {
                id: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                refEntity: '',
                refRecordId: '',
                checklist: '',
                state: '',
                preparedById: localStorage.getItem("userId"),
                checklistList: [],
            },

            checklistArray: [{ checklist: '', state: false }],
        }
    },
    // mounted() {
    //     this.checklistTitle = this.checklistTaskRef.title;
    //     alert('this.checklistTitle');
    // },
    methods: {
        getChecklist: function(refRecordId, refEntity) {
            this.checklist.refEntity = refEntity;
            this.checklist.refRecordId = refRecordId;
            this.$bvModal.show('checklistReportPopup');
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                refRecordId: refRecordId,
                refEntity: refEntity
            }
            this.checklistTitle = this.checklistTaskRef.title;

            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/test/checklistreport/task/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.taskChecklistReportList.length > 0) 
                        this.checklistArray = result.taskChecklistReportList;
                    else 
                        checklistArray: [{ checklist: '', state: false }];
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        saveChecklist: function(checklistArray) {
            this.checklist.checklistList = checklistArray;
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/test/checklistreport/bulk/update', this.checklist)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });

                        this.$emit('updateChecklist');
                        this.$bvModal.hide('checklistReportPopup');
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        addChecklist: function(index) {
            if (index == (this.checklistArray.length - 1)) {
                this.checklistArray.push({ checklist: '', state: false });
            }
        },
        focusNextChecklist(index) {
            let elementId = 'checklist' + (index+1);
            let element = document.getElementById(elementId);

            if(element) {
                element.focus();
            }
            else this.addChecklist(index);
        },
        removeChecklist: function (index) {
            this.checklistArray = this.checklistArray.filter((data, arrIndex) => index != arrIndex);
        },
        clearData: function() {
            this.checklist.refEntity = '';
            this.checklist.refRecordId = '';
            // this.checklistTitle = '';
            this.checklistArray = [{ checklist: '' }];
            // this.$v.checklist.$reset();
        },
        createChecklistArray: function() {
            let checklistArray = this.checklistArray.filter((data,i,arr) => !(i == (arr.length-1) && data.checklist == ''));
            this.saveChecklist(checklistArray);
        },
        closeDialog() {
            this.clearData();
            this.$emit('closeDialog');
        },
    }
}
</script>