<template>
    <div class="referenceDetailPopup">
        <b-modal id="taskSatatusUpdatePopup" size="lg" ref="taskSatatusUpdatePopupRef" @hide="closeDialog" modal-class="" no-enforce-focus title="Task Status Update" scrollable>
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="card-body p-3">
                    <div class="row input-group-label">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Task Title</label>
                                <b-form-input type="text" v-model="taskData.title" class="form-control" id="estimatedStartDt1" readonly></b-form-input>
                            </div>
                            <!-- <b-input-group :prepend="taskData.taskId ? taskData.taskId.toString() : ''" class="mb-3">
                                <b-form-input aria-label="First name" disabled v-model="taskData.title"></b-form-input>
                            </b-input-group> -->
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Status</label>
                                <select v-model="taskData.statusId" class="form-control">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in status" :value="info.id">{{info.value}}</option>
                                </select>
                                <label v-if="$v.taskData.statusId.$error && !$v.taskData.statusId.required" class="text-danger">Please Select Status</label>
                            </div>
                        </div>
                    </div>
                    <div class="row input-group-label">
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label for="startDate">Start Time</label>
                                <div class="input-group">
                                    <b-col class="col-6 pl-0 pr-0">
                                        <b-form-input type="date" v-model="taskData.startDateValue" :value="taskData.startDateValue" id="estimatedStartDt1" required></b-form-input>
                                    </b-col>
                                    <b-col class="col-6 pl-1 pr-0">
                                        <b-form-input type="time" v-model="taskData.startTimeValue" :value="taskData.startTimeValue" required></b-form-input>
                                    </b-col>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-6">
                            <div class="form-group">
                                <label for="endDate">End Time</label>
                                <div class="input-group">
                                    <b-col class="col-6 pl-0 pr-0">
                                        <b-form-input type="date" v-model="taskData.endDateValue" :value="taskData.endDateValue" id="estimatedStartDt1" required></b-form-input>
                                    </b-col>
                                    <b-col class="col-6 pl-1 pr-0">
                                        <b-form-input type="time" v-model="taskData.endTimeValue" :value="taskData.endTimeValue" required></b-form-input>
                                    </b-col>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-12">
                            <div class="form-group">
                                <label>Status Remarks</label>
                                <textarea type="text" v-model="taskData.description" maxlength="2000" class="form-control" id="description" rows="2" required></textarea>
                            </div>
                        </div>
                        <div class="col-sm-6 col-lg-6">
                            <label>Progress</label>
                            <span class="d-block">
                                <input type="range" v-model="taskData.progressPercentage" class="form-range w-100" min="0" max="100" step="5">
                                <span class="d-block">{{ taskData.progressPercentage ? taskData.progressPercentage : 0 }}%</span>
                            </span>
                        </div>
                    </div>
                </div>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <button type="submit" @click="cancelEditMode;$bvModal.hide('taskSatatusUpdatePopup');" class="btn btn-secondary float-right mb-0 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                    <button type="submit" @click="saveStatusChange" :disabled="showOverlay" class="btn btn-primary float-right mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Save</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    props: {
        statusUpdate: Object
    },
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entity: 'task',

            taskData: {
                id: '',
                taskId: '',
                title: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: '',
                progressPercentage: 0,
                startDate: '',
                endDate: '',
                startDateValue: new Date().toISOString().substr(0, 10),
                startTimeValue: '',
                endDateValue: new Date().toISOString().substr(0, 10),
                endTimeValue: '',
                refEntity: 'task',
                statusId: '',
                activityId: '',
                status: '',
                createdById: localStorage.getItem("userId"),
                description: '',
            },

            status: [],

        }
    },
    mounted() {
        this.initialSettings();
        this.lookupData();
    },
    validations: {
        taskData: {
            statusId: {
                required,
            }
        },
    },
    watch: {
        'taskData.title'() {
            this.elements.editMode = false;
            this.elements.visible = false;
        },
    },
    methods: {
        initialSettings: function() {
            this.taskData.id = this.statusUpdate.id;
            this.taskData.taskId = this.statusUpdate.taskId;
            this.taskData.title = this.statusUpdate.title;
            this.taskData.entityRecordMasterId = this.statusUpdate.entityRecordMasterId;
            this.taskData.statusId = this.statusUpdate.statusId;
            this.taskData.status = this.statusUpdate.status;
            this.taskData.activityId = this.statusUpdate.activityId;
            this.taskData.progressPercentage = this.statusUpdate.progressPercentage ? this.statusUpdate.progressPercentage : 0;
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/statusupdate/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.status = result.statusLookup;
                })
        },
        saveStatusChange: function() {
            if (this.taskData.startDate == '' || this.taskData.startDate == null) { 
                this.taskData.startDate = this.combineDateAndTime(this.taskData.startDateValue, this.taskData.startTimeValue) 
            }
            if (this.taskData.endDate == '' || this.taskData.endDate == null) { 
                this.taskData.endDate = this.combineDateAndTime(this.taskData.endDateValue, this.taskData.endTimeValue) 
            }
            this.$v.taskData.$touch();
            if (!this.$v.taskData.$invalid) {
                this.showOverlay = true;
                axios.post(this.$store.getters.getAPIBasePath + '/task/status/update', this.taskData)
                    .then((response) => {
                    this.showOverlay = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.$emit('updateParent');
                            this.$bvModal.hide('taskSatatusUpdatePopup');
                            // this.clearData();
                        }
                    }).catch((error) => {
                        this.showOverlay = false;
                    })
            }
        },
        cancelEditMode: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;

            this.taskData.statusId = this.statusUpdate.statusId;
            this.taskData.status = this.statusUpdate.status;
            this.taskData.progressPercentage = this.statusUpdate.progressPercentage ? this.statusUpdate.progressPercentage : 0;
            this.taskData.description = '';
            this.taskData.startDate = '';
            this.taskData.endDate = '';
            this.$v.taskData.$reset();
        },
        clearData: function() {
        },
        progressBarVariant: function(percentage) {
            if (percentage != null) {
                if (percentage < 25) {
                    return 'danger';
                } else if (percentage < 50) {
                    return 'warning';
                } else if (percentage < 75) {
                    return 'info';
                } else if (percentage < 100) {
                    return 'success';
                } else if (percentage == 100) {
                    return 'primary';
                }
            }
        },
        combineDateAndTime: function(date, time) {
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
        closeDialog() {
            this.$emit('closeDialog');
        },
    }
}
</script>