<template>
    <div class="type">
        <b-modal scrollable ref="ermMasterModal" size="md" id="ermMasterModal" centered modal-class="ermMasterModal" :title="$store.state.ermMasterInfo.title" @hide="closeDialog" :no-close-on-backdrop="true" ok-title="Ok">
            <div class="p-2">
                <b-card no-body class="mb-3">
                    <b-card-header header-tag="header" class="p-0 accordion-header" role="tab">
                        <label class="d-block p-2 accordion-title" block href="#" v-b-toggle.accordion-add>
                            <span v-if="!elements.editMode"><i class="fa fa-plus-circle pr-2 action-link"></i>New {{ $store.state.ermMasterInfo.title }}</span>
                            <span v-if="elements.editMode">Edit</span>
                        </label>
                    </b-card-header>
                    <b-collapse id="accordion-add" accordion="my-accordion2" v-model="elements.visible" role="tabpanel">
                        <b-card-body class="p-0">
                            <div class="row">
                                <b-input-group class="form-group py-2">
                                    <div class="align-middle float-left my-auto ml-4 mr-0" cols="2">Title</div>
                                    <b-col cols="8" class="pr-1">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.entityRecordMaster.title.$error }">
                                            <b-form-input id="title" v-model="entityRecordMaster.title" placeholder="Enter Title"></b-form-input>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.required" class="text-danger">Please Enter Title</label>
                                    </b-col>
                                    <div class="float-right">
                                        <div class="more-btns">
                                            <button type="submit" title="Save" @click="saveERMMaster" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-check" aria-hidden="true"></i></button>
                                            <button type="submit" title="Clear" @click="clearERMMasterData" class="sbtn btn btn-xs action-link p-1"><i class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </b-input-group>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-table show-empty small selectable :select-mode="selectMode" :busy="isBusy" sort-icon-left :items="ermList" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection">
                    <template v-slot:cell(actions)="data">
                        <a v-if="data.item.isFreeze==true" href="javascript:" class="text-center" title="Freezed"><i class="fa fa-lock fa-custom"></i></a>
                        <a v-else href="javascript:" class="text-center" title="Edit" @click="showEdit(data.item)"><i class="fa fa-pencil-square-o fa-custom fa-lg"></i></a>
                        <!-- <a href="javascript:" title="Delete" @click="" data-toggle="modal" data-target="#removeERMMaster"><i class="fa fa-trash pl-2"></i></a> -->
                    </template>
                    <template v-slot:empty="scope">
                        <div class="text-center">No {{ $store.state.ermMasterInfo.title }} found</div>
                    </template>
                    <template v-slot:emptyfiltered="scope">
                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                    </template>
                </b-table>
                <span :class="'badge-secondary'" class="fs-11 badge badge-pill pointer text-center float-left">{{this.totalRows}} Items</span> 
                <b-pagination v-if="ermList && ermList.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number align="fill" size="sm" class="my-0 pull-right"></b-pagination>
            </div>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required } from "vuelidate/lib/validators";
import { EventBus } from '@/main';
export default {
    data() {
        return {
            elements: {
                visible: false,
                editMode: false
            },
            isBusy: false,
            entityRecordMaster: {
                title: '',
                clientId: '',
                ermTypeId: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                statusId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityId: '',
                entity: this.$store.state.ermMasterInfo.entity,
                refEntityId: '',
                refEntity: this.$store.state.ermMasterInfo.refEntity,
                isArchive: false,
                attachments: []
            },
            entity: this.$store.state.ermMasterInfo.entity,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            sortDesc: false,
            pageOptions: [2, 5, 10, 15],
            sortBy: '',
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            ermList: [],
            fields: [
                { key: 'entityRecordMasterId', label: 'Id', sortable: true, sortDirection: 'desc' },
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc', class: '' },
                { key: 'actions', label: 'Actions' }
            ],
        }
    },
    validations: {
        entityRecordMaster: {
            title: {
                required
            }
        }
    },
    watch: {
        'entity': {
            handler: function(val1, val2) {
                this.getERMMasterList();
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        entityRecordMasterId: {
            get() {
                return this.$store.state.ermId;
            },
            set(val) {
                this.$store.state.ermId = val;
            }
        },
    },
    mounted() {
        this.getERMMasterList();
        this.lookupData();
    },
    methods: {
        getERMMasterList: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                userId: this.userId,
                entity: this.entity,
                isArchive: false,
            }
            this.isBusy = true;
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/list', data)
                .then((response) => {
                    let result = response.data;
                    if (result.entityRecordMasterList) {
                        this.ermList = result.entityRecordMasterList;         
                        this.totalRows = this.ermList.length;
                        this.isBusy = false;
                    }
                }).catch(error => {
                    this.isBusy = false;
                });
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.entityRecordMaster.vhextAccountId,
                projectId: this.entityRecordMaster.projectId,
                entity: this.$store.state.ermMasterInfo.entity,
                refEntity: this.$store.state.ermMasterInfo.refEntity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordMaster.entityId = result.entityId;
                    this.entityRecordMaster.refEntityId = result.refEntityId;
                })

        },
        saveERMMaster: function() {
            this.$v.entityRecordMaster.$touch();
            if (!this.$v.entityRecordMaster.$invalid) {
                if (this.elements.editMode) {
                    this.updateERMMaster();
                } else {
                    this.addERMMaster();
                }
            }
        },
        addERMMaster: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/add', this.entityRecordMaster)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        EventBus.$emit('refreshERMLookup');
                        this.clearERMMasterData();
                        this.getERMMasterList();
                    }
                })
        },
        updateERMMaster: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMaster.id,
                title: this.entityRecordMaster.title
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/updatetitle', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        EventBus.$emit('refreshERMLookup');
                        this.clearERMMasterData();
                        this.getERMMasterList();
                    }
                })
        },
        clearERMMasterData: function() {
            this.entityRecordMaster.title = '';
            this.$v.entityRecordMaster.$reset();
            this.elements.editMode = false;
        },
        showEdit: function(items) {
            let data = items;
            this.elements.visible = true;
            this.elements.editMode = true;
            this.entityRecordMaster.id = data.id;
            this.entityRecordMaster.title = data.title;
        },
        closeDialog() {
            this.$emit('closeDialog');
        }

    }
}
</script>