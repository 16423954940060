<template>
    <div>
        <ClientRequirementMaster :ermMasterInfo="ermMasterInfo"></ClientRequirementMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ClientRequirementMaster from '@/components/ermcomponents/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        ClientRequirementMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            routePath: '',
            ermMasterInfo: {
                title: "Client Requirement Master",
                entity: 'user_requirement_master',
                refEntity: 'user_requirement',
                redirectURL: '/urqmas/list/'
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
        if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURL = '/client/urqmas/list';
        } else {
            this.ermMasterInfo.redirectURL = '/urqmas/list';
        }
    },
    methods: {}
}
</script>