export const requestsPlan = {
    methods: {
        addActivity: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/general/activity/add', data);
        },
        getActivity: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/general/activity/list', data);
        },
        updateActivity: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/general/activity/update', data);
        },
        updateActivation: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', data);
        },
    	getPlanLookup: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/plan/lookup/all', data);
        },
        getPlans: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/plan/list', data);
        },
        updatePlan: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/plan/update', data);
        },
        addProcess: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/process/add', data);
        },
        getProcesses: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/process/all/list', data);
        },
        updateProcesses: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/process/update', data);
        },
        updateActivationStates: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', data);
        },
        addDeliverable: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/add', data);
        },
        getDeliverableLookup: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/lookup/all', data);
        },
        getDeliverables: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/list', data);
        },
        updateDeliverable: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/common/deliverable/update', data);
        },
    }
};