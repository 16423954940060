<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-8">
                            <h2 class="pl-2 mb-0 titel-text">TimeSheet</h2>
                        </div>
                        <div class="col-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row px-2">
                <div class="card col-12 col-lg-4 col-xl-4 mb-4 ">
                    <div class="profile_header">
                        <div class="row">
                            <div class="col-12 col-lg-6 col-xl-6 text-left">
                                <div>
                                    <p class="mx-0 my-2">
                                        <span class="font-light"><i class="fa fa-list pr-2" aria-hidden="true"></i></span>
                                        <input type="text" v-model="timeSheet.title" @change="updateTimeSheetData" class="view-form w-50" placeholder="Title" name="title">
                                    </p>
                                    <p class="mx-0 my-2">
                                        <span class="font-light mr-2"><i class="fa fa-sitemap pr-1" aria-hidden="true"></i>Task</span>
                                        <select v-model="timeSheet.taskId" @change="updateTimeSheetData" class="view-form w-50">
                                            <option value=""><span class="font-light">Select</span></option>
                                            <option v-for="(info, index) in tasks" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </p>
                                    <p class="mx-0 my-2">
                                        <span class="font-light mr-2"><i class="fa fa-sitemap pr-1" aria-hidden="true"></i>Activity</span>
                                        <select v-model="timeSheet.activityId" @change="updateTimeSheetData" class="view-form w-50">
                                            <option value=""><span class="font-light">Select</span></option>
                                            <option v-for="(info, index) in activities" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </p>
                                    <p class="mx-0 my-2">
                                        <span class="font-light mr-2"><i class="fa fa-calendar pr-2" aria-hidden="true"></i>Period</span>
                                        <b-dropdown ref="ddStartDt" :text="timeSheet.startDate | formatDate" variant="outline-secondary" no-caret size="sm" class="p-0">
                                            <b-calendar v-model="timeSheet.startDate" @context="updateTimeSheetData; $refs.ddStartDt.hide(true)" today-variant="primary" hide-header label-help="" selected-variant="secondary"></b-calendar>
                                        </b-dropdown>
                                        -
                                        <b-dropdown ref="ddEndDt" :text="timeSheet.endDate | formatDate" variant="outline-secondary" no-caret size="sm" class="p-0">
                                            <b-calendar v-model="timeSheet.endDate" @context="updateTimeSheetData; $refs.ddEndDt.hide(true)" today-variant="primary" hide-header label-help="" selected-variant="secondary"></b-calendar>
                                        </b-dropdown>
                                    </p>
                                    <p class="mx-0 my-2">
                                        <span class="font-light mr-2"><i class="fa fa-sitemap pr-1" aria-hidden="true"></i>Task Status</span>
                                        <select v-model="timeSheet.statusId" @change="updateTimeSheetData" class="view-form w-40">
                                            <option value=""><span class="font-light">Select</span></option>
                                            <option v-for="(info, index) in timeSheetStatus" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </p>
                                    <p class="mx-0 mt-2 mb-2">
                                        <textarea class="form-control" @change="updateTimeSheetData" v-model="timeSheet.description" placeholder="Description" rows="2">
                                        </textarea>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsTask } from "@/mixins/requestsTask";
import Multiselect from 'vue-multiselect';

export default {
    name: 'app',
    components: {
        Multiselect,
    },
    mixins: [requestsTask],
    data() {
        return {
            timeSheet: {},
            tasks: [],
            activities: [],
            timeSheetStatus: [],

            timeSheet: {  
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                preparedById: localStorage.getItem("userId"),
                entityRecordMasterId: this.$route.params.ermId,
                entity: this.entity
            }


        }
    },
    mounted() {
        this.timeSheet = JSON.parse(localStorage.getItem("rowData"));
        console.log(this.timeSheet)
        this.lookupData();

    },
    methods: {
        async lookupData() {
            try {                
                let data = {
                    vhextAccountId: this.timeSheet.vhextAccountId,
                    projectId: this.timeSheet.projectId,
                    entityRecordMasterId: this.timeSheet.entityRecordMasterId,
                    entity: 'time_sheet',
                }
                const response = await this.getTimeSheetLookup(data);
                let result = response.data;
                this.tasks = result.taskLookup;
                this.activities = result.activityLookup;
                this.timeSheetStatus = result.statusLookup;
            } catch(error) {
                console.log(error);
            }
        },
        async updateTimeSheetData() {
            try {
                this.timeSheet.isArchive = false
                this.timeSheet.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.timeSheet.projectId = localStorage.getItem("projectId");
                console.log(this.timeSheet);
                const response = await this.updateTimeSheet(this.timeSheet);
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: 'TimeSheet info Saved',
                        position: 'topCenter'
                    });
                    console.log('saved')
                }
                localStorage.setItem("rowData", JSON.stringify(this.timeSheet));
            } catch (error) {
                console.log(error);
            }
        },


    }
}
</script>