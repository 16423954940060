<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pb-1 px-3">
                            <div class="row no-gutters">
                                <div class="col-12">
                                    <div class="row no-gutters mb-2">
                                        <div class="col-4 pr-1">
                                            <label class="mb-0 p-1 d-block header-details text-muted">Member Name</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-1 mb-0 d-block header-details">{{projectTeam.memberName}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row no-gutters mb-2">
                                        <div class="col-4 pr-1">
                                            <label class="mb-0 p-1 d-block header-details text-muted">Position</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-1 mb-0 d-block header-details">{{projectTeam.position}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row no-gutters mb-2">
                                        <div class="col-4 pr-1">
                                            <label class="mb-0 p-1 d-block header-details text-muted">Phone</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-1 mb-0 d-block header-details">{{projectTeam.phone}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row no-gutters mb-2">
                                        <div class="col-4 pr-1">
                                            <label class="mb-0 p-1 d-block header-details text-muted">Email</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-1 mb-0 d-block header-details">{{projectTeam.emailId}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row no-gutters mb-2">
                                        <div class="col-4 pr-1">
                                            <label class="mb-0 p-1 d-block header-details text-muted">Qualification</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-1 mb-0 d-block header-details">{{projectTeam.qualification}}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row no-gutters mb-2">
                                        <div class="col-4 pr-1">
                                            <label class="mb-0 p-1 d-block header-details text-muted">Required From</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-1 mb-0 d-block header-details">{{projectTeam.requiredFrom | formatDateTimeAmPm }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="row no-gutters mb-2">
                                        <div class="col-4 pr-1">
                                            <label class="mb-0 p-1 d-block header-details text-muted">Required Till</label>
                                        </div>
                                        <div class="col-8">
                                            <label class="p-1 mb-0 d-block header-details">{{projectTeam.requiredTill | formatDateTimeAmPm }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog id="deleteProjectTeam" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete team member?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { EventBus } from '@/main';
export default {

    name: 'app',
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entityRecordMasterId: this.$route.params.ermId,
            entity: 'project_team',
            userId: localStorage.getItem("userId"),

            projectTeam: {},

            deleteParam: {
                id: 0
            }
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'Team Member';
        this.$store.state.editPageUrl = 'editprojectteam';
        this.projectTeam = JSON.parse(localStorage.getItem("rowData"));
    },
    created() {
        EventBus.$on("triggerDelete", this.deleteProjectTeam);
    },
    destroyed() {
        EventBus.$off("triggerDelete", this.deleteProjectTeam);
    },
    methods: {
        deleteProjectTeam: function(id) {
            this.deleteParam.id = this.projectTeam.id;
            this.$nextTick(() => {
                this.$bvModal.show('deleteProjectTeam');
            })
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/resource/projectteam/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/projectteam');
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
    }
}
</script>