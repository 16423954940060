<template>
    <div class="forgotPasswordPopup">
        <b-modal id="forgotPasswordPopup" centered ref="forgotPasswordPopupRef" @hide="closeDialog" modal-class="" title="Forgot Password ?" no-close-on-backdrop no-close-on-esc no-enforce-focus>
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <b-container fluid>
                    <b-row class="mb-1">
                        <b-col cols="2 mt-2">Email</b-col>
                        <b-col class="">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.forgotPassword.emailId.$error }">
                                <input type="text" v-model="forgotPassword.emailId" class="form-control" id="email" maxlength="100" placeholder="Please enter Email ID">
                                <div class="required-icon"></div>
                            </div>
                            <label v-if="$v.forgotPassword.emailId.$error && !$v.forgotPassword.emailId.email" class="text-danger">Enter Valid Email Id</label>
                            <label v-if="$v.forgotPassword.emailId.$error && !$v.forgotPassword.emailId.required" class="text-danger">Please enter Email Id</label>
                            <!-- <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label> -->
                        </b-col>
                    </b-row>
                </b-container>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <b-button variant="outline-primary" size="sm" :disabled="showOverlay" class="float-right" @click="closeDialog">Cancel</b-button>
                    <b-button variant="primary" size="sm" :disabled="showOverlay" @click="sendEmailVerification" class="float-right mr-2">
                        <span v-if="showOverlay" >
                            <b-spinner small label="Small Spinner" class="mr-2"></b-spinner>Sending Mail
                        </span>
                        <span v-else>Send Verification Mail</span>
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            forgotPassword: {
                emailId: '',
            },

            showOverlay: false,
        }
    },
    validations: {
        forgotPassword: {
            emailId: {
                required,
                email,
            }
        },
    },
    methods: {
        sendEmailVerification: function(flag) {
           // this.error = false;
           this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/resetpassword', this.forgotPassword)
                .then((response) => {
                    this.showOverlay = false;                        
                    let result = response.data;
                    if (result.status == 'OK') {
                        iziToast.success({
                            title: 'Check Email',
                            message: 'A reset link has been sent to ' + this.forgotPassword.emailId,
                            drag: true,
                            icon: 'fa fa-envelope',
                            position: 'center',
                            titleSize: '15px',
                            backgroundColor: 'white',
                            progressBar: false,
                            titleLineHeight: '40px',
                            messageSize: '15px',
                            messageLineHeight: '40px',
                        });
                        this.$bvModal.hide('forgotPasswordPopup');
                    }
                    else {
                        iziToast.error({
                            title: 'Error',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                })
                .catch(error=>{
                    this.showOverlay = false;
                    iziToast.error({
                        title: 'Error',
                        message: error.message,
                        position: 'topRight'
                    });
                })
        },
        closeDialog() {
            this.$emit('closeDialog');
            this.$bvModal.hide('forgotPasswordPopup');
        },
    }
}
</script>