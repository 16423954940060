
<template>
	<div id="app" >
	  	<b-overlay :show="showAppLoader" spinner-variant="primary" z-index="20000" opacity="0.4" rounded="sm">
			<div class="" v-bind:class="[ (layout === 'login-layout') ? 'login-layout' : '']">
				<component :is="layout">
					<router-view />
				</component>
			</div>
			<template #overlay>
				<div class="text-center font-weight-bold fs-16">
					<b-spinner variant="primary"></b-spinner>
					<p id="cancel-label">Please wait...</p>
				</div>
			</template>
	  	</b-overlay>
	</div>
</template>
<script>
const default_layout = "default";
export default {
	name: 'App',
	computed: {
		layout() {
			return (this.$route.meta.layout || default_layout) + "-layout";
		},
		showAppLoader: {
			get() {
				return this.$store.state.showAppLoader;
			},
			set(val) {
				this.$store.state.showAppLoader = val;
			}
		}
	},
	data() {
		return {      
		}
	},
	mounted() {
		if (this.layout == "login-layout" || this.layout == "home-layout") {
		   // $('#globalapploader').fadeOut('slow');
		}
	},
}
</script>