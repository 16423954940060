<template>
    <div class='demo-app'>
        <div class='demo-app-main'>
            <FullCalendar ref="fullCalendar" :options="calendarOptions"  />
        </div>
    </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from 'moment';

export default {
    props: {
        calendarEvents: Array
    },
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    mounted(){
        this.obj = this.$refs.fullCalendar.getApi();      

    },
    data() {
        return {
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin,timeGridPlugin],
                initialView: 'dayGridMonth',
                selectable: true,
                eventColor: "#007bff",
                eventTextColor: "#fff",
                dateClick: this.renderDate,
                events: this.calendarEvents,
                eventClick: this.eventSelected,
                eventMouseEnter: this.renderEventHover,
                selectAllow: this.selectRules,
                dayMaxEvents: true,
                displayEventEnd:true,
                aspectRatio: 2.5,
                height: '75vh',
                headerToolbar: {
                //   left: 'prev,next today dayGridMonth,timeGridWeek,timeGridDay',
                  left: 'prev,next',
                  center: 'title',
                  right: ''
                },
                eventTimeFormat: {
                    hour: 'numeric',
                    minute: '2-digit',
                    meridiem: 'short'
                },
                buttonText: {
                    today:'Today',
                    day: 'D',
                    week: 'W',
                    month: 'M'
                }
            }
        }
    },
    methods: {
        eventSelected(event, jsEvent, view) {
            this.$emit('event-selected', event.event)
        },
        forceRerender() {
            this.obj.render();
        },
        renderDate(date) {
            this.$emit('date-click', date)
        },
        selectRules(selectInfo) {
            return (selectInfo.start < new Date(this.$options.filters.formatDate(new Date()))) ? false : true;
        },
        renderEventHover(info) {
            // let fullCalendar = this.$refs.fullCalendar.getApi().currentData.renderableEventStore.instances;
            this.$emit('mouse-hover-event', info)
        },

    }
}
</script>
