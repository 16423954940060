<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row">
                        <div class="col-8 pl-2 pr-0">
                            <h2 class="pl-2 mb-0 titel-text">{{ermMasterInfo.title}}</h2> 
                        </div>
                        <div class="col-4 px-0">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-sm-12 col-xl-8 px-2 mb-5">
                    <div class="card mb-4">
                        <div class="card-body p-2">
                            <div id="scope-app" class="mb-3">
                                <b-card-body class="p-1">
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="title">{{ermMasterInfo.titleLabelName}}</label>
                                                <div class="required-field-block" :class="{ 'is-invalid': $v.entityRecordMaster.title.$error }">
                                                    <input type="text" class="form-control" v-model="entityRecordMaster.title" id="title" :class="{ 'is-invalid': $v.entityRecordMaster.title.$error }" placeholder="Enter Title">
                                                    <div class="required-icon"></div>
                                                </div>
                                                <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.minLength" class="text-danger">Title must have at least {{$v.entityRecordMaster.title.$params.minLength.min}} letters.</label>
                                                <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.required" class="text-danger">Please enter Title</label>
                                                <label v-if="$v.entityRecordMaster.title.$error && !$v.entityRecordMaster.title.maxLength" class="text-danger">Title must not exceed {{$v.entityRecordMaster.title.$params.maxLength.max}} letters.</label>
                                            </div>
                                        </div>
                                        <div v-if="ermMasterInfo.showTypeLookup" class="col-12">
                                            <div class="form-group">
                                                <label for="title">{{ermMasterInfo.typeLabelName}}</label>
                                                <select v-model="entityRecordMaster.ermTypeId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in entityRecordCustomType" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="ermMasterInfo.showTypeLookup" class="col-12">
                                            <div class="form-group">
                                                <label for="client">Client</label>
                                                <select v-model="entityRecordMaster.clientId" id="clientId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-else class="col-12">
                                            <div class="form-group">
                                                <label for="client">Client</label>
                                                <select v-model="entityRecordMaster.clientId" id="clientId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in clients" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="description">{{ermMasterInfo.descriptionLabelName}}</label>
                                                <textarea v-model="entityRecordMaster.description" class="form-control" id="description" rows="2" maxlength="1000" placeholder="Enter Description"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="statusId">Status</label>
                                                <select v-model="entityRecordMaster.statusId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in entityRecordStatus" :value="info.id">{{info.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" @click="saveData" class="btn btn-primary mb-0 mr-2 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-floppy-o pr-2"></i>Submit</button>
                                    <button type="submit" @click="getPrevUrl()" class="btn btn-primary mb-0 mr-4 pt-1 pb-1 pl-3 pr-3"><i class="fa fa-times pr-2"></i>Cancel</button>
                                    <a href="javascript:" title="Reset" @click="clearData"><i class="fa fa-refresh" aria-hidden="true"></i></a>
                                </b-card-body>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'app',
    props: {
        ermMasterInfo: Object
    },
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            error: false,
            clients: [],
            entityRecordStatus: [],
            entityRecordCustomType: [],
            users: [],
            entityRecordMaster: {
                title: '',
                clientId: '',
                ermTypeId: '',
                description: '',
                createdById: '',
                statusId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityId: '',
                entity: this.ermMasterInfo.entity,
                refEntityId: '',
                refEntity: this.ermMasterInfo.refEntity,
                isArchive: false,
                attachments: []
            },
            addMore: [],
        }
    },
    mounted() {
        this.lookupData();
        this.setDefaultValue();
        this.addMore.push(this.addMore.length + 1);
    },
    validations: {
        entityRecordMaster: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.entityRecordMaster.vhextAccountId,
                projectId: this.entityRecordMaster.projectId,
                entity: this.ermMasterInfo.entity,
                refEntity: this.ermMasterInfo.refEntity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.entityRecordStatus = result.statusLookup;
                    this.users = result.userLookup;
                    this.clients = result.clientLookup;
                    this.entityRecordCustomType = result.typeLookup;
                    if (this.ermMasterInfo.typeCode != null && this.ermMasterInfo.typeCode == 'cli') {
                        this.entityRecordCustomType = this.filterCustomType(this.entityRecordCustomType, 'Financial Transaction');
                    }


                    this.entityRecordMaster.entityId = result.entityId;
                    this.entityRecordMaster.refEntityId = result.refEntityId;
                    this.entityRecordMaster.createdById = localStorage.getItem("userId");
                })

        },
        saveData: function() {
            this.$v.entityRecordMaster.$touch();
            if (!this.$v.entityRecordMaster.$invalid) {
                this.saveERMMaster();
            }
        },
        saveERMMaster: function() {
            axios.post(this.$store.getters.getAPIBasePath + '/common/entityrecordmaster/add', this.entityRecordMaster)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        if (this.entityRecordMaster.attachments != null && this.entityRecordMaster.attachments.length > 0)
                            this.submitFiles(result.id, result.entityId);
                        else {
                            this.showMessage('Record Master Created', result.id);
                        }
                    }
                })
        },
        clearData: function() {
            this.entityRecordMaster.title = '';
            this.entityRecordMaster.clientId = '';
            this.entityRecordMaster.ermTypeId = '';
            this.entityRecordMaster.description = '';
            this.entityRecordMaster.createdById = localStorage.getItem("userId");
            this.entityRecordMaster.statusId = '';
            this.entityRecordMaster.createdDt = '';
            this.entityRecordMaster.lastUpdatedDt = '';
            this.entityRecordMaster.id = '';
            this.error = false;
            this.$v.entityRecordMaster.$reset();
        },
        setDefaultValue: function() {
            if (!Object.keys(this.ermMasterInfo).includes("typeLabelName"))
                this.ermMasterInfo.typeLabelName = "Type";
            if (!Object.keys(this.ermMasterInfo).includes("titleLabelName"))
                this.ermMasterInfo.titleLabelName = "Title";
            if (!Object.keys(this.ermMasterInfo).includes("descriptionLabelName"))
                this.ermMasterInfo.descriptionLabelName = "Description";
            if (!Object.keys(this.ermMasterInfo).includes("showTypeLookup"))
                this.ermMasterInfo.showTypeLookup = false;
        },
        addInput: function() {
            this.addMore.push(this.addMore.length + 1)
        },
        removeFile: function(index) {
            this.entityRecordMaster.attachments.splice(index, 1);
        },
        submitFiles: function(entityRecordMasterId, entityId) {
            let formData = new FormData();

            for (const row of Object.keys(this.entityRecordMaster.attachments)) {
                formData.append('files', this.entityRecordMaster.attachments[row]);
            }
            formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
            formData.append('attachedById', localStorage.getItem("userId"));
            formData.append('projectId', this.entityRecordMaster.projectId);
            formData.append('refEntity', this.ermMasterInfo.entity);
            formData.append('refRecordId', entityRecordMasterId);


            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.showMessage('Entity Record Master Created with attachment.', entityRecordMasterId);
                    console.log('Attachment uploaded');
                })
                .catch(error => {
                    console.log(error);
                    this.showMessage('Entity Record Master Created without attachment.', entityRecordMasterId);
                    console.log('Error Attachment uploaded');
                });
        },
        showMessage: function(message, id) {
            iziToast.success({
                title: 'Success',
                message: message,
                position: 'topRight'
            });
            this.$router.push(this.ermMasterInfo.redirectURL);
        },
        saveAttachment: function() {
            this.$v.entityRecordMaster.$touch();
            if (this.entityRecordMaster.attachments != null && this.entityRecordMaster.attachments.length == 0) {
                this.error = true;
            } else if (!this.$v.entityRecordMaster.$invalid) {
                this.error = false;
                this.saveERMMaster();
            }
        },
    }
}
</script>