<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-8">
                            <h2 class="pl-2 mb-0 titel-text">ScopeStatement View</h2>
                        </div>
                        <div class="col-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Title</span>
                                <input type="text" v-model="scopeStatement.title" @change="updateScopeStatementData" class="view-form w-50" placeholder="Title" name="PhaseTitle">
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Scope Type</span>
                                <select v-model="scopeStatement.scopeTypeId" @change="updateScopeStatementData" class="view-form w-50">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in scopeStatementTypes" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-3">InScope</span>
                                <b-form-checkbox class="pt-2 custom-toggle" v-model="scopeStatement.inScope" @change="updateScopeStatementData" name="check-button" switch>
                                </b-form-checkbox>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Scope Statement</span>
                                 <textarea v-model="scopeStatement.scopeStatement" @change="updateScopeStatementData" class="form-control" rows="2" required></textarea>
                            </p> 
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsRequirement } from "@/mixins/requestsRequirement.js";

export default {
    name: 'app',
    mixins: [requestsRequirement],

    components: {
    },
    data() {
        return {
            scopeStatement: {},
            scopeStatementTypes: [],
            title: [],
            inScope: [],
            scopeStatement: [],

            entity: 'scope_statement',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            activityLookupTitle: 'Activity Settings',


        }

    },
    mounted() {
        this.scopeStatement = JSON.parse(localStorage.getItem("rowData"));
        this.lookupData();
        console.log(this.scopeStatement);
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/scope/scopestatement/lookup/all', data)
                .then((response) => {
                    let result = response.data;
            console.log(result);
                    this.scopeStatementTypes = result.customTypeLookup;
                    this.scopeStatementStatus = result.statusLookup;
                })

        },
        async updateScopeStatementData() {
            try {
                console.log(this.scopeStatement);
                const response = await this.updateScopeStatement(this.scopeStatement);
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topCenter'
                    });
                    console.log('saved')
                }
                localStorage.setItem("rowData", JSON.stringify(this.scopeStatement));
            } catch (error) {
                console.log(error);
            }
        },
    },

    
}

</script>