<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-6">
                            <h2 class="pl-2 mb-0 titel-text">Edit Contact</h2>
                        </div>
                        <div class="col-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <div class="row">
                                 <div class="col-12">
                                    <div class="form-group">
                                        <label for="contactName">Contact Name</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.contact.contactName.$error }">
                                            <input type="text" class="form-control" id="contactName" disabled v-model="contact.contactName" placeholder="Enter Contact Name">
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.contact.contactName.$error && !$v.contact.contactName.required" class="text-danger">Please Fill Contact Name</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="position">Job Title</label>
                                        <input type="text" class="form-control" id="position" v-model="contact.position" placeholder="Enter Job Title">  
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="emailId">E-mail</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.contact.emailId.$error }">
	                                        <input type="text" class="form-control" id="emailId" disabled v-model="contact.emailId" placeholder="Enter E-mail Address">
                                        	<div class="required-icon"></div>
                                        </div>	
                                        <label v-if="$v.contact.emailId.$error && !$v.contact.emailId.required" class="text-danger">Please Fill E-mail</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="mobile">Mobile</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.contact.mobile.$error }">
                                        	<input type="text" class="form-control" id="mobile" v-model="contact.mobile" placeholder="Enter Mobile Number">
                                        	<div class="required-icon"></div>
										</div>	
                                        <label v-if="$v.contact.mobile.$error && !$v.contact.mobile.required" class="text-danger">Please Fill Mobile Number</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="phone">Phone</label>
                                        <input class="form-control" type="text" id="phone" v-model="contact.phone" placeholder="Enter Phone Number">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card-body p-0 mb-3">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                    <span>Address</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                <b-card-body class="p-1">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="buildingName">Building Name</label>
                                                               <input type="text" class="form-control" id="buildingName" v-model="contact.buildingName">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="streetName">Street Name</label>
                                                                <input type="text" class="form-control" id="streetName" v-model="contact.streetName">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="city">City</label>
                                                                <input type="text" class="form-control" id="city" v-model="contact.city">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="state">State</label>
                                                                <input type="text" class="form-control" id="state" v-model="contact.state">
                                                            </div>
                                                        </div>
                                                         <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="country">Country</label>
                                                                <input type="text" class="form-control" id="country" v-model="contact.country">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="pincode">Postal Code</label>
                                                                <input type="text" class="form-control" id="pincode" v-model="contact.pincode">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="website">Web Address</label>
                                                                <input type="text" class="form-control" id="website" v-model="contact.website">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:" @click="updateContact" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Update</a>
                            <a href="javascript:" @click="clearData" class="btn btn-info mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";

export default {
    name: 'app',
 
    data() {
        return {
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            contact: {  
            },
        }
    },
    mounted() {
    	this.contact = JSON.parse(localStorage.getItem("rowData"));
    },
    validations: {
        contact: {
            contactName: {
                required
            },
            emailId: {
                required
            },
            mobile: {
                required
            },
        }
    },
    methods: {
        updateContact: function() {
        	this.$v.contact.$touch();
            if (!this.$v.contact.$invalid) {

	        	console.log(this.contact)
            axios.post(this.$store.getters.getAPIBasePath + '/contact/update', this.contact)
                .then((response) => {
                    console.log(this.contact);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: 'Contact info updated successfully',
                            position: 'topRight'
                        });
                        this.getContact();
                        this.clearData();
                    }
                    console.log(result);
                })
            }
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.contact.contactName = '';
            this.contact.position = '';
            this.contact.emailId = '';
            this.contact.mobile = '';
            this.contact.phone = '';
            this.contact.firstName = '';
            this.contact.middleName = '';
            this.contact.lastName = '';
            this.contact.photo = '';
            this.contact.country = '';
            this.contact.buildingName = '';
            this.contact.streetName = '';
            this.contact.city = '';
            this.contact.state = '';
            this.contact.pincode = '';
            this.contact.website = '';
            this.$v.contact.$reset();
        },    
    }
}
</script>