<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-6">
                            <h2 class="pl-2 mb-0 titel-text">New Budget Plan</h2>
                        </div>
                        <div class="col-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70 ">
                        <div class="card-body pt-4 pb-4 pl-4 pr-4">
                            <div class="row mb-2">
                                <div class="row mb-2">
                                    <div class="col-12 mt-0">
                                        <div class="form-group">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.budgetPlan.title.$error }">
                                                <label for="title" class="inp">
                                                    <input type="text" id="title" placeholder="  " v-model="budgetPlan.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.budgetPlan.title.$error }" >
                                                    <span class="label">Item Title</span>
                                                    <span class="focus-bg"></span>
                                                </label>
                                                <label v-if="$v.budgetPlan.title.$error && !$v.budgetPlan.title.minLength" class="text-danger">Item Title must have at least {{$v.budgetPlan.title.$params.minLength.min}} letters.</label>
                                                <label v-if="$v.budgetPlan.title.$error && !$v.budgetPlan.title.required" class="text-danger pl-2">Please enter Item Title</label>
                                                <label v-if="$v.budgetPlan.title.$error && !$v.budgetPlan.title.maxLength" class="text-danger">Item Title must not exceed {{$v.budgetPlan.title.$params.maxLength.max}} letters.</label>
                                            </div>   
                                        </div>
                                    </div>
                                    <div class="col-12" >
                                        <div class="form-group">
                                            <label>Activity</label>
                                            <a :title="activityLookupTitle" class="setting-icon pl-2" href="javascript:" v-b-modal.activityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                            <select v-model="budgetPlan.activityId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in budgetPlanActivity" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="budgetCategoryId">Category</label>
                                            <a :title="categoryLookupTitle" class="setting-icon pl-2" href="javascript:" v-b-modal.categoryModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                            <select v-model="budgetPlan.budgetCategoryId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in budgetPlanCategory" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group"  :class="{ 'is-invalid': $v.budgetPlan.estimatedCost.$error }">
                                            <label>Estimated Cost</label>
                                            <VhextDecimalMoneyControl :value="Number(this.budgetPlan.estimatedCost)" @money-input="moneyControlInput" />
                                        </div>
                                        <label v-if="$v.budgetPlan.estimatedCost.$error && !$v.budgetPlan.estimatedCost.required" class="text-danger">Please Fill Estimated Cost</label>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Accountable</label>
                                            <select v-model="budgetPlan.accountablePersonId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:" @click="saveBudgetPlan" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</a>
                            <a href="javascript:" @click="clearData" class="btn btn-info mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</a>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <CategoryLookup v-if="elements.showCategory" ref="categoryLookupModal" :entity="finTxnEntity" :categoryLookupTitle="categoryLookupTitle" @refresh-lookup="lookupData" />
        <ActivityLookup ref="activityLookupModal" :entity="this.entity" :activityLookupTitle="activityLookupTitle" @refresh-lookup="lookupData" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import VhextDecimalMoneyControl from '@/components/usercontrols/VhextDecimalMoneyControl.vue';
import { requestsFinance } from "@/mixins/requestsFinance";

export default {
    name: 'app',
    mixins: [requestsFinance],
    components: {
        VhextDecimalMoneyControl
    },

    data() {
        return {
            elements: {
                alreadyExists: false
            },

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            categoryLookupTitle: 'Budget Plan Category Settings',
            activityLookupTitle: 'Activity Settings',
            notEnabled: true,
            prependText: localStorage.getItem("currency"),
            budgetPlanTypes: [],
            budgetPlanCategory: [],
            users: [],
            budgetPlanActivity: [],
            entity: '',
            finTxnEntity: 'financial_transaction',
            budgetPlan: {
                budgetId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                typeId: '',
                budgetCategoryId: '',
                title: '',
                description: '',
                estimatedCost: null,
                preparedById: '',
                remarks: '',
                accountablePersonId: '',
                statusId: '',
                activityId: '',
                entityId: ''
            },
        }
    },
   mounted() {
        this.initialSettings();
        this.lookupData();
    },
    validations: {
        budgetPlan: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            estimatedCost: {
                required,
            }
        }
    },
    methods: {

        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'budget_plan';
            this.userId = localStorage.getItem("userId");

            this.budgetPlan.vhextAccountId = this.vhextAccountId;
            this.budgetPlan.projectId = this.projectId;
            this.budgetPlan.entityRecordMasterId = this.entityRecordMasterId;
            this.budgetPlan.preparedById = this.userId;
            this.elements.showCategory = true;

        },

        async lookupData() {
            try {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    entity: this.entity,
                }
                console.log(data);
                const response = await this.getBudgetPlanLookup(data);
                let result = response.data;
                this.budgetPlanTypes = result.customTypeLookup;
                this.budgetPlanCategory = result.customCategoryLookup;
                this.users = result.userLookup;
                this.budgetPlanActivity = result.activityLookup;
                console.log(result);
            } catch (error) {
                console.log(error);
            }

        },
        async saveBudgetPlan() {
            this.$v.budgetPlan.$touch();
            console.log(this.budgetPlan);
            if (!this.$v.budgetPlan.$invalid) {
                try {
                    const response = await this.addBudgetPlan(this.budgetPlan);
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.clearData();
                            this.$router.push('/budgetplan/' + this.entityRecordMasterId );
                        }
                } catch (error) {
                    console.log(error);
                }
            }
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.budgetPlan.title = '';
            this.budgetPlan.budgetCategoryId = '';
            this.budgetPlan.description = '';
            this.budgetPlan.estimatedCost = '';
            this.budgetPlan.accountablePersonId = '';
            this.budgetPlan.activityId = '';
            this.budgetPlan.estimatedCost = null;
            this.$v.budgetPlan.$reset();
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },

       moneyControlInput: function(value) {
            this.budgetPlan.estimatedCost = value;
        }

    }
}
</script>