<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-6">
                            <h2 class="pl-2 mb-0 titel-text">New Requirement</h2>
                        </div>
                        <div class="col-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70 ">
                        <div class="card-body pt-4 pb-4 pl-4 pr-4">
                            <div class="row mb-2">
                                <div class="row mb-2">
                                    <div class="col-12 mt-0">
                                        <div class="form-group">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.requirementSpec.title.$error }">
                                                <label for="title" class="inp">
                                                    <input type="text" id="title" placeholder="  " v-model="requirementSpec.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.requirementSpec.title.$error }" >
                                                    <span class="label">Title</span>
                                                    <span class="focus-bg"></span>
                                                </label>
                                                <label v-if="$v.requirementSpec.title.$error && !$v.requirementSpec.title.minLength" class="text-danger">Title must have at least {{$v.requirementSpec.title.$params.minLength.min}} letters.</label>
                                                <label v-if="$v.requirementSpec.title.$error && !$v.requirementSpec.title.required" class="text-danger">Please enter Title</label>
                                                <label v-if="$v.requirementSpec.title.$error && !$v.requirementSpec.title.maxLength" class="text-danger">Title must not exceed {{$v.requirementSpec.title.$params.maxLength.max}} letters.</label>
                                            </div>   
                                        </div>
                                    </div>
                                    <div class="col-12" >
                                        <div class="form-group">
                                            <label for="requirementTypeId">Requirement Type</label>
                                             <a :title="typeLookupTitle" class="setting-icon ml-2" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                            <select v-model="requirementSpec.requirementTypeId" id="requirementTypeId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in requirementTypes" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="requirementSpec">Requirement Specification</label>
                                            <textarea class="form-control"  v-model="requirementSpec.requirementSpecification" placeholder="Enter Specification" rows="3">
                                            </textarea>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <!-- <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" :attachments="attachments" /> -->
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="priorityId">Priority</label>
                                                <select v-model="requirementSpec.priorityId" id="priorityId" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in requirementPriorities" :value="info.id">{{info.value}}</option>
                                                </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="dueDate">Due Date</label>
                                            <input type="date" class="form-control" v-model="requirementSpec.dueDate" id="dueDate">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="statusId">Status</label>
                                            <select v-model="requirementSpec.statusId" id="statusId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in requirementStatus" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:" @click="saveRequirementSpec" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</a>
                            <a href="javascript:" @click="clearData" class="btn btn-info mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsRequirement } from "@/mixins/requestsRequirement";
export default {
     mixins: [requestsRequirement],
    name: 'app',

    data() {
        return {
            elements: {
                alreadyExists: false
            },

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            htmlData: '',
            htmlDetailViewTitle: 'Requirement Specification',
            typeLookupTitle: 'Requirement Type Settings',
            functionalRefPopupTitle: 'Add Reference',
            entity: 'requirement_spec',
            users: [],
            requirementTypes: [],
            requirementStatus: [],
            requirementPriorities: [],
            requirementActivities: [],
            ermReferenceList: [],
            requirementSpec: {
                id: 0,
                requirementSpecId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                title: '',
                requirementTypeId: '',
                requirementSpecification: '',
                preparedById: '',
                preparedDt: new Date().toISOString().substr(0, 10),
                priorityId: '',
                dueDate: '',
                statusId: '',
            },
            requirementSpecs: [],
            weblinks: 'google.com',
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },        
            menuSetting: {
                height: 300,
                menubar: false,
            },
        }

    },
   mounted() {
        this.initialSettings();
        this.lookupData();
    },
    validations: {
        requirementSpec: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {

        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'requirement_spec';
            this.userId = localStorage.getItem("userId");

            this.requirementSpec.vhextAccountId = this.vhextAccountId;
            this.requirementSpec.projectId = this.projectId;
            this.requirementSpec.entityRecordMasterId = this.entityRecordMasterId;
            this.requirementSpec.preparedById = this.userId;
            if (this.entityRecordMasterId.length > 10) {
                this.getShareDecodedData(this.entityRecordMasterId);
            } else {
                this.lookupData();
            }
        },

        async lookupData() {
            try {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
                const response = await this.getRequirementSpecLookup(data);
                let result = response.data;
                this.requirementTypes = result.customTypeLookup;
                this.requirementStatus = result.statusLookup;
                this.requirementPriorities = result.priorityLookup;
                this.users = result.userLookup;
                console.log(result);
            }catch (error) {
                console.log(error);
            }
        },
        async saveRequirementSpec() {
            this.$v.requirementSpec.$touch();
            console.log(this.requirementSpec);
            if (!this.$v.requirementSpec.$invalid) {
                try {
                    const response = await this.addRequirementSpec(this.requirementSpec);
                    let result = response.data;
                    console.log(result);
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        let erdId = result.id;
                        this.clearData();
                        this.$router.push('/requirementspec/' + this.entityRecordMasterId );
                    }
                } catch (error) {
                console.log(error);
            }
            }
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.requirementSpec.id = null;
            this.requirementSpec.requirementSpecId = '';
            this.requirementSpec.title = '';
            this.requirementSpec.requirementTypeId = '';
            this.requirementSpec.type = '';
            this.requirementSpec.requirementSpecification = '';
            this.requirementSpec.priorityId = '';
            this.requirementSpec.priority = '';
            this.requirementSpec.dueDate = '';
            this.requirementSpec.statusId = '';
            this.requirementSpec.status = '';
            this.requirementSpec.hasReference = '';
            this.requirementSpec.hasRefLink = '';
            this.requirementSpec.hasAttachment = '';
            this.requirementSpec.preparedById = this.userId;            
            this.requirementSpec.preparedBy = '';            
            this.requirementSpec.preparedDt = new Date().toISOString().substr(0, 10);
            this.attachments = [];            
            // this.$refs.referenceModal.clearReference();
            this.$v.requirementSpec.$reset();
        },
    }
}
</script>