<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <div class="row">
                                
                                <div class="col-12">
                                    <div class="form-group ">
                                        <label for="clientName">Client Name</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.client.clientName.$error }">
                                            <input type="text" v-model="client.clientName" :class="{ 'is-invalid': $v.client.clientName.$error }" class="form-control" id="clientName" placeholder="Enter Client Name">
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.minLength" class="text-danger">Client Name must have at least {{$v.client.clientName.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.required" class="text-danger">Please enter Client Name</label>
                                        <label v-if="$v.client.clientName.$error && !$v.client.clientName.maxLength" class="text-danger">Client Name must not exceed {{$v.client.clientName.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>

                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="clientTypeId">Client Type</label>
                                        <select v-model="client.clientType" @change="changeClientType(client.clientType)" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in clientTypes" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div v-if="client.clientTypeName != 'company'" class="col-12">
                                    <div class="form-group">
                                        <label for="profession">Profession</label>
                                        <input type="text" v-model="client.profession" class="form-control" id="profession">
                                    </div>
                                </div>
                                <div v-if="client.clientTypeName != 'company'" class="col-12">
                                    <div class="form-group">
                                        <label for="employer">Employer</label>
                                        <input type="text" v-model="client.employer" class="form-control" id="employer">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="emailId">Email ID</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.client.emailId.$error, 'is-invalid': error }">
                                            <input type="email" v-model="client.emailId" placeholder="Enter Email Id" class="form-control" :class="{ 'is-invalid': $v.client.emailId.$error, 'is-invalid': error }" id="emailId">
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.client.emailId.$error && !$v.client.emailId.email" class="text-danger">Enter Valid EmailId</label>
                                        <label v-if="$v.client.emailId.$error && !$v.client.emailId.required" class="text-danger">Please enter EmailId</label>
                                        <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="card-body p-0 mb-3">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                <label class="d-block p-1 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                    <span>Contact Info</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                <b-card-body class="p-0">
                                                    <div class="row">
						                                    <div class="col-12">
						                                        <div class="form-group">					        
					                                                <b-form-input type="text" v-model="client.phone" placeholder="Phone" class="form-control" id="phone"></b-form-input>
						                                        </div>
						                                    </div>
						                                    <div class="col-12">
						                                        <div class="form-group">					        
					                                                <b-form-input type="text" v-model="client.mobile" placeholder="Mobile" class="form-control" id="mobile"></b-form-input>
						                                        </div>
						                                    </div>
					                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="card-body p-0 mb-3">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                <label class="d-block p-1 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                    <span>Address</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                <b-card-body class="p-0">
                                                    <div class="row">
					                                    <div class="col-12">
					                                        <div class="form-group">					        
				                                                <b-form-input type="text" v-model="client.address1" placeholder="Address 1" class="form-control" id="address1"></b-form-input>
					                                        </div>
					                                    </div>
					                                    <div class="col-12">
					                                        <div class="form-group">
				                                                <b-form-input type="text" v-model="client.address2" placeholder="Address 2" class="form-control" id="address2"></b-form-input>
					                                        </div>
					                                    </div>
					                                    <div class="col-12">
					                                        <div class="form-group">
				                                                <b-form-input type="text" v-model="client.city" placeholder="City" class="form-control" id="city"></b-form-input>
					                                        </div>
					                                    </div>
					                                    <div class="col-12">
					                                        <div class="form-group">
				                                                <b-form-input type="text" v-model="client.state" placeholder="State" class="form-control" id="state"></b-form-input>
					                                        </div>
					                                    </div>
					                                    <div class="col-12">
					                                        <div class="form-group">
				                                                <select v-model="client.countryId" class="form-control">
				                                                    <option value="">Country</option>
				                                                    <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
				                                                </select>
					                                        </div>
					                                    </div>
					                                    <div class="col-12">
					                                        <div class="form-group">
				                                                <b-form-input type="text" v-model="client.pincode" placeholder="Pincode" class="form-control" id="pincode"></b-form-input>
					                                        </div>
					                                    </div>
					                                </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { EventBus } from '@/main';
export default {
    name: 'app',
    data() {
        return {
            elements: {
                alreadyExists: false
            },
            error: false,
            clientTypes: [],
            users: [],
            countries: [],
            prependText: 'Full Name',

            client: {},
        }
    },
    mounted() {
        this.lookupData();
        this.$store.state.pageTitle = 'Edit Client';
        this.client = JSON.parse(localStorage.getItem("rowData"));
    },
    created() {
        EventBus.$on("savePage", this.updateClient);
    },
    destroyed() {
        EventBus.$off("savePage", this.updateClient);
    },
    validations: {
        client: {
            clientName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            },
            emailId: {
                required,
                email,
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId")
            }
            axios.post(this.$store.getters.getAPIBasePath + '/client/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.clientTypes = result.typeLookup;
                    this.users = result.userLookup;
                    this.countries = result.countryLookup;
                    console.log(result.customTypeLookup);
                    console.log(localStorage.getItem("projectId"));
                    console.log(localStorage.getItem("vhextAccountId"));
                })

        },
        updateClient: function() {
            if (this.client.clientType != null) {
                this.client.clientTypeId = this.client.clientType.id;
            }
            this.$v.client.$touch();
            if (!this.$v.client.$invalid) {
                this.$store.state.showAppLoader = true;
                axios.post(this.$store.getters.getAPIBasePath + '/client/update', this.client)
                    .then((response) => {
                        this.$store.state.showAppLoader = false;
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            localStorage.setItem("rowData", JSON.stringify(this.client));
                            this.$nextTick(() => {
                                this.$router.push('/landing/viewclient')
                            })
                        }
                    })
                    .catch(() => {
                        this.$store.state.showAppLoader = false;
                        iziToast.error({
                            title: 'Error',
                            message: 'Error',
                            position: 'topRight'
                        });
                    })
            }

        },
        clearData: function() {
            this.client.title = '';
            this.client.clientType = '';
            this.client.clientTypeId = '';
            this.client.clientTypeName = '';
            this.client.clientName = '';
            this.client.contactPerson = '';
            this.client.firstName = '';
            this.client.middleName = '';
            this.client.lastName = '';
            this.client.emailId = '';
            this.client.phone = '';
            this.client.mobile = '';
            this.client.address1 = '';
            this.client.address2 = '';
            this.client.city = '';
            this.client.state = '';
            this.client.countryId = '';
            this.client.pincode = '';
            this.client.hasContactInfo = '';
            this.client.active = '';
            this.client.profession = '';
            this.client.employer = '';
            this.client.position = '';
            this.elements.alreadyExists = false;
            this.error = false;
            this.$v.client.$reset();
        },
        changeClientType: function(data) {
            if (data.text != null && data.text.length > 0) {
                this.client.clientTypeName = data.text.toLowerCase();
            }
            if (this.client.clientTypeName == 'company') {
                this.prependText = 'Contact Person';
            } else {
                this.prependText = 'Full Name';
            }
        }

    }
}
</script>