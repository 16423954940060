<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class=" py-4 pl-3 pr-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.task.title.$error }">
                                            <label for="title" class="inp">
                                                <input type="text" id="title" placeholder=" " v-model="task.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.task.title.$error }">
                                                <span class="label">Task Title</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                        </div>
                                        <label v-if="$v.task.title.$error && !$v.task.title.minLength" class="text-danger">Task Title must have at least {{$v.task.title.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.task.title.$error && !$v.task.title.required" class="text-danger">Please enter Task Title</label>
                                        <label v-if="$v.task.title.$error && !$v.task.title.maxLength" class="text-danger">Task Title must not exceed {{$v.task.title.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Start Date</label>
                                        <div class="input-group">
                                            <b-col class="col-7 pl-0 pr-0">
                                                <b-form-input type="date" v-model="task.estimatedStartDate" :value="task.estimatedStartDate" id="estimatedStartDt1" required></b-form-input>
                                            </b-col>
                                            <b-col class="col-5 pl-1 pr-0">
                                                <b-form-input type="time" v-model="task.estimatedStartTime" :value="task.estimatedStartTime" required></b-form-input>
                                            </b-col>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Due Date</label>
                                        <div class="input-group">
                                            <b-col class="col-7 pl-0 pr-0">
                                                <b-form-input type="date" v-model="task.estimatedEndDate" :value="task.estimatedEndDate" id="estimatedStartDt1" required></b-form-input>
                                            </b-col>
                                            <b-col class="col-5 pl-1 pr-0">
                                                <b-form-input type="time" v-model="task.estimatedEndTime" :value="task.estimatedEndTime" required></b-form-input>
                                            </b-col>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="processActivity">Process/Activity</label>
                                        <select v-model="task.processActivity" class="form-control" id="processActivity">
                                            <option value="">Select</option>
                                            <option v-for="(info,index) in processActivities" :key="index" :value="{activityId: info.id, processId: info.processId}">{{ info.process ? info.process + ' / ' : ''}}{{info.value}}</option>
                                        </select>
                                        <a :title="processActivityLookupTitle" class="setting-icon" href="javascript:" v-b-modal.processActivityModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="assignedToId">Assigned To</label>
                                        <select v-model="task.assignedToId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info,index) in users" :value="info.id" :key="index">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="statusId">Status</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.task.statusId.$error }">
                                            <select v-model="task.statusId" class="form-control" :class="{ 'is-invalid': $v.task.statusId.$error }">
                                                <option value="">Select</option>
                                                <option v-for="(info,index) in taskStatus" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.task.statusId.$error && !$v.task.statusId.required" class="text-danger">Please Select a Status</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="categoryId">Dev Plan / Task Group</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.task.entityRecordMasterId.$error }">
                                            <select v-model="task.entityRecordMasterId" class="form-control" :class="{ 'is-invalid': $v.task.entityRecordMasterId.$error }">
                                                <option value="">Select</option>
                                                <option v-for="(info,index) in entityERMLookup" :value="info.id" :key="index">{{info.value}}</option>
                                            </select>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.task.entityRecordMasterId.$error && !$v.task.entityRecordMasterId.required" class="text-danger">Please Select a Dev Plan / Task Group</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="description">Description</label>
                                        <textarea type="text" v-model="task.description" maxlength="2000" class="form-control" id="description" rows="2" required></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ProcessActivityLookup ref="processActivityModal" :processActivityLookupTitle="processActivityLookupTitle" @refresh-lookup="lookupData" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsTask } from "@/mixins/requestsTask";
import { EventBus } from '@/main';
import ProcessActivityLookup from '@/components/popup/lookup/ProcessActivityLookup.vue';
export default {
    name: 'app',
    components: {
        ProcessActivityLookup
    },
    mixins: [requestsTask],
    data() {
        return {
            entityERMLookup: [],
            users: [],
            processActivities: [],
            taskStatus: [],

            entity: 'task',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            projectTypeId: localStorage.getItem("projectTypeId"),

            processActivityLookupTitle: 'Process / Activity Settings',

            task: {
                id: '',
                taskId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: localStorage.getItem("ermId"),
                userId: localStorage.getItem("userId"),
                entity: 'task',
                refEntity: '',
                title: '',
                planId: '',
                requirementId: '',
                requirement: '',
                processActivity: '',
                processId: '',
                activityId: '',
                activity: '',
                taskTypeId: '',
                type: '',
                isArchive: false,
                description: '',
                priorityId: '',
                priority: '',
                startDate: '',
                endDate: '',
                estimatedStartDt: '',
                estimatedStartDate: this.getDateValue(new Date()),
                estimatedStartTime: this.getTimeValue(new Date()),
                estimatedEndDt: '',
                estimatedEndDate: this.getDateValue(new Date()),
                estimatedEndTime: this.getTimeValue(new Date()),
                assignedById: localStorage.getItem("userId"),
                assignedTo: '',
                assignedToId: '',
                effort: '',
                effortType: '',
                progressPercentage: '',
                statusId: '',
                status: '',
                hasReference: '',
                hasAttachment: '',
                hasComment: '',
                entityId: '',

                refErmId: '',
                refErdId: '',
                refEntityId: '',
                refErdTitle: '',
                refErdDescription: '',
                refEntity: '',

                createdDt: '',
                lastUpdatedDt: '',
                taskAssignment: '',
                attachments: [],
                checklistList: [],
            },

        }
    },
    validations: {
        task: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            statusId: {
                required
            },
            entityRecordMasterId: {
                required
            }
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'New Task';
        this.lookupData();
    },
    created() {
        EventBus.$on("savePage", this.savePageData);
    },
    destroyed() {
        EventBus.$off("savePage", this.savePageData);
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: this.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/task/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.processActivities = result.processActivityLookup;
                    this.taskStatus = result.statusLookup;
                    this.entityERMLookup = result.entityERMLookup;
                    // this.task.entityRecordMasterId = this.entityERMLookup.length>0 ? this.entityERMLookup[0].id : '';
                    this.task.statusId = this.taskStatus.length>0 ? this.taskStatus[0].id : '';
                })
        },
        savePageData() {
            this.saveTask();
        },
        async saveTask() {
            this.task.estimatedStartDt = this.combineDateAndTime(this.task.estimatedStartDate, this.task.estimatedStartTime);
            this.task.estimatedEndDt = this.combineDateAndTime(this.task.estimatedEndDate, this.task.estimatedEndTime);
            this.task.processId = this.task.processActivity.processId;
            this.task.activityId = this.task.processActivity.activityId;

            this.$v.task.$touch();
            if (!this.$v.task.$invalid) {
                try {
                    this.$store.state.showAppLoader = true;
                    const response = await this.addTask(this.task);
                    this.$store.state.showAppLoader = false;
                    let result = response.data;                        
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.$router.push('/task');
                    }
                } catch (error) {
                    this.$store.state.showAppLoader = false;
                    iziToast.error({
                        title: 'Error',
                        message: 'Error',
                        position: 'topRight'
                    });
                }
            }
        },
        clearData: function() {
            this.task.title = '';
            this.task.estimatedEndDt = '';
            this.task.estimatedEndDate = this.getDateValue(new Date());
            this.task.estimatedEndTime = this.getTimeValue(new Date());
            this.task.estimatedStartDt = '';
            this.task.estimatedStartDate = this.getDateValue(new Date());
            this.task.estimatedStartTime = this.getTimeValue(new Date());
            this.task.taskAssignment = '';
            this.task.processActivity = '';
            this.task.processId = '';
            this.task.activityId = '';
            this.task.requirementId = '';
            this.task.requirement = '';
            this.task.effort = '';
            this.task.effortType = '';
            this.task.progressPercentage = '';
            this.task.statusId = this.taskStatus.length>0 ? this.taskStatus[0].id : '';
            this.task.assignedTo = '';
            this.task.assignedToId = '';
            this.task.taskId = '';
            this.task.planId = '';
            this.task.activity = '';
            this.task.taskTypeId = '';
            this.task.type = '';
            this.task.description = '';
            this.task.priorityId = '';
            this.task.priority = '';
            this.task.startDate = '';
            this.task.endDate = '';
            this.task.hasReference = '';
            this.task.hasAttachment = '';
            this.task.hasComment = '';
            this.task.entityId = '';
            this.task.refErmId = '';
            this.task.entityRecordMasterId = this.entityERMLookup.length>0 ? this.entityERMLookup[0].id : '';
            this.task.attachments = [];
            this.task.checklistList = [];
            this.hasChecklist = false;
            this.$v.task.$reset();
        },
    }
}
</script>