import AddProject from '@/views/project/AddProject.vue'
import ViewProject from '@/views/project/ViewProject.vue'
import EditProject from '@/views/project/EditProject.vue'
import IssueMaster from '@/views/project/IssueMaster.vue'
import IssueMasterList from '@/views/project/IssueMasterList.vue'
import AddIssue from '@/views/project/AddIssue.vue'
import Issue from '@/views/project/Issue.vue'
import ViewIssue from '@/views/project/ViewIssue.vue'
import AddProjectDocument from '@/views/project/AddProjectDocument.vue'
import ProjectDocument from '@/views/project/ProjectDocument.vue'
import ProjectDocumentMaster from '@/views/project/ProjectDocumentMaster.vue'
import ProjectDocumentMasterList from '@/views/project/ProjectDocumentMasterList.vue'


export default [
    {
        path: '/landing/addproject',
        name: 'addproject',
        component: AddProject,
        meta: { layout: 'addpage', accessedby: 'pm' }
    },
    {
        path: '/viewproject/:id',
        name: 'viewproject',
        component: ViewProject,
        meta: { layout: 'viewpage', accessedby: 'pm' }
    },
    {
        path: '/editproject/:id',
        name: 'editproject',
        component: EditProject,
        meta: { layout: 'addpage', accessedby: 'pm' }
    },
    {
        path: '/issmas',
        name: 'issmas',
        component: IssueMaster,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/issmas/list',
        name: 'issmaslist',
        component: IssueMasterList,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/addissue/:ermId',
        name: 'addissue',
        component: AddIssue,
        meta: { layout: 'addpage', accessedby: 'pm' }
    },
    {
        path: '/issue/:ermId',
        name: 'issue',
        component: Issue,
        meta: { accessedby: 'pm' }
    },
    {
        path: '/viewissue/:ermId',
        name: 'viewissue',
        component: ViewIssue,
        meta: { layout: 'viewpage', accessedby: 'pm' }
    },
    {
        path: '/addprojectdocument/:ermId',
        name: 'addprojectdocument',
        component: AddProjectDocument,
        meta: { layout: 'addpage', accessedby: 'pm' }
    },
    {
        path: '/pdcmas',
        name: 'pdcmas',
        component: ProjectDocumentMaster,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/pdcmas/list',
        name: 'pdcmaslist',
        component: ProjectDocumentMasterList,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/projectdocument/:ermId',
        name: 'projectdocument',
        component: ProjectDocument,
        meta: { accessedby: 'pm' }
    },
    
];