<template>
    <div class="content_wrapper">
        <div class="">
            <div class="container-fluid">
                <div class="chart_section row">
                    <div class="col-lg-8 col-xl-8 px-2">
                        <div class="card mb-70">
                            <div class="card-body pt-4 pb-4 pl-3 pr-3">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.project.title.$error }">
                                            <label for="title" class="inp">
                                                <input type="text" id="title" placeholder=" " v-model="project.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.project.title.$error }">
                                                <span class="label">Title</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                            <label v-if="$v.project.title.$error && !$v.project.title.minLength" class="text-danger">Title must have at least {{$v.project.title.$params.minLength.min}} letters.</label>
                                            <label v-if="$v.project.title.$error && !$v.project.title.required" class="text-danger">Please enter Title</label>
                                            <label v-if="$v.project.title.$error && !$v.project.title.maxLength" class="text-danger">Title must not exceed {{$v.project.title.$params.maxLength.max}} letters.</label>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="projectTypeId">Project Type</label>
                                            <select v-model="project.projectTypeId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in projectTypes" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group select-box-3">
                                            <label for="duration">Duration</label>

                                            <div class="row">
                                                <div class="col-4">
                                                    <select v-model="project.durationYears" class="form-control">
                                                        <option value="">Years</option>
                                                        <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                                                    </select>
                                                </div>                                            
                                                <div class="col-4 pl-0">
                                                    <select v-model="project.durationMonths" class="form-control">
                                                        <option value="">Months</option>
                                                        <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                                                    </select>
                                                </div>
                                                <div class="col-4 pl-0">
                                                    <select v-model="project.durationDays" class="form-control">
                                                        <option value="">Days</option>
                                                        <option v-for="(info, index) in daysList" :value="info.id">{{info.name}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="startDate">Start Date</label>
                                            <div>
                                                <input v-model="project.startDate" type="date" class="form-control" placeholder="Start Date">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label for="endDate">End Date</label>
                                            <div>
                                                <input v-model="project.endDate" type="date" class="form-control" placeholder="End Date">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Budget</label>
                                            <b-input-group>
                                                <b-input-group-prepend class="custom-input-group-prepend">
                                                    <span class="input-group-text">
                                                        <i class="fa fa-inr" aria-hidden="true"></i>
                                                    </span>
                                                </b-input-group-prepend>
                                                <b-form-input type="number" v-model="project.initialBudget">
                                                </b-form-input>
                                                <b-input-group-append class="custom-input-group-append">
                                                    <span class="input-group-text">
                                                        .00
                                                    </span>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import month from '@/json/month.json';
import days from '@/json/days.json';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsMixin } from "@/mixins/requestsMixin";
import { EventBus } from '@/main';
export default {
    name: 'app',
    mixins: [requestsMixin],
    data() {
        return {
            monthList: month,
            daysList: days,
            files: '',
            projectTypes: [],
            addMore: [],

            project: {
                id: '',
                title: '',
                description: '',
                projectTypeId: '',
                projectAccessTypeId: '',
                projectManagerId: localStorage.getItem("userId"),
                durationDays: '',
                durationMonths: '',
                durationYears: '',
                initialBudget: '',
                startDate: '',
                endDate: '',
                isArchive: false,
                isProject: 1,
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                attachments: [],
                entity: 'project',
                userTypeCode: localStorage.getItem("userTypeCode"),
            },

        }
    },
    mounted() {
        this.$store.state.pageTitle = 'New Project';
        this.lookupData();
    },
    created() {
        EventBus.$on("savePage", this.saveProject);
    },
    destroyed() {
        EventBus.$off("savePage", this.saveProject);
    },
    validations: {
        project: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
        }
    },
    methods: {
        async lookupData() {
            try {                
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                }
                const response = await this.getProjectLookup(data);
                let result = response.data;
                this.projectTypes = result.typeLookup;
                this.users = result.userLookup;
            } catch(error) {
                console.log(error);
            }
        },
        async saveProject() {
            try {
                this.$v.project.$touch();
                console.log(this.project);
                if (!this.$v.project.$invalid) {
                    localStorage.setItem('projectName', this.project.title);
                    console.log(this.project)
                    const response = await this.addProject(this.project);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        localStorage.setItem('projectId', result.id);
                        this.project.id = result.id;
                        localStorage.setItem('projectTypeId', this.project.projectTypeId);
                        localStorage.setItem('projectName', this.project.title);
                        // localStorage.setItem('currency', data.currencySymbol);
                        localStorage.setItem("rowData", JSON.stringify(this.project));
                        this.$nextTick(() => {
                            this.$router.push('/viewproject/' + this.project.id)
                        })
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                }
            } catch(error) {
                console.log(error);
            }

        },
        clearData: function() {
            this.project.title = '';
            this.project.description = '';
            this.project.projectTypeId = '';
            this.project.projectAccessTypeId = '';
            this.project.projectManagerId = '';
            this.project.categoryId = '';
            this.project.durationDays = '';
            this.project.durationMonths = '';
            this.project.durationYears = '';
            this.project.initialBudget = '';
            this.project.startDate = '';
            this.project.endDate = '';
            this.project.description = '';
            this.project.statusId = '1';
            this.project.timeZone = '';
            this.project.location = '';
            this.project.countryId = '';
            this.$v.project.$reset();
        },
        addInput: function() {
            this.addMore.push(this.addMore.length + 1)
        },
        removeFile: function(index) {
            this.project.attachments.splice(index, 1);
        },
        submitFiles: function(projectId) {
            let formData = new FormData();
            for (const row of Object.keys(this.project.attachments)) {
                formData.append('files', this.project.attachments[row]);
            }
            formData.append('vhextAccountId', localStorage.getItem("vhextAccountId"));
            formData.append('attachedById', localStorage.getItem("userId"));
            formData.append('projectId', projectId);
            formData.append('refEntity', this.project.entity);
            formData.append('refRecordId', projectId);
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.showMessage('Project Created with attachment.', projectId);
                    console.log('Attachment uploaded');
                })
                .catch(error => {
                    this.showMessage('Project Created without attachment.', projectId);
                    console.log('Error Attachment uploaded');
                });
        },
        showMessage: function(message, id) {
            iziToast.success({
                title: 'Success',
                message: message,
                position: 'topRight'
            });
            // this.getCurrencySymbol(id);
        },
        getCurrencySymbol: function(id) {

            let data = {
                projectId: id
            }
            axios.post(this.$store.getters.getAPIBasePath + '/project/currency', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == true) {
                        localStorage.setItem('currency', result.currency);
                    }
                    localStorage.setItem('projectId', id);
                    this.$router.push('/projectview');
                })
        }

    }
}
</script>