<template>
    <div>
        <div class="sufee-login d-flex align-content-center flex-wrap">
            <div class="container">
                <div class="login-content">
                    <div class="login-form">
                        <form>
                            <div class="form-group">
                                <label>Account Name</label>
                                <input type="text" v-model="vhextAccount.accountName" class="form-control" :class="{ 'is-invalid': $v.vhextAccount.accountName.$error }" placeholder="Account Name">
                                <label v-if="$v.vhextAccount.accountName.$error && !$v.vhextAccount.accountName.minLength" class="text-danger">Account Name must have at least {{$v.vhextAccount.accountName.$params.minLength.min}} letters.</label>
                                <label v-if="$v.vhextAccount.accountName.$error && !$v.vhextAccount.accountName.required" class="text-danger">Account Name is required.</label>
                                <label v-if="$v.vhextAccount.accountName.$error && !$v.vhextAccount.accountName.maxLength" class="text-danger">Account Name must not exceed {{$v.vhextAccount.accountName.$params.maxLength.max}} letters.</label>
                            </div>
                            <div class="form-group">
                                <label>Email Id</label>
                                <input type="email" class="form-control" v-model="vhextAccount.emailId" :class="{ 'is-invalid': $v.vhextAccount.emailId.$error }" placeholder="Email Id">
                                <label v-if="submitted && !$v.vhextAccount.emailId.required" class="text-danger">EmailId is required</label>
                                <label v-if="submitted &&  !$v.vhextAccount.emailId.email" class="text-danger">Email is not valid</label>
                                <label v-if="error" class="text-danger">EmailId already exists.</label>
                            </div>
                            <div class="form-group">
                                <label for="vhextAccountTypeId">Account Type</label>
                                <select v-model="vhextAccount.vhextAccountTypeId" class="form-control" id="vhextAccountTypeId" :class="{ 'is-invalid': $v.vhextAccount.vhextAccountTypeId.$error }">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in accountTypes" :key="index" :value="info.id">{{info.value}}</option>
                                </select>
                                <label v-if="$v.vhextAccount.vhextAccountTypeId.$error && !$v.vhextAccount.vhextAccountTypeId.required" class="text-danger">Account Type is required.</label>
                            </div>
                            <div class="form-group">
                                <label>Phone</label>
                                <input type="text" v-model="vhextAccount.phone" class="form-control" placeholder="Phone">
                            </div>
                            <div class="form-group">
                                <label>Company Name</label>
                                <input type="text" v-model="vhextAccount.company" class="form-control" placeholder="Company Name">
                            </div>
                            <div class="form-group">
                                <label>Country</label>
                                <select v-model="vhextAccount.countryId" class="form-control" id="countryId">
                                    <option value="">Select</option>
                                    <option v-for="(info, index) in countries" :key="index" :value="info.id">{{info.value}}</option>
                                </select>
                            </div>
                            <div class="checkbox">
                                <label>
                                    <input v-model="vhextAccount.terms" type="checkbox"> I accept the <a href="#" class="action-link">Terms of Service</a>
                                </label>
                            </div>
                            <button type="submit" @click.prevent="saveAccount" class="btn btn-success btn-flat m-b-30 m-t-30">Register</button>
                            
                            <div class="register-link m-t-15 text-center">
                                <p>Already have account ?
                                    <a href="#" @click.prevent="$router.push('/login')" > Sign in</a>
                                </p>
                            </div>
                            <!-- <a href="/dashboard"> Dashboard</a> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    data() {
        return {
            error: false,
            entity: 'vhext_account',
            submitted: false,
            accountTypes: [],
            countries: [],
            vhextAccount: {
                accountName: '',
                vhextAccountTypeId: '',
                emailId: '',
                phone: '',
                password: '',
                confirmationPassword: '',
                terms: false,
                company: '',
                jobTitle: '',
                countryId: '',
                isMobileActivation: 1,
            },
        }
    },
    mounted() {
        this.lookupData();
        console.log(localStorage.getItem("pageRedirectURL"));
    },
    validations: {
        vhextAccount: {
            accountName: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            emailId: {
                required,
                email
            },
            vhextAccountTypeId: {
                required,
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                entity: this.entity
            }
            axios.post(this.$store.getters.getAPIBasePath + '/vhext/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.accountTypes = result.typeLookup;
                    this.accountTypes = this.filterAccountType("Individual", "Company");
                    this.countries = result.countryLookup;
                })

        },
        saveAccount: function() {
            this.submitted = true;
            this.$v.vhextAccount.$touch();
            if (!this.$v.vhextAccount.$invalid) {
                if (!this.vhextAccount.terms) {
                    iziToast.info({
                        title: 'Please accept the Terms',
                        position: 'topRight'
                    });
                    return;
                }
                this.$store.state.showAppLoader = true;
                this.error = false;
                axios.post(this.$store.getters.getAPIBasePath + '/vhext/account/add', this.vhextAccount)
                    .then((response) => {
                        this.$store.state.showAppLoader = false;
                        let result = response.data;
                        if (result.status == "CREATED") {
                            iziToast.success({
                                title: 'Check Email',
                                message: `Activation code has been sent to "${this.vhextAccount.emailId}"`,
                                drag: true,
                                timeout: 8000,
                                icon: 'fa fa-envelope',
                                position: 'center',
                                titleSize: '15px',
                                backgroundColor: 'white',
                                progressBar: false,
                                titleLineHeight: '40px',
                                messageSize: '15px',
                                messageLineHeight: '40px',
                            });
                            this.$router.push('/activation');
                            this.clearData();
                        } else if (result.status == 'OK') {
                            this.error = true;
                            iziToast.info({
                                message: result.message,
                                position: 'topRight'
                            });

                        }
                    })
                    .catch(error => {
                        this.$store.state.showAppLoader = false;
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
        },

        clearData: function() {
            this.vhextAccount.accountName = '';
            this.vhextAccount.vhextAccountTypeId = '';
            this.vhextAccount.emailId = '';
            this.vhextAccount.phone = '';
            this.vhextAccount.password = '';
            this.vhextAccount.terms = false;
            this.vhextAccount.confirmationPassword = '';
            this.vhextAccount.company = '';
            this.vhextAccount.accountName = '';
            this.vhextAccount.countryId = '';
            this.$v.vhextAccount.$reset();
        },
        filterAccountType: function(individual, company) {
            return this.accountTypes.filter(function(data, key) {
                if (data.value == individual || data.value == company) {
                    return data;
                }
            });
        }

    }
}
</script>