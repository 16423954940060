<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-8">
                            <h2 class="pl-2 mb-0 titel-text">Requirement</h2>
                        </div>
                        <div class="col-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply" aria-hidden="true"></i> Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pb-1 px-3">
                            <p class="mx-0 mb-2 mt-0">
                                <span class="font-light mr-2">Title</span>
                                <input type="text" id="title" v-model="requirementSpec.title" @change="updateRequirementSpec" class="view-form w-50  " 
                                 >
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Req Type</span>
                                <a :title="typeLookupTitle" href="javascript:" v-b-modal.typeModal></a>
                                <select v-model="requirementSpec.requirementTypeId" @change="updateRequirementSpec" class="view-form w-50  ">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in requirementTypes" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Req Specification</span>
                                <textarea type="text" v-model="requirementSpec.requirementSpecification" @change="updateRequirementSpec" class="view-form w-100 mt-0" placeholder="Enter Requirement Specification" name="requirementSpecification" ></textarea>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Priority</span>
                                <select v-model="requirementSpec.priorityId" @change="updateRequirementSpec" class="view-form w-50  ">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in requirementPriorities" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Due Date</span>
                                <input type="date" v-model="requirementSpec.dueDate" @change="updateRequirementSpec" class="view-form w-50 " placeholder="Enter dueDate" name="dueDate" >
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Status</span>
                                <select v-model="requirementSpec.statusId" @change="updateRequirementSpec" class="view-form w-50 ">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in requirementStatus" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsRequirement } from "@/mixins/requestsRequirement";

export default {

    name: 'app',
    mixins: [requestsRequirement],
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            typeLookupTitle: 'Requirement Type Settings',
            entity: 'requirement_spec',
            requirementTypes: [],
            requirementStatus: [],
            requirementPriorities: [],
            requirementActivities: [],
            ermReferenceList: [],
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            requirementSpec: {},
        }
    },
    mounted() {
        this.requirementSpec = JSON.parse(localStorage.getItem("rowData"));
        this.lookupData();
    },
    methods: {
        async lookupData() {
            try {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                    entity: this.entity,
                }
            
                    const response = await this.getRequirementSpecLookup(data);
                    let result = response.data;
                    this.requirementTypes = result.customTypeLookup;
                    this.requirementStatus = result.statusLookup;
                    this.requirementPriorities = result.priorityLookup;
                    console.log(result);
                }catch (error) {
                console.log(error);
            }
        },
        async updateRequirementSpec() {
            try {
                    const response = await this.updateRequirementSpecs(this.requirementSpec);
                    console.log(this.requirementSpec);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    console.log(result);
                    localStorage.setItem("rowData", JSON.stringify(this.requirementSpec));
                } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>