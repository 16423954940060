<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row">
                        <div class="col-8 pr-0">
                            <h2 class="pl-2 mb-0 titel-text">Budget Plan</h2>
                        </div>
                        <div class="col-4 pl-0">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i> Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pb-1 px-3">
                            <p class="mx-0 mb-2 mt-0">
                                <span class="font-light mr-2">Item Title</span>
                                <input type="text" id="title" v-model="budgetPlan.title" @change="updateBudgetPlan" class="view-form w-50  " :class="{ 'is-invalid': $v.budgetPlan.title.$error }" >
                                <label v-if="$v.budgetPlan.title.$error && !$v.budgetPlan.title.required" class="text-danger pl-1">Please enter Item Title</label>
                            </p>
                            <p  class="mx-0 my-2">
                                <a :title="activityLookupTitle" href="javascript:" v-b-modal.activityModal></a>
                                <span class="font-light mr-2">Activity</span>
                                <select v-model="budgetPlan.activity" @change="updateBudgetPlan" class="view-form w-50 ">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in budgetPlanActivity" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Category</span>
                                <a :title="categoryLookupTitle" href="javascript:" v-b-modal.categoryModal></a>
                                <select v-model="budgetPlan.budgetCategoryId" @change="updateBudgetPlan" class="view-form w-50  ">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in budgetPlanCategory" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Estimated Cost</span>
                                <input type="number" v-model="budgetPlan.estimatedCost" @change="updateBudgetPlan" class="view-form w-50 " placeholder="Set EstimatedCost" name="estimatedCost"  :class="{ 'is-invalid': $v.budgetPlan.estimatedCost.$error }">
                                <label v-if="$v.budgetPlan.estimatedCost.$error && !$v.budgetPlan.estimatedCost.required" class="text-danger">Please Fill Estimated Cost</label>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Accountable</span>
                                <select v-model="budgetPlan.accountablePersonId" @change="updateBudgetPlan" class="view-form w-50 ">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsFinance } from "@/mixins/requestsFinance";
export default {
    name: 'app',
    mixins: [requestsFinance],
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            categoryLookupTitle: 'Budget Plan Category Settings',
            activityLookupTitle: 'Activity Settings',
            notEnabled: true,
            prependText: localStorage.getItem("currency"),
            budgetPlanTypes: [],
            budgetPlanCategory: [],
            users: [],
            budgetPlanActivity: [],
            entity: 'budget_plan',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            budgetPlan: { },
        }



    },
    mounted() {
        this.budgetPlan = JSON.parse(localStorage.getItem("rowData"));
        this.lookupData();
    },
    validations: {
        budgetPlan: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            estimatedCost: {
                required,
            }
        }
    },
    methods: {
        async lookupData() {
            try {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            console.log(data);
            const response = await this.getBudgetPlanLookup(data);
                    let result = response.data;
                    this.budgetPlanTypes = result.customTypeLookup;
                    this.budgetPlanCategory = result.customCategoryLookup;
                    this.users = result.userLookup;
                    this.budgetPlanActivity = result.activityLookup;
                    console.log(result);
                }catch (error) {
                    console.log(error);
                }

        },
        async updateBudgetPlan() {
            try {
            this.$v.budgetPlan.$touch();
            console.log(this.budgetPlan);
                if (!this.$v.budgetPlan.$invalid) {
                const response = await this.updateBudgetPlans(this.budgetPlan);
                        let result = response.data;
                        if (result.status == 'CREATED') {
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                        }
                    }
                    localStorage.setItem("rowData", JSON.stringify(this.budgetPlan));   
                }catch (error) {
                    console.log(error);
                }            
        },

    }
}
</script>