<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12  col-md-6">
                            <div class="float-left">
                                <h1 class="text-dark titel-text">Project Team</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" show-empty small selectable :select-mode="selectMode" 
                                    sort-icon-left :items="projectTeams" :fields="projectTeamFields" responsive="sm" @row-selected="onRowSelected" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                        <template v-slot:cell(memberName)="data">
                                            <div  class="text-overflow-dots">
                                                <p>{{ data.item.memberName }}</p>
                                                <p class="fs-small">{{ data.item.position }}</p>
                                            </div>
                                        </template>
                                        <template v-slot:cell(emailId)="data">
                                            <div  class="">
                                                <p v-if="data.item.emailId"><i class="fa fa-xs fa-envelope text-primary pr-1" aria-hidden="true"></i>{{ data.item.emailId }} </p>
                                            </div>
                                        </template>
                                        <template  v-slot:empty="scope">
                                            <div class="text-center">No Team Members Found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="projectTeams.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" @click="$router.push('/addprojectteam/' + entityRecordMasterId)" size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import { requestsResource } from "@/mixins/requestsResource";

export default {
     mixins: [requestsResource],
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entityRecordMasterId: this.$route.params.ermId,
            entity: 'project_team',
            userId: localStorage.getItem("userId"),
            projectTeams: [],

            projectTeamFields: [
                { key: 'memberName', label: 'Name', sortable: true, sortDirection: 'desc' },
                { key: 'emailId', label: 'Email Id', sortable: true, sortDirection: 'desc' },
            ],
            selectMode: 'single',
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
            totalRows: 1,
        }
    },
    mounted() {
        this.getProjectTeams();
    },
    methods: {
        async getProjectTeams() {
            try {
                let data = {
                    entityRecordMasterId: this.entityRecordMasterId,
                }
                const response = await this.getProjectTeamList(data);
                console.log(response);
                let result = response.data;
                if (result.projectTeamMemberList.length != 0) {
                    this.projectTeams = result.projectTeamMemberList;
                    this.totalRows = this.projectTeams.length;
                }
            } catch (error) {
                console.log(error);
            }
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },


        onRowSelected: function(items) {
             if (items && items.length > 0){
                let data = items[0];
                console.log(data)
                localStorage.setItem("rowData", JSON.stringify(data));
                this.$nextTick(() => {
                    this.$router.push('/viewprojectteam');
                });
            }
        },
    }
}
</script>