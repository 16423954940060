export const requestsProject = {
    methods: {
    	addIssue: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/project/issue/add', data);
        },
        getIssues: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/project/issue/list', data);
        },
        getIssueLookup: function(data) {            
            return axios.post(this.$store.getters.getAPIBasePath + '/project/issue/lookup/all', data);
        },
        updateIssues: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/project/issue/update', data);
        },
    }
};