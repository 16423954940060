export const requestsFinance = {
    methods: {
        getAccountHead: function(data) {            
            return axios.post(this.$store.getters.getAPIBasePath + '/common/accounthead/list', data);
        },
        addAccountHead: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/common/accounthead/add', data);
        },
        updateAccountHead: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/common/accounthead/update', data);
        },
    	addBudgetPlan: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/finance/budget/add', data);
        },
        getBudgetPlan: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/finance/budget/list', data);
        },
        getBudgetPlanLookup: function(data) {            
            return axios.post(this.$store.getters.getAPIBasePath + '/finance/budget/lookup/all', data);
        },
        updateBudgetPlans: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/finance/budget/update', data);
        },
        getFinancialTxn: function(data) {            
            return axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/list', data);
        },
        addFinancialTxn: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/add', data);
        },
        updateFinancialTxn: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/finance/financialtransaction/update', data);
        },
    }
};