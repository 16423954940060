export const requestsResource = {
    methods: {
        getProjectTeamList: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/member/list', data);
        },
        getProjectTeamLookup: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/lookup/all', data);
        },
        saveProjectTeam: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/add', data);
        },
        updateProjectTeam: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/projectteam/update', data);
        },
    	addTechnologySkill: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/add', data);
        },
        getTechnologySkill: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/list', data);
        },
        updateTechnologySkill: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/technologyskill/update', data);
        },
        updateActivation: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/activationstate/update', data);
        },
    	addUser: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/user/account/create', data);
        },
        updateUser: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/user/update', data);
        },
        addRoleResponsibility: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/add', data);
        },
        getRoleResponsibilities: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/list', data);
        },
        updateRoleResponsibility: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/resource/roleresponsibility/update', data);
        },
    }
};