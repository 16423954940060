<template>
    <div>
        <div class="side_bar dark_blue side_bg_img scroll_auto">
            <ul id="dc_accordion" class="sidebar-menu tree">
                <li v-if="true" class="menu_sub" v-for="(info, index) in appMenu">
                    <a href="#" v-if="index>0 && appMenu[index - 1].moduleId != info.moduleId ">
                        <i class="fa fa-laptop"></i> <span> {{info.module}}</span> <span class="fa fa-angle-down styleicon"></span>
                    </a>
                    <a href="#" v-if="index==0 ">
                        <i class="fa fa-laptop"></i> <span> {{info.module}}</span> <span class="fa fa-angle-down styleicon"></span>
                    </a>
                    <ul class="down_menu" v-if="index==0">
                        <li class="menu_sub" v-for="(mainmenu, mmindex) in appMenu">
                            <router-link v-if="info.moduleId == mainmenu.moduleId" :to="mainmenu.webUrl">
                                <i :class="mainmenu.icon"></i>
                                <span>{{mainmenu.customMainMenu}}</span>
                            </router-link>
                        </li>
                    </ul>
                    <ul class="down_menu" v-if="index>0 && appMenu[index - 1].moduleId != info.moduleId ">
                        <li class="menu_sub" v-for="(mainmenu, mmindex) in appMenu">
                            <span v-if="mmindex>0 && appMenu[mmindex - 1].mainMenuId != mainmenu.mainMenuId">
                                <router-link v-if="info.moduleId == mainmenu.moduleId" :to="mainmenu.webUrl">
                                    <i :class="mainmenu.icon"></i>
                                    <span>{{mainmenu.customMainMenu}}</span>
                                </router-link>
                            </span>
                            <span v-if="mmindex==0">
                                <router-link v-if="info.moduleId == mainmenu.moduleId" :to="mainmenu.webUrl">
                                    <i :class="mainmenu.icon"></i>
                                    <span>{{mainmenu.customMainMenu}}</span>
                                </router-link>
                            </span>
                        </li>
                    </ul>
                </li>
                <!-- <li v-if="false" class="menu_sub">
    <a href="#"> <i class="fa fa-laptop"></i> <span>Plan</span> <span class="fa fa-angle-down styleicon"></span> </a>
    <ul class="down_menu">
        <li class="menu_sub">
            <a href="#"><i class="fa fa-pie-chart"></i><span>Process </span> <span class="fa fa-angle-down styleicon"></span> </a>
            <ul class="down_menu">
                <li>
                    <a href="#"><i class="fa fa-pie-chart"></i> Goals & Objectives</a>
                </li>
                <li>
                    <a href="#"><i class="fa fa-pie-chart"></i> Process</a>
                </li>
                <li>
                    <a href="#">Activities</a>
                </li>
            </ul>
        </li>
        <li class="menu_sub">
            <a href="#"><i class="fa fa-pie-chart"></i><span>Scope </span> <span class="fa fa-angle-down styleicon"></span> </a>
            <ul class="down_menu">
                <li>
                    <a href="#"><i class="fa fa-pie-chart"></i>Client Requirement</a>
                </li>
                <li>
                    <a href="#"><i class="fa fa-pie-chart"></i>Scope Statement</a>
                </li>
            </ul>
        </li>
        <li>
            <a href="#"><i class="fa fa-pie-chart"></i> communication</a>
        </li>
    </ul>
</li> -->
            </ul>
        </div>
    </div>
</template>
<script>
export default {

    data() {
        return {
            elements: {
                showLogoBlock: false,
            },
            appMenu: [],
        }
    },
    mounted() {
        this.loadMenu();
    },
    methods: {
        loadMenu() {
            let data = {
                userId: localStorage.getItem("userId"), // 348, 
                projectId: localStorage.getItem("projectId"), //256, 
            }
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/app/custommenu', data)
                .then((response) => {
                    let result = response.data;
                    this.appMenu = result.appCustomMenuLookup;
                })
        },
        targetId: function(id) {
            return "#collapseAuthorization" + id;
        },
        randomId: function(id) {
            return "collapseAuthorization" + id;

        }
    }
}
</script>