<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-8">
                            <h2 class="pl-2 mb-0 titel-text">User View</h2>
                        </div>
                        <div class="col-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                        <div class="row">
                            <div class="pl-4 text-left">
                                <input type="text" v-model="user.name" @change="updateUserData" class="view-form w-80" placeholder="User Name" name="name">
                                <div>
                                    <p class="mb-0"  v-if="user.type!=''">{{user.type}}</p>                            
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="text-left pl-4 mt-1">
                                <label v-if="user != null && user.emailId != null && user.emailId.length != 0" class="ml-0 mr-2"><i class="fa fa-envelope-o font-weight-bold action-link pr-1" aria-hidden="true"></i> {{user.emailId}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="text-left pl-4">
                                <i class="fa fa-phone font-weight-bold action-link pr-1" aria-hidden="true"></i>
                                <input type="text" v-model="user.phone" @change="updateUserData" class="view-form w-80" placeholder="Phone" name="phone">
                            </div>
                        </div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { requestsResource } from "@/mixins/requestsResource";

export default {
    name: 'app',
    mixins: [requestsResource],

    data() {
        return {
            user: {},
        }
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem("rowData"));
        console.log(this.user);
    },
    methods: {
        async updateUserData() {
            try {
                console.log(this.user);
                const response = await this.updateUser(this.user);
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topCenter'
                    });
                    localStorage.setItem("rowData", JSON.stringify(this.user));
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
}

</script>