import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userdata: {},
        pageTitle: 'vhextech',
        ermLookup: [],
        ermId: '',
        isActionItemRedirect: false,
        ermMasterInfo: {
            title: '',
            entity: '',
            refEntity: '',
        },
        editPageUrl: '',
        showAppLoader: false,
        apibasepath: process.env.VUE_APP_BASEURL,
        local_storage_name: process.env.VUE_APP_LOCALSTORAGE_NAME,
        configloaded: 0,
        default_redirects: {
            prm: '/prm/landing',
            cmr: '/cmr/landing',
            cli: '/cli/landing',
            tmb: '/prm/landing',
            general: '/landing'
        },

    },
    getters: {
        getAPIBasePath(state) {
            return state.apibasepath;
        },
        getDefaultRedirects(state) {
            return state.default_redirects;
        },
        isConfigLoaded(state) {
            if (state.configloaded == 1) {
                return true;
            }
            return false;
        },
        currentUser(state) {
            return state.userdata;
        },
        getLocalStorageName(state) {
            return state.local_storage_name;
        },
        getAuthToken(state) {
            return localStorage.getItem(state.local_storage_name);
        },
    },
    mutations: {
        saveUserData(state, payload) {
            state.userdata = payload;
        },
        markAsConfigLoaded(state, flag) {
            state.configloaded = flag;
        },
    },
    actions: {
        doLogout(context) {
            localStorage.clear();
            localStorage.removeItem(context.getters.getLocalStorageName);
            localStorage.removeItem("projectId");
            localStorage.removeItem('projectTypeId');
            localStorage.removeItem("currency");
            localStorage.removeItem("vhextAccountId");
            localStorage.removeItem("userId");
            localStorage.removeItem("userTypeCode");
            localStorage.removeItem("pageRedirectURL");
            localStorage.removeItem("rowData");
            context.commit("markAsConfigLoaded", 0);
        },
        setupLogin(context, resultset) {
            return new Promise((resolve, reject) => {
                localStorage.removeItem(context.getters.getLocalStorageName);
                context.commit("saveUserData", resultset.data);
                localStorage.setItem(context.getters.getLocalStorageName, resultset.data.token);
                localStorage.setItem("vhextAccountId", resultset.data.vhextAccountId);
                localStorage.setItem("userId", resultset.data.userId);
                localStorage.setItem("userTypeCode", resultset.data.userType);
                resolve("true");
            });
        },
    },
    modules: {}
})