<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row px-2 mb-3">
                <div class="card col-12 col-lg-4 col-xl-4 mb-5 px-0">
                    <b-tabs content-class="mt-3" lazy>
                        <b-tab title="Event / Meeting">
                            <div class="px-2">
                                <div class="row no-gutters">
                                    <div class="col">
                                        <div class="row no-gutters mb-0">
                                            <div class="col-12">
                                                <label class="mb-0 d-block font-weight-bold my-1">{{ calendarEvent.title }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="calendarEvent.isAllDayEvent" class="col float-right pl-1">
                                        <div class="row no-gutters mb-0">
                                            <div class="col-12 text-right">
                                                <label class="p-1 mb-0 d-inline-block ">All Day</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="divider-line mt-0"></div>
                                <div class="row no-gutters">
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2 ">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details">Host</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details text-muted">{{ calendarEvent.hostedBy }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2 ">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details">Date</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details text-muted">{{ calendarEvent.startRecur | formatDate }} to {{ calendarEvent.endRecur | formatDate }}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <template v-if="calendarEvent.isAllDayEvent">
                                        <div class="col-6 col-sm-6 pr-1">
                                            <div class="row mt-0 mb-2">
                                                <div class="col-12">
                                                    <div class="text-right">
                                                        <h6 class=""><label class="badge badge-secondary" title="All Day Event">All Day</label></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="!calendarEvent.isAllDayEvent">
                                        <div class="col-12">
                                            <div class="row no-gutters mb-2 ">
                                                <div class="col-4 pr-1">
                                                    <label class="mb-0 p-1 d-block header-details">Time</label>
                                                </div>
                                                <div class="col-8">
                                                    <label class="p-1 mb-0 d-block header-details text-muted">{{ calendarEvent.startTime | formatTime }} to {{ calendarEvent.endTime | formatTime }}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2 ">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details">Repeat</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-2 mb-0 d-block header-details text-muted">
                                                    <span v-for="day,index in (calendarEvent.daysOfWeek)" :key="index"><span v-if="index!==0">,</span> {{ days[day] }}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2 ">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details">Participants</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-2 mb-0 d-block header-details text-muted">
                                                    <span v-for="participant,index in eventParticipants" :key="index"><span v-if="index!==0">,</span> {{ participant.value }}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2 ">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details">Location</label>
                                            </div>
                                            <div class="col-8">
                                                <label v-if="calendarEvent.location" class="p-2 mb-0 d-block header-details text-muted">{{ calendarEvent.location }}</label>
                                                <label v-else class="p-2 mb-0 d-block header-details text-center">-</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="row no-gutters mb-2 ">
                                            <div class="col-4 pr-1">
                                                <label class="mb-0 p-1 d-block header-details">Link</label>
                                            </div>
                                            <div class="col-8">
                                                <label class="p-1 mb-0 d-block header-details text-muted">
                                                    <a :href="calendarEvent.link" target="_blank" class="action-link">{{ calendarEvent.link }}</a>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row no-gutters">
                                    <div v-if="calendarEvent.description" class="col-12">
                                        <div class="card mb-2">
                                            <div class="card-body py-1 px-0">
                                                <p class="contact-title mb-2 pl-1"><span>Agenda</span></p>
                                                <div class="row no-gutters">
                                                    <div class="col-12 m-0">
                                                        <label class="px-1 mb-0 d-block header-details text-muted" v-html="calendarEvent.description"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="MOM">
                            <MOMViewComponent :momData="momData" ref="meetingMinuteViewRef" />
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>
        <Dialog id="deleteCalendarEvent" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Event?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import dayList from '@/json/daysofweek.json';
import MOMViewComponent from '@/components/common/MOMViewComponent.vue';
import { EventBus } from '@/main';
export default {
    name: 'app',
    components: {
        MOMViewComponent
    },
    data() {
        return {
            calendarEvent: {},
            rowData: {},
            days: dayList,
            eventParticipants: [],
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            userId: localStorage.getItem("userId"),
            entity: 'calendarEvent',

            momData: {
                calendarEventId: '',
                entityRecordMasterId: '',
                eventId: localStorage.getItem("eventId"),
            },

            deleteParam: {
                id: 0
            },
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'Event';
        this.$store.state.editPageUrl = 'editcalevt';
        if(this.$store.state.isActionItemRedirect) {
            let erdId = localStorage.getItem("erdId");
            this.getEventDetails(erdId);
            this.getParticipantList(erdId);
        }
        else {
            this.rowData = JSON.parse(localStorage.getItem("rowData"));
            this.getEventDetails(this.rowData.id);
            this.getParticipantList(this.rowData.id);
        }
    },
    created() {
        EventBus.$on("triggerDelete", this.deleteCalendarEvent);
    },
    destroyed() {
        EventBus.$off("triggerDelete", this.deleteCalendarEvent);
        this.$store.state.isActionItemRedirect = false;
        this.$store.state.ermLookup = [];
    },
    methods: {
        getEventDetails: function(id) {
            axios.get(this.$store.getters.getAPIBasePath + '/communication/calendarevent/view/' + id)
            .then((response) => {
                this.loading = false;
                let result = response.data;
                if (result.calendarEvent) {
                    this.calendarEvent = response.data.calendarEvent;
                    let calendarEvent = JSON.stringify(result.calendarEvent);
                    calendarEvent = JSON.parse(calendarEvent);
                    if (this.calendarEvent.daysOfWeek && this.calendarEvent.daysOfWeek.length>0) {
                        let daysOfWeek = this.calendarEvent.daysOfWeek.split(',');
                        calendarEvent.daysToRepeat = [];
                        daysOfWeek.forEach((day) => {
                            calendarEvent.daysToRepeat.push(this.days[day]);
                        })
                        calendarEvent.daysOfWeek = calendarEvent.daysOfWeek.split(',');
                        calendarEvent['startRecur'] = calendarEvent['startDate'];
                        delete calendarEvent['startDate'];
                        calendarEvent['endRecur'] = calendarEvent['endDate'];
                        delete calendarEvent['endDate'];
                        
                        calendarEvent.eventStartDate = this.getDateValue(calendarEvent.startRecur);
                        calendarEvent.eventEndDate = this.getDateValue(calendarEvent.endRecur);
                        calendarEvent.eventStartTime = calendarEvent.startTime ? calendarEvent.startTime.toString().substr(0, 5) : null;
                        calendarEvent.eventEndTime = calendarEvent.endTime ? calendarEvent.endTime.toString().substr(0, 5) : null;
                    } 
                    else {
                        calendarEvent.daysToRepeat = [];
                        calendarEvent.eventStartDate = this.getDateValue(calendarEvent.startDate);
                        calendarEvent.eventEndDate = this.getDateValue(calendarEvent.endDate);
                        calendarEvent.eventStartTime = this.getTimeValue(calendarEvent.startDate);
                        calendarEvent.eventEndTime = this.getTimeValue(calendarEvent.endDate);
                    }
                    this.$nextTick(() => {
                        calendarEvent = JSON.stringify(calendarEvent);
                        localStorage.setItem("rowData", calendarEvent);
                    })
                    this.calendarEvent.startRecur = result.calendarEvent.startDate;
                    this.calendarEvent.endRecur = result.calendarEvent.endDate;
                    this.calendarEvent.daysOfWeek = result.calendarEvent.daysOfWeek.split(',');
                    this.momData.calendarEventId = this.calendarEvent.id;
                    this.momData.entityRecordMasterId = this.calendarEvent.entityRecordMasterId;
                    this.$nextTick(() => {
                        this.getMeetingMinute();
                    });
                }
            })
            .catch(error => {
                this.loading = false;
                console.log(error);
            });
        },
        getParticipantList: function(id) {
            let data = {
                eventId: id
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/eventparticipant/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.eventParticipants = result.eventParticipantLookup;
                })
        },
        getMeetingMinute: function() {
            if (this.momData.eventId) {
                let data = {
                    entityRecordMasterId: this.momData.entityRecordMasterId,
                    calendarEventId: this.momData.calendarEventId,
                    eventId: this.momData.eventId,
                }
                axios.post(this.$store.getters.getAPIBasePath + '/communication/meetingminute/list', data)
                    .then((response) => {
                        let result = response.data;
                        if (result.meetingMinuteList && result.meetingMinuteList.length > 0) {
                            this.meetingMinutes = result.meetingMinuteList;
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        },
        deleteCalendarEvent: function() {
            this.deleteParam.id = this.calendarEvent.id;
            this.$nextTick(() => {
                this.$bvModal.show('deleteCalendarEvent');
            })
        },
        onYes: function() {
        	let startDate = new Date(this.combineDateAndTime(this.calendarEvent.eventStartDate, this.calendarEvent.eventStartTime));
        	let currentDate = new Date();
        	if (startDate > currentDate) {
	            axios.delete(this.$store.getters.getAPIBasePath + '/communication/calendarevent/delete/' + this.deleteParam.id)
	                .then(response => {
	                    var result = response.data;
	                    if (result.status == "OK") {
	                        iziToast.success({
	                            title: 'Success',
	                            message: result.message,
	                            position: 'topRight'
	                        });
	                        this.$router.push('/calevt');
	                    }
	                })
	                .catch(error => {
	                    console.log(error);
	                });
        	}
        	else {
        		this.$nextTick(() => {
        			this.cancelEvent(currentDate);
        		})
        	}
        },
        cancelEvent: function(endDate) {
        	let data = {
                id: this.calendarEvent.id,
                endDate: endDate
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/cancel', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/calevt');
                    }
                })
        },
        combineDateAndTime: function(date, time) {
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },


    }
}
</script>