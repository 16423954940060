<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12  col-md-6">
                            <div class="float-left">
                                <h1 class="text-dark">Role & Responsibility</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" show-empty @row-selected="onRowSelected" small selectable :select-mode="selectMode" 
                                    sort-icon-left :items="roleResponsibilities" :busy="isBusy" :fields="roleResponsibilityFields" responsive="sm" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                        <template v-slot:cell(active)="data">
                                            <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                            </b-form-checkbox>
                                        </template>
                                        <template  v-slot:empty="scope">
                                            <div class="text-center">No RoleResponsibilities Found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                        <template v-slot:cell(role)="data">
                                            <div  class="">
                                                <p>{{ data.item.role }} 
                                                    <!-- <span v-if="data.item.responsibility" class="fs-small"> - {{ data.item.responsibility }}</span> -->
                                                </p>
                                                <p class="fs-small">{{ data.item.responsibility }}</p>
                                            </div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="roleResponsibilities.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" v-b-modal.modal-1 size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
        <b-modal centered id="modal-1" @hide="clearData" :title="elements.editMode ? 'Edit Role & Responsibility' : 'Add Role & Responsibility'">
            <div class="row">
                <div class="col-12">
                    <div class="form-group ">
                        <div class="required-field-block" :class="{ 'is-invalid': $v.roleResponsibility.role.$error }">
                            <label for="role" class="inp">
                                <input type="text" id="role" placeholder=" " v-model="roleResponsibility.role" class="mobile-form-control"  :class="{ 'is-invalid': $v.roleResponsibility.role.$error }">
                                <span class="label">Role</span>
                                <span class="focus-bg"></span>
                            </label>
                        </div>
                        <label v-if="$v.roleResponsibility.role.$error && !$v.roleResponsibility.role.minLength" class="text-danger">Role must have at least {{$v.roleResponsibility.role.$params.minLength.min}} letters.</label>
                        <label v-if="$v.roleResponsibility.role.$error && !$v.roleResponsibility.role.required" class="text-danger">Please enter Role</label>
                        <label v-if="$v.roleResponsibility.role.$error && !$v.roleResponsibility.role.maxLength" class="text-danger">Role must not exceed {{$v.roleResponsibility.role.$params.maxLength.max}} letters.</label>
                    </div>
                </div> 
                <div class="col-12">
                    <div class="form-group">
                        <label for="responsibilities" class="inp">
                            <b-form-tags input-id="tags-separators" id="responsibilities" v-model="roleResponsibility.responsibilities" separator="," placeholder="Responsibilities" class="mobile-form-control" ></b-form-tags>
                            <!-- <span class="label">Responsibilities</span> -->
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label for="active">Active</label>
                        <div class="input-group ">
                            <b-form-checkbox class="custom-toggle" v-model="roleResponsibility.active" name="check-button" switch>
                            </b-form-checkbox>
                        </div>
                    </div>
                </div> 
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <button class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right" @click="$bvModal.hide('modal-1')">
                        Cancel
                    </button>
                    <button size="sm" @click="saveData" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right mr-2">
                        {{elements.editMode ? 'Update' : 'Save'}}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsResource } from "@/mixins/requestsResource";
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    mixins: [requestsResource],
    data() {
        return {
            elements: {
                
                editMode: false,
                
            },

            isBusy: false,
            roleResponsibilities: [],

            roleResponsibility: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                roleResponsibilityId: '',
                role: '',
                responsibility: '',
                responsibilities: [],
                preparedById: '',
                preparedBy:'',
                active: '',
                refEntity: 'role_responsibility',
                refRecordId: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            entity: "role_responsibility",

            roleResponsibilityFields: [
                { key: 'role', label: 'Role', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },
            ],

            selectMode: 'single',
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
            totalRows: 1,
            
        }
    },
    mounted() {
        this.getAllRoleResponsibilitys();
    },
    validations: {
        roleResponsibility: {
            role: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            }
        }
    },
    methods: {
        async getAllRoleResponsibilitys() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                accountablePersonId: localStorage.getItem("userId"),
            }
            const response = await this.getRoleResponsibilities(data);
            console.log(response);
            let result = response.data;
            console.log(result);
            if (result.roleResponsibilityList && result.roleResponsibilityList.length != 0) {
                this.roleResponsibilities = result.roleResponsibilityList;
                
                this.totalRows = this.roleResponsibilities.length;
            }
        },
        saveData: function() {
        	this.roleResponsibility.responsibility = this.arrayToString(this.roleResponsibility.responsibilities);
            this.$v.roleResponsibility.$touch();
            console.log(this.roleResponsibility);
            if (!this.$v.roleResponsibility.$invalid) {
                if (this.elements.editMode) {
                    this.updateRoleResponsibilityData();
                } else this.saveRoleResponsibility();
            }
        },
        async saveRoleResponsibility() {
            try {              
                console.log(this.roleResponsibility)
                const response = await this.addRoleResponsibility(this.roleResponsibility);
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.clearData();
                    this.getAllRoleResponsibilitys();
                    this.$bvModal.hide('modal-1');
                }
            } catch (error) {
                console.log(error);
            }
        },
        async updateRoleResponsibilityData() {
            try {              
                console.log(this.roleResponsibility)
                const response = await this.updateRoleResponsibility(this.roleResponsibility);
                let result = response.data;
                this.elements.editMode = false;
                if (result.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.clearData();
                    this.getAllRoleResponsibilitys();
                    this.$bvModal.hide('modal-1');
                }
            } catch (error) {
                console.log(error);
            }
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            console.log(item.active);
            if (item.active == true) {
                this.roleResponsibility.entity = this.entity;
                this.roleResponsibility.id = item.id;
                this.roleResponsibility.active = 0;

                this.updateActivation();

            } else {
                this.roleResponsibility.entity = this.entity;
                this.roleResponsibility.id = item.id;
                this.roleResponsibility.active = 1;

                this.updateActivation();
            }
        },
        async updateActivation() {
            try {
                const response = await this.updateActivationState(this.roleResponsibility);
                    this.isBusy = false;
                    this.elements.editMode = false;
                    let result = response;
                    if (result.status == 200) {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    //this.elements.showDetail = false;
                    this.roleResponsibility.active = true;
                    this.getAllRoleResponsibilitys();
                } catch (error) {
                    this.isBusy = false;
                    console.log(error);
                    

                }
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items.[0];
                console.log(data);
                this.elements.editMode = true;
	            this.roleResponsibility.id = data.id;
	            this.roleResponsibility.roleResponsibilityId = data.roleResponsibilityId;
	            this.roleResponsibility.role = data.role;
	            this.roleResponsibility.preparedById = data.preparedById;
	            this.roleResponsibility.responsibilities = data.responsibility.split(',');
	            this.roleResponsibility.active = data.active;
                this.$nextTick(()=> {
                    this.$bvModal.show('modal-1');
                });
            }
        },
        clearData: function() {
            this.elements.editMode = false;
            this.roleResponsibility.roleResponsibilityId = '';
            this.roleResponsibility.role = '';
            this.roleResponsibility.preparedById = '';
            this.roleResponsibility.responsibility = '';
            this.roleResponsibility.responsibilities = [];
            this.roleResponsibility.active = true;
            this.roleResponsibility.id = '';
            this.$v.roleResponsibility.$reset();
        },
        arrayToString: function (arrayData) {
            if (arrayData != null && arrayData.length > 0) {
                return arrayData.toString();
            }
            return "";
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>