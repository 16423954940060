<template>
    <div>
        <ScopeStatementMasterList :ermMasterInfo="ermMasterInfo"></ScopeStatementMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ScopeStatementMasterList from '@/components/ermcomponents/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        ScopeStatementMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Scope Doc List",
                entity: 'scope_statement_master',
                refEntity: 'scope_statement',
                tooltip: 'New Scope Statement',
                redirectURLName: 'scopestatementlist',
                newEntityMasterURL: 'scpmas',
                redirectURL: '/scpmas',
                actionName: 'Scope Statement',
                previewURLName: 'sstrep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>