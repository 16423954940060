<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70 ">
                        <div class="card-body pt-4 pb-4 pl-4 pr-4">
                            <div class="row mb-2">
                                <div class="row mb-2">
                                    <div class="col-12 mt-0">
                                        <div class="form-group">
                                            <label for="assignedToId">Team Member</label>
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectTeam.member.$error }">
                                                <select v-model="projectTeam.member" @change="showHRDetails(projectTeam.member)" id="member" class="form-control">
                                                    <option value="">Select</option>
                                                    <option v-for="(info, index) in humanResources" :key="index" :value="{id: info.id, text:info.value}">{{ info.value }}</option>
                                                </select>
                                                <label v-if="$v.projectTeam.member.$error && !$v.projectTeam.member.required" class="text-danger">Please enter Title</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12" >
                                        <div class="form-group">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectTeam.emailId.$error, 'is-invalid': error }">
                                                <label for="title" class="inp">
                                                    <input type="text" id="title" placeholder=" " v-model="projectTeam.emailId" class="mobile-form-control"  :class="{ 'is-invalid': $v.projectTeam.emailId.$error }">
                                                    <span class="label">Email Id</span>
                                                    <span class="focus-bg"></span>
                                                </label>
                                            </div>
                                            <label v-if="$v.projectTeam.emailId.$error && !$v.projectTeam.emailId.email" class="text-danger">Enter Valid Email Id</label>
                                            <label v-if="$v.projectTeam.emailId.$error && !$v.projectTeam.emailId.required" class="text-danger">Please enter Email Id</label>
                                            <label v-show="elements.alreadyExists" class="text-danger">Email already exists.</label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="title" class="inp">
                                                <input type="text" id="title" placeholder=" " v-model="projectTeam.position" class="mobile-form-control" >
                                                <span class="label">Position</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="requiredFrom">Required From</label>
                                            <input v-model="projectTeam.requiredFrom" type="Date" class="form-control" placeholder="Required From">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="requiredTill">Required From</label>
                                            <input v-model="projectTeam.requiredTill" type="Date" class="form-control" placeholder="Required From">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email } from "vuelidate/lib/validators";
import { requestsResource } from "@/mixins/requestsResource";
import { EventBus } from '@/main';
export default {
    mixins: [requestsResource],
    name: 'app',

    data() {
        return {
            error: false,
            elements: {
                alreadyExists: false,
            },
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entityRecordMasterId: this.$route.params.ermId,
            entity: 'project_team',
            userId: localStorage.getItem("userId"),

            projectTeam: {
                id: '',
                projectTeamId: '',
                userId: '',
                userTypeId: '',
                userType: '',
                userTypeCode: '',
                memberTypeId: '',
                memberTypeCode: 'TMB',
                memberName: '',
                member: '',
                projectMemberId: '',
                humanResourceId: '',
                emailId: '',
                phone: '',
                humanResource: '',
                position: '',
                roleResponsibility: '',
                technologySkill: '',
                preparedBy: '',
                qualification: '',
                experienceYears: '',
                experienceMonths: '',
                requiredFrom: '',
                requiredTill: '',
                entity: 'project_team',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                createdDt: '',
                lastUpdatedDt: '',
                invite: false
            },
            humanResources: [],
            projectTeams: [],
        }

    },
    mounted() {
        this.$store.state.pageTitle = 'New Member';
        this.lookupData();
        this.getProjectTeams();
    },
    created() {
        EventBus.$on("savePage", this.saveProjectTeamData);
    },
    destroyed() {
        EventBus.$off("savePage", this.saveProjectTeamData);
    },
    validations: {
        projectTeam: {
            member: {
                required
            },
            emailId: {
                required,
                email,
            }
        },
    },
    methods: {
        async getProjectTeams() {
            try {
                let data = {
                    entityRecordMasterId: this.entityRecordMasterId,
                }
                const response = await this.getProjectTeamList(data);
                let result = response.data;
                if (result.projectTeamMemberList.length != 0) {
                    this.projectTeams = result.projectTeamMemberList;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async lookupData() {
            try {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                }
                const response = await this.getProjectTeamLookup(data);
                let result = response.data;
                this.humanResources = result.humanResourceLookup;
            } catch (error) {
                console.log(error);
            }
        },
        async saveProjectTeamData() {
            if (this.projectTeam.member != null) {
                this.projectTeam.memberName = this.projectTeam.member.text;
                this.projectTeam.humanResourceId = this.projectTeam.member.id;
            }
            this.$v.projectTeam.$touch();
            console.log(this.projectTeam);
            if (!this.$v.projectTeam.$invalid && !(this.checkHRExists(this.projectTeam.humanResourceId)) && !this.checkEmailExists(this.projectTeam.emailId)) {
                try {
                    this.error = false;
                    this.$store.state.showAppLoader = true;
                    const response = await this.saveProjectTeam(this.projectTeam);
                    this.$store.state.showAppLoader = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/projectteam/' + this.entityRecordMasterId);
                    } else if (result.status == 'OK') {
                        this.elements.alreadyExists = true;
                        this.error = true;
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                } catch (error) {
                    this.$store.state.showAppLoader = false;
                    console.log(error);
                }
            }
        },
        showHRDetails: function(hrs) {
            if (!this.checkHRExists(hrs.id)) {
                axios.get(this.$store.getters.getAPIBasePath + '/resource/humanresource/view/' + hrs.id)
                    .then((response) => {
                        let result = response.data;

                        this.clearInputData();
                        this.projectTeam.userId = result.humanResourceView.userId;
                        this.projectTeam.userTypeId = result.humanResourceView.userTypeId;
                        this.projectTeam.userType = result.humanResourceView.userType;
                        this.projectTeam.userTypeCode = result.humanResourceView.userTypeCode;
                        this.projectTeam.emailId = result.humanResourceView.emailId;
                        this.projectTeam.position = result.humanResourceView.position;
                        this.projectTeam.qualification = result.humanResourceView.qualification;
                        this.projectTeam.experienceYears = result.humanResourceView.experienceYears;
                        this.projectTeam.experienceMonths = result.humanResourceView.experienceMonths;
                    })
                    .catch(error => {
                        iziToast.error({
                           title: 'Error',
                           message: 'Error',
                           position: 'topRight'
                       });
                    })
            }
            //this.getContacts();
        },
        checkHRExists: function(hrsId) {
            let result = false;
            this.projectTeams.map(data => {
                if (data.humanResourceId == hrsId) {
                    result = true;
                }
            });
            if(result == true) {
                iziToast.info({
                    message: 'Team Member already exist.',
                    position: 'topRight'
                });
            }
            return result;
        },
        checkEmailExists: function(emailId) {
            let result = false;
            this.elements.alreadyExists = false;
            this.error = false;
            this.projectTeams.map(data => {
                if (data.emailId == emailId) {
                    this.elements.alreadyExists = true;
                    this.error = true;
                    result = true;
                }
            });
            return result;
        },
        clearInputData: function() {
            this.projectTeam.memberName = '';
            this.projectTeam.humanResourceId = '';
            this.projectTeam.projectMemberId = '';
            this.projectTeam.emailId = '';
            this.projectTeam.position = '';
            this.projectTeam.roleResponsibility = '';
            this.projectTeam.technologySkill = '';
            this.projectTeam.qualification = '';
            this.projectTeam.experienceYears = '';
            this.projectTeam.experienceMonths = '';
            this.projectTeam.requiredFrom = '';
            this.projectTeam.requiredTill = '';
            this.projectTeam.id = '';
            this.projectTeam.userType = '';
            this.projectTeam.loginName = '';
            this.projectTeam.userName = '';
            this.elements.alreadyExists = false;
            this.error = false;
        },
    }
}
</script>