<template>
    <div>
        <div class="sufee-login d-flex align-content-center flex-wrap">
            <div class="container">
                <div class="login-content">
                    <div class="login-form">
                        <div class="form-group">
                            <div class="required-field-block" :class="{ 'is-invalid': submitted && $v.login.loginName.$error }">
                                <label>Email address</label>
                                <input type="email" v-model="login.loginName" class="form-control" placeholder="Email" autocomplete="off" :class="{ 'is-invalid': $v.login.loginName.$error }">
                                <div class="required-icon"></div>
                            </div>
                            <div v-if="submitted && $v.login.loginName.$error" class="invalid-feedback">
                                <span v-if="submitted && !$v.login.loginName.required" class="text-danger">Email is required</span>
                                <span v-if="submitted &&  !$v.login.loginName.email">Email is not valid</span>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="required-field-block" :class="{ 'is-invalid': submitted && $v.login.password.$error }">
                                <label>Password</label>
                                <input type="password" v-model="login.password" class="form-control" id="loginPassword" placeholder="Enter Password" autocomplete="off" :class="{ 'is-invalid': $v.login.password.$error }">
                                <div class="required-icon"></div>
                            </div>
                            <div v-if="submitted && $v.login.password.$error" class="invalid-feedback">
                                <span v-if="submitted && !$v.login.password.required" class="text-danger">Password is required</span>
                                <span v-if="!$v.login.password.minLength">Password must have at least {{$v.login.password.$params.minLength.min}} letters.</span>
                            </div>
                        </div>
                        <div class="checkbox">
                            <label>
                                <input type="checkbox"> Remember Me
                            </label>
                            <label class="pull-right">
                                <a href="#" v-b-modal.forgotPasswordPopup>Forgotten Password?</a>
                            </label>
                        </div>
                        <button type="submit" @click="submitLogin()" class="btn btn-info btn-flat m-b-30 m-t-30">Sign in</button>
                        <div class="register-link m-t-15 text-center">
                            <p>Don't have account ?
                                <a href="#" @click.prevent="$router.push('/register')" > Sign Up Here</a>
                            </p>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>
        <ForgotPasswordPopup ref="forgotPasswordPopupRef" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ForgotPasswordPopup from '@/components/popup/action/ForgotPasswordPopup.vue'
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsMixin } from "@/mixins/requestsMixin";
export default {
    mixins: [requestsMixin],
    components: {
        ForgotPasswordPopup,
    },
    data() {
        return {
            elements: {
                showRegister: false,
            },
            error: false,
            entity: 'vhext_account',
            submitted: false,
            accountTypes: [],
            countries: [],
            vhextAccount: {
                accountName: '',
                vhextAccountTypeId: '',
                emailId: '',
                phone: '',
                password: '',
                confirmationPassword: '',
                terms: '',
                company: '',
                jobTitle: '',
                countryId: ''
            },
            login: {
                loginName: '',
                password: '',
                key: this.$route.params.key,
                userType: this.$route.params.userType
            }
        }
    },
    mounted() {

    },
    validations: {
        login: {
            loginName: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(6)
            }

        }
    },
    methods: {
        async submitLogin() {

            this.submitted = true;
            this.$v.login.$touch();
            if (!this.$v.login.$invalid) {
                try {
                    this.$store.state.showAppLoader = true;
                    const response = await this.performLogin(this.login);
                    this.$store.state.showAppLoader = false;
                    let result = response.data;
                    if (response.data) {
                        const defaultredirects = this.$store.getters.getDefaultRedirects;
                        this.$store.dispatch("setupLogin", result).then(() => {
                            if (result.data.userType == "CMR") {
                                this.$router.push({ path: defaultredirects.cmr });
                            } else if (result.data.userType == "PRM") {
                                this.$router.push({ path: defaultredirects.prm });
                            } else if (result.data.userType == "CLI") {
                                this.$router.push({ path: defaultredirects.cli });
                            } else if (result.data.userType == "TMB") {
                                this.$router.push({ path: defaultredirects.tmb });
                            } else {
                                this.$router.push({ path: defaultredirects.general });
                            }
                        });
                    }
                }
                catch(error) {
                    this.$store.state.showAppLoader = false;
                    console.log(error)
                    iziToast.error({
                       title: 'Error',
                       message: 'Error Occured',
                       position: 'topRight'
                   });
                }
            }
        },


        clearData: function() {
            this.elements.showRegister = false;
            this.vhextAccount.accountName = '';
            this.vhextAccount.vhextAccountTypeId = '';
            this.vhextAccount.emailId = '';
            this.vhextAccount.phone = '';
            this.vhextAccount.password = '';
            this.vhextAccount.terms = '';
            this.vhextAccount.confirmationPassword = '';
            this.vhextAccount.company = '';
            this.vhextAccount.title = '';
            this.vhextAccount.countryId = '';
            this.$v.vhextAccount.$reset();
        },
        filterAccountType: function(individual, company) {
            return this.accountTypes.filter(function(data, key) {
                if (data.value == individual || data.value == company) {
                    return data;
                }
            });
        }

    }
}
</script>