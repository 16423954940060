<template>
    <div class="h-100">
        <div class="">
        	<Header />
            <slot />
        </div>
    </div>
</template>
<script>
import Header from "@/components/LoginHeader.vue";

export default {
    components: {
        Header,    
    },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
