<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <div class="page-heading pt-0 pb-2">
                    <div class="row ">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-10 col-lg-2 text-left">
                                    <div class="row mt-0 mb-0 text-center px-0">
                                        <div class="col pull-left px-0">
                                            <a href="javascript:" @click.prevent="changePage('prevPage',1);"><i class="fa fa-2x fa-angle-left action-link mx-0 mt-0"></i></a>
                                        </div>
                                        <div class="col-6 px-0 mt-1">
                                            <h6 class="mt-0" v-if="currentStartDate">
                                                {{ currentStartDate | formatDate }}
                                            </h6>
                                        </div>
                                        <div class="col pull-right px-0">
                                            <a href="javascript:" @click.prevent="changePage('nextPage',1);"><i class="fa fa-2x fa-angle-right action-link mt-0"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2">
                                    <div class="float-left action-item-page">
                                        <span id="dropdownCal" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="badge badge-pill badge-info pointer mr-1" title="Jump to Date">
                                            <i class="fa fa-calendar text-light"></i>
                                            <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownCal">
                                                <b-calendar v-model="selectedDate" today-variant="primary" selected-variant="secondary" @context="dateList(selectedDate)">
                                                </b-calendar>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4 px-0">
                                    <nav class="breadcrumb-container" aria-label="breadcrumb">
                                        <ol class="breadcrumb justify-content-between m-0">
                                            <li class="">
                                                <a href="#" @click.prevent="actionItems = actionItemsAll; activeFilter=null;" :class="{'active-item' : !activeFilter}"><i class="fa fa-inbox pr-1 action-link"></i>All</a>
                                            </li>
                                            <li class="">
                                                <a href="#" title="Filter Task" @click.prevent="filterItems('TASK')" :class="{'active-item' : activeFilter == 'TASK'}"><i class="fa fa-tasks pr-1 action-link"></i>Task</a>
                                            </li>
                                            <li class="">
                                                <a href="#" title="Filter Meeting" @click.prevent="filterItems('MTG')" :class="{'active-item' : activeFilter == 'MTG'}"> <i class="fa fa-calendar-check-o pr-1 action-link"></i>MTG</a>
                                            </li>
                                            <li class="">
                                                <a href="#" title="Filter Email" @click.prevent="filterItems('EMAIL')" :class="{'active-item' : activeFilter == 'EMAIL'}"><i class="fa fa-envelope pr-1 action-link"></i>Email</a>
                                            </li>
                                            <li class="">
                                                <a href="#" title="Filter Review" @click.prevent="filterItems('REV')" :class="{'active-item' : activeFilter == 'REV'}"> <i class="fa fa-comments-o pr-1 action-link"></i>REV</a>
                                            </li>
                                            <li class="">
                                                <a href="#" title="Filter Approval" @click.prevent="filterItems('APV')" :class="{'active-item' : activeFilter == 'APV'}"><i class="fa fa-check pr-1 action-link"></i>APV</a>
                                            </li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                            <div class="separator mb-2"></div>
                        </div>
                    </div>
                </div>
                <section class="chart_section">
                    <div class="row px-2">
                        <div v-for="day,index in daysToShow" :key="index" class="col p-0">
                            <div class="action-item-vl">
                                <div v-for="task,itemIndex in dailyActionItems(currentDateList[index])" :key="itemIndex" class="row">
                                    <template v-if="(task.actionItemSource == 'REV')">
                                        <div class="col-12 text-left mb-2">
                                            <div class="action-card my-0">
                                                <div class="action-card-body">                                        
                                                    <a href="#" @click.prevent="setErdId(task.entityRecordId, task.entity)" class="action-link" title="View Report" :to="{ name: getRedirectUrlName(task.entity)}">
                                                        <span class="badge pointer float-right" :class="task.className" title="Review">{{ task.actionItemSource }}</span>
                                                    </a>
                                                    <div class="card-media-body-top">
                                                        <span :title="task.actionItemTitle" class=""><a href="#" @click.prevent="setErdId(task.entityRecordId, task.entity)">{{ task.actionItemTitle }}</a></span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="">
                                                        <div>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <span title="Status" class="subtle">{{ task.status }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div v-if="(task.actionItemDt != null) || (task.actionItemDueDt != null)">
                                                            <div class="row">
                                                                <div v-if="(task.actionItemDt && task.actionItemDt != null)" class="col-6 pr-0">
                                                                    <label title="Review Request Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDateTimeAmPm }}</label>
                                                                </div>
                                                                <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-6 pr-0">
                                                                    <label title="Review Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDateTimeAmPm }}</label>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-3 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge class="task-badge" title="Request By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge class="task-badge" title="Review By"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 px-0 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="(task.actionItemSource == 'APV')">
                                        <div class="col-12 text-left mb-2">
                                            <div class="action-card my-0">
                                                <div class="action-card-body">
                                                    <a href="#" @click.prevent="setErdId(task.entityRecordId, task.entity)" class="action-link" title="View Report" :to="{ name: getRedirectUrlName(task.entity)}">
                                                        <span class="badge pointer float-right" :class="task.className" title="Approval">{{ task.actionItemSource }}</span>
                                                    </a>
                                                    <div class="card-media-body-top">
                                                        <span :title="task.actionItemTitle" class=""><a href="#" @click.prevent="setErdId(task.entityRecordId, task.entity)">{{ task.actionItemTitle }}</a></span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="">
                                                        <div v-if="task.status" class="row">
                                                            <div class="col-12">
                                                                <span title="Status" class="subtle">{{ task.status }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="divider-line"></div>
                                                        <div v-if="(task.actionItemDt != null) || (task.actionItemDueDt != null)">
                                                            <div class="row">
                                                                <div v-if="(task.actionItemDt && task.actionItemDt != null)" class="col-6 pr-0">
                                                                    <label title="Approval Request Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDateTimeAmPm }}</label>
                                                                </div>
                                                                <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-6 pr-0">
                                                                    <label title="Approval Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDateTimeAmPm }}</label>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-3 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge class="task-badge" title="Requested By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge class="task-badge" title="Approval By"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="(task.actionItemSource == 'SHARE')">
                                        <div class="col-12 text-left mb-2">
                                            <div class="action-card my-0">
                                                <div class="action-card-body">
                                                    <a href="#" @click.prevent="setErdId(task.entityRecordId, task.entity)" class="action-link" title="View Report" :to="{ name: getRedirectUrlName(task.entity)}">
                                                        <span class="badge pointer float-right" :class="task.className" title="Share">{{ task.actionItemSource }}</span>
                                                    </a>
                                                    <div class="card-media-body-top">
                                                        <span :title="task.actionItemTitle" class=""><a href="#" @click.prevent="setErdId(task.entityRecordId, task.entity)">{{ task.actionItemTitle }}</a></span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="">
                                                        <div v-if="(task.actionItemDt != null) || (task.actionItemDueDt != null)">
                                                            <div class="row">
                                                                <div v-if="(task.actionItemDt && task.actionItemDt != null)" class="col-6 pr-0">
                                                                    <label title="Shared Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDateTimeAmPm }}</label>
                                                                </div>
                                                                <!-- <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-6">
                                                                    <label title="End Date" class="subtle mr-2"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDate }}</label>
                                                                    <label title="End Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatTimeAmPm }}</label>
                                                                </div> -->
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-3 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge class="task-badge" title="Shared By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge class="task-badge" title="Shared To"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="(task.actionItemSource == 'TASK')">
                                        <div class="col-12 text-left mb-2">
                                            <div class="action-card my-0">
                                                <div class="action-card-body">
                                                    <a href="#" @click.prevent="setErdId(task.entityRecordId, task.entity)" class="action-link" title="Task" >
                                                        <span class="badge pointer float-right" :class="task.className" title="Task">{{ task.actionItemSource }}</span>
                                                    </a>
                                                    <div class="card-media-body-top">
                                                        <span :title="task.actionItemTitle" class=""><a href="#" @click.prevent="setErdId(task.entityRecordId, task.entity)">{{ task.actionItemTitle }}</a></span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="">
                                                        <div>
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <span title="Activity" class="subtle">{Activity}</span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <span title="Status" class="subtle">{{ task.status }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div v-if="(task.actionItemDt != null) || (task.actionItemDueDt != null)">
                                                            <div class="row">
                                                                <div v-if="(task.actionItemDt && task.actionItemDt != null)" class="col-6 pr-0">
                                                                    <label title="Start Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDateTimeAmPm }}</label>
                                                                    <!-- <label title="Start Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatTimeAmPm }}</label> -->
                                                                </div>
                                                                <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-6 pr-0">
                                                                    <label title="End Date" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDateTimeAmPm }}</label>
                                                                    <!-- <label title="End Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatTimeAmPm }}</label> -->
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-3 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge class="task-badge" title="Assigned By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge class="task-badge" title="Assigned To"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="(task.actionItemSource == 'MTG')">
                                        <div class="col-12 text-left mb-2">
                                            <div class="action-card my-0">
                                                <div class="action-card-body">
                                                    <a href="#" @click.prevent="setErdId(task.entityRecordId, task.entity)" class="action-link" title="Task" :to="{ name: getRedirectUrlName(task.entity)}">
                                                        <span class="badge pointer float-right" :class="task.className" title="Meeting">{{ task.actionItemSource }}</span>
                                                    </a>
                                                    <div class="card-media-body-top">
                                                        <span :title="task.actionItemTitle" class=""><a href="#" @click.prevent="setErdId(task.entityRecordId, task.entity)">{{ task.actionItemTitle }}</a></span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="">
                                                        <div>
                                                            <div class="row">
                                                                <div class="col-7 pr-0">
                                                                    <span title="Meeting Date" class="subtle">{{ task.actionItemDt | formatDate }}</span>
                                                                </div>
                                                                <div class="col-5 px-0">
                                                                    <span title="Status" class="subtle">{Location}</span>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div v-if="(task.actionItemDt != null) || (task.actionItemDueDt != null)">
                                                            <div class="row">
                                                                <div v-if="(task.actionItemDt && task.actionItemDt != null)" class="col-6 pr-0">
                                                                    <label title="Start Time" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDateTimeAmPm }}</label>
                                                                </div>
                                                                <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-6 pr-0">
                                                                    <label title="End Time" class="subtle"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDateTimeAmPm }}</label>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-3 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge class="task-badge" title="Organized By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge class="task-badge" title="Participant"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="(task.actionItemSource == 'EMAIL')">
                                        <div class="col-12 text-left mb-2">
                                            <div class="action-card my-0">
                                                <div class="action-card-body">
                                                    <a href="#" @click.prevent="setErdId(task.entityRecordId, task.entity)" class="action-link" title="Task" :to="{ name: getRedirectUrlName(task.entity)}">
                                                        <span class="badge pointer float-right" :class="task.className" title="Email">{{ task.actionItemSource }}</span>
                                                    </a>
                                                    <div class="card-media-body-top">
                                                        <span :title="task.actionItemTitle" class=""><a href="#" @click.prevent="setErdId(task.entityRecordId, task.entity)">{{ task.actionItemTitle }}</a></span>
                                                    </div>
                                                    <div class="divider-line"></div>
                                                    <div class="">
                                                        <div v-if="(task.actionItemDt != null) || (task.actionItemDueDt != null)">
                                                            <div class="row">
                                                                <div v-if="(task.actionItemDt && task.actionItemDt != null)" class="col-12">
                                                                    <label title="Send Date" class="subtle mr-2"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatDate }}</label>
                                                                    <label title="Send Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDt | formatTimeAmPm }}</label>
                                                                </div>
                                                                <!-- <div v-if="(task.actionItemDueDt && task.actionItemDueDt != null)" class="col-6">
                                                                    <label title="End Date" class="subtle mr-2"><i class="fa fa-calendar-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatDate }}</label>
                                                                    <label title="End Time" class="subtle"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.actionItemDueDt | formatTimeAmPm }}</label>
                                                                </div> -->
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-3 text-left">
                                                                <div v-if="(task.actionItemBy != null && task.actionItemTo != null)">
                                                                    <b-badge class="task-badge" title="Send By"> {{ task.actionItemBy }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 text-left">
                                                                <div v-if="(task.actionItemTo != null)">
                                                                    <b-badge class="task-badge" title="Send To"> {{ task.actionItemTo }} </b-badge>
                                                                </div>
                                                            </div>
                                                            <div class="col-6 text-right">
                                                                <div v-if="(task.createdDt != null)">
                                                                    <span title="Created Time" class="fs-12"> <i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.createdDt | formatTimeAmPm }} </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div v-if="currentDateList[index] && dailyActionItems(currentDateList[index].length) == 0" class="row">
                                    <div class="col-12 text-left mb-2">
                                        <div class="action-card my-0">
                                            <div class="action-card-body text-center">
                                                No Items Found
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <!-- <ReferenceDetailPopup ref="referenceDetailRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <EmailDetailPopup ref="emailDetailPopupRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <ReviewApprovalDetailPopup ref="reviewApprovalDetailPopupRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <ShareDetailPopup ref="shareDetailPopupRef" :detailViewData="detailViewData" />
        <TaskDetailPopup ref="taskDetailPopupRef" :referenceDetailId="referencePopupId" :refPopupTitle="refPopupTitle" :refEntity="refPopupEntity" />
        <MeetingPrepartionDetailPopup ref="meetingPrepartionDetailPopupRef" /> -->
    </div>
</template>
<script>
// import TaskDetailPopup from '@/components/popup/itemdetail/TaskDetailPopup.vue';
// import EmailDetailPopup from '@/components/popup/itemdetail/EmailDetailPopup.vue';
// import ReferenceDetailPopup from '@/components/popup/reference/ReferenceDetailPopup.vue';
// import ReviewApprovalDetailPopup from '@/components/popup/itemdetail/ReviewApprovalDetailPopup.vue';
// import ShareDetailPopup from '@/components/popup/itemdetail/ShareDetailPopup.vue';
// import MeetingPrepartionDetailPopup from '@/components/popup/itemdetail/MeetingPrepartionDetailPopup.vue';
import moment from 'moment';
import { commonMixin } from "@/mixins/commonMixin";
export default {
    mixins: [commonMixin],
    // components: {
    //     EmailDetailPopup,
    //     ReferenceDetailPopup,
    //     ReviewApprovalDetailPopup,
    //     ShareDetailPopup,
    //     TaskDetailPopup,
    //     MeetingPrepartionDetailPopup
    // },
    data() {
        return {
            refPopupTitle: '',
            referencePopupId: 0,
            refPopupEntity: '',
            detailViewData: {},

            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),

            daysToShow: 1,
            selectedDate: null,
            activeFilter: null,
            currentStartDate: new Date(),
            currentEndDate: new Date(),
            currentDateList: [],

            days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],

            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],

            actionItems: [],
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'Action Item';
        this.dateList();
        this.loadData();
    },
    methods: {
        loadData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                userId: localStorage.getItem("userId"),
                isArchive: false,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/common/actionitem/list', data)
                .then((response) => {
                    let result = response.data;
                    this.actionItemsAll = result.actionItemList;
                    this.actionItems = this.actionItemsAll;
                })

        },
        dateList(selectedDate) {
            if (selectedDate) {
                this.currentStartDate = moment(selectedDate).toDate();
                this.daysToShow = 1;
            } else {

                /* to set Sunday as the first day */
                // this.currentStartDate = moment().startOf('week').toDate();

                /* to set Monday as the first day */
                // this.currentStartDate = moment().startOf('isoweek').toDate();

                /* to set current day as the first day */
                this.currentStartDate = new Date();
            }
            var endDate = new Date();
            endDate.setDate(this.currentStartDate.getDate() + 6);

            this.currentEndDate = endDate;
            this.currentDateList = this.getDaysArray(this.currentStartDate, endDate);
        },
        changePage(page, number) {
            var startDate = new Date(this.currentStartDate);
            var endDate = new Date(this.currentEndDate);

            if (page === 'nextPage') {
                startDate.setDate(this.currentStartDate.getDate() + number);
                endDate.setDate(this.currentEndDate.getDate() + number);
            }
            if (page === 'prevPage') {
                startDate.setDate(this.currentStartDate.getDate() - number);
                endDate.setDate(this.currentEndDate.getDate() - number);
            }
            this.currentStartDate = startDate;
            this.currentEndDate = endDate;

            this.currentDateList = this.getDaysArray(this.currentStartDate, this.currentEndDate);
        },
        getDaysArray: function(s, e) {
            for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
                a.push(new Date(d));
            }
            return a;
        },
        dailyActionItems(itemDate) {
            return this.actionItems.filter(item => new Date(item.actionItemDt).toString().substr(0, 10) == new Date(itemDate).toString().substr(0, 10));
        },
        filterItems(filter) {
            this.activeFilter = filter;
            this.actionItems = this.actionItemsAll.filter((items) => items.actionItemSource == filter);
        },
        viewReference: function(data, title) {
            this.referencePopupId = data.entityRecordId;
            this.refPopupTitle = title;
            this.refPopupEntity = data.actionItemSource;
            this.detailViewData = data;

            if (this.refPopupEntity == 'task' || this.refPopupEntity == 'TASK') {
                this.$refs.taskDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            } 
            else if (this.refPopupEntity == 'APV' || this.refPopupEntity == 'REV') {
                this.$refs.reviewApprovalDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity, data.entity, data.entityDisplayName);
            }
            else if (this.refPopupEntity == 'SHARE') {
                this.$refs.shareDetailPopupRef.getDetails();
            }
            else if (this.refPopupEntity == 'EMAIL') {
                this.$refs.emailDetailPopupRef.getDetails(this.referencePopupId, this.refPopupEntity);
            }
            else if (this.refPopupEntity == 'MTG') {
                this.$refs.meetingPrepartionDetailPopupRef.getDetails(this.referencePopupId, data.entity, null);
            }
            else {
                this.$nextTick(() => {
                    this.$root.$emit('bv::show::modal', 'referenceDetail', '#referenceDetailRef');
                })
                this.$refs.referenceDetailRef.getRequirementSpecs(this.referencePopupId, this.refPopupEntity);
            }
        },
        setErdId: function(erdId, entity){
            this.$store.state.isActionItemRedirect = true;
            localStorage.setItem("erdId", erdId);
            this.$router.push({ name: this.getRedirectUrlName(entity) });
        }
    }
}
</script>