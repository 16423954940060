<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12  col-md-6">
                            <div class="float-left">
                                <h1 class="text-dark titel-text">Budget Plan</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" show-empty small selectable :select-mode="selectMode" 
                                    sort-icon-left :items="budgetPlans" :fields="budgetPlanFields" responsive="sm" @row-selected="onRowSelected" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                        <template v-slot:cell(title)="data">
                                            <div :class="data.item.title" class="text-overflow-dots">
                                                <p >{{ data.value }} </p>
                                                <p v-if="data.item.activity"><span class="pr-2 font-light">Activity</span>{{ data.item.activity }} </p>
                                            </div>
                                        </template>
                                        
                                        <template v-slot:cell(estimatedCost)="data">
                                            
                                            <span v-if="data.item.estimatedCost" class="label label-primary mb-1 mr-1"><i v-bind:class="[ (prependText === 'INR') ? 'fa fa-inr' : 'fa fa-usd']"></i> {{data.item.estimatedCost}}</span>
                                            <p v-if="data.item.budgetCategoryId" class="pr-1 text-overflow-dots " ><span class="pr-2 font-light">Category</span>{{ data.item.category }} </p>
                                       </template>
                                        <template  v-slot:empty="scope">
                                            <div class="text-center">No BudgetPlans Found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="budgetPlans.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" @click="$router.push('/addbudgetplan/' + entityRecordMasterId)" size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsFinance } from "@/mixins/requestsFinance";
export default {
    name: 'app',
    mixins: [requestsFinance],
    data() {
        return {
            elements: {
                showRegister: false,
            },
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            categoryLookupTitle: 'Budget Plan Category Settings',
            activityLookupTitle: 'Activity Settings',
            notEnabled: true,
            budgetPlanTypes: [],
            budgetPlanCategory: [],
            users: [],
            budgetPlanActivity: [],
            entity: '',
            finTxnEntity: 'financial_transaction',
            budgetPlans: [],

            budgetPlanFields: [
                { key: 'title', label: 'Item Title', tdClass: 'table-title-col-size', sortable: true, sortDirection: 'desc' },
                { key: 'estimatedCost', label: 'Estimated Cost', sortable: true, sortDirection: 'desc' },
            ],
            selectMode: 'single',
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
            isBusy: true,
            totalRows: 1,
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })

        },
        prependText: function() {
            return localStorage.getItem("currency") == "null" ? 'INR' : localStorage.getItem("currency")
        },
        totalExpenses() {
            return this.budgetPlans.reduce((accum, item) => {
                return accum + parseFloat(item.estimatedCost)
            }, 0.00)
        }

    },
    mounted() {
        this.initialSettings();
        this.getBudgetPlans();
    },
    methods: {

         
        initialSettings: function() {
            this.vhextAccountId = localStorage.getItem("vhextAccountId");
            this.projectId = localStorage.getItem("projectId");
            this.entityRecordMasterId = this.$route.params.ermId;
            this.entity = 'budget_plan';
            this.userId = localStorage.getItem("userId");
            this.elements.showCategory = true;

        },

        async getBudgetPlans() {
            try {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId,
            }
            const response = await this.getBudgetPlan(data);
                    let result = response.data;
                    this.budgetPlans = result.budgetlist;
                    this.totalRows = this.budgetPlans.length;
                    console.log(this.budgetPlans);
                } catch (error) {
                    console.log(error);
                }
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },


        onRowSelected: function(items) {
             if (items && items.length > 0){
                let data = items[0];
                console.log(data)
                localStorage.setItem("rowData", JSON.stringify(data));
                this.$nextTick(() => {
                    this.$router.push('/viewBudgetPlan/' + data.id);
                });
            }
        },

        filterAccountType: function(individual, company) {
            return this.accountTypes.filter(function(data, key) {
                if (data.value == individual || data.value == company) {
                    return data;
                }
            });
        },
    }
}
</script>