<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-60">
                        <div class="card-body pl-3 pt-1 pr-3">
                            <div class="row">
                                 <div class="col-12">
                                    <div class="form-group">
                                        <label for="contactName">Contact Name</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.contact.contactName.$error }">
                                            <input type="text" class="form-control" id="contactName" v-model="contact.contactName" placeholder="Enter Contact Name">
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.contact.contactName.$error && !$v.contact.contactName.required" class="text-danger">Please Fill Contact Name</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="position">Job Title</label>
                                        <input type="text" class="form-control" id="position" v-model="contact.position" placeholder="Enter Job Title">  
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="emailId">E-mail</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.contact.emailId.$error }">
	                                        <input type="text" class="form-control" id="emailId"  v-model="contact.emailId" placeholder="Enter E-mail Address">
                                        	<div class="required-icon"></div>
                                        </div>	
                                        <label v-if="$v.contact.emailId.$error && !$v.contact.emailId.required" class="text-danger">Please Fill E-mail</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="mobile">Mobile</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.contact.mobile.$error }">
                                        	<input type="text" class="form-control" id="mobile" v-model="contact.mobile" placeholder="Enter Mobile Number">
                                        	<div class="required-icon"></div>
										</div>	
                                        <label v-if="$v.contact.mobile.$error && !$v.contact.mobile.required" class="text-danger">Please Fill Mobile Number</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="phone">Phone</label>
                                        <input class="form-control" type="text" id="phone" v-model="contact.phone" placeholder="Enter Phone Number">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card-body p-0 mb-1">
                                        <b-card no-body class="">
                                            <b-card-header header-tag="header" class=" accordion-header p-0" role="tab">
                                                <label class="d-block p-2 accordion-title" block href="javascript:" v-b-toggle.accordion-2>
                                                    <span>Address</span>
                                                </label>
                                            </b-card-header>
                                            <b-collapse id="accordion-2" accordion="my-accordion1" role="tabpanel" class="p-0">
                                                <b-card-body class="p-1">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="address1">Address1</label>
                                                               <input type="text" class="form-control" id="address1" v-model="contact.address1">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="address2">Address2</label>
                                                                <input type="text" class="form-control" id="address2" v-model="contact.address2">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="city">City</label>
                                                                <input type="text" class="form-control" id="city" v-model="contact.city">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="state">State</label>
                                                                <input type="text" class="form-control col-12" id="state" v-model="contact.state">
                                                            </div>
                                                        </div>
                                                         <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="country">Country</label>
                                                                <select v-model="contact.countryId" class="form-control">
                                                                    <option value="">Select</option>
                                                                    <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="pincode">Postal Code</label>
                                                                <input type="text" class="form-control" id="pincode" v-model="contact.pincode">
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="website">Web Address</label>
                                                                <input type="text" class="form-control" id="website" v-model="contact.website">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-card-body>
                                            </b-collapse>
                                        </b-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { EventBus } from '@/main';
export default {
    name: 'app',
    data() {
        return {
           
            elements: {
                editMode: false,
                visible: false,
                viewMode: false
            },
            countries: [],
            contact: {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                userId: localStorage.getItem("userId"),
                refEntity: 'contact',
                contactName: '',
                position: '',
                emailId: '',
                mobile: '',
                phone: '',
                firstName: '',
                middleName: '',
                lastName: '',
                photo: '',
                country: '',
                buildingName: '',
                streetName: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                pincode: '',
                website: ''
                
            },

        }
    },
    validations: {
        contact: {
            contactName: {
                required
            },
            emailId: {
                required
            },
            mobile: {
                required
            },
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'New Contact';
        this.lookupData();
    },
    created() {
        EventBus.$on("savePage", this.saveContact);
    },
    destroyed() {
        EventBus.$off("savePage", this.saveContact);
    },
    methods: {

         lookupData: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            axios.post(this.$store.getters.getAPIBasePath + '/contact/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.countries = result.countryLookup;             
                })
        },
        saveContact: function() {
        	this.$v.contact.$touch();
            if (!this.$v.contact.$invalid) {

	        	console.log(this.contact)
	            axios.post(this.$store.getters.getAPIBasePath + '/contact/add', this.contact)
	                .then((response) => {
	                    let result = response.data;
	                    if (result.status == "CREATED") {
	                        iziToast.success({
	                            title: 'Success',
	                            message: 'Contact info loaded successfully',
	                            position: 'topRight'
	                        });
                            this.$router.push('/landing/contact');

	                    }
	                })
	        }
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.contact.contactName = '';
            this.contact.position = '';
            this.contact.emailId = '';
            this.contact.mobile = '';
            this.contact.phone = '';
            this.contact.firstName = '';
            this.contact.middleName = '';
            this.contact.lastName = '';
            this.contact.photo = '';
            this.contact.country = '';
            this.contact.buildingName = '';
            this.contact.streetName = '';
            this.contact.address1 = '';
            this.contact.address2 = '';
            this.contact.city = '';
            this.contact.state = '';
            this.contact.pincode = '';
            this.contact.website = '';
            this.$v.contact.$reset();
        },    
    }
}
</script>