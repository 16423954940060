<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <!-- breadcrumb -->
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12  col-md-6">
                            <div class="float-left">
                                <h1 class="text-dark titel-text">Resource Pool</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- breadcrumb_End -->
                <!-- Section -->
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" show-empty small selectable @row-selected="onRowSelected" :select-mode="selectMode" 
                                    sort-icon-left :items="humanResources" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                        <template v-slot:cell(name)="data">
                                            <div  class="text-overflow-dots">
                                                <p>{{ data.item.name }}</p>
                                                <p class="fs-small">{{ data.item.position }}</p>
                                            </div>
                                        </template>
                                        <template v-slot:cell(emailId)="data">
                                            <div  class="">
                                                <p v-if="data.item.mobile"><i class="fa fa-xs fa-mobile text-primary pr-1" aria-hidden="true"></i>{{ data.item.mobile }} </p>
                                                <p v-if="data.item.emailId"><i class="fa fa-xs fa-envelope text-primary pr-1" aria-hidden="true"></i>{{ data.item.emailId }} </p>
                                            </div>
                                        </template>
                                        <template  v-slot:empty="scope">
                                            <div class="text-center">No Resources Found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="humanResources.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" @click="$router.push('/landing/addresource')" size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsMixin } from "@/mixins/requestsMixin";

export default {
    mixins: [requestsMixin],
    data() {
        return {
            fields: [
                { key: 'name', label: 'Name', sortable: true, sortDirection: 'desc' },
                // { key: 'position', label: 'Position', sortable: true, sortDirection: 'desc' },
                // { key: 'qualification', label: 'Qualification', sortable: true, sortDirection: 'desc' },
                // { key: 'experienceYears', label: 'Experience', sortable: true, sortDirection: 'desc' }, 
                // { key: 'mobile', label: 'Mobile', sortable: true, sortDirection: 'desc' },         
                { key: 'emailId', label: 'Contact', sortable: true, sortDirection: 'desc' },
            ],
            selectMode: 'single',
            humanResources: [],
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
            totalRows: 1,
        }
    },
    mounted() {
        this.getAllResources();
    },
    methods: {
        async getAllResources() {
            try {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                }
                this.$store.state.showAppLoader = true;
                const response = await this.getResources(data);
                this.$store.state.showAppLoader = false;
                let result = response.data;
                if (result.humanResourceList && result.humanResourceList.length != 0) {
                    this.humanResources = result.humanResourceList;
                    console.log(result);
                    this.totalRows = this.humanResources.length;
                }
            }
            catch(error) {
                this.$store.state.showAppLoader = false;
                iziToast.error({
                    title: 'Error',
                    message: 'Error',
                    position: 'topRight'
                });
            }
        },
        onRowSelected: function(items) {
             if (items && items.length > 0){
                let data = items[0];
                console.log(data)
                localStorage.setItem("rowData", JSON.stringify(data));
                this.$nextTick(() => {
                    this.$router.push('/landing/viewresource/' + data.id);
                })
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>