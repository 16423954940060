<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.calendarEvent.title.$error }">
                                            <label for="title" class="inp">
                                                <input type="text" id="title" placeholder=" " v-model="calendarEvent.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.calendarEvent.title.$error }">
                                                <span class="label">Event Title</span>
                                                <span class="focus-bg"></span>
                                            </label>
                                        </div>
                                        <label v-if="$v.calendarEvent.title.$error && !$v.calendarEvent.title.minLength" class="text-danger">Item Title must have at least {{$v.calendarEvent.title.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.calendarEvent.title.$error && !$v.calendarEvent.title.required" class="text-danger">Please enter Item Title</label>
                                        <label v-if="$v.calendarEvent.title.$error && !$v.calendarEvent.title.maxLength" class="text-danger">Item Title must not exceed {{$v.calendarEvent.title.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="categoryId">Event Group</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.calendarEvent.entityRecordMasterId.$error }">
                                            <select v-model="calendarEvent.entityRecordMasterId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in entityERMLookup" :key="index" :value="info.id">{{info.value}}</option>
                                            </select>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.calendarEvent.entityRecordMasterId.$error && !$v.calendarEvent.entityRecordMasterId.required" class="text-danger">Please Select a Event Group</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="eventTypeId">Event Type</label>
                                        <select v-model="calendarEvent.eventTypeId" class="form-control" id="eventTypeId">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in eventTypes" :key="index" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="form-group">
                                        <label for="eventFrequency">Occurrence</label>
                                        <select v-model="calendarEvent.eventFrequency" class="form-control" id="eventFrequency">
                                            <option value="0">Single Day</option>
                                            <option value="1">Recurring</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-4 pl-0">
                                    <div class="form-group">
                                        <label for="transactionDt" style="visibility: hidden;">All Day</label>
                                        <div class="row no-gutters pt-2 text-right">
                                            <div class="col-6">
                                                <label for="transactionDt" class="float-right">All Day</label>
                                            </div>
                                            <div class="col-6">
                                                <b-form-checkbox class="custom-toggle mr-0" id="isAllDayEvent" v-model="calendarEvent.isAllDayEvent" name="check-button" switch></b-form-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="calendarEvent.eventFrequency == 0" class="col-12">
                                    <div class="form-group">
                                        <label for="eventStartTime">Date</label>
                                        <input type="date" class="form-control" v-model="calendarEvent.eventStartDate" id="eventStartTime">
                                    </div>
                                </div>
                                <div v-else class="col-12">
                                    <div class="form-group">
                                        <label for="eventStartTime">Date</label>
                                        <div class="form-row">
                                            <b-col cols="5" class="pr-0">
                                                <input type="date" class="form-control" v-model="calendarEvent.eventStartDate" id="eventStartTime">
                                            </b-col>
                                            <b-col class="px-0 my-auto text-center">
                                                <label class="my-auto" for="eventEndTime">to</label>
                                            </b-col>
                                            <b-col cols="5" class="pl-0">
                                                <input type="date" class="form-control" v-model="calendarEvent.eventEndDate" id="eventEndDate">
                                            </b-col>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!calendarEvent.isAllDayEvent" class="col-12">
                                    <div class="form-group">
                                        <label for="transactionTypeId">Time</label>
                                        <div class="form-row">
                                            <b-col cols="5" class="pr-0">
                                                <input type="time" class="form-control" v-model="calendarEvent.eventStartTime" id="eventStartTime">
                                            </b-col>
                                            <b-col class="px-0 my-auto text-center">
                                                <label class="my-auto" for="eventEndTime">to</label>
                                            </b-col>
                                            <b-col cols="5" class="pl-0">
                                                <input type="time" class="form-control" v-model="calendarEvent.eventEndTime" id="eventEndTime">
                                            </b-col>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="calendarEvent.eventFrequency == 1" class="col-12">
                                    <div class="form-group">
                                        <label for="deliverables">Repeat</label>
                                        <multiselect v-model="calendarEvent.daysToRepeat" placeholder="Days to repeat" :options="days" name="repeat"  select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" id="daysOfWeek"></multiselect>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="location" class="inp">
                                            <input type="text" id="location" placeholder=" " v-model="calendarEvent.location" class="mobile-form-control" >
                                            <span class="label">Location</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="link" class="inp">
                                            <input type="text" id="link" placeholder=" " v-model="calendarEvent.link" class="mobile-form-control" >
                                            <span class="label">Link</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="hostedById">Host</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.calendarEvent.hostedById.$error }">
                                            <select v-model="calendarEvent.hostedById" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in users" :key="index" :value="info.id">{{info.value}}</option>
                                            </select>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.calendarEvent.hostedById.$error && !$v.calendarEvent.hostedById.required" class="text-danger">Please Select a Meeting Host</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="eventParticipant">Event Participants</label>
                                        <multiselect v-model="calendarEvent.eventParticipant" placeholder="Add Participants" :options="allParticipants" name="eventParticipant" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" id="eventParticipant"></multiselect>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="description">Agenda</label>
                                        <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp"  :init="initObj" v-model="calendarEvent.description"></editor>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { requestsFinance } from "@/mixins/requestsFinance";
import Editor from '@tinymce/tinymce-vue';
import dayList from '@/json/daysofweek.json';
import Multiselect from 'vue-multiselect';
import { EventBus } from '@/main';

export default {
    name: 'app',
    components: {
        Editor,
        Multiselect,
    },
    mixins: [requestsFinance],
    data() {
        return {
        	showDialog: false,
        	elements: {
        		visibleAjenda: false,
        		visible: false,
        		viewMode: false,
        		viewModeMOM: true,
        		editMode: false
        	},
        	activeTab: 1,
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entity: "calendar_event",

            ermMasterInfo: {
                title: "Meeting Preparation Master",
                entity: 'meeting_minute_master',
                refEntity: 'calendar_event',
            },

            calendarEvent: {
                editMode: false,
                viewMode: null,
                showMOM: false,
                title: '',
                eventDate: this.getDateValue(new Date()),
                startDate: '',
                eventStartDate: this.getDateValue(new Date()),
                eventStartTime: this.getTimeValue(new Date()),
                endDate: '',
                eventEndDate: this.getDateValue(new Date()),
                eventEndTime: this.getTimeValue(new Date()),
                link: '',
                location: '',
                eventTypeId: '',
                eventFrequency: 0,
                statusId: '',
                isAllDayEvent: false,
                daysOfWeek: [],
                daysToRepeat: [],
                hostedById: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entityRecordMasterId: localStorage.getItem("ermId"),
                participantId: localStorage.getItem("userId"),
                eventParticipant: []
            },

            days: dayList,
            users: [],
            allParticipants: [],
            eventStatus: [],
            eventTypes: [],
            entityERMLookup: [],

            // toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent ',
            initObj: {
                min_height: 150,
                max_height: 350,
                branding: false,
                menubar: false,
                statusbar: false,
                plugins: ['link', 'paste', 'image', 'lists', 'autoresize'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },

            deleteParam: {
                id: 0
            }
        }
    },

    mounted() {
        this.lookupData();
        this.$store.state.pageTitle = 'New Event';
        // this.setCalendarEventData();
    },
    created() {
        EventBus.$on("savePage", this.saveData);
    },
    destroyed() {
        EventBus.$off("savePage", this.saveData);
    },
    validations: {
        calendarEvent: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            entityRecordMasterId: {
            	required
            },//
            hostedById: {
            	required
            },
        },
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.allParticipants = result.userLookup;
                    this.eventStatus = result.statusLookup;
                    this.eventTypes = result.customTypeLookup;
                    this.entityERMLookup = result.entityERMLookup;
                })
        },
        saveData: function() {
        	this.$v.calendarEvent.$touch();
            if (!this.$v.calendarEvent.$invalid) {

	            this.calendarEvent.eventParticipantList = [];
	            for (var index = 0; index < this.calendarEvent.eventParticipant.length; index++) {

	                this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.eventParticipant[index].id, eventParticipantTypeId: 1 });
	            }
	            this.calendarEvent.eventParticipantList = this.calendarEvent.eventParticipantList.filter((participant) => 
            		(participant.participantId != this.calendarEvent.hostedById && participant.participantId != this.calendarEvent.createdById)
            	);
	            if (this.calendarEvent.createdById == this.calendarEvent.hostedById) {
		            this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.createdById, eventParticipantTypeId: 2 });
		        }
		        else {
		            this.calendarEvent.hostedById ? this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.hostedById, eventParticipantTypeId: 2 }) : '';
		            this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.createdById, eventParticipantTypeId: 2 });
		        }
	            
	            this.calendarEvent.startTime = null;
	            this.calendarEvent.endTime = null;
	            if (this.calendarEvent.isAllDayEvent) {
	                this.calendarEvent.startDate = this.getDateValue(this.calendarEvent.eventStartDate);
	                this.calendarEvent.endDate = this.calendarEvent.eventFrequency==0 ? null : this.getDateValue(this.calendarEvent.eventEndDate);
	                if (this.calendarEvent.eventFrequency==0) {
	                	this.calendarEvent.daysOfWeek = null;
	                }
	                else if (this.calendarEvent.daysToRepeat && this.calendarEvent.daysToRepeat.length>0) {
	                    this.calendarEvent.daysOfWeek = [];
	                    this.calendarEvent.daysToRepeat.forEach((day) => {
	                        this.calendarEvent.daysOfWeek.push(this.days.indexOf(day));
	                    })
	                    this.calendarEvent.daysOfWeek = this.calendarEvent.daysOfWeek.toString();
	                } else this.calendarEvent.daysOfWeek = null;
	            } else {
	                if (this.calendarEvent.startDate == '' || this.calendarEvent.startDate == null) { 
	                    this.calendarEvent.startDate = this.combineDateAndTime(this.calendarEvent.eventStartDate, this.calendarEvent.eventStartTime) 
	                }
	                if (this.calendarEvent.endDate == '' || this.calendarEvent.endDate == null) { 
	                    this.calendarEvent.endDate = this.combineDateAndTime(this.calendarEvent.eventEndDate, this.calendarEvent.eventEndTime) 
	                }
	                if (this.calendarEvent.eventFrequency==0) {
	                    this.calendarEvent.endDate = this.combineDateAndTime(this.calendarEvent.eventStartDate, this.calendarEvent.eventEndTime) 
	                }
	                if (this.calendarEvent.daysToRepeat && this.calendarEvent.daysToRepeat.length>0) {
	                     this.calendarEvent.daysOfWeek = [];
	                    this.calendarEvent.daysToRepeat.forEach((day) => {
	                        this.calendarEvent.daysOfWeek.push(this.days.indexOf(day));
	                    })
	                    this.calendarEvent.daysOfWeek = this.calendarEvent.daysOfWeek.toString();
	                    this.calendarEvent.startTime = this.calendarEvent.eventStartTime + ":00";
	                    this.calendarEvent.endTime = this.calendarEvent.eventEndTime + ":00";
	                } else this.calendarEvent.daysOfWeek = null;
	            }
            	this.showOverlay = true;
                this.$nextTick(() => {
                    this.saveEvent();
                })
            }
        },
        saveEvent: function() {
            console.log(this.calendarEvent);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/add', this.calendarEvent)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.showOverlay = false;
                        this.$router.push('/calevt');
                        this.clearData();
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        getParticipantList: function(eventId) {
            let data = {
                eventId: eventId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/eventparticipant/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.calendarEvent.eventParticipant = result.eventParticipantLookup;
                    console.log(result);
                })
        },
        setCalendarEventData() {
        	if (this.calendarEventData.viewMode) {        		
				this.getParticipantList(this.calendarEventData.id);
        	}
			console.log(this.calendarEventData)
            this.calendarEvent.id = this.calendarEventData.id;
            this.calendarEvent.title = this.calendarEventData.title;
            this.calendarEvent.isAllDayEvent = this.calendarEventData.isAllDayEvent;
            this.calendarEvent.daysToRepeat = this.calendarEventData.daysToRepeat;
            this.calendarEvent.eventStartDate = this.getDateValue(this.calendarEventData.eventStartDate);
            this.calendarEvent.eventEndDate = this.getDateValue(this.calendarEventData.eventEndDate);
            this.calendarEvent.eventStartTime = this.calendarEventData.eventStartTime;
            this.calendarEvent.eventEndTime = this.calendarEventData.eventEndTime;
            this.calendarEvent.eventDate = this.calendarEventData.eventDate;
            this.calendarEvent.description = this.calendarEventData.description;
            this.calendarEvent.type = this.calendarEventData.type;
            this.calendarEvent.link = this.calendarEventData.link;
            this.calendarEvent.eventFrequency = this.calendarEventData.eventFrequency;
            this.calendarEvent.eventTypeId = this.calendarEventData.eventTypeId;
            this.calendarEvent.status = this.calendarEventData.status;
            this.calendarEvent.statusId = this.calendarEventData.statusId;
            this.calendarEvent.projectId = this.calendarEventData.projectId;
            this.calendarEvent.vhextAccountId = this.calendarEventData.vhextAccountId;
            this.calendarEvent.entityRecordMasterId = this.calendarEventData.entityRecordMasterId;
            this.calendarEvent.createdBy = this.calendarEventData.createdBy;
            this.calendarEvent.hostedBy = this.calendarEventData.hostedBy;
            this.calendarEvent.createdById = this.calendarEventData.createdById;
            this.calendarEvent.hostedById = this.calendarEventData.hostedById;
            this.calendarEvent.eventId = this.calendarEventData.eventId;
            this.calendarEvent.location = this.calendarEventData.location;
            this.calendarEvent.editMode = this.calendarEventData.editMode;
            this.calendarEvent.viewMode = this.calendarEventData.viewMode;
            this.calendarEvent.showMOM = this.calendarEventData.showMOM;
            this.calendarEvent.isMOMRecorder = this.calendarEventData.isMOMRecorder;

            // this.meetingMinute.selectedDate = this.calendarEventData.selectedDate;
            // this.meetingMinute.calendarEventId = this.calendarEventData.id;

            // let date = this.calendarEventData.selectedDate;
            // this.meetingMinute.eventId = moment(new Date(date)).format('YYYYMMDD');
            // this.momData.calendarEventId = this.calendarEventData.id;
            // this.momData.entityRecordMasterId = this.calendarEventData.entityRecordMasterId;
            // this.momData.eventId = this.meetingMinute.eventId;
            // this.$nextTick(() => {
	        //     this.getMeetingMinute(this.meetingMinute.eventId);
            // })
        },
        combineDateAndTime: function(date, time) {
            console.log(date, time);
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
        clearData: function() {
            this.calendarEvent.title = '';
            this.calendarEvent.isAllDayEvent = false;
            this.calendarEvent.daysOfWeek = [];
            this.calendarEvent.daysToRepeat = [];
            this.calendarEvent.eventDate = this.getDateValue(new Date());
            this.calendarEvent.startDate = '';
            this.calendarEvent.endDate = '';
            this.calendarEvent.eventStartDate = this.getDateValue(new Date());
            this.calendarEvent.eventEndDate = this.getDateValue(new Date());
            this.calendarEvent.eventStartTime = this.getTimeValue(new Date());
            this.calendarEvent.eventEndTime = this.getTimeValue(new Date());
            this.calendarEvent.statusId = '';
            this.calendarEvent.hostedById = '';
            this.calendarEvent.link = '';
            this.calendarEvent.location = '';
            this.calendarEvent.description = '';
            this.calendarEvent.eventTypeId = '';
            this.calendarEvent.eventFrequency = 0;
            this.calendarEvent.eventParticipantList = '';
            this.calendarEvent.eventParticipant = '';
            this.showOverlay = false;
            this.elements.visibleAjenda = false;
            this.$v.calendarEvent.$reset();
        },
        cancelEditMode() {
        	this.setCalendarEventData();
        	this.calendarEvent.viewMode = true;
        	this.calendarEvent.editMode = false;
        },
    }
}
</script>