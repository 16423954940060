<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-6">
                            <h2 class="pl-2 mb-0 titel-text">New Requirement</h2>
                        </div>
                        <div class="col-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70 ">
                        <div class="card-body pt-4 pb-4 pl-4 pr-4">
                            <div class="row mb-2">
                                <div class="row mb-2">
                                    <div class="col-12 mt-0">
                                        <div class="form-group">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.userRequirement.title.$error }">
                                                <label for="title" class="inp">
                                                    <input type="text" id="title" placeholder=" " v-model="userRequirement.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.userRequirement.title.$error }">
                                                    <span class="label">Title</span>
                                                    <span class="focus-bg"></span>
                                                </label>
                                                <div class="required-icon"></div>
                                            </div>
                                            <label v-if="$v.userRequirement.title.$error && !$v.userRequirement.title.required" class="text-danger">Please enter Title</label>
                                        </div>
                                    </div>
                                    <div class="col-12" >
                                        <div class="form-group">
                                            <label for="userRequirementTypeId">Requirement Type</label>
                                            <a :title="typeLookupTitle" class="setting-icon pl-2" href="javascript:" v-b-modal.typeModal><i class="fa fa-cog action-link" aria-hidden="true"></i></a>
                                            <select v-model="userRequirement.userRequirementTypeId" id="userRequirementTypeId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in requirementTypes" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="preparedDt">Requirement Date</label>
                                            <input v-model="userRequirement.preparedDt" id="preparedDt" type="date" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="userRequirement">Requirement</label>
                                            <textarea v-model="userRequirement.userRequirement" id="userRequirement" type="text" class="form-control"></textarea>
                                            <!-- <editor api-key="lbcn6twu8tv4xan8fn1tgqiqy9hhvsse01s48crluvfuafzp" v-model="userRequirement.userRequirement"></editor> -->
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="priorityId">Priority</label>
                                            <select v-model="userRequirement.priorityId" id="priorityId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in requirementPriorities" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="dueDate">Due Date</label>
                                            <input type="date" class="form-control" v-model="userRequirement.dueDate" id="dueDate">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="statusId">Status</label>
                                            <select v-model="userRequirement.statusId" id="statusId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in requirementStatus" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row mb-1">
                                <div class="col-12">
                                    <AttachmentComponent ref="attachmentControl" :attachmentInfo="attachmentInfo" :attachments="attachments" />
                                </div>
                            </div> -->
                            <!-- <div class="row mb-2">
                                
                                <FunctionalReferencePopup v-if="elements.editMode" ref="referenceModal" :entity="this.entity" :erdId="userRequirement.id" />
                            </div> -->
                            <a href="javascript:" @click="saveClientRequirement" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</a>
                            <a href="javascript:" @click="clearData" class="btn btn-info mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</a>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <ClientReqDetailPopup v-if="showDialog" ref="clientReqDetailPopupRef" :detailViewData="detailViewData" :referenceDetailId="referencePopupId" @closeDialog="closeDialog" />

        <TypeLookup ref="typeLookupModal" :entity="this.entity" :typeLookupTitle="typeLookupTitle"  @refresh-lookup="lookupData"/>

        <HTMLDetailViewPopup ref="htmlDetailViewRef" :htmlData="htmlData" :htmlDetailViewTitle="htmlDetailViewTitle" />

        <Dialog id="deleteUserRequirement" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete User Requirement" /> -->
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required } from "vuelidate/lib/validators";
import { requestsRequirement } from "@/mixins/requestsRequirement";
export default {
    name: 'app',
    mixins: [requestsRequirement],
    // components: {
    //     ClientReqDetailPopup,
    //     TypeLookup,
    //     FunctionalReferencePopup,
    //     HTMLDetailViewPopup,        
    //     Editor,
    //     AttachmentComponent
    // },
    data() {
        return {
            elements: {
                alreadyExists: false
            },

            // htmlData: '',
            typeLookupTitle:'User Requirement Type',
            // htmlDetailViewTitle: 'Client Requirement',

            error: false,
            users: [],
            requirementTypes: [],
            requirementStatus: [],
            requirementPriorities: [],
            requirementActivities: [],

            entity:'user_requirement',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entityRecordMasterId: this.$route.params.ermId,
            userId: localStorage.getItem("userId"),

            userRequirement: {
                id: '',
                title: '',
                userRequirementTypeId: '',
                vhextAccountId: '',
                projectId: '',
                entityRecordMasterId: '',
                preparedById: '',
                preparedDt: new Date().toISOString().substr(0, 10),
                priorityId: '',
                hasReference: '',
                hasRefLink: '',
                hasAttachment: '',
                dueDate: new Date().toISOString().substr(0, 10),
                statusId: '',
                userRequirement: '',
                userRequirementById: '',
            },
        }
    },
    mounted() {
        this.lookupData();
    },
    validations: {
        userRequirement: {
            title: {
                required,
                // minLength: minLength(2),
                // maxLength: maxLength(100)
            }
        }
    },
    methods: {
        async lookupData() {
            try {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                entity: this.entity,
            }
            const response = await this.getClientRequirementLookup(data);
                    let result = response.data;
                    this.requirementTypes = result.customTypeLookup;
                    this.requirementStatus = result.statusLookup;
                    this.requirementPriorities = result.priorityLookup;
                    this.requirementActivities = result.activityLookup;
                    this.users = result.userLookup;
                } catch (error){
                    console.log(error);
                }
        },
        async saveClientRequirement() {
            this.userRequirement.vhextAccountId = this.vhextAccountId;
            this.userRequirement.projectId = this.projectId;
            this.userRequirement.entityRecordMasterId = this.entityRecordMasterId;
            this.userRequirement.preparedById = this.userId;

            this.$v.userRequirement.$touch();
            if (!this.$v.userRequirement.$invalid) {
                try {
                    const response = await this.addClientRequirement(this.userRequirement);
                        console.log(this.userRequirement);
                        let result = response.data;
                        if (result.status == "CREATED") {
                            // this.attachDocument(this.entity, result.id);                    
                            iziToast.success({
                                title: 'Success',
                                message: result.message,
                                position: 'topRight'
                            });
                            this.clearData();
                            this.$router.push('/clientrequirement/' + this.entityRecordMasterId );

                        }

                } catch (error){
                    console.log(error);
                }
            }
        },
        clearData: function() {
            this.elements.visible = false;
            this.elements.viewMode = false;
            this.userRequirement.userRequirementId = '';
            this.userRequirement.title = '';
            this.userRequirement.userRequirementTypeId = '';
            this.userRequirement.userRequirementById = this.userId;
            this.userRequirement.preparedById = this.userId;            
            this.userRequirement.preparedDt = new Date().toISOString().substr(0, 10);
            this.userRequirement.priorityId = '';
            this.userRequirement.dueDate = new Date().toISOString().substr(0, 10);
            this.userRequirement.statusId = '';
            this.userRequirement.hasReference = '';
            this.userRequirement.hasRefLink = '';
            this.userRequirement.hasAttachment = '';
            this.userRequirement.userRequirement = '';
            this.$v.userRequirement.$reset();
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
        }

    }
}
</script>