<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-8">
                            <h2 class="pl-2 mb-0 titel-text">New Scope</h2>
                        </div>
                        <div class="col-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <div class="row">
                                <div class="col-12">
                                    <div class="required-field-block" :class="{ 'is-invalid': $v.scopeStatement.title.$error }">
                                        <label for="title" class="inp">
                                            <input type="text" id="title" placeholder=" " v-model="scopeStatement.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.scopeStatement.title.$error }">
                                            <span class="label">Title</span>
                                            <span class="focus-bg"></span>
                                        </label>
                                        <label v-if="$v.scopeStatement.title.$error && !$v.scopeStatement.title.minLength" class="text-danger">Title must have at least {{$v.scopeStatement.title.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.scopeStatement.title.$error && !$v.scopeStatement.title.required" class="text-danger">Please enter Title</label>
                                        <label v-if="$v.scopeStatement.title.$error && !$v.scopeStatement.title.maxLength" class="text-danger">Title must not exceed {{$v.scopeStatement.title.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="form-group">
                                        <label for="scopeTypeId">Scope Type</label>
                                        <select v-model="scopeStatement.scopeTypeId" class="form-control">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in scopeStatementTypes" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-group p-1">
                                        <label for="inScope">In Scope</label>
                                        <div class="input-group ">
                                            <b-form-checkbox class="custom-toggle" v-model="scopeStatement.inScope" name="check-button" switch>
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Scope Statement</label>
                                        <div class="required-field-block" :class="{ 'is-invalid': $v.scopeStatement.scopeStatement.$error }">
                                            <textarea v-model="scopeStatement.scopeStatement" class="form-control" rows="2" required></textarea>
                                            <div class="required-icon"></div>
                                        </div>
                                        <label v-if="$v.scopeStatement.scopeStatement.$error && !$v.scopeStatement.scopeStatement.required" class="text-danger">Please enter Scope Statement</label>
                                        <label v-if="$v.scopeStatement.scopeStatement.$error && !$v.scopeStatement.scopeStatement.maxLength" class="text-danger">Scope Statement must not exceed {{$v.m.scopeStatement.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:" @click="saveScopeStatement" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</a>
                            <a href="javascript:" @click="clearData" class="btn btn-info mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsRequirement } from "@/mixins/requestsRequirement.js";
export default {
    name: 'app',
    mixins: [requestsRequirement],
    data() {
        return {
            files: '',
            scopeStatementTypes: [],
            addMore: [],

            scopeStatement: {
                scopeStatementId: '',
                title: '',
                scopeTypeId: '',
                scopeStatement: '',
                inScope: true,
                entity: 'scope_statement',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
            },

        }
    },
    mounted() {
        this.lookupData();
    },
    validations: {
        scopeStatement: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            scopeStatement:{
                required,
                maxLength: maxLength(100)

            },
        }
    },
    methods: {
        async lookupData() {
            try {                
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId")
                }
                const response = await this.getScopeStatementLookup(data);
                let result = response.data;
                this.scopeStatementTypes = result.customTypeLookup;
            } catch(error) {
                console.log(error);
            }
        },
        async saveScopeStatement() {
            this.$v.scopeStatement.$touch();
            if (!this.$v.scopeStatement.$invalid) {
                try {                    
                    console.log(this.scopeStatement)
                    const response = await this.addScopeStatement(this.scopeStatement);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: 'ScopeStatement info loaded successfully',
                            position: 'topRight'
                        });
                        this.clearData();
                        this.$router.push('/scopestatementlist/' + this.scopeStatement.entityRecordMasterId );

                    }
                } catch (error) {
                    console.log(error);
                }
            }
        },
        clearData: function() {
            this.scopeStatement.title = '';
            this.scopeStatement.scopeTypeId = '';
            this.scopeStatement.inScope = '';
            this.scopeStatement.scopeStatement = '';
            this.$v.scopeStatement.$reset();
        },

    }
}
</script>