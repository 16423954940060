<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <!-- <div class="page-heading pt-0 pb-2 task-page">
                    <div class="row">
                        <div class="col-4">
                            <div class="float-left">
                                <h2 class="text-dark">Task</h2>
                            </div>
                        </div>
                        <div class="col float-right">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0 px-0">
                                    <li class="mr-2">
                                        <b-button class="pull-right addnew-quicktassk-btn" @click="$router.push('/addtsk')" variant="outline-info" size="sm"><i class="fa fa-plus"></i> Add Task</b-button>
                                    </li>
                                    <li class="">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div> -->
                <section class="task-page">
                    <div class="row">
                        <div class="col-12 col-lg-12 col-xl-12 px-2">
                            <div class="carousel-users">
                                <carousel v-if="carousel" :autoplay="false" :dots="false" :nav="false" :responsive="{0:{items:3,nav:false},600:{items:5,nav:false},900:{items:5,nav:false}}">
                                    <template slot="prev"><span class="prev prev-arrow-btn"><i aria-hidden="true" class="fa fa-angle-left"></i></span></template> 
                                        <label v-for="status,index in filteredStatus" :key="status.id" @click="taskStatusData = status" class="col p-0 text-center" :class="taskStatusData.value == status.value ? 'active' : ''"><span>{{ status.value }}</span></label>
                                    <template slot="next"><span class="next next-arrow-btn"><i aria-hidden="true" class="fa fa-angle-right"></i></span></template>
                                </carousel>
                                <b-button v-if="elements.filterMode" class="btn btn-xs text-right px-2 mx-3 all-user-btn" variant="outline-success" @click="clearFilter" size="sm">All</b-button>
                            </div>
                        </div>
                        <div class="col-12 col-lg-12 col-xl-12 position-relative px-2">
                            <b-overlay :show="showOverlay" spinner-variant="primary" opacity="0" rounded="sm">
                                <div class="row">
                                    <div class="col-12 col-md-3 mb-1">
                                        <div class="tasskLists" v-bind:class="{hide: !elements.isHide }">
                                            <div v-for="task in filteredTasks">
                                                <div v-if="task.status == taskStatusData.value" class="card-media mb-2">
                                                    <div class="card-media-body dropdown show">
                                                        <a type="button" class="float-right" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i class="fa fa-ellipsis-v p-1"></i>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-primary" aria-labelledby="dropdownMenuLink">
                                                            <a class="dropdown-item addnew-tassk pb-1 py-1" @click.prevent="viewItemDetails(task)" href="#"><i class="fa fa-list-alt pr-2" aria-hidden="true"></i>Task Detail</a>
                                                            <a v-if="task.assignedById == userId" class="dropdown-item addnew-tassk py-1" @click.prevent="editTask(task)" href="#"><i class="fa fa-pencil pr-2"></i>Edit Task</a>
                                                            <!-- <a class="dropdown-item addnew-tassk pb-1 py-1" @click="toggleDiv();duplicateTask(task);" href="#"><i class="fa fa-copy pr-2" aria-hidden="true"></i>Duplicate Task</a> -->
                                                            <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click.prevent="showStatusPopup(task)" href="#"><i class="fa fa-edit pr-2 mt-1 mb-0" aria-hidden="true"></i>Task Status Update</a>
                                                            <a v-if="task.assignedById == userId" class="dropdown-item py-1" @click.prevent="deleteTask(task.id)" v-b-modal.deleteTask href="#"><i class="fa fa-trash pr-2"></i>Delete Task</a>
                                                            <!-- <a v-if="task.assignedById == userId || task.assignedToId == userId" class="dropdown-item addnew-tassk my-0 py-1" @click="openTimeSheet(task)" href="#"><i class="fa fa-history pr-2 mt-1 mb-0" aria-hidden="true"></i>Time Sheet</a> -->
                                                            <a v-if="task.assignedById == userId || userTypeCode == 'PRM' || userTypeCode == 'CMR' || userTypeCode == 'VSA'" class="dropdown-item addnew-tassk my-0 py-1" @click.prevent="archiveTask(task)" v-b-modal.archiveTask href="#"><i class="fa fa-archive pr-2 mt-1 mb-0" aria-hidden="true"></i>Archive</a>
                                                        </div>
                                                        <span v-if="task.status!=null" title="Status" class="card-media-object-tag subtle float-right mr-1" :class='task.status.replaceAll(" ", "")'>{{ task.status }}</span>
                                                        <span title="Created on" class="subtle float-right mr-1">{{ getTimeDuration(task.createdDt) }}</span>
                                                        <div class="card-media-body-top">
                                                            <span class="">
                                                                <a href="#" class="action-link" @click.prevent="viewItemDetails(task)">{{ task.title }}</a>
                                                                <i v-if="task.priority" class="fa fa-exclamation-circle pl-1 pointer" :class="priorityColour(task.priority)" :title="`${task.priority ? task.priority : 'Low'} priority`"></i>
                                                            </span>
                                                        </div>
                                                        <div class="divider-line"></div>
                                                        <div class="row">
                                                            <div class="col-6 my-auto">
                                                                <a href="#" class="subtle align-middle" @click.prevent="viewProcessActivity(task)" title="Process/Activity">{{ task.activity }} <i class="fa fa-info-circle" aria-hidden="true"></i></a>
                                                            </div>
                                                            <div class="col-6">
                                                                <span class="subtle" title="Task Progression">{{ task.progressPercentage ? task.progressPercentage : 0 }}% done</span>
                                                                <b-progress height="7px" :variant="progressBarVariant(task)" v-model="task.progressPercentage"></b-progress>
                                                            </div>
                                                        </div>
                                                        <div class="divider-line"></div>
                                                        <div v-if="(task.estimatedStartDt != null) || (task.estimatedEndDt != null)">
                                                            <div class="row">
                                                                <div class="col-6">
                                                                    <span class="subtle" title="Start Date"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedStartDt | formatDateTimeYearCheck }}</span>
                                                                </div>
                                                                <div class="col-6">
                                                                    <span class="subtle float-right" :class="(taskStatusData.value !== 'Closed' && taskStatusData.value !== 'Completed' && taskStatusData.value !== 'Cancelled') ? dueDateStyling(task.estimatedEndDt) : ''" title="Due Date"><i class="fa fa-clock-o pr-1" aria-hidden="true"></i>{{ task.estimatedEndDt | formatDateTimeYearCheck }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="divider-line"></div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="row card-media-object-social-list pt-1">
                                                                    <div class="col-auto">
                                                                        <div v-if="(task.assignedBy != null)">
                                                                            <b-badge class="task-badge" title="Assigned By"> {{ task.assignedBy }} </b-badge>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-auto px-0">
                                                                        <div v-if="(task.assignedTo != null)">
                                                                            <b-badge class="task-badge" :title="`Assigned To: ${task.assignedTo}`"> {{ task.assignedTo | getAcronym }} </b-badge>
                                                                        </div>
                                                                    </div>

                                                                    <div v-if="(task.assignedBy != null)" class="col text-right">
                                                                        <div v-if="(task.checklistItemCount > 0)">
                                                                                <a href="#" @click.prevent="openChecklistReportPopup(task)" title="Show Checklist"><i class="fa fa-tasks action-link"></i> Checklist {{task.checklistItemCount}}</a>
                                                                        </div>
                                                                        <div v-else>
                                                                                <a href="#" @click.prevent="openChecklistReportPopup(task)" title="Show Checklist"><i class="fa fa-plus-circle action-link"></i> Checklist</a>
                                                                        </div>
                                                                    </div>            
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="!getTaskByStatusId(taskStatusData.id).length" class="card-media mb-2">
                                                <div class="card-media-body">
                                                    <div class="stage-card text-center">No Task Found</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-overlay>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" @click="$router.push('/addtsk')" size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
        <ActivityDetailPopup ref="activityDetailPopupRef" @closeDialog="closeDialog" />
        <ChecklistReportPopup ref="checklistReportPopupRef" :checklistTaskRef="checklistTaskRef" @updateChecklist="getTasks(entityRecordMasterId)" @closeDialog="closeDialog" />
        <TaskStatusUpdatePopup v-if="showDialog" ref="taskSatatusUpdatePopupRef" :statusUpdate="statusUpdate" @updateParent="getTasks(entityRecordMasterId)" @closeDialog="closeDialog" />
        <Dialog id="deleteTask" :onYes="onYes" :returnParams="deleteParam" title="Delete Confirmation" message="Are you sure to delete Task?" />
        <Dialog id="archiveTask" :onYes="onYesArchive" :returnParams="archiveParam" title="Confirmation" message="Are you sure to Archive the Task?" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs, between, numeric } from "vuelidate/lib/validators";
import { requestsFinance } from "@/mixins/requestsFinance";
import ActivityDetailPopup from '@/components/popup/itemdetail/ActivityDetailPopup.vue';
import ChecklistReportPopup from '@/components/popup/action/ChecklistReportPopup.vue';
import TaskStatusUpdatePopup from '@/components/popup/action/TaskStatusUpdatePopup.vue';
import carousel from 'vue-owl-carousel';
import { EventBus } from '@/main';

export default {
    mixins: [requestsFinance],
    components: {
        carousel,
        ActivityDetailPopup,
        ChecklistReportPopup,
        TaskStatusUpdatePopup
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,
            elements: {
                editMode: false,
                viewMode: false,
                viewOnlyComponent: false,
                filterMode: false,
                taskStatusUpdate: false,
                isHide: true,
                isDisable: true
            },
            carousel:false,
            showDialog: false,
            taskId: null,
            effortType: '',
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            userTypeCode: localStorage.getItem("userTypeCode"),
            entityRecordMasterId: '',

            filteredUserId: null,

            checklistTaskRef: {
                id: '',
                title: '',
                itemCount: '',
                checkeditemCount: '',
            },

            task: {
                id: null,
                taskId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.entityRecordMasterId,
                userId: localStorage.getItem("userId"),
                // ermEntity: 'task',
                entity: 'task',
                title: '',
                isArchive: false,
                planId: '',
                requirementId: '',
                requirement: '',
                activityId: '',
                activity: '',
                taskTypeId: '',
                type: '',
                description: '',
                priorityId: '',
                priority: '',
                startDate: '',
                endDate: '',
                estimatedStartDt: '',
                estimatedStartDate: this.getDateValue(new Date()),
                estimatedStartTime: this.getTimeValue(new Date()),
                estimatedEndDt: '',
                estimatedEndDate: this.getDateValue(new Date()),
                estimatedEndTime: this.getTimeValue(new Date()),
                assignedById: localStorage.getItem("userId"),
                assignedTo: '',
                assignedToId: '',
                effort: '',
                effortType: '1',
                progressPercentage: 0,
                statusId: '',
                status: '',
                hasReference: '',
                hasAttachment: '',
                hasComment: '',
                hasChecklist: '',
                entityId: '',
                refErmId: '',
                createdDt: '',
                lastUpdatedDt: '',
                taskAssignment: '',
                attachments: [],
                checkListTag: '',
                response: '',
                checklistList: [],
                checklistItemCount: '',
            },
            taskStatusData: {},

            statusUpdate: {
                id: '',
                taskId: '',
                title: '',
                entityRecordMasterId: '',
                progressPercentage: 0,
                statusId: '',
                status: '',
                activityId: '',
            },

            detailViewData: {},
            referencePopupId: 0,

            entity: 'development_plan_master',

            // effortTypes: [],
            effortTypes: [
                { name: "Man Hours", id: 1 },
                { name: "Man Days", id: 2 },
                { name: "Man Months", id: 3 },
                { name: "Man Years", id: 4 },
            ],

            allTasks: [],
            tasks: [],
            taskCheckList: [],
            taskCheckListAll: [],
            comments: [],
            addMoreAttachment: [],
            users: [],
            ermLookup: [],

            taskStatus: [],
            entityRecordMasters: [],
            search: '',
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 15, 20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            deleteParam: {
                id: 0
            },
            archiveParam: {
                id: 0,
                isArchive: false
            },
            toolbar: 'undo redo preview | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | weblinks',
            initObj: {
                height: 300,
                branding: false,
                menubar: true,
                plugins: ['link', 'paste', 'wordcount', 'preview', 'image imagetools'],
                paste_data_images: true,
                images_upload_url: 'postAcceptor.php',
                automatic_uploads: false,
                file_picker_types: 'image',
                relative_urls: false,
                remove_script_host: false,
            },
        }
    },
    computed: {
        filteredTasks() {
            return this.tasks.filter(task => {
                return task.title.toLowerCase().includes(this.search.toLowerCase())
            })
        },
        filteredStatus() {
            // return this.taskStatus.filter(status => (status.value != 'On Hold' && status.value != 'Cancelled'));
            return this.taskStatus;
        },
    },
    mounted() {
        this.$store.state.pageTitle = 'Task';
        this.$store.state.ermMasterInfo.title = "Development Plan";
        this.$store.state.ermMasterInfo.entity = 'development_plan_master';
        this.$store.state.ermMasterInfo.refEntity = 'task';

        this.lookupData();

        this.addMoreAttachment.push(this.addMoreAttachment.length + 1);
    },
    created() {
        EventBus.$on("changeERM", this.changeERM);
        EventBus.$on("refreshERMLookup", this.lookupData);
    },
    destroyed() {
        EventBus.$off("changeERM", this.changeERM);
        EventBus.$off("refreshERMLookup", this.lookupData);
        this.$store.state.ermLookup = [];
    },
    validations: {
        task: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            statusId: {
                required
            },
            progressPercentage: {
                between: between(0, 100),
                numeric
            },
            entityRecordMasterId: {
                required
            }
        },
    },
    methods: {
        toggleDiv: function() {
            this.elements.isHide = !this.elements.isHide;
            this.elements.isDisable = !this.elements.isDisable;
        },
        getTasks: function(ermId) {
            let data = {
                entityRecordMasterId: ermId,
                isArchive: false,
            }
            console.log(data);
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.tasks = result.taskList;
                    this.allTasks = this.tasks;
                    console.log(this.tasks);
                    this.$nextTick(() => {                            
                        if (this.elements.filterMode && this.filteredUserId) {
                            this.teamMemberFilter(this.filteredUserId);
                        } else this.clearFilter();
                    })
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: 'task'
            }
            this.$store.state.showAppLoader = true;
            axios.post(this.$store.getters.getAPIBasePath + '/task/lookup/all', data)
                .then((response) => {
                    this.$store.state.showAppLoader = false;
                    let result = response.data;
                    this.users = result.userLookup;
                    this.taskStatus = result.statusLookup;
                    this.ermLookup = result.entityERMLookup;
                    this.$store.state.ermLookup = result.entityERMLookup;
                    if(this.ermLookup && this.ermLookup.length > 0){
                        this.entityRecordMasterId = this.ermLookup[0].id;
                        this.$store.state.ermId = this.ermLookup[0].id;
                        localStorage.setItem('ermId', this.ermLookup[0].id);
                        this.getTasks(this.entityRecordMasterId);
                    }
                    else {
                        this.entityRecordMasterId = '';
                        this.$store.state.ermId = '';
                        localStorage.setItem('ermId', '');
                    }
                    this.task.statusId = this.taskStatus[0].statusLookupId;
                    this.taskStatusData = this.taskStatus[0];
                    this.carousel = true;
                    console.log(this.users);
                })
                .catch(error => {
                    this.$store.state.showAppLoader = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        changeERM: function(ermId) {
            console.log(ermId);
            if (ermId !== null && ermId) {
                this.getTasks(ermId);
                localStorage.setItem('ermId', ermId);
            }
        },
        archiveTask: function(item) {
            this.archiveParam.id = item.id;
            this.archiveParam.isArchive = item.isArchive;
        },
        onYesArchive: function(item) {
            let data = {
                id: this.archiveParam.id,
                entity: 'task',
                columnName: 'is_archive',
                stateValue: (this.archiveParam.isArchive == true ? false : true)
            }
            axios.post(this.$store.getters.getAPIBasePath + '/dynamicsql/recordstate/update', data)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Task Archived',
                            position: 'topRight'
                        });
                        this.getTasks(this.entityRecordMasterId);
                    }
                })
        },
        deleteTask: function(id) {
            console.log(id)
            this.deleteParam.id = id;
        },
        onYes: function() {
            axios.delete(this.$store.getters.getAPIBasePath + '/task/delete/' + this.deleteParam.id)
                .then(response => {
                    var result = response.data;
                    if (result.status == "OK") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.getTasks(this.entityRecordMasterId);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getTaskByStatusId(statusId) {
            return this.tasks.filter(task => task.statusId == statusId);
        },
        teamMemberFilter: function(userId) {
            this.elements.filterMode = true;
            this.tasks = this.allTasks;
            if (this.tasks != null) {
                var userTask = this.tasks;
                this.tasks = userTask.filter(function(e) {
                    return e.assignedToId == userId || e.assignedById == userId;
                });
            }
        },
        clearFilter: function() {
            this.tasks = this.allTasks;
            this.filteredUserId = null;
            this.elements.filterMode = false;
        },
        progressBarVariant: function(task) {
            if (task.progressPercentage != null) {
                if (task.progressPercentage < 25) {
                    return 'danger';
                } else if (task.progressPercentage < 50) {
                    return 'warning';
                } else if (task.progressPercentage < 75) {
                    return 'info';
                } else if (task.progressPercentage < 100) {
                    return 'success';
                } else if (task.progressPercentage == 100) {
                    return 'primary';
                }
            }
        },
        gatGanttChart: function() {
            if (this.task.entityRecordMasterId !== null) {
                this.$router.push('/taskgantt/' + this.task.entityRecordMasterId);
            }
        },
        dueDateStyling(date){
            date = new Date(date);
            let today = new Date();

            if (date && date !== null) {

                if (this.getDateValue(date) == this.getDateValue(today) && (date > today)) {        
                    return 'text-warning';
                }
                else if (date < today) {        
                    return 'text-danger';
                }
                else if (date > today) {        
                    return 'text-success';
                }
            }
        },
        priorityColour(priority) {
            if (priority && priority == 'High') {
                return 'text-danger';
            }
            if (priority && priority == 'Medium') {
                return 'text-warning';
            }
            else {
                return 'text-success';
            }
        },
        viewItemDetails: function(data) {
            data.estimatedStartDate = null;
            data.estimatedStartTime = null;
            data.estimatedEndDate = null;
            data.estimatedEndTime = null;
            localStorage.setItem("rowData", JSON.stringify(data));
            localStorage.setItem("ermId", data.entityRecordMasterId);
            this.$nextTick(() => {
                this.$router.push('/viewtsk');
            });
        },
        editTask: function(data) {
            data.estimatedStartDate = null;
            data.estimatedStartTime = null;
            data.estimatedEndDate = null;
            data.estimatedEndTime = null;
            localStorage.setItem("rowData", JSON.stringify(data));
            localStorage.setItem("ermId", data.entityRecordMasterId);
            this.$nextTick(() => {
                this.$router.push('/edittsk');
            });
        },
        viewProcessActivity: function(data) {
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.activityDetailPopupRef.getDetails(data.processId, data.activityId);
            })
        },
        showStatusPopup: function(data) {
            console.log(data);
            if (data && data !== null) {
                this.statusUpdate.id = data.id;
                this.statusUpdate.taskId = data.taskId;
                this.statusUpdate.title = data.title;
                this.statusUpdate.entityRecordMasterId = data.entityRecordMasterId;
                this.statusUpdate.statusId = data.statusId;
                this.statusUpdate.status = data.status;
                this.statusUpdate.activityId = data.activityId;
                this.statusUpdate.progressPercentage = data.progressPercentage ? data.progressPercentage : 0;

                this.showDialog = true;
                this.$nextTick(()=> {
                    this.$bvModal.show('taskSatatusUpdatePopup');
                })
            }
        },
        openTimeSheet: function(data) {
            this.taskId = data.id;
            this.showDialog= true;
            this.$nextTick(()=> {
                this.$refs.tiemSheetPopupRef.openDialog();
            })
        },
        openChecklistReportPopup(data) {
            let refEntity = 'task';
            this.checklistTaskRef.title = data.title +  ' - Checklist';
            this.$refs.checklistReportPopupRef.getChecklist(data.id, refEntity);
        },
        closeDialog: function() {
            console.log('closed');
            this.showDialog = false;
            // this.clearData();
        },
        setERMData: function(ermId,ermTitle) {
            localStorage.setItem('ermId', ermId);
            localStorage.setItem('ermTitle', ermTitle);
        },
        // findEffortType(typeId) {
        //     let type = this.effortTypes.find(eff => eff.id == typeId);
        //     return type ? type.name : '';
        // }
        // function ddlToolTipsRegion(ddlRegion) {  
        //     if (ddlRegion.value == 0) {  
        //         ddlRegion.title = "";  
        //     } else {  
        //         ddlRegion.title = "Selected Region is : " + ddlRegion.options[ddlRegion.selectedIndex].text;  
        //     }  
        // }  

    }
}
</script>