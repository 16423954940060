<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row">
                        <div class="col-8 pr-0">
                            <h2 class="pl-2 mb-0 titel-text">Contact</h2>
                        </div>
                        <div class="col-4 pl-0">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pb-1 px-3">
                            <p class="mx-0 mb-2 mt-0">
                                <h6><i class="fa fa-user pr-2" aria-hidden="true"></i>
                                    <input type="text" v-model=contact.contactName  @change="updateContact" class="view-form w-60" placeholder="Contact Name" name="contactName" :class="{ 'is-invalid': $v.contact.contactName.$error }">
                                </h6>
                                <label v-if="$v.contact.contactName.$error && !$v.contact.contactName.required" class="text-danger">Please Enter Contact Name</label>
                            </p>
                            <p class="mx-0 my-2">
                                <i class="fa fa-crosshairs pr-2 "></i> 
                                <input type="text" v-model=contact.position @change="updateContact" class="view-form w-60" placeholder="Position" name="position">
                            </p>
                            <h3 class="mx-0 mb-2 mt-0">Contact Info</h3>
                            <p class="mx-0 my-2">
                                <i class="fa fa-envelope-o font-weight-bold action-link pr-2" aria-hidden="true"></i>
                                <input type="text" v-model=contact.emailId  @change="updateContact" class="view-form w-60" placeholder="E-mail" name="emailId" :class="{ 'is-invalid': $v.contact.emailId.$error }">
                                <label v-if="$v.contact.emailId.$error && !$v.contact.emailId.required" class="text-danger">Please Fill E-mail</label>
                            </p>                              
                            <p class="mx-0 my-2">
                                <i class="fa fa-mobile font-weight-bold action-link pl-1 pr-2" aria-hidden="true"></i>
                                <input type="text" v-model=contact.mobile @change="updateContact" class="view-form w-60" placeholder="Mobile" name="mobile" :class="{ 'is-invalid': $v.contact.mobile.$error }"> 
                                <label v-if="$v.contact.mobile.$error && !$v.contact.mobile.required" class="text-danger">Please Fill Mobile Number</label>
                            </p>
                            <p class="mx-0 my-2">
                                <i class="fa fa-phone font-weight-bold action-link pr-2" aria-hidden="true"></i> 
                                <input type="text" v-model=contact.phone @change="updateContact" class="view-form w-60" placeholder="Phone" name="phone">
                            </p>
                        </div>
                        <div class="divider"></div>
                        <div class="profile_custom pb-1  px-3" >
                            <h3 v-b-modal.modal-1 ><i class="fa fa-map-marker ml-0 mr-2 action-link" aria-hidden="true"></i>Address Info</h3>
                            <div class="mx-0 mb-2 mt-0">
                                <span  v-b-modal.modal-1 v-if="contact.address1 ">{{contact.address1}}, </span>
                                <span v-b-modal.modal-1 v-if="contact.address2 ">{{contact.address2}}, </span>
                                <span v-b-modal.modal-1 v-if="contact.city ">{{contact.city}}, </span>
                                <span v-b-modal.modal-1 v-if="contact.state ">{{contact.state}}, </span>
                                <span v-b-modal.modal-1 v-if="contact.country ">{{contact.country}}</span>
                                <span v-b-modal.modal-1 v-if="contact.pincode ">{{contact.pincode}}, </span>
                                <span v-b-modal.modal-1 v-if="contact.website ">{{contact.website}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-1" title="Change address">
            <p  class="mx-0 my-2 inp">
                <input type="text" v-model=contact.address1 class="view-form w-60 "  placeholder="Address1" name="address1">
            </p>                                          
            <p  class="mx-0 my-2 inp">
                <input type="text" v-model=contact.address2 class="view-form w-60" placeholder="Address2" name="address2">
            </p>
            <p  class="mx-0 my-2 inp">
                <input type="text" v-model=contact.city class="view-form w-60" placeholder="City" name="city">
            </p>
            <p  class="mx-0 my-2 inp">
                <input type="text" v-model=contact.state class="view-form w-60" placeholder="State" name="state">
            </p>
            <div class="form-group">
                <label for="country">Country</label>
                <select v-model="contact.countryId" class="form-control">
                    <option value="">Select</option>
                    <option v-for="(info, index) in countries" :value="info.id">{{info.value}}</option>
                </select>
            </div>
            <p  class="mx-0 my-2 inp">
                <input type="text" v-model=contact.pincode class="view-form w-60" placeholder="Pincode" name="pincode">
            </p>
            <p  class="mx-0 my-2 inp">
                <input type="text" v-model=contact.website class="view-form w-60" placeholder="Website" name="website">
            </p>
            <template #modal-footer>
                <div class="w-100">
                    <button class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right" @click="$bvModal.hide('modal-1')">Cancel</button>
                    <button size="sm" @click="updateContact" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right mr-2">Update</button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";

export default {
    name: 'app',
    data() {
        return {
            contact: {},
            countries: [],
        }

    },
    validations: {
            contact: {
                contactName: {
                    required
                },
                emailId: {
                    required
                },
                mobile: {
                    required
                }
            }
        },

    mounted() {
        this.$store.state.editPageUrl = 'editcontact';
        this.contact = JSON.parse(localStorage.getItem("rowData"));
        this.lookupData();
    },
     methods: {
        lookupData: function() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            console.log(data)
            axios.post(this.$store.getters.getAPIBasePath + '/contact/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result)
                    this.countries = result.countryLookup;             
                })
        },
        updateContact: function() {
            this.$v.contact.$touch();
            if (!this.$v.contact.$invalid) {

                console.log(this.contact)
            axios.post(this.$store.getters.getAPIBasePath + '/contact/update', this.contact)
                .then((response) => {
                    console.log(this.contact);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: 'Contact info updated successfully',
                            position: 'topRight'
                        });
                        this.$bvModal.hide('modal-1')
                    }
                    localStorage.setItem("rowData", JSON.stringify(this.contact));
                    console.log(result);
                })
            }
        },
    }
}
</script>