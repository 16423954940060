<template>
    <div>
        <div class="wrapper">
            <Header :pageTitle="pageTitle" :enableSaveBtn="true" :enableBackBtn="true" />
            <div class="container_full">
                <main>
                    <slot />
                </main>
            </div>
            <Footer />
        </div>
    </div>
</template>
<script>
import Header from "@/components/NavigationHeader.vue";
import Footer from '@/layouts/blocks/FooterMenu';
export default {
    components: {
        Header,
        Footer,
    },
    computed: {
        pageTitle() {
            return this.$store.state.pageTitle;
        },
    }
 };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>