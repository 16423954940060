export const requestsRequirement = {
    methods: {
        addClientRequirement: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/requirement/userrequirement/add', data);
        },
    	addRequirementSpec: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/add', data);
        },
        addScopeStatement: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/scope/scopestatement/add', data);
        },
        getClientRequirement: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/requirement/userrequirement/list', data);
        },
        getRequirementSpec: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/list', data);
        },
        getClientRequirementLookup: function(data) {            
            return axios.post(this.$store.getters.getAPIBasePath + '/requirement/userrequirement/lookup/all', data);
        },
        getRequirementSpecLookup: function(data) {            
            return axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/lookup/all', data);
        },
        getScopeStatement: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/scope/scopestatement/list', data);
        },
        updateClientRequirement: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/requirement/userrequirement/update', data);
        },
        updateRequirementSpecs: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/requirement/requirementspec/update', data);
        },
        getScopeStatementLookup: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/scope/scopestatement/lookup/all', data);
        },
        updateScopeStatement: function(data) {
            return axios.post(this.$store.getters.getAPIBasePath + '/scope/scopestatement/update', data);
        },
    }
};