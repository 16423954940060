<template>
    <div>
        <ProjectTeamMasterList :ermMasterInfo="ermMasterInfo"></ProjectTeamMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ProjectTeamMasterList from '../../components/ermcomponents/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        ProjectTeamMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Project Teams",
                entity: 'project_team_master',
                refEntity: 'project_team',
                tooltip: 'New Project Team',
                redirectURLName: 'projectteam',
                newEntityMasterURL: 'prtmas',
                redirectURL: '/prtmas',
                actionName: 'Team Member',
                previewURLName: 'prtrep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>