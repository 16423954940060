<template>
    <div>
        <PlanMaster :ermMasterInfo="ermMasterInfo"></PlanMaster>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import PlanMaster from '@/components/ermcomponents/ERMMaster.vue'
export default {
    name: 'app',
    components: {
        PlanMaster,
    },
    data() {
        return {
            elements: {
                showDetail: false,
            },
            routePath: '',
            ermMasterInfo: {
                title: "Plan Master",
                entity: 'plan_master',
                refEntity: 'plan',
                redirectURL: '/plnmas/list/'
            }
        }
    },
    mounted() {
        this.routePath = this.$route.path;
        if (this.routePath != null && this.routePath.indexOf('client') == 1) {
            this.ermMasterInfo.redirectURL = '/client/plnmas/list';
        } else {
            this.ermMasterInfo.redirectURL = '/plnmas/list';
        }
    },
    methods: {}
}
</script>