<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12  col-md-6">
                            <div class="float-left">
                                <h1 class="text-dark titel-text">Project Document</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" show-empty small selectable :select-mode="selectMode" 
                                    sort-icon-left :items="projectDocuments" :fields="projectDocumentFields" responsive="sm" @row-selected="onRowSelected" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                        <template v-slot:cell(title)="data">
                                            <div :class="data.item.title" class="text-overflow-dots">
                                                <p >{{ data.value }} </p>
                                            </div>
                                        </template>
                                        
                                        <template v-slot:cell(type)="data">
                                            <div  class="text-overflow-dots">
                                                <span v-if="data.item.type" class="label label-primary mb-1 mr-1"> {{data.item.type}}</span>
                                            </div>
                                       </template>
                                        <template  v-slot:empty="scope">
                                            <div class="text-center">No Project Documents</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="projectDocuments.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" @click="$router.push('/addprojectdocument/' + entityRecordMasterId)" size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { requestsProject } from "@/mixins/requestsProject";
export default {
    mixins: [requestsProject],
    data() {
        return {
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            typeLookupTitle: 'Project Document Type Settings',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entityRecordMasterId: this.$route.params.ermId,
            entity: 'project_document',
            attachments: [],
            documentTypes: [],
            users: [],
            documentStatus: [],
            projectDocuments: [],
            projects: [],

            projectDocumentFields: [
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'type', label: 'Document Type', sortable: true, sortDirection: 'desc' },
            ],
            selectMode: 'single',
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
           
            totalRows: 1,
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })

        },

    },
    mounted() {

        this.getProjectDocument();
    },
    methods: {
        getProjectDocument: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/project/document/list', data)
                .then((response) => {
                    let result = response.data;
                    this.projectDocuments = result.projectDocumentList;
                    this.totalRows = this.projectDocuments.length;
                })
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        onRowSelected: function(items) {
            if (items != null) {
                let data = items. [0];
                if (data != undefined)
                    this.showView(data);
                this.$nextTick(() => {
                    this.$router.push('/viewIssue/' + data.id);
                });
            }
        },
    }
}
</script> 