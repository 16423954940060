<template>
    <div>
        <header class="main-header">
            <ol class="top-navbar-header">
                <li class="pl-0">
                    <a href="#" class="text-white p-2" @click.prevent="getPrevUrl()"><i class="fa fa-angle-left fa-lg-header pr-2" aria-hidden="true"></i></a>
                </li>
                <li class="position-absolute ml-4 pl-0">
                    <h2 class="pl-2 mb-0 titel-text text-white">{{ pageTitle }}</h2>
                </li>
                <li class="">
                    <router-link class="text-white p-2 fs-18" :to="{ name: editPageUrl}"><i class="fa fa-pencil"></i></router-link>
                    <a href="javascript:" class="text-white p-2 fs-18"  @click.prevent="triggerDelete"><i class="fa fa-trash-o p-1" aria-hidden="true"></i></a>
                </li>
            </ol>
            <!-- <div class="container_header">
                <div class="logo ">
                    <div class="row d-flex min-h pos-md-r">
                        <div class="col-6 pr-0 pl-0">
                            <div  class="icon_menu full_menu">
                                <a href="#" class="txt-light d-inline"><i class="fa fa-angle-left fa-2x"></i></a>
                            </div>
                            <a href="#" class="logo-img-section">
                                <span class="text-light">
                                    {{ pageTitle }}
                                </span>
                            </a>
                        </div>
                        <div class="col-6 pl-0">
                            <div class="right_bar_top align-items-end">
                                <ul class="viewpage-icons">
                                    <li class="txt-light d-inline">
                                        <router-link class="text-light" :to="{ name: editPageUrl}"><i class="fa fa-pencil"></i></router-link>
                                    </li>
                                    <li class="txt-light d-inline">
                                        <a href="#" class="txt-light d-inline" @click="triggerDelete"><i class="fa fa-trash-o"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </header>
    </div>
</template>
<script>
import { requestsMixin } from "@/mixins/requestsMixin";
import { EventBus } from '@/main';
export default {
    name: 'app',
    components: {},
    mixins: [requestsMixin],
    data() {
        return {
            elements: {
                showDetail: false,                
            },
            routePath: '',
            entityRecordMasterId: '',
        }
    },
    computed: {
        editPageUrl() {
            return this.$store.state.editPageUrl;
        },
        pageTitle() {
            return this.$store.state.pageTitle;
        },
        ermLookup() {
            return this.$store.state.ermLookup;
        }
    },
    mounted() {},
    methods: {
        changeERM() {
            EventBus.$emit('changeERM', this.entityRecordMasterId);
        },
        triggerDelete() {
            EventBus.$emit('triggerDelete');
        }
    }
}
</script>