<template>
    <div>
        <div class="sufee-login d-flex align-content-center flex-wrap">
            <div class="container">
                <div class="login-content">
                    <div class="login-form">
                        <template v-if="!isCodeSubmitted" >
                            <h6 class="text-center mb-3">Please enter the activation code.</h6>
                            <form>
                                <div class="form-group">
                                    <input type="text" v-model="activationKey" class="form-control" :class="{ 'is-invalid': $v.activationKey.$error }" placeholder="Enter Activation Code">
                                    <label v-if="$v.activationKey.$error && !$v.activationKey.required" class="text-danger">Activation Code is required.</label>
                                </div>
                                <button type="submit" @click.prevent="loadCheckAccessToken" class="btn btn-success btn-flat m-b-30 mt-3">Verify</button>
                                
                                <div class="register-link mt-3 text-center">
                                    <p>
                                        <a href="#" @click.prevent="" >Click here</a> to resend activation code
                                    </p>
                                </div>
                                <!-- <a href="/dashboard"> Dashboard</a> -->
                            </form>
                        </template>
                        <template v-else>
                            <h2 class="text-center">Create Password</h2>
                            <form v-if="showForm">
                                <div class="row pt-3">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>Password</label>
                                            <input type="password" v-model="activation.password" class="form-control" :class="{ 'is-invalid': elements.submitted && $v.activation.$error }" maxlength="45" size="30"  placeholder="Enter a new password">
                                            <label v-if="elements.submitted && $v.activation.password.$error && !$v.activation.password.minLength" class="text-danger">Password must have at least {{$v.activation.password.$params.minLength.min}} characters.</label>
                                            <label v-if="elements.submitted && $v.activation.password.$error && !$v.activation.password.required" class="text-danger">Password is required.</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <input type="password" v-model="activation.confirmPassword" maxlength="45" size="30" class="form-control" id="confirmPassword" placeholder="Confirm Password" :class="{ 'is-invalid': elements.submitted && $v.activation.confirmPassword.$error }">
                                            <label v-if="$v.activation.confirmPassword.$error && !$v.activation.confirmPassword.required">Confirm Password is required</label>
                                            <label v-else-if="$v.activation.confirmPassword.$error && !$v.activation.confirmPassword.sameAsPassword">Passwords must match</label>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" @click.prevent="submitActivation" class="btn btn-success btn-flat m-b-30 m-t-30">Submit</button>
                            </form>
                            <div v-if="!showForm" class="box-wrapper shadow-sm p-5 mb-4 bg-white rounded pos-rel">
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="col-sm-12 clientinfo">
                                            <p class="text-center">Your account has already activated</p>
                                            <p class="text-center">
                                                <router-link :to="{ name: 'login' }">Click here to login</router-link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import iziToast from 'izitoast';
export default {
    data() {
        return {
            isCodeSubmitted: false,
            showForm: true,
            elements: {
                submitted: false
            },
            activationKey: '',
            activation: {
                id: "",
                password: "",
                userTypeId: "",
            },
            userInfo: [],
            login: {
                loginName: '',
                password: ''
            },

        }
    },
    validations: {
        activationKey: {
            required,
        },
        activation: {
            password: {
                required,
                minLength: minLength(6)
            },
            confirmPassword: {
                required,
                sameAsPassword: sameAs('password')
            }
        }
    },
    mounted() {
        // this.loadCheckAccessToken(this.activationKey);
    },
    methods: {
        updatePassword() {
            let data = { 
                id: this.userInfo.userId, 
                password: this.activation.password
            };
            axios.post(this.$store.getters.getAPIBasePath + '/user/update/password', data)
                .then(response => {
                    this.performLogin();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        loadCheckAccessToken() {
            this.$v.activationKey.$touch();
            if (!this.$v.activationKey.$invalid) {
                let data = { 
                    activationKey: this.activationKey
                };
                this.$store.state.showAppLoader = true;
                axios.post(this.$store.getters.getAPIBasePath + '/user/checkaccesstoken', data)
                    .then(response => {
                        this.$store.state.showAppLoader = false;
                        this.isCodeSubmitted = true;
                        let result = response.data;

                        if (result.status) {
                            this.userInfo = result.user;
                        } else {
                            iziToast.info({
                                message: 'User already activated',
                                position: 'topRight'
                            });
                            this.showForm = false;
                        }
                    })
                    .catch(error => {
                        this.$store.state.showAppLoader = false;
                        console.log(error);
                    });
            }
        },
        submitActivation: function() {
            this.elements.submitted = true;
            this.$v.activation.$touch();
            if (!this.$v.activation.$invalid) {
                this.updatePassword();
            }
        },
        performLogin() {
            var container = $("#app-container");
            var allMenuClassNames = "menu-hidden sub-hidden main-hidden menu-sub-hidden main-show-temporary sub-show-temporary menu-mobile";
            container.removeClass(allMenuClassNames);
            this.login.loginName = this.userInfo.emailId;
            this.login.password = this.activation.password;
            this.$store.state.showAppLoader = true;
            axios.post(this.$store.getters.getAPIBasePath + '/user/authenticate', this.login)
                .then(response => {
                    this.$store.state.showAppLoader = false;
                    let result = response.data;
                    if (result.result) {
                        const defaultredirects = this.$store.getters.getDefaultRedirects;
                        this.$store.dispatch("setupLogin", result).then(() => {
                            if (result.data.userType == "CMR") {
                                this.$router.push({ path: defaultredirects.cmr });
                            } else if (result.data.userType == "PRM") {
                                this.$router.push({ path: defaultredirects.prm });
                            } else if (result.data.userType == "CLI") {
                                this.$router.push({ path: defaultredirects.cli });
                            } else if (result.data.userType == "TMB") {
                                this.$router.push({ path: defaultredirects.tmb });
                            } else if (result.data.userType == "VSA") {
                                this.$router.push({ path: defaultredirects.vsa });
                            } else {
                                this.$router.push({ path: defaultredirects.general });
                            }
                        });
                    } else {
                        iziToast.error({
                            title: 'Error Occured',
                            message: 'Invalid credentials provided',
                            position: 'topRight'
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.$store.state.showAppLoader = false;
                    iziToast.error({
                        title: 'Error Occured',
                        message: 'Please try again',
                        position: 'topRight'
                    });
                });
        }
    },

}
</script>