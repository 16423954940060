<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-8">
                            <h2 class="pl-2 mb-0 titel-text">Objective</h2>
                        </div>
                        <div class="col-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row px-2">
                <div class="card col-12 col-lg-4 col-xl-4 mb-4 ">
                    <div class="profile_header">
                        <div class="row">
                            <div class="col-12 col-lg-6 col-xl-6 text-left">
                                <div>
                                    <p class="mx-0 my-2">
                                        <span class="font-light"><i class="fa fa-list pr-2" aria-hidden="true"></i></span>
                                        <input type="text" v-model="objective.title" @change="updateObjectiveData" class="view-form w-50" placeholder="Title" name="title">
                                    </p>
                                    <p class="mx-0 my-2">
                                        <span class="font-light mr-2"><i class="fa fa-sitemap pr-1" aria-hidden="true"></i>Type</span>
                                        <select v-model="objective.objectiveTypeId" @change="updateObjectiveData" class="view-form w-50">
                                            <option value=""><span class="font-light">Select</span></option>
                                            <option v-for="(info, index) in objectiveTypes" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </p>
                                    <p class="mx-0 my-2">
                                        <span class="font-light"><i class="fa fa-calendar pr-2" aria-hidden="true"></i></span>
                                        <input type="date" v-model="objective.timeline" @change="updateObjectiveData" class="view-form w-50" placeholder="Timeline" name="objective">
                                    </p>
                                    <p class="mx-0 mt-2 mb-2">
                                        <textarea class="form-control" @change="updateObjectiveData" v-model="objective.objective" placeholder="Objective" rows="2">
                                        </textarea>
                                    </p>

                                    <p class="mx-0 my-2">
                                        <span class="font-light mr-2"><i class="fa fa-sitemap pr-1" aria-hidden="true"></i>Deliverables</span>
                                        <!-- <span v-if="objective.deliverable" v-for="deliverable in (JSON.parse(objective.deliverable))">{{deliverable.value}}, </span> -->
                                    </p>
                                    <p class="mx-0 my-2">
                                        <span class="font-light mr-2"><i class="fa fa-sitemap pr-1" aria-hidden="true"></i>Resp Person</span>
                                        <select v-model="objective.accountablePersonId" @change="updateObjectiveData" class="view-form w-40">
                                            <option value=""><span class="font-light">Select</span></option>
                                            <option v-for="(info, index) in users" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </p>
                                    <p class="mx-0 my-2">
                                        <span class="font-light mr-2"><i class="fa fa-sitemap pr-1" aria-hidden="true"></i>Status</span>
                                        <select v-model="objective.statusId" @change="updateObjectiveData" class="view-form w-40">
                                            <option value=""><span class="font-light">Select</span></option>
                                            <option v-for="(info, index) in objectiveStatus" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsMixin } from "@/mixins/requestsMixin";
import Multiselect from 'vue-multiselect';

export default {
    name: 'app',
    components: {
        Multiselect,
    },
    mixins: [requestsMixin],
    data() {
        return {
            objective: {},
            objectiveStatus: [],
            objectiveTypes: [],
            deliverables: [],
            users: [],


        }
    },
    mounted() {
        this.objective = JSON.parse(localStorage.getItem("rowData"));
        console.log(this.objective)
        this.lookupData();

    },
    methods: {
        async lookupData() {
            try {                
                let data = {
                    projectId: this.objective.projectId,
                    vhextAccountId: this.objective.vhextAccountId,
                    entity: 'objective',
                }
                const response = await this.getObjectiveLookup(data);
                let result = response.data;
                this.objectiveStatus = result.statusLookup;
                this.objectiveTypes = result.customTypeLookup;
                this.users = result.userLookup;
                this.deliverables = result.deliverableLookup;
            } catch(error) {
                console.log(error);
            }
        },
        async updateObjectiveData() {
            try {
                this.objective.isArchive = false
                this.objective.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.objective.projectId = localStorage.getItem("projectId");
                this.objective.entityRecordMasterId = this.$route.params.ermId;
                console.log(this.objective);
                const response = await this.updateObjective(this.objective);
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: 'Objective info Saved',
                        position: 'topCenter'
                    });
                    console.log('saved')
                }
                localStorage.setItem("rowData", JSON.stringify(this.objective));
            } catch (error) {
                console.log(error);
            }
        },


    }
}
</script>