<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12">
                            <div class="float-left">
                                <h2 class="text-dark titel-text">Activities</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" show-empty small selectable @row-selected="onRowSelected" :busy="isBusy" :select-mode="selectMode" 
                                    sort-icon-left :items="activities" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                    
                                        <template v-slot:cell(active)="data">
                                            <b-form-checkbox class="m-0" v-model="data.value" @change="assignActivationState(data.item)">
                                            </b-form-checkbox>
                                        </template>
                                        <template  v-slot:empty="scope">
                                            <div class="text-center">No Activities Found</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="activities.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" v-b-modal.modal-1 size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
        <b-modal id="modal-1" centered @hide="clearData" :title="elements.editMode ? 'Edit Activity' : 'Activity'">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.activity.activity.$error }">
                                <label for="activity" class="inp">
                                    <input type="text" id="activity" placeholder=" " v-model="activity.activity" class="mobile-form-control"  :class="{ 'is-invalid': $v.activity.activity.$error }">
                                    <span class="label">Activity</span>
                                    <span class="focus-bg"></span>
                                </label>
                            </div>
                            <label v-if="$v.activity.activity.$error && !$v.activity.activity.minLength" class="text-danger">Activity name must have at least {{$v.activity.activity.$params.minLength.min}} letters.</label>
                            <label v-if="$v.activity.activity.$error && !$v.activity.activity.required" class="text-danger">Please enter activity</label>
                            <label v-if="$v.activity.activity.$error && !$v.activity.activity.maxLength" class="text-danger">Activity name must not exceed {{$v.activity.activity.$params.maxLength.max}} letters.</label>
                        </div>
                    </div> 
                    <div class="col-12">
                        <div class="form-group">
                            <label for="active">Active</label>
                            <div class="input-group ">
                                <b-form-checkbox class="custom-toggle" v-model="activity.active" name="check-button" switch>
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div> 
                </div>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <button class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right" @click="$bvModal.hide('modal-1')">
                        Cancel
                    </button>
                    <button size="sm" :disabled="showOverlay" @click="saveData" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right mr-2">
                        {{elements.editMode ? 'Update' : 'Save'}}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsPlan } from "@/mixins/requestsPlan.js";

export default {
    mixins: [requestsPlan],
    data() {
        return {
            elements: {
                editMode: false,
            },
            showOverlay: false,
            isBusy: false,

            activities: [],
            activity: {
                id: '',
                entity: 'activity',
                activityId: '',
                createdById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                activity: '',
                active: true,
                description: '',
                createdBy: '',
            },
            entity: "activity",

            fields: [
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc', thClass: 'text-center', tdClass: 'text-center' },

            ],

            selectMode: 'single',
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
            totalRows: 1,
            notEnabled: true,

        }
    },
    mounted() {
        this.getActivities();
    },
    validations: {
        activity: {
            activity: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            }
        }
    },
    methods: {
        async getActivities() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            const response = await this.getActivity(data);
            console.log(response);
            let result = response.data;
            console.log(result);
            if (result.activityList && result.activityList.length != 0) {
                this.activities = result.activityList;
                
                this.totalRows = this.activities.length;
            }
        },
        saveData: function() {
            this.$v.activity.$touch();
            if (!this.$v.activity.$invalid) {
                if (!this.matchTextValExists(this.activity.activity, this.activity.id)) {
                    if (this.elements.editMode) {
                        this.updateActivities();
                    } else {
                        this.saveActivity();
                    }
                }
                else {
                    iziToast.info({
                        message: 'Activity already exist.',
                        position: 'topCenter'
                    });
                }
            }
        },
        async saveActivity() {
            try {                    
                this.showOverlay = true;
                const response = await this.addActivity(this.activity);
                this.showOverlay = false;
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.clearData();
                    this.getActivities();
                    this.$bvModal.hide('modal-1');
                }
            } catch (error) {
                this.showOverlay = false;
                console.log(error);
            }
        },
        async updateActivities() {
            try {
                this.showOverlay = true;
                const response = await this.updateActivity(this.activity);
                this.showOverlay = false;
                let result = response.data;
                if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getActivities();
                        this.$bvModal.hide('modal-1');
                    }
                } catch (error) {
                    this.showOverlay = false;
                    console.log(error);
            }
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.activity.entity = this.entity;
                this.activity.id = item.id;
                this.activity.active = 0;

                this.updateActivationState();
            } else {
                this.activity.entity = this.entity;
                this.activity.id = item.id;
                this.activity.active = 1;

                this.updateActivationState();
            }
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                console.log(data)
                this.elements.editMode = true;
                this.activity.id = data.id;
                this.activity.description = data.description;
                this.activity.active = data.active;
                this.activity.activity = data.activity;
                this.activity.activityId = data.activityId;
                this.activity.createdBy = data.createdBy;
                this.activity.createdById = data.createdById;
                this.activity.createdDt = data.createdDt;
                this.activity.lastUpdatedDt = data.lastUpdatedDt;
                this.activity.projectId = data.projectId;
                this.activity.vhextAccountId = data.vhextAccountId;
                this.$nextTick(() => {
                    this.$bvModal.show('modal-1');
                })
            }
        },
        async updateActivationState() {
            try {
                const response = await this.updateActivation(this.activity);
                this.isBusy = false;
                let result = response;
                if (result.data.status == 'CREATED') {
                    iziToast.success({
                        title: 'Updated',
                        position: 'topCenter'
                    });
                }
                // this.elements.showDetail = false;
                this.activity.active = true;
                this.getActivities();
            } catch (error) {
                this.isBusy = false;
                console.log(error);
            }
        },
        matchTextValExists: function(textVal, id) {
            let result = false;
            this.activities.map(data => {
                if (textVal && data.activity && data.id !== id) {
                    if (data.activity.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        clearData: function() {
            this.elements.editMode = false;
            this.activity.id = '';
            this.activity.activity = '';
            this.activity.active = true;
            this.activity.description = '';
            this.activity.createdById = localStorage.getItem("userId");
            this.$v.activity.$reset();
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>