import AddClientRequirement from '@/views/requirement/AddClientRequirement.vue'
import ClientRequirement from '@/views/requirement/ClientRequirement.vue'
import ClientRequirementMaster from '@/views/requirement/ClientRequirementMaster.vue'
import ClientRequirementMasterList from '@/views/requirement/ClientRequirementMasterList.vue'
import ViewClientRequirement from '@/views/requirement/ViewClientRequirement.vue'
import AddRequirementSpec from '@/views/requirement/AddRequirementSpec.vue'
import RequirementSpec from '@/views/requirement/RequirementSpec.vue'
import RequirementSpecMaster from '@/views/requirement/RequirementSpecMaster.vue'
import RequirementSpecMasterList from '@/views/requirement/RequirementSpecMasterList.vue'
import ViewRequirementSpec from '@/views/requirement/ViewRequirementSpec.vue'

export default [
{
        path: '/addclientrequirement/:ermId',
        name: 'addclientrequirement',
        component: AddClientRequirement,
        meta: { layout: 'addpage', accessedby: 'pm' }
    },
    {
        path: '/clientrequirement/:ermId',
        name: 'clientrequirement',
        component: ClientRequirement,
        meta: { accessedby: 'pm' }
    },
    {
        path: '/viewclientrequirement/:ermId',
        name: 'viewclientrequirement',
        component: ViewClientRequirement,
        meta: { layout: 'viewpage', accessedby: 'pm' }
    },
    {
        path: '/addrequirementspec/:ermId',
        name: 'addrequirementspec',
        component: AddRequirementSpec,
        meta: { layout: 'addpage', accessedby: 'pm' }
    },
    {
        path: '/reqspc/:ermId',
        name: 'reqspc',
        component: RequirementSpec,
        meta: { accessedby: 'pm' }
    },
    {
        path: '/srsmas',
        name: 'srsmas',
        component: RequirementSpecMaster,
        meta: { accessedby: 'VSA|CMR|PRM|TMB' }
    },
    {
        path: '/srsmas/list',
        name: 'srsmaslist',
        component: RequirementSpecMasterList,
        meta: { accessedby: 'VSA|CMR|PRM|TMB' }
    },
    {
        path: '/viewrequirementspec/:ermId',
        name: 'viewrequirementspec',
        component: ViewRequirementSpec,
        meta: { layout: 'viewpage', accessedby: 'pm' }
    },
    {
        path: '/urqmas',
        name: 'urqmas',
        component: ClientRequirementMaster,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/urqmas/list',
        name: 'urqmaslist',
        component: ClientRequirementMasterList,
        meta: { accessedby: 'PRM|TMB' }
    },
];