import ScopeStatementMaster from '@/views/scope/ScopeStatementMaster.vue'
import ScopeStatementMasterList from '@/views/scope/ScopeStatementMasterList.vue'
import AddScopeStatement from '@/views/scope/AddScopeStatement.vue'
import ScopeStatementList from '@/views/scope/ScopeStatementList.vue'
import ViewScopeStatement from '@/views/scope/ViewScopeStatement.vue'




export default [
    {
        path: '/scpmas',
        name: 'scpmas',
        component: ScopeStatementMaster,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/scpmas/list',
        name: 'scpmaslist',
        component: ScopeStatementMasterList,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/addscopestatement/:ermId',
        name: 'addscopestatement',
        component: AddScopeStatement,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/scopestatementlist/:ermId',
        name: 'scopestatementlist',
        component: ScopeStatementList,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/viewscopestatement/:ermId',
        name: 'viewscopestatement',
        component: ViewScopeStatement,
        meta: { layout: 'viewpage', accessedby: 'pm' }
    },
];