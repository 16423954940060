<template>
    <div>
        <div class="footer-menu">
            <b-overlay :show="showAppLoader" z-index="20000" opacity="0.4" rounded="sm">
                <div class="row no-gutters justify-content-center">
                    <div v-if="elements.showPRMMenu" v-for="(menu,index) in mainMenu" class="col-auto">
                        <a class="text-center" href="#" @click.prevent="activeMenuRoute = menu.route;routeToPage(menu)" :class="menu.route == activeMenuRoute ? 'active' : ''">
                            <i class="fa" :class="menu.icon"></i>
                            <p>{{menu.menu}}</p>
                        </a>
                    </div>
                    <div v-if="elements.showPRMMenu"  class="col-auto">
                        <div class="dropdown">
                            <a href="javascript:;" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown" data-close-others="true" aria-expanded="true"> <i class="fa fa-ellipsis-h"></i>
                                <p>More</p>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-default mobile-dropdown-menu">
                                <li v-for="(menu,index) in moreMenu">
                                    <a href="#" @click.prevent="routeToPage(menu)"> <i class="fa" :class="menu.icon"></i> <p>{{menu.menu}}</p></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="!elements.showPRMMenu" v-for="menu in footerMenu" class="col-auto">
                        <a href="#" @click.prevent="activeMenuRoute = menu.route;routeToPage(menu)" :class="menu.route == activeMenuRoute ? 'active' : ''"> 
                            <i class="fa" :class="menu.icon"></i>
                            <p>{{menu.menu}}</p>
                        </a>
                    </div>
                </div>
                <template #overlay>
                    <div class="">
                    </div>
                </template>
            </b-overlay>
        </div>
    </div>
</template>
<script>
export default {
    name: 'footer-menu',
    data() {
        return {
            // activeMenuLanding: '/prm/landing',
            activeMenuRoute: '',
            elements: {
                showPRMMenu:false,
            },
            footerMenu: [
                {id: 101, menu: 'Projects', icon: 'fa-home', route: '/prm/landing'},
                {id: 102, menu: 'Clients', icon: 'fa-user-o', route: '/prm/landing/client'},
                {id: 103, menu: 'Users', icon: 'fa-users', route: '/prm/landing/user'},
                {id: 104, menu: 'Resources', icon: 'fa-id-card', route: '/prm/landing/resource'},
                {id: 105, menu: 'Contacts', icon: 'fa-address-card-o', route: '/prm/landing/contact'},
            ],
            prmMenu: [
                {id: 101, menu: 'Home', icon: 'fa-home', route: '/prm/landing'},
                {id: 102, menu: 'Action Item', icon: 'fa-clock-o', route: '/actionitem'},
                {id: 103, menu: 'Dashboard', icon: 'fa-tachometer', route: '/dashboard'},
                {id: 104, menu: 'Task', icon: 'fa-id-card', route: '/task'},
                {id: 105, menu: 'Finance', icon: 'fa-money', route: '/fintxnbrd'},
                {id: 106, menu: 'Plan', icon: 'fa-id-card', route: '/plnmas/list'},
                {id: 107, menu: 'Budget Plan', icon: 'fa-calendar', route: '/bplmas/list'},
                {id: 108, menu: 'Scope', icon: 'fa-envelope-o', route: '/scpmas/list'},
                {id: 108, menu: 'Teams', icon: 'fa-users', route: '/prtmas/list'},
                {id: 109, menu: 'Client Requirement', icon: 'fa-user-o', route: '/urqmas/list'},
                {id: 110, menu: 'Requirement Spec', icon: 'fa-users', route: '/srsmas/list'},
                {id: 111, menu: 'Project Documents', icon: 'fa-id-card', route: '/pdcmas/list'},
                {id: 112, menu: 'Calendar', icon: 'fa-address-card-o', route: '/calevt'},
                {id: 112, menu: 'Issues', icon: 'fa-address-card-o', route: '/issmas/list'},
                {id: 113, menu: 'Settings', icon: 'fa-cog', route: '/settings'},
            ],
            
        }
    },
    watch: {
      '$route.path': {
          handler: function(path) { 
            if (path != null && path.indexOf('landing') != -1) {
                this.elements.showPRMMenu=false;
            } else {
                this.elements.showPRMMenu=true;
            }
          },
          deep: true,
          immediate: true
      },
    },
    computed: {
        mainMenu() {
            return this.prmMenu.filter((item, index) => index < 5);
        },
        moreMenu() {
            return this.prmMenu.filter((item, index) => index >= 5);
        },
        showAppLoader: {
			get() {
				return this.$store.state.showAppLoader;
			},
			set(val) {
				this.$store.state.showAppLoader = val;
			}
		}
    },
    mounted() {
        this.activeMenuRoute = this.$route.path;
    },
    methods: {
        routeToPage: function(menu) {
            let currentPath = this.$route.path;
            if (currentPath && currentPath !== menu.route) {
                this.$router.push(menu.route);
            }
        }
    }
}
</script>