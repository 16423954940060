import Company from '@/views/company/Company.vue'
import AddCompany from '@/views/company/AddCompany.vue'
import ViewCompany from '@/views/company/ViewCompany.vue'


export default [
	{
        path: '/company',
        name: 'company',
        component: Company,
        meta: { accessedby: 'pm' }
    }, 
    {
        path: '/company/add',
        name: 'addcompany',
        component: AddCompany,
        meta: { accessedby: 'pm' }
    },
    {
        path: '/viewcompany/:id',
        name: 'viewcompany',
        component: ViewCompany,
        meta: { accessedby: 'pm' }
    }

];