<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-8">
                            <h2 class="pl-2 mb-0 titel-text">Company</h2>
                        </div>
                        <div class="col-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row px-2">
                <div class="card col-12 col-lg-4 col-xl-4 mb-4 ">
                    <div class="profile_header">
                        <div class="row">
                            <div v-b-modal.modal-2 class="col-12 col-lg-6 col-xl-6 text-left">
                                <div>
                                    <h6 class="mb-0" v-b-modal.modal-2 v-if="company.companyName!=''">{{company.companyName}}  [{{company.industry}}]</h6>
                                    <p class="m-0">
                                        <span >{{company.shortName}}</span>
                                    </p>
                                    <p class="m-0">
                                        <span >{{company.contactName}}</span>
                                    </p>
                                    <!-- <p class="m-0">
                                        <span >{{company.emailId}}</span>
                                    </p> -->
                                    <p class="m-0">
                                        <span >{{company.mobile}}</span>
                                    </p>
                                    <p class="m-0">
                                        <span >{{company.phone}}</span>
                                    </p>

                                    
                                </div>
                            </div>
                        </div>
                        <div class="divider"></div>
                        <div class="profile_custom">
                            <h3 class="mt-3 mb-2">Contact Info</h3>
                            
                            <div class="pl-2 ">
                                <span class="font-light mr-2"><i class="fa fa-phone font-weight-bold action-link pr-1" aria-hidden="true"></i>Office Phone</span>
                                <input @change="updateCompanyData" type="text" v-model=company.officePhone class="view-form w-60" placeholder="Office Phone" name="officePhone">
                            </div>                        
                            <div class="pl-2 ">
                                <span class="font-light mr-2"><i class="fa fa-envelope-o font-weight-bold action-link pr-1" aria-hidden="true"></i>Email-Id</span>
                                <input @change="updateCompanyData" type="text" v-model=company.emailId class="view-form w-60" placeholder="Email-Id" name="emailId">   
                            </div>
                            <div class="pl-2 ">
                                <span class="font-light mr-2"><i class="fa fa-chrome font-weight-bold action-link pr-2" aria-hidden="true"></i>Website</span> 
                                <input @change="updateCompanyData" type="text" v-model=company.website class="view-form w-60" placeholder="Website" name="website">
                            </div>
                        </div>
                    </div>

                    <div class="divider"></div>
                    <div class="profile_custom">
                        <h3 v-b-modal.modal-3 class="mt-3 mb-2">Address Info</h3>
                        <div class="pl-2">
                            <p v-if="company.address1 != ''">
                                <i v-b-modal.modal-3 class="fa fa-map-marker ml-0 mr-2 action-link" aria-hidden="true"></i>
                                <span v-b-modal.modal-3 v-if="company.address1 ">{{company.address1}}</span>
                                <span v-b-modal.modal-3 v-if="company.streetName ">, {{company.streetName}}</span>
                                <span v-b-modal.modal-3 v-if="company.city ">, {{company.city}}</span>
                                <span v-b-modal.modal-3 v-if="company.state ">, {{company.state}}</span>
                                <span v-b-modal.modal-3 v-if="company.country ">, {{company.country}}</span>
                                <span v-b-modal.modal-3 v-if="company.pincode ">, {{company.pincode}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div v-b-modal.modal-1 class="profile_custom" v-if="company.website != null && company.facebookId !=''">
                        <h3 class="mt-3 mb-3">Social Connect</h3>
                        <div class="pl-2">
                            <p class="ml-0 mr-4" v-if="company.website != null && company.website !=''"><i class="fa fa-globe font-weight-bold action-link" aria-hidden="true"></i> {{company.website}}</p>
                            <p class="ml-0 mr-4" v-if="company.facebookId != null && company.facebookId !=''"><i class="fa fa-facebook-square font-weight-bold action-link" aria-hidden="true"></i> {{company.facebookId}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-2" title="Company Info">
            <div class="row">
                <div class="col-12">
                    <div class="form-group ">
                        <label for="companyName" class="inp">
                            <input type="text" id="companyName" placeholder=" " v-model=company.companyName class="mobile-form-control">
                            <span class="label">Company Name</span>
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div>                                            
                <div class="col-12">
                    <div class="form-group ">
                        <span class="font-light mr-2">Industry</span>
                        <select v-model="company.industryId" class="form-control" id="industryId">
                            <option value="">Industry</option>
                             <option v-for="(info, index) in industries" placeholder="Country" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group ">
                        <label for="shortName" class="inp">
                            <input type="text" id="shortName" placeholder=" " v-model=company.shortName class="mobile-form-control">
                            <span class="label">Short Name</span>
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group ">
                        <label for="contactName" class="inp">
                            <input type="text" id="contactName" placeholder=" " v-model=company.contactName class="mobile-form-control">
                            <span class="label">Contact Name</span>
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group ">
                        <label for="mobile" class="inp">
                            <input type="text" id="mobile" placeholder=" " v-model=company.mobile class="mobile-form-control">
                            <span class="label">Mobile</span>
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group ">
                        <label for="phone" class="inp">
                            <input type="text" id="phone" placeholder=" " v-model=company.phone class="mobile-form-control">
                            <span class="label">Phone</span>
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div>  
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <button class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right" @click="$bvModal.hide('modal-2')">
                        Cancel
                    </button>
                    <button size="sm" @click="updateCompanyData" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right mr-2">
                        Update
                    </button>
                </div>
            </template>
        </b-modal>
        <b-modal id="modal-3" title="Address Info">
            <div class="row">
                <div class="col-12">
                    <div class="form-group ">
                        <label for="address1" class="inp">
                            <input type="text" id="address1" placeholder=" " v-model=company.address1 class="mobile-form-control">
                            <span class="label">Address-1</span>
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group ">
                        <label for="streetName" class="inp">
                            <input type="text" id="streetName" placeholder=" " v-model=company.streetName class="mobile-form-control">
                            <span class="label">street Name</span>
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group ">
                        <label for="city" class="inp">
                            <input type="text" id="city" placeholder=" " v-model=company.city class="mobile-form-control">
                            <span class="label">City</span>
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group ">
                        <label for="state" class="inp">
                            <input type="text" id="state" placeholder=" " v-model=company.state class="mobile-form-control">
                            <span class="label">State</span>
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group ">
                        <label class="font-light mr-2">Country</label>
                        <select v-model="company.countryId" class="form-control" id="countryId">
                            <option value="">Country</option>
                             <option v-for="(info, index) in countries" placeholder="Country" :value="{id: info.id, text:info.value}">{{info.value}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group ">
                        <label for="pincode" class="inp">
                            <input type="text" id="pincode" placeholder=" " v-model=company.pincode class="mobile-form-control">
                            <span class="label">Pincode</span>
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <button class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right" @click="$bvModal.hide('modal-3')">
                        Cancel
                    </button>
                    <button size="sm" @click="updateCompanyData" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right mr-2">
                        Update
                    </button>
                </div>
            </template>
        </b-modal>
        <b-modal id="modal-1" title="Social Connect">
            <div class="row">
                <div class="col-12">
                    <div class="form-group ">
                        <label for="website" class="inp">
                            <input type="text" id="website" placeholder=" " v-model=company.website class="mobile-form-control">
                            <span class="label">Website</span>
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div> 
                <div class="col-12">
                    <div class="form-group ">
                        <label for="facebookId" class="inp">
                            <input type="text" id="facebookId" placeholder=" " v-model=company.facebookId class="mobile-form-control">
                            <span class="label">FacebookId</span>
                            <span class="focus-bg"></span>
                        </label>
                    </div>
                </div> 
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <button class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right" @click="$bvModal.hide('modal-1')">
                        Cancel
                    </button>
                    <button size="sm" @click="updateCompanyData" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right mr-2">
                        Update
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsMixin } from "@/mixins/requestsMixin";

export default {
    name: 'app',
    mixins: [requestsMixin],
    data() {
        return {
            company: {},
            industries: [],
            companyType: [],
            users: [],
            countries: [],

        }
    },
    mounted() {
        this.$store.state.editPageUrl = 'editcompany';
        this.company = JSON.parse(localStorage.getItem("rowData"));
        console.log(this.company);
        this.lookupData();

    },
    methods: {
        async lookupData() {
            try {
                let data = {
                    vhextAccountId: localStorage.getItem("vhextAccountId"),
                    projectId: localStorage.getItem("projectId")
                }
                const response = await this.getCompanyLookup(data);
                let result = response.data;
                this.industries= result.industryLookup;                   
                this.companyType = result.customTypeLookup;
                this.users = result.userLookup;
                this.countries = result.countryLookup;
            } catch (error) {
                console.log(error);
            }
        },

        async updateCompanyData() {
            try {
                this.company.isArchive = false
                this.company.vhextAccountId = localStorage.getItem("vhextAccountId");
                this.company.projectId = localStorage.getItem("projectId");
                console.log(this.company);
                const response = await this.updateCompany(this.company);
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topCenter'
                    });
                    console.log('saved')
                }
                localStorage.setItem("rowData", JSON.stringify(this.company));
            } catch (error) {
                console.log(error);
            }
        },
    }
}
</script>