import AccountHead from '@/views/finance/AccountHead.vue'
import FinTxnBoard from '@/views/finance/FinTxnBoard.vue'
import AddFinancialTxn from '@/views/finance/AddFinancialTxn.vue'
import FinancialTxn from '@/views/finance/FinancialTxn.vue'
import ViewFinancialTxn from '@/views/finance/ViewFinancialTxn.vue'
import EditFinancialTxn from '@/views/finance/EditFinancialTxn.vue'
import BudgetPlanMaster from '@/views/finance/BudgetPlanMaster.vue'
import BudgetPlanMasterList from '@/views/finance/BudgetPlanMasterList.vue'
import AddBudgetPlan from '@/views/finance/AddBudgetPlan.vue'
import BudgetPlan from '@/views/finance/BudgetPlan.vue'
import ViewBudgetPlan from '@/views/finance/ViewBudgetPlan.vue'

export default [

    {
        path: '/acchead',
        name: 'acchead',
        component: AccountHead,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/fintxnbrd',
        name: 'fintxnbrd',
        component: FinTxnBoard,
        meta: { layout: 'ermpage', accessedby: 'pm' }
    },
    {
        path: '/addfinancialtxn',
        name: 'addfinancialtxn',
        component: AddFinancialTxn,
        meta: { layout: 'addpage', accessedby: 'pm' }
    },
    {
        path: '/financialtxn/:ermId',
        name: 'financialtxn',
        component: FinancialTxn,
        meta: { accessedby: 'pm' }
    },
    {
        path: '/viewfinancialtxn',
        name: 'viewfinancialtxn',
        component: ViewFinancialTxn,
        meta: { layout: 'viewpage', accessedby: 'pm' }
    },
    {
        path: '/editfinancialtxn',
        name: 'editfinancialtxn',
        component: EditFinancialTxn,
        meta: { layout: 'addpage', accessedby: 'pm' }
    },
    {
        path: '/bplmas',
        name: 'bplmas',
        component: BudgetPlanMaster,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/bplmas/list',
        name: 'bplmaslist',
        component: BudgetPlanMasterList,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/addbudgetplan/:ermId',
        name: 'addbudgetplan',
        component: AddBudgetPlan,
        meta: { layout: 'addpage', accessedby: 'pm' }
    },
    {
        path: '/budgetplan/:ermId',
        name: 'budgetplan',
        component: BudgetPlan,
        meta: { accessedby: 'pm' }
    },
    {
        path: '/viewbudgetplan/:ermId',
        name: 'addbudgetplan',
        component: ViewBudgetPlan,
        meta: { layout: 'viewpage', accessedby: 'pm' }
    },
];