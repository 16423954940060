<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-8">
                            <h2 class="pl-2 mb-0 titel-text">Client Requirement</h2>
                        </div>
                        <div class="col-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pb-1 px-3">
                            <p class="mx-0 mb-2 mt-0">
                                <span class="font-light mr-2">Title</span>
                                <input type="text" id="title" v-model="userRequirement.title" @change="updateUserRequirement" class="view-form w-50  " 
                                 >
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Require.Type</span>
                                <select v-model="userRequirement.userRequirementTypeId" @change="updateUserRequirement" class="view-form w-50">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in requirementTypes" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                            <p  class="mx-0 my-2 ">
                                <span class="font-light mr-2">Requirement</span>
                                <textarea type="text" v-model="userRequirement.userRequirement" @change="updateUserRequirement" class="view-form w-100" placeholder="Requirement" name="userRequirement"></textarea>
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2 ">Priority</span>
                                <select v-model="userRequirement.priorityId" @change="updateUserRequirement" class="view-form w-50">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in requirementPriorities" :value="info.id">{{info.value}}</option>
                                </select>
                            </p> 
                            <p  class="mx-0 my-2">
                                <span class=" font-light mr-2">Due Date</span>
                                <input type="date" v-model="userRequirement.dueDate" @change="updateUserRequirement" class=" pl-2 view-form w-60" placeholder="DueDate" name="dueDate">
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Status</span>
                                <select v-model="userRequirement.statusId" @change="updateUserRequirement" class="view-form w-50">
                                    <option value=""><span class="font-light">Select</span></option>
                                    <option v-for="(info, index) in requirementStatus" :value="info.id">{{info.value}}</option>
                                </select>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsRequirement } from "@/mixins/requestsRequirement";

export default {
    mixins: [requestsRequirement],
    name: 'app',
    data() {
        return {
            userRequirement: {},
            requirementTypes: [],
            requirementStatus: [],
            requirementPriorities: [],
            entity:'user_requirement',
        }

    },
    mounted() {
        this.userRequirement = JSON.parse(localStorage.getItem("rowData"));
        this.lookupData();
    },
     methods: {
         async lookupData() {
            try {
            let data = {
    
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                entity: this.entity,
                
            }
            const response = await this.getClientRequirementLookup(data);
                    let result = response.data;
                    console.log(this.requirementStatus)
                    this.requirementTypes = result.customTypeLookup;
                    this.requirementStatus = result.statusLookup;
                    this.requirementPriorities = result.priorityLookup;
                }catch (error){
                    console.log(error);
                }
        },
        async updateUserRequirement() {
            try {
                const response = await this.updateClientRequirement(this.userRequirement);
                    console.log(this.userRequirement);
                    let result = response.data;
                    if (result.status == 'CREATED') {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                    localStorage.setItem("rowData", JSON.stringify(this.userRequirement));
                    console.log(result);
                }catch (error){
                console.log(error);
            }
        },
    }
}
</script>