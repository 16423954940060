<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <!-- <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-8">
                            <h2 class="pl-2 mb-0 titel-text">Human Resource</h2>
                        </div>
                        <div class="col-4">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-1" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70">
                        <div class="card-body pt-4 pb-4 pl-3 pr-3">
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Name</span>
                                <input type="text" v-model="humanResource.name" @change="updateResourceData" class="view-form w-80" placeholder="User Name" name="name">
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Position</span>
                                <input type="text" v-model="humanResource.position" @change="updateResourceData" class="view-form w-60" placeholder="User Name" name="position">
                            </p>
                            <p  class="mx-0 my-2">
                                <span class="font-light mr-2">Qualification</span>
                                <input type="text" v-model="humanResource.qualification" @change="updateResourceData" class="view-form w-50" placeholder="User Name" name="qualification">
                            </p>
                            <p class="mx-0 my-2">
                                <label v-if="humanResource != null && humanResource.emailId != null && humanResource.emailId.length != 0" class="ml-0 mr-2"><i class="fa fa-envelope-o font-weight-bold action-link pr-1" aria-hidden="true"></i> {{humanResource.emailId}}</label>
                            </p>
                            <p class="mx-0 my-2">
                                <i class="fa fa-phone font-weight-bold action-link pr-1" aria-hidden="true"></i>
                                <input type="text" v-model="humanResource.mobile" @change="updateResourceData" class="view-form w-80" placeholder="Phone" name="mobile">
                            </p>
                            <p class="mx-0 my-1 no-outline">
                                <span v-b-modal.modal-1 class="font-light no-outline mr-2"><i class="fa fa-clock-o pr-2" aria-hidden="true"></i>Experience</span>
                                <span v-b-modal.modal-1 v-if="humanResource.experienceYears" class="no-outline">
                                    {{ humanResource.experienceYears }} {{ humanResource.experienceYears == 1 ? 'year' : 'years'}}
                                </span>
                                <span v-b-modal.modal-1 v-if="humanResource.experienceMonths" class="no-outline">
                                     {{ humanResource.experienceMonths }} {{ humanResource.experienceMonths == 1 ? 'month' : 'months'}}
                                </span>
                            </p>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modal-1" title="Experience">
            <div class="row">
                <div class="col-6">
                    <label>Years</label>
                    <select v-model="humanResource.experienceYears" class="form-control">
                        <option value="">0</option>
                        <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                    </select>
                </div>                                            
                <div class="col-6 pl-0">
                    <label>Months</label>
                    <select v-model="humanResource.experienceMonths" class="form-control">
                        <option value="">0</option>
                        <option v-for="(info, index) in monthList" :value="info.id">{{info.name}}</option>
                    </select>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import month from '@/json/month.json';
import Multiselect from 'vue-multiselect';
import { requestsMixin } from "@/mixins/requestsMixin";

export default {
    name: 'app',
    mixins: [requestsMixin],

    components: {
        Multiselect
    },
    data() {
        return {
        	monthList: month,

            humanResource: {},

            entity: 'humanResource',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            activityLookupTitle: 'Activity Settings',


        }

    },
    mounted() {
        this.humanResource = JSON.parse(localStorage.getItem("rowData"));
    },
    methods: {
        async updateResourceData() {
            try {
                console.log(this.humanResource);
                const response = await this.updateResouce(this.humanResource);
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topCenter'
                    });
                    localStorage.setItem("rowData", JSON.stringify(this.humanResource));
                }
            } catch (error) {
                console.log(error);
            }
        },
    },

    
}

</script>