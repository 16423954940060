<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 px-2 pt-0 pb-2">
                    <div class="row ">
                        <div class="col-6">
                            <h2 class="pl-2 mb-0 titel-text">New Project Document</h2>
                        </div>
                        <div class="col-6">
                            <nav class="breadcrumb-container d-sm-block d-lg-inline-block float-right" aria-label="breadcrumb">
                                <ol class="breadcrumb pt-0 pb-0 mb-0">
                                    <li class="breadcrumb-item">
                                        <a href="javascript:" @click="getPrevUrl()"><i class="fa fa-reply pr-2" aria-hidden="true"></i>Back</a>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chart_section row">
                <div class="col-lg-8 col-xl-8 px-2">
                    <div class="card mb-70 ">
                        <div class="card-body pt-4 pb-4 pl-4 pr-4">
                            <div class="row mb-2">
                                <div class="row mb-2">
                                    <div class="col-12 mt-0">
                                        <div class="form-group">
                                            <div class="required-field-block" :class="{ 'is-invalid': $v.projectDocument.title.$error }">
                                                <label for="title" class="inp">
                                                    <input type="text" id="title" placeholder="  " v-model="projectDocument.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.projectDocument.title.$error }" >
                                                    <span class="label">Title</span>
                                                </label>
                                                <label v-if="$v.projectDocument.title.$error && !$v.projectDocument.title.minLength" class="text-danger">Title must have at least {{$v.projectDocument.title.$params.minLength.min}} letters.</label>
                                                <label v-if="$v.projectDocument.title.$error && !$v.projectDocument.title.required" class="text-danger">Please enter Title</label>
                                                <label v-if="$v.projectDocument.title.$error && !$v.projectDocument.title.maxLength" class="text-danger">Title must not exceed {{$v.projectDocument.title.$params.maxLength.max}} letters.</label>
                                            </div>   
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="duration">Document Type</label>
                                            <a :title="typeLookupTitle" class="setting-icon ml-2 " href="javascript:" v-b-modal.typeModal><i class="fa fa-cog" aria-hidden="true"></i></a>
                                            <select v-model="projectDocument.documentTypeId" class="form-control">
                                                <option value="">Select</option>
                                                <option v-for="(info, index) in documentTypes" :value="info.id">{{info.value}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div  class="col-12">
                                        <div class="form-group">
                                            <div>
                                                <label>Attachment </label>
                                                <a href="javascript:" class="float-right button-add" @click="addInput"><i class="fa fa-plus-circle pr-2"></i>Add More</a>
                                            </div>
                                            <div id="Multi-attachment" v-for="(input, index) in addMore">
                                                <p class="attachments mb-2 ">
                                                    <b-form-file class="attachments-list" size="sm" v-model="projectDocument.attachments[index]" placeholder="Choose a file.">
                                                        <template slot="file-name" slot-scope="{ names }">
                                                            <b-badge variant="secondary">{{ names[0] }}</b-badge>
                                                        </template>
                                                    </b-form-file>
                                                    <a href="javascript:" @click="removeFile(index)" class="float-right button-remove p-2 attachments-remove"><i class="fa fa-minus-circle pr-2"></i></a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:" @click="saveData" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-floppy-o pr-2"></i>Save</a>
                            <a href="javascript:" @click="clearData" class="btn btn-info mb-0 pt-1 pb-1 pl-3 pr-3 mt-3"><i class="fa fa-times pr-2"></i>Cancel</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
// import TypeLookup from '@/components/blocks/lookup/TypeLookup.vue'
import { requestsProject } from "@/mixins/requestsProject";
export default {
    mixins: [requestsProject],
    name: 'app',
    // components: {
    //     TypeLookup,
    // },

    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                visible: false
            },
            addMore: [],
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            typeLookupTitle: 'Project Document Type Settings',
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entityRecordMasterId: this.$route.params.ermId,
            attachments: [],
            projectDocument: {
                title: '',
                documentTypeId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                uploadedById: localStorage.getItem("userId"),
                entity: 'project_document',
                hasAttachment: '',
                statusId: '',
                description: '',
                attachments: []
            },
       

            notEnabled: true,
            documentTypes: [],
            users: [],
            documentStatus: [],
            projectDocuments: [],
            projects: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
        }

    },
    mounted() {
        this.$store.state.pageTitle = 'New Document';
        this.lookupData();
        this.addMore.push(this.addMore.length + 1);
    },
    validations: {
        projectDocument: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            }
        }
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.projectDocument.vhextAccountId,
                projectId: this.projectDocument.projectId,
                entity: this.projectDocument.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/project/document/lookup/all', data)
                .then((response) => {
                    let result = response.data;

                    this.documentTypes = result.customTypeLookup;
                    this.users = result.userLookup;
                    this.documentStatus = result.statusLookup;
                    console.log(result);
                })

        },
        saveData: function() {
            this.$v.projectDocument.$touch();
            if (!this.$v.projectDocument.$invalid) {
                if (this.elements.editMode) {
                    this.updateProjectDocument();
                } else {
                    this.saveProjectDocument();
                }
            }
        },
        saveProjectDocument: function() {
            console.log(this.projectDocument);
            axios.post(this.$store.getters.getAPIBasePath + '/project/document/add', this.projectDocument)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {

                        this.showMessage();
                        if (this.projectDocument.attachments != null && this.projectDocument.attachments.length > 0)
                            this.submitFiles(result.id);
                        else
                            this.clearData();
                        this.$router.push('/projectdocument/' + this.entityRecordMasterId );
                    }
                })
        },
        clearData: function() {
            this.elements.editMode = false;
            this.elements.showDetail = false;
            this.elements.visible = false;
            this.projectDocument.title = '';
            this.projectDocument.documentTypeId = '';
            this.projectDocument.preparedById = '';
            this.projectDocument.hasAttachment = '';
            this.projectDocument.status = '';
            this.projectDocument.description = '';
            this.projectDocument.id = '';
            this.projectDocument.attachments = [];
            this.addMore = [];
            this.addMore.push(this.addMore.length + 1);
            this.$v.projectDocument.$reset();
        },
         addInput: function() {
            this.addMore.push(this.addMore.length + 1)
        },
        removeFile: function(index) {
            console.log(index)
            this.projectDocument.attachments.splice(index, 1);
        },
        submitFiles: function(projectDocumentId) {
            if (this.elements.editMode && this.projectDocument.attachments.length == 0) {
                iziToast.info({
                    message: 'Please choose file.',
                    position: 'topRight'
                });
                return;
            }

            let formData = new FormData();
            for (const row of Object.keys(this.projectDocument.attachments)) {
                formData.append('files', this.projectDocument.attachments[row]);
            }
            formData.append('vhextAccountId', this.projectDocument.vhextAccountId);
            formData.append('attachedById', localStorage.getItem("userId"));
            formData.append('projectId', this.projectDocument.projectId);
            formData.append('refEntity', this.projectDocument.entity);
            formData.append('refRecordId', projectDocumentId);
            this.clearData();
            axios.post(this.$store.getters.getAPIBasePath + '/attachment/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.getAttachment(projectDocumentId);
                    console.log('Attachment uploaded');
                })
                .catch(error => {
                    console.log('Error Attachment uploaded');
                });
        },
        showMessage: function() {
            iziToast.success({
                title: 'Success',
                message: 'Project Document Created Successfully.',
                position: 'topRight'
            });
            
        },
    }
}
</script>