<template>
	<div>
		<ol class="top-navbar-header">
            <li class="pl-0">
                <a href="#" class="text-white p-2" @click.prevent="getPrevUrl()"><i class="fa fa-angle-left fa-lg-header pr-2" aria-hidden="true"></i></a>
            </li>
            <li class="position-absolute ml-4 pl-0">
                <h2 class="pl-2 mb-0 titel-text text-white">{{ pageTitle }}</h2>
            </li>
            <li class="">
                <a v-if="enableSaveBtn" href="javascript:" class="text-white p-2 fs-16" @click="savePage"><i class="fa fa-check pr-1" aria-hidden="true"></i>Save</a>
            </li>
        </ol>
	</div>
</template>
<script>
import { EventBus } from '@/main';
export default {
	name: 'navigation-header',
	props: {
		pageTitle: String,
		enableSaveBtn: Boolean,
	},
    methods: {
        savePage() {
            EventBus.$emit('savePage');
        },
    }
}
</script>