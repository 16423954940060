<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12  col-md-6">
                            <div class="float-left">
                                <h1 class="text-dark">Technology Skill</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" show-empty small selectable @row-selected="onRowSelected" :busy="isBusy" :select-mode="selectMode" 
                                    sort-icon-left :items="technologySkills" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                        <template v-slot:cell(technology)="data">
                                            <div  class="">
                                                <p>{{ data.item.technology }} 
                                                    <!-- <span v-if="data.item.skills" class="fs-small"> - {{ data.item.skills }}</span> -->
                                                </p>
                                                <p class="fs-small">{{ data.item.skills }}</p>
                                            </div>
                                        </template>
                                        <template v-slot:cell(active)="data">
                                            <b-form-checkbox class="ml-3" v-model="data.value" @change="assignActivationState(data.item)">
                                            </b-form-checkbox>
                                        </template>
                                        <template  v-slot:empty="scope">
                                            <div class="text-center">No Technology Skill Found</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="technologySkills.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" v-b-modal.modal-1 size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
        <b-modal id="modal-1" centered @hide="clearData" :title="elements.editMode ? 'Edit TechnologySkill' : 'TechnologySkill'"
>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <div class="required-field-block" :class="{ 'is-invalid': $v.technologySkill.technology.$error }">
                            <label for="technology" class="inp">
                                <input type="text" id="technology" placeholder=" " v-model="technologySkill.technology" class="mobile-form-control"  :class="{ 'is-invalid': $v.technologySkill.technology.$error }">
                                <span class="label">Technology</span>
                                <span class="focus-bg"></span>
                            </label>
                        </div>
                        <label v-if="$v.technologySkill.technology.$error && !$v.technologySkill.technology.minLength" class="text-danger">Technology name must have at least {{$v.technologySkill.technology.$params.minLength.min}} letters.</label>
                        <label v-if="$v.technologySkill.technology.$error && !$v.technologySkill.technology.required" class="text-danger">Please enter technology</label>
                        <label v-if="$v.technologySkill.technology.$error && !$v.technologySkill.technology.maxLength" class="text-danger">Technology name must not exceed {{$v.technologySkill.technology.$params.maxLength.max}} letters.</label>
                    </div>
                </div>
                <div class="col-12">
                    <label for="skillSet">Skill Set</label>
                    <b-form-tags input-id="tags-separators" v-model="technologySkill.skillSet" separator="," placeholder="Enter skill set" ></b-form-tags>
                </div>  
                <div class="col-12">
                    <div class="form-group">
                        <label for="active">Active</label>
                        <div class="input-group ">
                            <b-form-checkbox class="custom-toggle" v-model="technologySkill.active" name="check-button" switch>
                            </b-form-checkbox>
                        </div>
                    </div>
                </div> 
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <button class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right" @click="$bvModal.hide('modal-1')">
                        Cancel
                    </button>
                    <button size="sm" @click="saveData" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right mr-2">
                        {{elements.editMode ? 'Update' : 'Save'}}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { requestsResource } from "@/mixins/requestsResource.js";

export default {
    mixins: [requestsResource],
    data() {
        return {
            elements: {
                editMode: false,
            },
            isBusy: false,

            technologySkills: [],
            technologySkill: {
                technologySkillId: '',
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                preparedById: '',
                technology: '',
                skills: '',
                skillSet: [],
                active: '',
                createdDt: '',
                lastUpdatedDt: '',

            },
            entity: "technology_skill",

            fields: [
                { key: 'technology', label: 'Technology', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc' },

            ],

            selectMode: 'single',
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
            totalRows: 1,
            notEnabled: true,

        }
    },
    mounted() {
        this.getTechnologySkills();
    },
    validations: {
        technologySkill: {
            technology: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            }
        }
    },
    methods: {
        async getTechnologySkills() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
            }
            const response = await this.getTechnologySkill(data);
            console.log(response);
            let result = response.data;
            console.log(result);
            if (result.technologySkillList && result.technologySkillList.length != 0) {
                this.technologySkills = result.technologySkillList;
                
                this.totalRows = this.technologySkills.length;
            }
        },
        saveData: function() {
            this.$v.technologySkill.$touch();

            this.technologySkill.skills = this.arrayToString(this.technologySkill.skillSet);

            if (!this.$v.technologySkill.$invalid) {
                if (this.elements.editMode) {
                    this.updateTechnologySkills();
                } else {
                    this.saveTechnologySkill();
                }
            }
        },
        async saveTechnologySkill() {
            try {                    
                console.log(this.technologySkill)
                const response = await this.addTechnologySkill(this.technologySkill);
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.clearData();
                    this.getTechnologySkills();
                    this.$bvModal.hide('modal-1');
                }
            } catch (error) {
                console.log(error);
            }
        },
        assignActivationState: function(item) {
            this.isBusy = true;
            if (item.active == true) {
                this.technologySkill.entity = this.entity;
                this.technologySkill.id = item.id;
                this.technologySkill.active = 0;

                this.updateActivationState();
            } else {
                this.technologySkill.entity = this.entity;
                this.technologySkill.id = item.id;
                this.technologySkill.active = 1;

                this.updateActivationState();
            }
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items.[0];
                console.log(data)
                this.elements.editMode = true;
                this.technologySkill.technologySkillId = data.technologySkillId;
                this.technologySkill.preparedById = data.preparedById;
                this.technologySkill.technology = data.technology;
                this.technologySkill.skillSet = data.skills.split(',');
                this.technologySkill.active = data.active;
                this.technologySkill.preparedDt = data.preparedDt;
                this.technologySkill.id = data.id;
                this.$nextTick(() => {
                    this.$bvModal.show('modal-1');
                })
            }
        },
        async updateActivationState() {
            try {
                const response = await this.updateActivation(this.technologySkill);
                console.log(this.technologySkill.id);
                this.isBusy = false;
                let result = response;
                if (result.status == 200) {
                    iziToast.success({
                        title: 'Updated',
                        position: 'topCenter'
                    });
                }
                // this.elements.showDetail = false;
                this.technologySkill.active = true;
                this.getTechnologySkills();
            } catch (error) {
                this.isBusy = false;
                console.log(error);
            }
        },
        async updateTechnologySkills() {
            try {
                console.log(this.technologySkill)
                const response = await this.updateTechnologySkill(this.technologySkill);
                let result = response.data;
                if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getTechnologySkills();
                        this.$bvModal.hide('modal-1');
                    }
                } catch (error) {
                    console.log(error);
            }
        },
        clearData: function() {
            this.elements.editMode = false;
            this.technologySkill.active = true;
            this.technologySkill.skills = '';
            this.technologySkill.skillSet = [];
            this.technologySkill.preparedById = '';
            this.technologySkill.technology = '';
            this.technologySkill.id = '';
            this.$v.technologySkill.$reset();this.technologySkill.description = '';
            this.technologySkill.createdById = localStorage.getItem("userId");
            this.$v.technologySkill.$reset();
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        arrayToString: function (arrayData) {
            if (arrayData != null && arrayData.length > 0) {
                return arrayData.toString();
            }
            return "";
        },
    }
}
</script>