import ActionItem from '@/views/actionitem/ActionItem.vue'
import PlanMaster from '@/views/plan/PlanMaster.vue'
import PlanMasterList from '@/views/plan/PlanMasterList.vue'
import AddPlan from '@/views/plan/AddPlan.vue'
import PlanList from '@/views/plan/PlanList.vue'
import ViewPlan from '@/views/plan/ViewPlan.vue'
import Task from '@/views/development/Task.vue'
import AddTask from '@/views/development/AddTask.vue'
import EditTask from '@/views/development/EditTask.vue'
import ViewTask from '@/views/development/ViewTask.vue'
import AddTimeSheet from '@/views/task/AddTimeSheet.vue'
import TimeSheetList from '@/views/task/TimeSheetList.vue'
import ViewTimeSheet from '@/views/task/ViewTimeSheet.vue'


export default [
    {
        path: '/actionitem',
        name: 'actionitem',
        component: ActionItem,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/plnmas',
        name: 'plnmas',
        component: PlanMaster,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/plnmas/list',
        name: 'plnmaslist',
        component: PlanMasterList,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/addplan/:ermId',
        name: 'addplan',
        component: AddPlan,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/planlist/:ermId',
        name: 'planlist',
        component: PlanList,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/viewplan/:ermId',
        name: 'viewplan',
        component: ViewPlan,
        meta: { layout: 'viewpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/task',
        name: 'task',
        component: Task,
        meta: { layout: 'ermpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/addtsk',
        name: 'addtsk',
        component: AddTask,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/edittsk',
        name: 'edittsk',
        component: EditTask,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/viewtsk',
        name: 'viewtsk',
        component: ViewTask,
        meta: {  layout: 'viewpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/addtimesheet/:ermId',
        name: 'addtimesheet',
        component: AddTimeSheet,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/timesheetlist/:ermId',
        name: 'timesheetlist',
        component: TimeSheetList,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/viewtimesheet/:ermId',
        name: 'viewtimesheet',
        component: ViewTimeSheet,
        meta: { accessedby: 'PRM|TMB' }
    },
];