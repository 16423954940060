<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <b-overlay :show="showOverlay" opacity="0.4">
                    <section class="chart_section">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class=" mb-4">
                                    <div class="card-body p-0">
                                        <fullcalendar ref="evtCalendar" :key="calendarEventKey" :calendarEvents="filteredEvents" @date-click="handleDateClick" @event-selected="eventSelected" @mouse-hover-event="mouseHoverEvent" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <b-button class="btn floating-btn btn-info" @click="$router.push('/addcalevt')" size="sm">
                        <span class="icon-plus">+</span>
                    </b-button>
                </b-overlay>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import { EventBus } from '@/main';
import dayList from '@/json/daysofweek.json';
export default {
    name: 'app',
    data() {
        return {
            // elements: {
            //     childDataLoaded: false,
            // },
            showOverlay: false,
            showDialog: false,
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            userId: localStorage.getItem("userId"),
            entityRecordMasterId: '',

            calendarEventKey: 0,
            calendarEvent: {
                editMode: false,
                viewMode: null,
                showMOM: false,
                title: '',
                eventDate: this.getDateValue(new Date()),
                startDate: '',
                eventStartDate: this.getDateValue(new Date()),
                eventStartTime: this.getTimeValue(new Date()),
                endDate: '',
                eventEndDate: this.getDateValue(new Date()),
                eventEndTime: this.getTimeValue(new Date()),
                link: '',
                location: '',
                eventTypeId: '',
                eventParticipantTypeId: '',
                eventFrequency: 0,
                statusId: '',
                isAllDayEvent: false,
                daysOfWeek: [],
                hostedById: '',
                description: '',
                createdById: localStorage.getItem("userId"),
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                participantId: localStorage.getItem("userId"),
                entityRecordMasterId: '',
                eventParticipant: [],
                selectedDate: '',
                isMOMRecorder: false,
            },
            days: dayList,
            allEvents: [],
            filteredEvents: [],
            ermLookup: [],
        }
    },
    computed: {
        events() {
            return this.allEvents.map((event)=> {
                if (event.daysOfWeek && event.daysOfWeek !== null) {
                    event['startRecur'] = event['start'];
                    delete event['start'];
                    event['endRecur'] = event['end'];
                    event.defId = 10;
                    delete event['end'];
                    event.daysOfWeek = event.daysOfWeek.split(',');
                    this.filteredEvents = event;
                    return event;
                }
                else {
                    this.filteredEvents = event;
                    return event;
                }
            })
        },
        isHostOrCreator() {
            return this.calendarEvent.hostedById == this.userId || this.calendarEvent.createdById == this.userId;
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'Calendar';
        this.$store.state.ermMasterInfo.title = "Meeting Preparation Master";
        this.$store.state.ermMasterInfo.entity = 'meeting_minute_master';
        this.$store.state.ermMasterInfo.refEntity = 'calendar_event';
        this.getEvents();
        this.lookupData();
        // this.refreshCalendar();
    },
    created() {
        EventBus.$on("changeERM", this.filterEventData);
        EventBus.$on("refreshERMLookup", this.lookupData);
    },
    destroyed() {
        EventBus.$off("changeERM", this.filterEventData);
        EventBus.$off("refreshERMLookup", this.lookupData);
        this.$store.state.ermLookup = [];
    },
    methods: {
        refreshCalendar() {
            // this.$refs.evtCalendar.forceRerender();
        },
        getEvents: function() {
            // this.elements.childDataLoaded = false;
            this.showOverlay = true;
            let data = {
                participantId: this.calendarEvent.participantId
            }
            console.log(data);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/eventparticipant/list', data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.allEvents = result.eventParticipantList;
                    this.$nextTick(() => {
                        this.filterEventData(this.entityRecordMasterId);
                    })
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        lookupData: function() {
            let data = {
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                userId: localStorage.getItem("userId"),
                entity: 'meeting_minute_master'
            }
            this.$store.state.showAppLoader = true;
            axios.post(this.$store.getters.getAPIBasePath + '/lookup/entityerm', data)
                .then((response) => {
                    this.$store.state.showAppLoader = false;
                    let result = response.data;
                    this.ermLookup = result.entityERMLookup;
                    this.$store.state.ermLookup = result.entityERMLookup;
                    if(this.ermLookup && this.ermLookup.length > 0) {
                        this.entityRecordMasterId = this.ermLookup[0].id;
                        this.$store.state.ermId = this.ermLookup[0].id;
                        localStorage.setItem('ermId', this.ermLookup[0].id);
                    }
                    else {
                        this.entityRecordMasterId = '';
                        this.$store.state.ermId = '';
                        localStorage.setItem('ermId', '');
                    }
                })
                .catch(error => {
                    this.$store.state.showAppLoader = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error Occured',
                       position: 'topRight'
                   });
                })
        },
        getParticipantList: function(eventId) {
            let data = {
                eventId: eventId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/eventparticipant/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.calendarEvent.eventParticipant = result.eventParticipantLookup;
                    console.log(result);
                })
        },
        eventSelected: function(event) {
            let calendarProps = event.extendedProps;
            // this.$refs.addCalEventRef.getParticipantList(calendarProps.eventId);
            this.calendarEvent.id = calendarProps.eventId;
            this.calendarEvent.title = event.title;
            this.calendarEvent.isAllDayEvent = event.allDay;
            this.calendarEvent.selectedDate = event._instance.range.start;

            if (event._def.recurringDef) {
                let recurData = event._def.recurringDef.typeData;
                this.calendarEvent.daysToRepeat = [];
                recurData.daysOfWeek.forEach((day) => {
                    this.calendarEvent.daysToRepeat.push(this.days[day]);
                })
                this.calendarEvent.eventStartDate = this.getDateValue(recurData.startRecur);
                this.calendarEvent.eventEndDate = this.getDateValue(recurData.endRecur);
                this.events.forEach((event)=> {
                    if (event.eventId == calendarProps.eventId) {
                        console.log(event.startTime, event.endTime)
                        this.calendarEvent.eventStartTime = event.startTime ? event.startTime.toString().substr(0, 5) : null;
                        this.calendarEvent.eventEndTime = event.endTime ? event.endTime.toString().substr(0, 5) : null;                        
                    }
                });
            } else {
                this.calendarEvent.daysToRepeat = [];
                this.calendarEvent.eventStartDate = event.startStr;
                this.calendarEvent.eventEndDate = event.endStr;
                this.calendarEvent.eventStartTime = this.getTimeValue(event.startStr);
                this.calendarEvent.eventEndTime = this.getTimeValue(event.endStr);
            }
            this.calendarEvent.eventDate = calendarProps.eventDate;
            this.calendarEvent.eventFrequency = calendarProps.eventFrequency ? calendarProps.eventFrequency : 0;
            this.calendarEvent.type = calendarProps.type;
            this.calendarEvent.link = calendarProps.link;
            this.calendarEvent.eventTypeId = calendarProps.eventTypeId;
            this.calendarEvent.description = calendarProps.description;
            this.calendarEvent.status = calendarProps.status;
            this.calendarEvent.statusId = calendarProps.statusId;
            this.calendarEvent.projectId = calendarProps.projectId;
            this.calendarEvent.vhextAccountId = calendarProps.vhextAccountId;
            this.calendarEvent.entityRecordMasterId = calendarProps.entityRecordMasterId;
            this.calendarEvent.createdBy = calendarProps.createdBy;
            this.calendarEvent.hostedBy = calendarProps.hostedBy;
            this.calendarEvent.createdById = calendarProps.createdById;
            this.calendarEvent.hostedById = calendarProps.hostedById;
            this.calendarEvent.eventId = calendarProps.eventId;
            this.calendarEvent.location = calendarProps.location;
            this.calendarEvent.eventParticipantTypeId = calendarProps.eventParticipantTypeId;
            this.calendarEvent.editMode = false;
            this.showDialog = true;
            console.log(this.calendarEvent)
            this.$nextTick(()=> {
                let eventId = moment(String(this.calendarEvent.selectedDate)).format('YYYYMMDD')
                localStorage.setItem("rowData", JSON.stringify(this.calendarEvent));
                localStorage.setItem("ermId", this.calendarEvent.entityRecordMasterId);
                localStorage.setItem("eventId", eventId);
                this.$nextTick(() => {
                    this.$router.push('/viewcalevt');
                });
            })
            console.log(this.calendarEvent.hostedById,this.calendarEvent.createdById,this.userId)
            // if (this.calendarEvent.viewMode == true && !this.isHostOrCreator) {
            //     let eventId = moment(String(this.calendarEvent.selectedDate)).format('YYYYMMDD');
            //     this.$nextTick(()=> {
            //         this.$refs.meetingPrepartionDetailPopupRef.getDetails(this.calendarEvent.id, 'meeting_prepartion', eventId);
            //     })
            // }
            // else if (this.calendarEvent.viewMode == true && !this.isHostOrCreator && this.calendarEvent.eventParticipantTypeId == 2) {
            //     this.calendarEvent.isMOMRecorder = true;
            //     this.$nextTick(()=> {
            //         this.$bvModal.show('addCalEventPopup');
            //     })
            // }
            // else {
            //     this.$nextTick(()=> {
            //         this.$bvModal.show('addCalEventPopup');
            //     })
            // }
        },
        ermFilter: function(ermId) {
            console.log(ermId);
            if (ermId == 'all'|| ermId == '') {
                localStorage.setItem('ermId', '');
                this.filteredEvents = this.events;
            }
            else if (ermId !== null && ermId) {
                localStorage.setItem('ermId', ermId);
                this.entityRecordMasterId = ermId;
                this.filteredEvents = this.events;
                if (this.filteredEvents != null) {
                    var event = this.filteredEvents;
                    this.filteredEvents = event.filter(function(evt) {
                        return evt.entityRecordMasterId == ermId;
                    });
                }
            }
        },
        async filterEventData (ermId) {
            await this.ermFilter(ermId);
            this.calendarEventKey++;
        },
        handleDateClick: function(info) {
            console.log('date-click');
        },
        mouseHoverEvent: function(info) {
            console.log('mouseHoverEvent', info);
        },
        closeDialog: function() {;
            this.showDialog = false;
        },
        combineDateAndTime: function(date, time) {
            console.log(date, time);
            if (date && date !== '') {
                if (time && time !== '') {
                    return moment(date.toString() + 'T' + time.toString());
                } else
                    return moment(date.toString() + 'T' + '00:00');
            }
        },
    }
}
</script>