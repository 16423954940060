<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12">
                            <div class="float-left">
                                <h2 class="text-dark titel-text">Deliverables</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" show-empty @row-selected="onRowSelected" small selectable :select-mode="selectMode" 
                                    sort-icon-left :items="deliverables" :busy="isBusy" :fields="deliverableFields" responsive="sm" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                        <template v-slot:cell(active)="data">
                                            <b-form-checkbox class="m-0" v-model="data.value" @change="assignActivationState(data.item)">
                                            </b-form-checkbox>
                                        </template>
                                        <template  v-slot:empty="scope">
                                            <div class="text-center">No Deliverables Found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="deliverables.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" v-b-modal.modal-1 size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
        <b-modal centered id="modal-1" @hide="clearData" :title="elements.editMode ? 'Edit Deliverable' : 'Add Deliverable'">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group ">
                            <div class="required-field-block" :class="{ 'is-invalid': $v.deliverable.deliverable.$error }">
                                <label for="deliverable" class="inp">
                                    <input type="text" id="deliverable" placeholder=" " v-model="deliverable.deliverable" class="mobile-form-control"  :class="{ 'is-invalid': $v.deliverable.deliverable.$error }">
                                    <span class="label">Deliverable</span>
                                    <span class="focus-bg"></span>
                                </label>
                            </div>
                            <label v-if="$v.deliverable.deliverable.$error && !$v.deliverable.deliverable.minLength" class="text-danger">Deliverable must have at least {{$v.deliverable.deliverable.$params.minLength.min}} letters.</label>
                            <label v-if="$v.deliverable.deliverable.$error && !$v.deliverable.deliverable.required" class="text-danger">Please enter Deliverable</label>
                            <label v-if="$v.deliverable.deliverable.$error && !$v.deliverable.deliverable.maxLength" class="text-danger">Deliverabl must not exceed {{$v.deliverable.deliverable.$params.maxLength.max}} letters.</label>
                        </div>
                    </div> 
                    <div class="col-12">
                        <div class="form-group">
                            <label for="active">Active</label>
                            <div class="input-group ">
                                <b-form-checkbox class="custom-toggle" v-model="deliverable.active" name="check-button" switch>
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div> 
                </div>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <button class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right" @click="$bvModal.hide('modal-1')">
                        Cancel
                    </button>
                    <button size="sm" :disabled="showOverlay" @click="saveData" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right mr-2">
                        {{elements.editMode ? 'Update' : 'Save'}}
                    </button>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsPlan } from "@/mixins/requestsPlan";
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
export default {
    mixins: [requestsPlan],
    data() {
        return {
            elements: {
                editMode: false,
            },
            showOverlay: false,
            isBusy: false,
            deliverables: [],

            deliverable: {
                id: '',
                deliverableId: '',
                entity: '',
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                accountablePersonId: localStorage.getItem("userId"),
                deliverable: '',
                deliverableTypeId: '',
                type: '',
                description: '',
                active: true,
                status: '',
                statusId: '',
                hasReview: '',
                accountablePerson: '',
                createdDt: '',
                lastUpdatedDt: '',
            },
            entity: 'deliverable',

            deliverableFields: [
                { key: 'deliverable', label: 'Deliverable', sortable: true, sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc', thClass: 'text-center', tdClass: 'text-center' },
            ],

            selectMode: 'single',
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
            totalRows: 1,
            
        }
    },
    mounted() {
        this.getAllDeliverables();
    },
    validations: {
        deliverable: {
            deliverable: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(45)
            }
        }
    },
    methods: {
        async getAllDeliverables() {
            let data = {
                projectId: localStorage.getItem("projectId"),
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                accountablePersonId: localStorage.getItem("userId"),
            }
            const response = await this.getDeliverables(data);
            console.log(response);
            let result = response.data;
            console.log(result);
            if (result.deliverableList && result.deliverableList.length != 0) {
                this.deliverables = result.deliverableList;
                
                this.totalRows = this.deliverables.length;
            }
        },
        saveData: function() {
            this.$v.deliverable.$touch();
            console.log(this.deliverable);
            if (!this.$v.deliverable.$invalid) {
                if (this.elements.editMode) {
                    this.updateDeliverableData();
                }
                else this.saveDeliverable();
            }
        },
        async saveDeliverable() {
            try {
                this.showOverlay = true;
                const response = await this.addDeliverable(this.deliverable);
                this.showOverlay = false;
                let result = response.data;
                if (result.status == 'CREATED') {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.clearData();
                    this.getAllDeliverables();
                    this.$bvModal.hide('modal-1');
                }
            } catch (error) {
                this.showOverlay = false;
                console.log(error);
            }
        },
        async updateDeliverableData() {
            try {
                this.showOverlay = true;
                const response = await this.updateDeliverable(this.deliverable);
                this.showOverlay = false;
                let result = response.data;
                this.elements.editMode = false;
                if (result.status == "CREATED") {
                    iziToast.success({
                        title: 'Success',
                        message: result.message,
                        position: 'topRight'
                    });
                    this.clearData();
                    this.getAllDeliverables();
                    this.$bvModal.hide('modal-1');
                }
            } catch (error) {
                this.showOverlay = false;
                console.log(error);
            }
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            console.log(item.active);
            if (item.active == true) {
                this.deliverable.entity = this.entity;
                this.deliverable.id = item.id;
                this.deliverable.active = 0;

                this.updateActivation();

            } else {
                this.deliverable.entity = this.entity;
                this.deliverable.id = item.id;
                this.deliverable.active = 1;

                this.updateActivation();
            }
        },
        async updateActivation() {
            try {
                const response = await this.updateActivationStates(this.deliverable);
                    this.isBusy = false;
                    let result = response;
                    if (result.data.status == 'CREATED') {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    //this.elements.showDetail = false;
                    this.deliverable.active = true;
                    this.getAllDeliverables();
                } catch (error) {
                    this.isBusy = false;
                    console.log(error);
                }
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                console.log(data);
                this.elements.editMode = true;
                this.deliverable.id = data.id;
                this.deliverable.deliverable = data.deliverable;
                this.deliverable.deliverableId = data.deliverableId;
                this.deliverable.deliverableTypeId = data.deliverableTypeId;
                this.deliverable.type = data.type;
                this.deliverable.description = data.description;
                this.deliverable.active = data.active;
                this.deliverable.status = data.status;
                this.deliverable.statusId = data.statusId;
                this.deliverable.hasReview = data.hasReview;
                this.deliverable.accountablePersonId = data.accountablePersonId;
                this.deliverable.accountablePerson = data.accountablePerson;
                this.deliverable.createdDt = data.createdDt;
                this.deliverable.lastUpdatedDt = data.lastUpdatedDt;
                this.$nextTick(()=> {
                    this.$bvModal.show('modal-1');
                });
            }
        },
        clearData: function() {
            this.elements.editMode = false;
            this.deliverable.id = '';
            this.deliverable.deliverable = '';
            this.deliverable.deliverableTypeId = '';
            this.deliverable.statusId = '';
            this.deliverable.accountablePersonId = localStorage.getItem("userId");
            this.deliverable.active = true;
            this.deliverable.description = '';
            this.$v.deliverable.$reset();
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>