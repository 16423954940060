import Contact from '@/views/contact/Contact.vue'
import AddContact from '@/views/contact/AddContact.vue'
import ViewContact from '@/views/contact/ViewContact.vue'
import EditContact from '@/views/contact/EditContact.vue'
import ProjectTeam from '@/views/resource/ProjectTeam.vue'
import AddProjectTeam from '@/views/resource/AddProjectTeam.vue'
import EditProjectTeam from '@/views/resource/EditProjectTeam.vue'
import ViewProjectTeam from '@/views/resource/ViewProjectTeam.vue'
import ProjectTeamMaster from '@/views/resource/ProjectTeamMaster.vue'
import ProjectTeamMasterList from '@/views/resource/ProjectTeamMasterList.vue'
import Resource from '@/views/resource/Resource.vue'
import AddResource from '@/views/resource/AddResource.vue'
import ViewResource from '@/views/resource/ViewResource.vue'
import EditResource from '@/views/resource/EditResource.vue'
import User from '@/views/user/User.vue'
import AddUser from '@/views/user/AddUser.vue'
import ViewUser from '@/views/user/ViewUser.vue'
import EditUser from '@/views/user/EditUser.vue'
import TechnologySkill from '@/views/resource/TechnologySkill.vue'
import RoleResponsibility from '@/views/resource/RoleResponsibility.vue'


export default [{
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/landing/addcontact',
        name: 'addcontact',
        component: AddContact,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/landing/viewcontact/:id',
        name: 'viewcontact',
        component: ViewContact,
        meta: { layout: 'viewpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/landing/editcontact/:id',
        name: 'editcontact',
        component: EditContact,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/prtmas',
        name: 'prtmas',
        component: ProjectTeamMaster,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/prtmas/list',
        name: 'prtmaslist',
        component: ProjectTeamMasterList,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/projectteam/:ermId',
        name: 'projectteam',
        component: ProjectTeam,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/addprojectteam/:ermId',
        name: 'addprojectteam',
        component: AddProjectTeam,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/viewprojectteam',
        name: 'viewprojectteam',
        component: ViewProjectTeam,
        meta: { layout: 'viewpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/editprojectteam',
        name: 'editprojectteam',
        component: EditProjectTeam,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/resource',
        name: 'resource',
        component: Resource,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/landing/addresource',
        name: 'addresource',
        component: AddResource,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/landing/viewresource/:id',
        name: 'viewresource',
        component: ViewResource,
        meta: { layout: 'viewpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/landing/editresource/:id',
        name: 'editresource',
        component: EditResource,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/user',
        name: 'user',
        component: User,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/landing/adduser',
        name: 'adduser',
        component: AddUser,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/landing/viewuser/:id',
        name: 'viewuser',
        component: ViewUser,
        meta: { layout: 'viewpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/landing/edituser/:id',
        name: 'edituser',
        component: EditUser,
        meta: { layout: 'addpage', accessedby: 'PRM|TMB' }
    },
    {
        path: '/technologyskill',
        name: 'technologyskill',
        component: TechnologySkill,
        meta: { accessedby: 'PRM|TMB' }
    },
    {
        path: '/roleresponsibility',
        name: 'roleresponsibility',
        component: RoleResponsibility,
        meta: { accessedby: 'PRM|TMB' }
    },
];