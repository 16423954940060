<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row px-2">
                <div class="card col-12 col-lg-4 col-xl-4 mb-4 ">
                    <div class="profile_header">
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Title</span>
                                    </div>
                                    <div class="col-7">
                                        <select v-model="projectTeam.humanResourceId" id="member" class="view-form">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in humanResources" :key="index" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Position</span>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" v-model="projectTeam.position" class="view-form" placeholder="Title" name="title">
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <!-- <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Phone</span>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" v-model="projectTeam.phone" class="view-form" placeholder="Title" name="title">
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div> -->
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Email</span>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" v-model="projectTeam.emailId" class="view-form" placeholder="Title" name="title">
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Qualification</span>
                                    </div>
                                    <div class="col-7">
                                        <input type="text" v-model="projectTeam.qualification" class="view-form" placeholder="Title" name="title">
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Required From</span>
                                    </div>
                                    <div class="col-7">
                                        <input type="date" v-model="projectTeam.requiredFrom" class="view-form" placeholder="Title" name="title">
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-5">
                                        <span class="font-light">Required Till</span>
                                    </div>
                                    <div class="col-7">
                                        <input type="date" v-model="projectTeam.requiredTill" class="view-form" placeholder="Title" name="title">
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div  class="mx-0 mt-2 mb-0 float-right">
                                    <span class="font-light fs-small">Updated {{ getTimeDuration(projectTeam.lastUpdatedDt) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsResource } from "@/mixins/requestsResource";
import { EventBus } from '@/main';
export default {
    name: 'app',
    mixins: [requestsResource],
    data() {
        return {
            error: false,
            elements: {
                alreadyExists: false,
            },
            projectTeam: {},

            projectTeams: [],
            humanResources: [],
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            entityRecordMasterId: this.$route.params.ermId,
            entity: 'project_team',
            userId: localStorage.getItem("userId"),

            deleteParam: {
                id: 0
            },
        }
    },
    mounted() {
        this.$store.state.pageTitle = 'Edit Member';
        this.projectTeam = JSON.parse(localStorage.getItem("rowData"));
        this.lookupData();
    },
    created() {
        EventBus.$on("savePage", this.updateProjectTeamData);
    },
    destroyed() {
        EventBus.$off("savePage", this.updateProjectTeamData);
    },
    methods: {
        async getProjectTeams() {
            try {
                let data = {
                    entityRecordMasterId: this.entityRecordMasterId,
                }
                const response = await this.getProjectTeamList(data);
                let result = response.data;
                if (result.projectTeamMemberList.length != 0) {
                    this.projectTeams = result.projectTeamMemberList;
                }
            } catch (error) {
                console.log(error);
            }
        },
        async lookupData() {
            try {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId,
                }
                const response = await this.getProjectTeamLookup(data);
                let result = response.data;
                this.humanResources = result.humanResourceLookup;
            } catch (error) {
                console.log(error);
            }
        },
        async updateProjectTeamData() {
            console.log(this.projectTeam);
            if (!(this.checkHRExists(this.projectTeam.humanResourceId)) && !this.checkEmailExists(this.projectTeam.emailId)) {
                try {
                    this.error = false;
                    this.$store.state.showAppLoader = true;
                    const response = await this.updateProjectTeam(this.projectTeam);
                    this.$store.state.showAppLoader = false;
                    let result = response.data;
                    console.log(result);
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.$router.push('/projectteam/' + this.projectTeam.entityRecordMasterId);
                    } else if (result.status == 'OK') {
                        this.elements.alreadyExists = true;
                        this.error = true;
                        iziToast.info({
                            message: result.message,
                            position: 'topRight'
                        });
                    }
                } catch (error) {
                    this.$store.state.showAppLoader = false;
                    console.log(error);
                }
            }
        },
        checkHRExists: function(hrsId) {
            let result = false;
            this.projectTeams.map(data => {
                if (data.humanResourceId == hrsId) {
                    result = true;
                }
            });
            if(result == true) {
                iziToast.info({
                    message: 'Team Member already exist.',
                    position: 'topRight'
                });
            }
            return result;
        },
        checkEmailExists: function(emailId) {
            let result = false;
            this.elements.alreadyExists = false;
            this.error = false;
            this.projectTeams.map(data => {
                if (data.emailId == emailId) {
                    this.elements.alreadyExists = true;
                    this.error = true;
                    result = true;
                }
            });
            return result;
        },
    }
}
</script>