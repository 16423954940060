<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="row px-2">
                <div class="card col-12 col-lg-4 col-xl-4 mb-4 ">
                    <div class="profile_header">
                        <div class="row mt-3">
                            <div class="col-12">
                                <div class="row form-group">
                                    <div class="col-4">
                                        <span class="font-light">Event Title</span>
                                    </div>
                                    <div class="col-8">
                                        <input type="text" v-model="calendarEvent.title" class="view-form" placeholder="Title" name="title">
                                        <label v-if="$v.calendarEvent.title.$error && !$v.calendarEvent.title.minLength" class="text-danger">Event Title must have at least {{$v.calendarEvent.title.$params.minLength.min}} letters.</label>
                                        <label v-if="$v.calendarEvent.title.$error && !$v.calendarEvent.title.required" class="text-danger">Please enter Event Title</label>
                                        <label v-if="$v.calendarEvent.title.$error && !$v.calendarEvent.title.maxLength" class="text-danger">Event Title must not exceed {{$v.calendarEvent.title.$params.maxLength.max}} letters.</label>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-4">
                                        <span class="font-light">Event Group</span>
                                    </div>
                                    <div class="col-8">
                                        <select v-model="calendarEvent.entityRecordMasterId" class="view-form">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in entityERMLookup" :key="index" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <label v-if="$v.calendarEvent.entityRecordMasterId.$error && !$v.calendarEvent.entityRecordMasterId.required" class="text-danger">Please Select a Event Group</label>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-4">
                                        <span class="font-light">Event Type</span>
                                    </div>
                                    <div class="col-8">
                                        <select v-model="calendarEvent.eventTypeId" class="view-form" id="eventTypeId">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in eventTypes" :key="index" :value="info.id">{{info.value}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-4">
                                        <span class="font-light">Occurrence</span>
                                    </div>
                                    <div class="col-8">
                                        <select v-model="calendarEvent.eventFrequency" class="view-form" id="eventFrequency">
                                            <option value="0">Single Day</option>
                                            <option value="1">Recurring</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-4">
                                        <span class="font-light">All Day</span>
                                    </div>
                                    <div class="col-8">
                                        <b-form-checkbox class="custom-toggle pt-1" id="isAllDayEvent" v-model="calendarEvent.isAllDayEvent" name="check-button" switch></b-form-checkbox>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div v-if="calendarEvent.eventFrequency == 0" class="row form-group">
                                    <div class="col-4">
                                        <span class="font-light">Date</span>
                                    </div>
                                    <div class="col-8">
                                        <input type="date" class="view-form" v-model="calendarEvent.eventStartDate" id="eventStartTime">
                                    </div>
                                </div>
                                <template v-else >
                                    <div class="row form-group">
                                        <div class="col-4">
                                            <span class="font-light">Start Date</span>
                                        </div>
                                        <div class="col-8">
                                            <input type="date" class="view-form" v-model="calendarEvent.eventStartDate" id="eventStartTime">
                                        </div>
                                    </div>
                                    <div class="divider-line mb-2"></div>
                                    <div class="row form-group">
                                        <div class="col-4">
                                            <span class="font-light">End Date</span>
                                        </div>
                                        <div class="col-8">
                                            <input type="date" class="view-form" v-model="calendarEvent.eventEndDate" id="eventStartTime">
                                        </div>
                                    </div>
                                </template>
                                <div class="divider-line mb-2"></div>
                                <template v-if="!calendarEvent.isAllDayEvent">
                                    <div class="row form-group">
                                        <div class="col-4">
                                            <span class="font-light">Time</span>
                                        </div>
                                        <div class="col-8">
                                            <div class="form-row">
                                                <b-col cols="5" class="pr-0">
                                                    <input type="time" class="view-form" v-model="calendarEvent.eventStartTime" id="eventStartTime">
                                                </b-col>
                                                <b-col class="px-0 my-auto text-center">
                                                    <label class="my-auto" for="eventEndTime">-</label>
                                                </b-col>
                                                <b-col cols="5" class="pl-0">
                                                    <input type="time" class="view-form" v-model="calendarEvent.eventEndTime" id="eventEndTime">
                                                </b-col>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="divider-line mb-2"></div>
                                </template>
                                <template v-if="calendarEvent.eventFrequency == 1">
                                    <div class="row form-group">
                                        <div class="col-4">
                                            <span class="font-light">Repeat</span>
                                        </div>
                                        <div class="col-8 text-dark">
                                            <span v-for="day,index in calendarEvent.daysToRepeat" :key="index"><span v-if="index!==0">,</span> {{ day }}</span>
                                            <!-- <multiselect v-model="calendarEvent.daysToRepeat" placeholder="Days to repeat" :options="days" name="repeat"  select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" id="daysOfWeek"></multiselect> -->
                                        </div>
                                    </div>
                                    <div class="divider-line mb-2"></div>
                                </template>
                                <div class="row form-group">
                                    <div class="col-4">
                                        <span class="font-light">Location</span>
                                    </div>
                                    <div class="col-8">
                                        <input type="text" class="view-form" v-model="calendarEvent.location" id="eventStartTime">
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-4">
                                        <span class="font-light">Link</span>
                                    </div>
                                    <div class="col-8">
                                        <input type="text" class="view-form" v-model="calendarEvent.link" id="eventStartTime">
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-4">
                                        <span class="font-light">Host</span>
                                    </div>
                                    <div class="col-8">
                                        <select v-model="calendarEvent.hostedById" class="view-form">
                                            <option value="">Select</option>
                                            <option v-for="(info, index) in users" :key="index" :value="info.id">{{info.value}}</option>
                                        </select>
                                        <label v-if="$v.calendarEvent.hostedById.$error && !$v.calendarEvent.hostedById.required" class="text-danger">Please Select a Meeting Host</label>
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group">
                                    <div class="col-4">
                                        <span class="font-light">Participants</span>
                                    </div>
                                    <div class="col-8 text-dark">
                                        <span v-for="participant,index in eventParticipants" :key="index"><span v-if="index!==0">,</span> {{ participant.value }}</span>
                                        <!-- <multiselect v-model="calendarEvent.eventParticipant" placeholder="Add Participants" :options="allParticipants" name="eventParticipant" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false" id="eventParticipant"></multiselect> -->
                                    </div>
                                </div>
                                <div class="divider-line mb-2"></div>
                                <div class="row form-group px-3">
                                    <span class="font-light">Agenda</span>
                                    <label class="description-field" v-html="calendarEvent.description" @click="showDescriptionEdit" ></label>
                                </div>
                                <div  class="mx-0 mt-2 mb-0 float-right">
                                    <span class="font-light fs-small">Updated {{ getTimeDuration(calendarEvent.lastUpdatedDt) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="task-duration" centered title="Task Duration">
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <label>Start Date</label>
                        <div class="input-group">
                            <div class="col-6 pl-0 pr-0">
                                <input type="date" class="form-control" v-model="calendarEvent.estimatedStartDate" id="estimatedStartDt1" required>
                            </div>
                            <div class="col-6 pl-1">
                                <input type="time" class="form-control" v-model="calendarEvent.estimatedStartTime" required>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label>Due Date</label>
                        <div class="input-group">
                            <div class="col-6 pl-0 pr-0">
                                <input type="date" class="form-control" v-model="calendarEvent.estimatedEndDate" id="estimatedStartDt1" required>
                            </div>
                            <div class="col-6 pl-1">
                                <input type="time" class="form-control" v-model="calendarEvent.estimatedEndTime" id="estimatedStartDt1" required>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <template #modal-footer>
                <div class="w-100">
                    <button class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right" @click="closeDialog">Cancel</button>
                    <button size="sm" @click="updateDuration" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right mr-2">Update</button>
                </div>
            </template>
        </b-modal>
        <DescriptionPopup ref="descriptionEditPopup" itemLabel="Description" :itemValue="calendarEvent.description" @updateValue="updateDescription" />
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { requestsTask } from "@/mixins/requestsTask";
import Editor from '@tinymce/tinymce-vue';
import dayList from '@/json/daysofweek.json';
import { EventBus } from '@/main';
import DescriptionPopup from '@/components/popup/action/DescriptionEditPopup.vue';
import Multiselect from 'vue-multiselect';
export default {
    name: 'app',
    components: {
        DescriptionPopup,
        Editor,
        Multiselect
    },
    mixins: [requestsTask],
    data() {
        return {
            calendarEvent: {},
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            userId: localStorage.getItem("userId"),
            entity: 'calendar_event',

            days: dayList,
            eventParticipants: [],
            users: [],
            allParticipants: [],
            eventStatus: [],
            eventTypes: [],
            entityERMLookup: [],
        }
    },
    validations: {
        calendarEvent: {
            title: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            entityRecordMasterId: {
            	required
            },
            hostedById: {
            	required
            },
        },
    },
    mounted() {
        this.$store.state.pageTitle = 'Edit Event';
        this.calendarEvent = JSON.parse(localStorage.getItem("rowData"));
        console.log(this.calendarEvent)
        this.lookupData();
        this.getParticipantList();
    },
    created() {
        EventBus.$on("savePage", this.saveData);
    },
    destroyed() {
        EventBus.$off("savePage", this.saveData);
    },
    methods: {
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                projectTypeId: this.projectTypeId,
                userId: this.userId,
                entity: this.entity,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.users = result.userLookup;
                    this.allParticipants = result.userLookup;
                    this.eventStatus = result.statusLookup;
                    this.eventTypes = result.customTypeLookup;
                    this.entityERMLookup = result.entityERMLookup;
                    console.log(result);
                })
        },
        getParticipantList: function() {
            let data = {
                eventId: this.calendarEvent.id
            }
            axios.post(this.$store.getters.getAPIBasePath + '/communication/eventparticipant/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.eventParticipants = result.eventParticipantLookup;
                    this.calendarEvent.eventParticipant = result.eventParticipantLookup;
                    console.log(result);
                })
        },
        saveData: function() {
        	this.$v.calendarEvent.$touch();
            if (!this.$v.calendarEvent.$invalid) {

	            this.calendarEvent.eventParticipantList = [];
	            for (var index = 0; index < this.calendarEvent.eventParticipant.length; index++) {

	                this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.eventParticipant[index].id, eventParticipantTypeId: 1 });
	            }
	            this.calendarEvent.eventParticipantList = this.calendarEvent.eventParticipantList.filter((participant) => 
            		(participant.participantId != this.calendarEvent.hostedById && participant.participantId != this.calendarEvent.createdById)
            	);
	            if (this.calendarEvent.createdById == this.calendarEvent.hostedById) {
		            this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.createdById, eventParticipantTypeId: 2 });
		        }
		        else {
		            this.calendarEvent.hostedById ? this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.hostedById, eventParticipantTypeId: 2 }) : '';
		            this.calendarEvent.eventParticipantList.push({ participantId: this.calendarEvent.createdById, eventParticipantTypeId: 2 });
		        }
	            
	            this.calendarEvent.startTime = null;
	            this.calendarEvent.endTime = null;
	            if (this.calendarEvent.isAllDayEvent) {
	                this.calendarEvent.startDate = this.getDateValue(this.calendarEvent.eventStartDate);
	                this.calendarEvent.endDate = this.calendarEvent.eventFrequency==0 ? null : this.getDateValue(this.calendarEvent.eventEndDate);
	                if (this.calendarEvent.eventFrequency==0) {
	                	this.calendarEvent.daysOfWeek = null;
	                }
	                else if (this.calendarEvent.daysToRepeat && this.calendarEvent.daysToRepeat.length>0) {
	                    this.calendarEvent.daysOfWeek = [];
	                    this.calendarEvent.daysToRepeat.forEach((day) => {
	                        this.calendarEvent.daysOfWeek.push(this.days.indexOf(day));
	                    })
	                    this.calendarEvent.daysOfWeek = this.calendarEvent.daysOfWeek.toString();
	                } else this.calendarEvent.daysOfWeek = null;
	            } else {
	                if (this.calendarEvent.startDate == '' || this.calendarEvent.startDate == null) { 
	                    this.calendarEvent.startDate = this.combineDateAndTime(this.calendarEvent.eventStartDate, this.calendarEvent.eventStartTime) 
	                }
	                if (this.calendarEvent.endDate == '' || this.calendarEvent.endDate == null) { 
	                    this.calendarEvent.endDate = this.combineDateAndTime(this.calendarEvent.eventEndDate, this.calendarEvent.eventEndTime) 
	                }
	                if (this.calendarEvent.eventFrequency==0) {
	                    this.calendarEvent.endDate = this.combineDateAndTime(this.calendarEvent.eventStartDate, this.calendarEvent.eventEndTime) 
	                }
	                if (this.calendarEvent.daysToRepeat && this.calendarEvent.daysToRepeat.length>0) {
	                     this.calendarEvent.daysOfWeek = [];
	                    this.calendarEvent.daysToRepeat.forEach((day) => {
	                        this.calendarEvent.daysOfWeek.push(this.days.indexOf(day));
	                    })
	                    this.calendarEvent.daysOfWeek = this.calendarEvent.daysOfWeek.toString();
	                    this.calendarEvent.startTime = this.calendarEvent.eventStartTime + ":00";
	                    this.calendarEvent.endTime = this.calendarEvent.eventEndTime + ":00";
	                } else this.calendarEvent.daysOfWeek = null;
	            }
            	this.showOverlay = true;
                this.$nextTick(() => {
                    this.updateEvent();
                })
            }
        },
        updateEvent: function() {
            console.log(this.calendarEvent);
            axios.post(this.$store.getters.getAPIBasePath + '/communication/calendarevent/update', this.calendarEvent)
                .then((response) => {
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        localStorage.setItem("rowData", JSON.stringify(this.calendarEvent));
                    }
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        showDurationEdit(data) {
            this.calendarEvent.estimatedStartDate = this.getDateValue(this.calendarEvent.estimatedStartDt);
            this.calendarEvent.estimatedStartTime = this.getTimeValue(this.calendarEvent.estimatedStartDt);
            this.calendarEvent.estimatedEndDate = this.getDateValue(this.calendarEvent.estimatedEndDt);
            this.calendarEvent.estimatedEndTime = this.getTimeValue(this.calendarEvent.estimatedEndDt);
            console.log(this.calendarEvent);
            this.$nextTick(() => {
                this.$bvModal.show('task-duration');
            })
        },
        updateDuration() {
            this.calendarEvent.estimatedStartDt = this.combineDateAndTime(this.calendarEvent.estimatedStartDate, this.calendarEvent.estimatedStartTime);
            this.calendarEvent.estimatedEndDt = this.combineDateAndTime(this.calendarEvent.estimatedEndDate, this.calendarEvent.estimatedEndTime);
            this.$nextTick(() => {
                this.$bvModal.hide('task-duration');
            })
        },
        showDescriptionEdit() {
            let popupTitle = 'Description';
            let value = this.calendarEvent.description;
            this.$refs.descriptionEditPopup.setDescriptionData(value, popupTitle);
            this.$nextTick(() => {
                this.$bvModal.show('description-edit-popup');
            })
        },
        updateDescription(val) {
            this.calendarEvent.description = val;
            this.$nextTick(() => {
                this.$bvModal.hide('description-edit-popup');
            })
        },
        closeDialog() {
            this.calendarEvent = JSON.parse(localStorage.getItem("rowData"));
            this.$bvModal.hide('task-duration');
        }
    }
}
</script>