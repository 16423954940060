<template>
    <div class="content_wrapper">
        <div class="container-fluid">
            <div class="page-heading pt-0 pb-2">
                <div class="row">
                    <div class="col-12">
                        <div class="float-left">
                            <h2 class="text-dark titel-text">Process</h2>
                        </div>
                    </div>
                </div>
            </div>
            <section class="chart_section">
                <div class="row">
                    <div class="col-12  col-md-6 py-1 px-2">
                        <div class="full_chart card mb-4 p-1">
                            <div class="data-table-rows data-tables-hide-filter">
                                <div class="table-responsive-lg mobile-table">
                                <b-table variant="primary" show-empty small selectable :select-mode="selectMode" :busy="isBusy" 
                                sort-icon-left :items="processListAll" :fields="processFields" responsive="sm" @row-selected="onRowSelected" :current-page="currentPage" :per-page="perPage" 
                                :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                    <template v-slot:cell(title)="data">
                                        <div :class="data.item.title" class="text-overflow-dots">
                                            <p >{{ data.value }} </p>
                                        </div>
                                    </template>
                                    <template v-slot:cell(active)="data">
                                        <b-form-checkbox class="m-0" v-model="data.value" @change="assignActivationState(data.item)">
                                        </b-form-checkbox>
                                    </template>

                                    <template  v-slot:empty="scope">
                                        <div class="text-center">No Process Found</div>
                                    </template>
                                    <template v-slot:emptyfiltered="scope">
                                        <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                    </template>
                                </b-table>
                                <b-pagination v-if="processListAll.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <b-button class="btn floating-btn btn-info" v-b-modal.modal-1 @click="process.projectTypeId = projectTypeId" size="sm">
            <span class="icon-plus">+</span>
        </b-button>
        <b-modal id="modal-1" centered  @hide="clearData" :title="elements.editMode ? 'Update Process' : 'New Process'
">
            <b-overlay :show="showOverlay" spinner-variant="info" opacity="0.4" rounded="sm">
                <div class="col-12">
                    <div class="form-group">
                        <div class="required-field-block" :class="{ 'is-invalid': $v.process.title.$error }">
                            <label for="title" class="inp">
                                <input type="text" id="title" placeholder="  " v-model="process.title" class="mobile-form-control"  :class="{ 'is-invalid': $v.process.title.$error }" >
                                <span class="label">Title</span>
                            </label>
                            <label v-if="$v.process.title.$error && !$v.process.title.required" class="text-danger">Please enter Title</label>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label>Project Type</label>
                        <div class="required-field-block" :class="{ 'is-invalid': $v.process.projectTypeId.$error }">
                            <select v-model="process.projectTypeId" class="form-control" :class="{ 'is-invalid': $v.process.projectTypeId.$error }" @change="handleChange($event)">
                                <option value="">Select</option>
                                <option v-for="(info, index) in projectTypes" :value="info.id" :key="index">{{info.value}}</option>
                            </select>
                        </div>
                        <label v-if="$v.process.projectTypeId.$error && !$v.process.projectTypeId.required" class="text-danger">Please select Project Type</label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label>Activities</label>
                        <multiselect v-model="process.activities" :options="activities" name="activity" label="value" track-by="id" select-label="" deselect-label="" :close-on-select="false" :clear-on-select="false" :multiple="true" :taggable="false"></multiselect>
                    </div>
                </div>
                <p  class="mx-0 my-2">
                    <span class="font-light ml-3 mr-2">Active</span>
                    <b-form-checkbox class="custom-toggle pt-2" v-model="process.active" name="check-button" switch>
                    </b-form-checkbox>
                </p>
            </b-overlay>
            <template #modal-footer>
                <div class="w-100">
                    <button class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right" @click="$bvModal.hide('modal-1')">Cancel</button>
                    <button size="sm" :disabled="showOverlay" @click="saveData" class="btn btn-info mb-0 mr-2 pt-1 pb-1 pl-3 pr-3 mt-3 float-right mr-2">{{ elements.editMode ? 'Update' : 'Save'}} </button>
                </div>
            </template>
        </b-modal>                   
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Multiselect from 'vue-multiselect';
import { requestsPlan } from "@/mixins/requestsPlan";
export default {
    name: 'app',
    mixins: [requestsPlan],
    components: {
        Multiselect
    },
    data() {
        return {
            elements: {
                editMode: false,
                visible: false
            },
            isBusy: false,
            showOverlay: false,
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projectTypeId: localStorage.getItem("projectTypeId"),
            entity: 'process',
            userId: localStorage.getItem("userId"),

            process: {
                id: '',
                processId: '',
                title: '',
                vhextAccountId: '',
                projectId: '',
                projectTypeId: '',
                projectType: '',
                active: true,
                description: '',
                activities: [],
                createdById: localStorage.getItem("userId"),            
            },
            
            notEnabled: true,
            projectTypes: [],
            users: [],
            processListAll: [],
            activities: [],
            tempActivities: [],
            transProps: {
                // Transition name
                name: 'flip-list'
            },
            processFields: [
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'activities', label: 'Activity', sortable: true, thClass: 'text-left', tdClass: 'text-left', sortDirection: 'desc' },
                { key: 'active', label: 'Active', sortable: true, sortDirection: 'desc', thClass: 'text-center', tdClass: 'text-center' },
            ],
            selectMode: 'single',
            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15,20],
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            deleteParam: {
                id: 0
            }
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        this.initialSettings();
        this.getProcess();
        this.lookupData();
    },
    validations: {
        process: {
            title: {
                required,
            },
            projectTypeId: {
                required
            },            
            description: {
                maxLength: maxLength(2000)
            }
        }
    },
    methods: {
        initialSettings: function() {
            this.projectTypeId = localStorage.getItem("projectTypeId");
            if (this.projectTypeId){
                this.process.projectTypeId = this.projectTypeId;
            }
        },
        async getProcess() {
            try {
                let data = {
                    vhextAccountId: this.vhextAccountId,
                    projectId: this.projectId
                }
                const response = await this.getProcesses(data);
                let result = response.data;
                if (result.processListAll && result.processListAll.length > 0) {
                    this.processListAll = result.processListAll;
                    this.totalRows = this.processListAll.length;
                }
                this.clearData();
            }
            catch (error) {
                console.log(error);
            }
        },
        lookupData: function() {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
            }
            axios.post(this.$store.getters.getAPIBasePath + '/process/lookup/all', data)
                .then((response) => {
                    let result = response.data;
                    this.projectTypes = result.projectTypeLookup;
                    this.users = result.userLookup;
                    this.activities = result.activityLookup;
                    this.filteredActivities(this.projectTypeId);
                })
        },
        handleChange(event) {
            this.projectTypeId = event.target.value;
            this.activityKey += 1;
            this.filteredActivities(this.projectTypeId);
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        saveData: function() {
            this.$v.process.$touch();
            if (!this.$v.process.$invalid) {
                if (!this.matchTextValExists(this.process.id, this.process.title)) {
                    this.process.vhextAccountId = this.vhextAccountId;
                    this.process.projectId = this.projectId;
                    this.process.createdById = this.userId;
                    this.process.processActivityList = [];
                    this.process.activities.forEach((data) => {
                        this.process.processActivityList.push({ 
                            activityId: data.id, 
                            activity: data.value,
                            input: data.input ? data.input : '',
                            output: data.output ? data.output : '',
                            task: data.task ? data.task : '',
                            verify: data.verify ? data.verify : '',
                        });
                    })
                    if (this.elements.editMode) {
                        this.updateProcessActivity();
                    } else {
                        this.saveProcessActivity();
                    }
                }
                else {
                    iziToast.info({
                        message: 'Process already exist.',
                        position: 'topCenter'
                    });
                }

            }

        },
        async saveProcessActivity() {
            try {
                this.showOverlay = true;
                const response = await this.addProcess(this.process);
                    this.showOverlay = false;
                    let result = response.data;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getProcess();
                        this.$bvModal.hide('modal-1')
                    }
                    
                } catch (error) {
                    this.showOverlay = false;
                    console.log(error);
                }
        },
        async updateProcessActivity() {
            try {
                this.showOverlay = true;
                const response = await this.updateProcesses(this.process);
                    this.showOverlay = false;
                    let result = response.data;
                    this.elements.editMode = false;
                    if (result.status == "CREATED") {
                        iziToast.success({
                            title: 'Success',
                            message: result.message,
                            position: 'topRight'
                        });
                        this.clearData();
                        this.getProcess();
                        this.$bvModal.hide('modal-1')
                    }
                } catch (error) {
                    this.showOverlay = false;
                    console.log(error);
                }
        },
        
        clearData: function() {
            this.elements.editMode = false;
            this.elements.visible = false;
            this.process.id = '';
            this.process.createdById = localStorage.getItem("userId");
            this.process.description = '';
            this.process.title = '';
            this.process.active = true;
            this.projectTypeId = localStorage.getItem("projectTypeId");
            this.process.activities = [];
            this.$v.process.$reset();
        },
        onRowSelected: function(items) {
            if (items.length > 0){
                let data = items[0];
                console.log(data)
                this.elements.editMode = true;
                this.process.id = data.id;
                this.process.title = data.title;
                this.process.processId = data.processId;
                this.process.projectTypeId = data.projectTypeId;
                this.process.userName = data.userName;
                this.process.createdById = data.createdById;
                this.process.description = data.description;
                this.process.active = data.active;
                this.process.createdDt = data.createdDt;
                this.process.lastUpdatedDt = data.lastUpdatedDt;
                this.projectTypeId = data.projectTypeId;

                this.activityKey += 1;

                this.filteredActivities(this.projectTypeId);
                this.getProcessActivities(data.id);
                this.$nextTick(() => {
                    this.$bvModal.show('modal-1');
                })
            }
        },
        getProcessActivities: function(refErdId) {
            let data = {
                vhextAccountId: this.vhextAccountId,
                projectId: this.projectId,
                refErdId: refErdId,
                refEntity: 'process'
            }
            this.showOverlay = true;
            axios.post(this.$store.getters.getAPIBasePath + '/process/activity/lookup/all',data)
                .then((response) => {
                    this.showOverlay = false;
                    let result = response.data;
                    this.process.activities = result.processActivityLookup;
                })
                .catch(error => {
                    this.showOverlay = false;
                    iziToast.error({
                       title: 'Error',
                       message: 'Error',
                       position: 'topRight'
                   });
                })
        },
        assignActivationState: function(item) {

            this.isBusy = true;
            if (item.active == true) {
                this.process.entity = this.entity;
                this.process.id = item.id;
                this.process.active = 0;

                this.updateActivationState();

            } else {
                this.process.entity = this.entity;
                this.process.id = item.id;
                this.process.active = 1;

                this.updateActivationState();
            }
        },
        async updateActivationState() {
            try {
            const response = await this.updateActivationStates(this.process);
                    this.isBusy = false;
                    let result = response;
                    console.log(result);
                    if (result.data.status == 'CREATED') {
                        iziToast.success({
                            title: 'Updated',
                            position: 'topCenter'
                        });
                    }
                    this.process.active = true;
                    this.getProcess();
                }
                catch (error) {
                    this.isBusy = false;
                    console.log(error);
                }
        },
        matchTextValExists: function(id, textVal) {
            let result = false;
            this.processListAll.map(data => {
                if (textVal && data.title && data.id !== id) {
                    if (data.title.toLowerCase() == textVal.toLowerCase()) {
                        result = true;
                    }
                }
            });
            return result;
        },
        filteredActivities: function(projectTypeId) {
            this.tempActivities = this.activities.filter(data => {

                return (data.projectTypeId == projectTypeId)

            });
        }
    }
}
</script>
