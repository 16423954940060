<template>
    <div>
        <ProjectDocumentMasterList :ermMasterInfo="ermMasterInfo"></ProjectDocumentMasterList>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import ProjectDocumentMasterList from '../../components/ermcomponents/ERMMasterList.vue'
export default {
    name: 'app',
    components: {
        ProjectDocumentMasterList,
    },
    data() {
        return {
            elements: {
                showDetail: false,
                editMode: false,
                showUATMasterList: false
            },
            ermMasterInfo: {
                title: "Project Doc Folders",
                entity: 'project_document_master',
                refEntity: 'project_document',
                tooltip: 'New Folder',
                redirectURLName: 'projectdocument',
                newEntityMasterURL: 'pdcmas',
                redirectURL: '/pdcmas',
                actionName: 'Project Documents',
                previewURLName: 'pdcrep'
            }
        }
    },
    mounted() {},
    methods: {}
}
</script>