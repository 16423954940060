<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12  col-md-6">
                            <div class="float-left">
                                <h1 class="text-dark titel-text">Issue</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" show-empty small selectable :select-mode="selectMode" 
                                    sort-icon-left :items="issues" :fields="issueFields" responsive="sm" @row-selected="onRowSelected" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                        <template v-slot:cell(title)="data">
                                            <div :class="data.item.title" class="text-overflow-dots">
                                                <p >{{ data.value }} </p>
                                                <p v-if="data.item.type" class="fs-small" ><span class="pr-2 font-light">Issue Type</span>{{ data.item.type }} </p>
                                                <p v-if="data.item.functionality" class="fs-small"><span class="pr-2 font-light">Functionality</span>{{ data.item.functionality }} </p>
                                                <p v-if="data.item.activity" class="fs-small"><span class="pr-2 font-light">Activity</span>{{ data.item.activity }} </p>
                                            </div>
                                        </template>
                                        
                                        <template v-slot:cell(status)="data">
                                            <div  class="text-overflow-dots">
                                                <span v-if="data.item.status" class="label label-primary mb-1 mr-1"> {{data.item.status}}</span>
                                                <p v-if="data.item.severity" class="pr-1 " ><span class="pr-2 font-light">Severity</span>{{ data.item.severity }} </p>
                                                <p v-if="data.item.solutionBy" class="pr-1 " ><span class="pr-2 font-light">Soln By</span>{{ data.item.solutionBy }} </p>
                                                <p v-if="data.item.solutionDt" class="pr-1 " ><span class="pr-2 font-light">Soln Date</span>{{ formatDate(data.item.solutionDt) }} </p>
                                            </div>
                                       </template>
                                        <template  v-slot:empty="scope">
                                            <div class="text-center">No Issue Found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="issues.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" @click="$router.push('/addissue/' + entityRecordMasterId)" size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import moment from 'moment';
import { requestsProject } from "@/mixins/requestsProject";
export default {
    mixins: [requestsProject],
    data() {
        return {
            vhextAccountId: localStorage.getItem("vhextAccountId"),
            projectId: localStorage.getItem("projectId"),
            projId: localStorage.getItem("projId"),
            projectName: localStorage.getItem("projectName"),
            entityRecordMasterId: this.$route.params.ermId,
            entity: 'issue',
            userId: localStorage.getItem("userId"),
            htmlData: '',
            htmlDetailViewTitle: 'Issue Detail',
            typeLookupTitle: 'Issue Type Settings',
            issueStatus: [],
            users: [],
            issueTypes: [],
            severities: [],
            functionalities: [],
            issueActivities: [],
            issues: [],
            projectDocument: {
                title: '',
                documentTypeId: '',
                vhextAccountId: localStorage.getItem("vhextAccountId"),
                projectId: localStorage.getItem("projectId"),
                entityRecordMasterId: this.$route.params.ermId,
                preparedById: localStorage.getItem("userId"),
                uploadedById: localStorage.getItem("userId"),
                entity: 'project_document',
                hasAttachment: '',
                statusId: '',
                description: '',
                attachments: []
            },

            issueFields: [
                { key: 'title', label: 'Title', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },
            ],
            selectMode: 'single',
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
           
            totalRows: 1,
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })

        },

    },
    mounted() {
        this.getIssue();
    },
    methods: {
        async getIssue() {
            try {
                let data = {
                    entityRecordMasterId: this.entityRecordMasterId,
                }
                const response = await this.getIssues(data);
                        let result = response.data;
                        if (result.issueList.length != 0) {
                            this.issues = result.issueList;
                            this.totalRows = this.issues.length;
                            console.log(this.issues);
                        }
                    }catch (error) {
                        console.log(error);
                }
            },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },


        onRowSelected: function(items) {
             if (items && items.length > 0){
                let data = items[0];
                console.log(data)
                localStorage.setItem("rowData", JSON.stringify(data));
                this.$nextTick(() => {
                    this.$router.push('/viewIssue/' + data.id);
                });
            }
        },

        filterAccountType: function(individual, company) {
            return this.accountTypes.filter(function(data, key) {
                if (data.value == individual || data.value == company) {
                    return data;
                }
            });
        },
    }
}
</script> 