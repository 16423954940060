<template>
    <div class="">
        <div class="content_wrapper">
            <div class="container-fluid">
                <!-- breadcrumb -->
                <div class="page-heading pt-0 pb-2">
                    <div class="row">
                        <div class="col-12  col-md-6">
                            <div class="float-left">
                                <h1 class="text-dark">Plans</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- breadcrumb_End -->
                <!-- Section -->
                <section class="chart_section">
                    <div class="row">
                        <div class="col-12  col-md-6 py-1 px-2">
                            <div class="full_chart card mb-4 p-1">
                                <div class="data-table-rows data-tables-hide-filter">
                                    <div class="table-responsive-lg mobile-table">
                                    <b-table variant="primary" show-empty small selectable @row-selected="onRowSelected" :select-mode="selectMode" 
                                    sort-icon-left :items="plans" :fields="fields" responsive="sm" :current-page="currentPage" :per-page="perPage" 
                                    :filter="filter" :filterIncludedFields="filterOn" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" 
                                    :sort-direction="sortDirection" @filtered="onFiltered" class="table mb-4" >
                                        <template v-slot:cell(phaseTitle)="data">
                                            <div class="text-overflow-dots">
                                            	<p>
                                                    {{ data.item.phaseTitle }}
                                                </p>
                                            	<p class="fs-small">{{ data.item.startDate }} - {{ data.item.endtDate }}</p>
                                            </div>
                                        </template>
                                        <template  v-slot:empty="scope">
                                            <div class="text-center">No Plans Found</div>
                                        </template>
                                        <template v-slot:emptyfiltered="scope">
                                            <div class="text-center">{{ scope.emptyFilteredText }}</div>
                                        </template>
                                    </b-table>
                                    <b-pagination v-if="plans.length!=0" pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm" class="my-0 pull-right"></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <b-button class="btn floating-btn btn-info" @click="$router.push('/addplan/'+entityRecordMasterId)" size="sm">
                    <span class="icon-plus">+</span>
                </b-button>
            </div>
        </div>
    </div>
</template>
<script>
import iziToast from 'izitoast';
import { requestsPlan } from "@/mixins/requestsPlan";

export default {
    mixins: [requestsPlan],
    data() {
        return {

            entityRecordMasterId: this.$route.params.ermId,
            plans: [],

            fields: [
                { key: 'phaseTitle', label: 'Phase Title', sortable: true, sortDirection: 'desc' },
                { key: 'activity', label: 'Activity', sortable: true, sortDirection: 'desc' },
                { key: 'status', label: 'Status', sortable: true, sortDirection: 'desc' },


            ],
            selectMode: 'single',
            currentPage: 1,
            perPage: 5,
            sortBy: '',
            filter: null,
            filterOn: [],
            sortDesc: false,
            sortDirection: 'asc',
            totalRows: 1,
        }
    },
    mounted() {
        this.getPlans();
    },
    methods: {

        getPlans: function() {
            let data = {
                entityRecordMasterId: this.entityRecordMasterId
            }
            axios.post(this.$store.getters.getAPIBasePath + '/plan/list', data)
                .then((response) => {
                    let result = response.data;
                    console.log(result);
                    this.plans = result.planList;
                    this.totalRows = this.plans.length;

                })
        },
        onRowSelected: function(items) {
             if (items && items.length > 0){
                let data = items[0];
                console.log(data)
                localStorage.setItem("rowData", JSON.stringify(data));
                this.$nextTick(() => {
                    this.$router.push('/viewplan/' + data.id);
                })
            }
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    }
}
</script>